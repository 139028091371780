import React from "react";
import Navbar from "../../navbar/navbar";
import "../../../style/compliance.css";

export default function Privacy() {
  return (
    <>
      <Navbar />
      <div className="compliancePage pagePadding row justify-content-center">
        <div className="col-12 col-md-11 col-lg-10">
          <div className="col-12 align-items-center justify-content-center">
            <h1 style={{ margin: "40px 0px", fontSize: "30px" }}>Privacy Policy</h1>
          </div>

          <div className="col-12">
            <p>
              Gratavid is a online platform provided and moderated by Gratavid, LLC
              (“Gratavid”). This privacy policy (“Policy”) describes how Gratavid
              collects, uses, protects and shares personal information of users of this
              website, www.gratavid.com (the “Site”). Gratavid is an Arkansas limited
              liability company that exists to provide users of the Site with a resource
              to create, share and request personalized videos (“Services”).
            </p>

            <h2>KEY TERMS</h2>
            <p>
              We use additional specific terms in this Policy that have specific meanings.
              These terms have the following meanings:
            </p>
            <ol type="a">
              <li>
                “Contact” is any person or entity in a User’s Distribution List or about
                whom a User has provided us information.
              </li>
              <li>
                “Distribution List” is a list of Contacts a User may upload or manage on
                our Site and all associated information related to those Contacts,
                including names, addresses, telephone numbers and email addresses.
              </li>
              <li>
                “User” means any person or entity that has subscribed to use the Service.
              </li>
              <li>“Visitors” means any person who visits the Site.</li>
            </ol>

            <h1>PRIVACY FOR USERS</h1>
            <p>
              This section applies to the information we collect and process for our Users
              or potential Users of our Services through our Site. If you are not a User,
              the Contacts or Visitors section of this policy may be more applicable to
              you and your information. In this section, “you” and “your” refer to Users
              and potential Users.
            </p>

            <h2>WHAT WE COLLECT & HOW WE USE IT</h2>
            <p>
              We respect your information and our goal is to collect as little user
              information as possible while still providing a quality service to you. We
              receive and store information about individuals in a range of ways.
            </p>
            <ol type="a">
              <li>
                <b>Information You Give Us. </b>
                We collect information when you create an account to subscribe to our
                Services or request a demonstration of our Services, namely your name, and
                contact information, such as your postal address, your email address,
                telephone number. If you have a business account, we also collect your
                business information, social media profile handles, identifying images,
                such as logos or site photographs for a business, and profile information
                as well as other information you directly give us on our Site. If you
                provide personal information in a video you upload to the Site, we will
                collect this information in that video format. We collect information
                necessary to provide the Services, specifically financial information,
                such as your credit card information and billing address, and account
                information, such as username and password. The information we collect
                from you is used to provide the Service to you and to fulfill any
                obligations from us or you in regards to the Service. This information
                includes records and copies of your correspondence with us and details of
                any transactions with us. Specifically, that includes billing you for the
                Service, providing customer service, communicating with you about your
                account, and to enforce compliance with our Terms of Use and applicable
                laws. You also may provide information to be published or displayed
                (hereinafter, “Posted Content”) on public areas of the Site, or
                transmitted to other Users of the Site or third parties (collectively,
                “User Contributions”). Your User Contributions are posted on and
                transmitted to others at your own risk. Although we limit access to
                certain pages/you may set certain privacy settings for such information by
                logging into your account profile, please be aware that no security
                measures are perfect or impenetrable. Additionally, we cannot control the
                actions of other users of the Site with whom you may choose to share your
                User Contributions. Therefore, we cannot and do not guarantee that your
                User Contributions will not be viewed by unauthorized persons.
              </li>

              <li>
                <b>Information Automatically Collected. </b>
                We automatically log information about you and your computer. For example,
                when visiting our Site, we log IP address, date and time of access,
                location information, browser information, operating system information
                and information about your use of and actions on our Site. While you use
                our site, we also collect information related to how you interact with us,
                such as your path through our Site, search terms you may use on the Site,
                or how long you interact with our Site. This information allows us to
                update our services and learn how we can better equip the Site to serve
                you. Finally, we may receive information from you, like a confirmation,
                when you open emails from us if you have an account for our Services. This
                confirmation helps us make our communications with you more interesting
                and helps us improve communications.
              </li>
              <li>
                <b>Cookies. </b>
                We log information using &quot;cookies.&quot; Cookies are small data files
                stored on your hard drive by a website. We may use both session Cookies
                (which expire once you close your web browser) and persistent Cookies
                (which stay on your computer until you delete them) to provide you with a
                more personal and interactive experience on our Site. We also use flash
                cookies (to collect and store information about your preferences and
                navigation to, from, and on our Website) and web beacons (to count users
                who have visited those pages or opened an email and for other related
                website statistics). We may also use the information we obtain to show you
                target advertising. The information we collect automatically through
                cookies is statistical data and does not include personal information, but
                we may maintain it or associate it with personal information we collect in
                other ways or receive from third parties. This type of information is
                collected to make the Site more useful to you and to tailor the experience
                with us to meet your special interests and needs. We may also use the
                information we collect to speed up your searches and help estimate our
                audience and usage patterns. Any data or information we collect will never
                be shared except under the circumstances discussed in this Policy.
              </li>
            </ol>

            <h2>USER DISTRIBUTION LISTS</h2>
            <p>
              We also collect Distribution Lists from you with Contact information. The
              information in your Distribution Lists are necessary for you to use the
              Services to send videos to your Contacts. We use the information in your
              Distribution Lists to provide the Services in accordance with our agreement
              with you or your organization and this Policy.
            </p>
            <p>
              We do not sell your Distribution List. We may contact a Contact in your
              Distribution List if that individual or entity contacts us.
            </p>

            <h2>YOUR CHOICES</h2>
            <p>
              We may contact you to request additional information from you, to notify you
              of opportunities, or to alert you to feedback on your videos. You have a
              choice about whether or not you wish to receive this information from us. If
              you do not want to receive communications from us, then you can delete your
              account by contacting support@gratavid.com.
            </p>

            <h2>SHARING OF PERSONAL INFORMATION</h2>
            <p>
              The goal of Gratavid is to provide a resource to create, share and request
              personalized videos by the users of the Site. If you upload a video to the
              Site, this content may also be viewable by other users of this Site. We do
              not sell or trade your personal information with others. We may share
              personal information with third parties for the following reasons:
            </p>
            <ol type="a">
              <li>
                We may share personal information with your consent. For example, if you
                submit a video or User Contribution, we may share that on our site or with
                your Contacts and/or Distribution List.
              </li>
              <li>
                We may share with permitted third party contractors of Gratavid for the
                purposes of performing our Services and to help us to deliver your videos
                or messages to your contacts (“Data Processors”).
              </li>
              <li>
                We may share aggregate or anonymous data (data that has had personally
                identifying characteristics, like your name or address, removed so that
                you cannot be identified) with third parties for advertising purposes or
                as part of providing our Services.
              </li>
              <li>
                We may share aggregate or anonymous data (data that has had personally
                identifying characteristics, like your name or address, removed so that
                you cannot be identified) with third parties for any lawful purpose,
                without restriction.
              </li>
              <li>
                We may share personal information for legal, protection, and safety
                purposes.
                <ol type="i">
                  <li>We may share information to comply with laws.</li>
                  <li>
                    We may share information to respond to lawful requests and legal
                    processes.
                  </li>
                  <li>
                    We may share information to protect the rights and property of
                    Gratavid, our agents, customers, and others. This includes enforcing
                    our agreements, policies, and terms of use.
                  </li>
                  <li>
                    We may share information to a buyer or other successor in the event of
                    a merger, divestiture, restructuring, reorganization, dissolution, or
                    other sale or transfer of some or all of Gratavid’s assets, whether as
                    a going concern or as part of bankruptcy, liquidation, or similar
                    proceeding, in which personal information held by Gratavid about our
                    Users is among the assets transferred.
                  </li>
                  <li>
                    We may share information in an emergency. This includes protecting the
                    safety of our employees and agents, our customers, or any person.
                  </li>
                </ol>
              </li>
              <li>We may share information with those who need it to do work for us.</li>
            </ol>

            <p>
              When we provide your information and/or personal information to Data
              Processors, we will have in place an agreement with each third party
              confirming on what basis the third party will handle your information and/or
              personal information and will ensure that there are sufficient safeguards
              and processes in place to protect your information and/or personal
              information. We require all third parties to respect the security of your
              information and/or personal information and to treat it in accordance with
              the law and only process that information and/or personal information in
              accordance with our (or our User’s) instructions. The third parties that we
              may send your information and/or personal information to are third parties
              under suitable protection mechanisms as laid out in applicable data
              protection laws.
            </p>

            <h2>INFORMATION CHOICES AND CHANGES</h2>
            <p>
              Our marketing emails tell you how to “unsubscribe.” If you unsubscribe, we
              may still send you non-marketing emails. Non-marketing emails include emails
              about your accounts and our business dealings with you.
            </p>
            <p>
              We will keep your information for as long as you have an account or as long
              as we need it to fulfill the purpose for which it was collected or disclosed
              to you at the time of collection. For instance, we must keep your name,
              financial information, and address for a period of time after your last
              payment for our Services in case there is any need to reimburse you. We may
              also keep some of your information as required to meet legal or regulatory
              requirements, resolve disputes, prevent fraud or abuse, enforce our Terms of
              Use, or for other permissible purposes.
            </p>
            <p>
              If you delete your User Contributions from the Site, copies of your User
              Contributions may remain viewable in cached and archived pages, or might
              have been copied or stored by other Site Users or visitors. Proper access
              and use of information provided on the Site, including User Contributions,
              is governed by our Terms of Use.
            </p>
            <p>
              You may send requests about personal information to our Contact Information
              below or to support@gratavid.com. You can request to change contact choices
              and update your personal information through your Account profile or through
              contacting us at support@gratavid.com.
            </p>
            <p>
              Some users may not like the use of cookies to collect information or may
              choose to change their privacy settings when they use the internet. You can
              typically remove and reject cookies from our Site with your browser
              settings. Many browsers are set to accept cookies until you change your
              settings. If you remove or reject our cookies, it could affect how our Site
              works for you.
            </p>

            <h1>PRIVACY FOR CONTACTS</h1>
            <p>
              This section applies to the information we collect and process for our
              Users’ Contacts as a data controller. Our Service is intended for use by our
              Users. As a result, for much of the information we collect and process about
              Contacts through the Service, we act as a data controller or as a separate
              data processor on behalf of our Users, depending on the service being
              provided and the amount of control Gratavid has over the means of data
              processing.
            </p>
            <p>
              Gratavid is not responsible for the privacy or security practices of our
              Users, which may differ from those set forth in this Policy. Please check
              with individual Users about the policies they have in place. In this
              section, “you” and “your” refer to Contacts.
            </p>

            <h2>WHAT WE COLLECT & HOW WE USE IT</h2>
            <p>We receive and store information about Contacts in a range of ways.</p>
            <ol type="a">
              <li>
                <b>Information Users Provide Us and Information You Provide Us. </b>A User
                may provide information about you as a Contact through the Service. When a
                User provides Contact information or a Distribution List, the User may
                provide us with certain contact information, namely your name, your postal
                address, your email address, telephone number. If you provide personal
                information in a video you upload to the Site, we will collect this
                information in that video format.
              </li>

              <li>
                <b>Information Automatically Collected. </b>
                We automatically log information about you and your computer. For example,
                when visiting our Site, we log IP address, date and time of access,
                location information, browser information, operating system information
                and information about your use of and actions on our Site. While you use
                our site, we also collect information related to how you interact with us,
                such as your path through our Site, search terms you may use on the Site,
                or how long you interact with our Site. This information allows us to
                update our services and learn how we can better equip the Site to serve
                you and our Users. Finally, we may receive information from you, like a
                confirmation, when you open emails from us or our Users. This confirmation
                helps us improve communications.
              </li>
              <li>
                <b>Cookies. </b>
                We log information using &quot;cookies.&quot; Cookies are small data files
                stored on your hard drive by a website. We may use both session Cookies
                (which expire once you close your web browser) and persistent Cookies
                (which stay on your computer until you delete them) to provide you with a
                more personal and interactive experience on our Site. We also use flash
                cookies (to collect and store information about your preferences and
                navigation to, from, and on our Website) and web beacons (to count users
                who have visited those pages or opened an email and for other related
                website statistics). We may also use the information we obtain to show you
                target advertising. The information we collect automatically through
                cookies is statistical data and does not include personal information, but
                we may maintain it or associate it with personal information we collect in
                other ways or receive from third parties. This type of information is
                collected to make the Site more useful to you and to tailor the experience
                with us to meet your special interests and needs. We may also use the
                information we collect to speed up your searches and help estimate our
                audience and usage patterns. Any data or information we collect will never
                be shared except under the circumstances discussed in this Policy.
              </li>
            </ol>

            <h2>SHARING OF PERSONAL INFORMATION</h2>
            <p>
              We do not sell or trade your personal information with others. We may share
              personal information with third parties for the following reasons:
            </p>
            <ol type="a">
              <li>
                We may share personal information with your consent. For example, if you
                submit a video, we may share that on our site.
              </li>
              <li>
                We may share with permitted third party contractors of Gratavid for the
                purposes of performing our Services and to help us to deliver videos or
                messages (“Data Processors”).
              </li>
              <li>
                We may share personal information for legal, protection, and safety
                purposes.
                <ol type="i">
                  <li>We may share information to comply with laws.</li>
                  <li>
                    We may share information to respond to lawful requests and legal
                    processes.
                  </li>
                  <li>
                    We may share information to protect the rights and property of
                    Gratavid, our agents, customers, and others. This includes enforcing
                    our agreements, policies, and terms of use.
                  </li>
                  <li>
                    We may share information to a buyer or other successor in the event of
                    a merger, divestiture, restructuring, reorganization, dissolution, or
                    other sale or transfer of some or all of Gratavid’s assets, whether as
                    a going concern or as part of bankruptcy, liquidation, or similar
                    proceeding, in which personal information held by Gratavid about our
                    Users is among the assets transferred.
                  </li>
                  <li>
                    We may share information in an emergency. This includes protecting the
                    safety of our employees and agents, our customers, or any person.
                  </li>
                </ol>
              </li>
              <li>We may share information with those who need it to do work for us.</li>
            </ol>

            <p>
              When we provide your information and/or personal information to Data
              Processors, we will have in place an agreement with each third party
              confirming on what basis the third party will handle your information and/or
              personal information and will ensure that there are sufficient safeguards
              and processes in place to protect your information and/or personal
              information. We require all third parties to respect the security of your
              information and/or personal information and to treat it in accordance with
              the law and only process that information and/or personal information in
              accordance with our (or our User’s) instructions. The third parties that we
              may send your information and/or personal information to are third parties
              under suitable protection mechanisms as laid out in applicable data
              protection laws.
            </p>

            <h2>INFORMATION CHOICES AND CHANGES</h2>
            <p>
              We keep Contact information as long as we need it to fulfill the purpose for
              which it was collected. For instance, we must keep your name, email address,
              phone number, and mailing address in case we need to alert you in the future
              or as required to meet legal or regulatory requirements, resolve disputes,
              prevent fraud or abuse, enforce our Terms of Use, or for other permissible
              purposes.
            </p>
            <p>
              The majority of information we collect and process about Contacts through
              the Services provided to us as processor on behalf of our Users. If you wish
              to exercise any data protection rights that may be available to you under
              applicable law or have questions or concerns about how your information
              collected, stored, or processed by Gratavid as a processor on behalf of our
              Users, you should contact the relevant User that is using our Services and
              refer to their separate privacy policies.
            </p>
            <p>
              If you no longer want to be contacted by one of our Users through our
              Service, please contact the User directly to update or delete your data. If
              you contact us directly, we may either forward your request to the relevant
              User or provide you with the identity of the User to enable you to contact
              them directly.
            </p>
            <p>
              We respond to all requests we receive from individuals wishing to exercise
              their data protection rights in accordance with applicable data protection
              laws. We may ask you to verify your identity in order to help us respond
              efficiently to your request.
            </p>
            <p>
              You may send requests about personal information to support@gratavid.com.
            </p>
            <p>
              Some users may not like the use of cookies to collect information or may
              choose to change their privacy settings when they use the internet. You can
              typically remove and reject cookies from our Site with your browser
              settings. Many browsers are set to accept cookies until you change your
              settings. If you remove or reject our cookies, it could affect how our Site
              works for you.
            </p>

            <h1>PRIVACY FOR VISITORS</h1>
            <p>
              This section applies to the information we collect and process for passive
              visitors of our Site that have not signed up for an account or request an
              account (“Visitors”). Our Service is intended for use by our Users. In this
              section, “you” and “your” refer to Visitors.
            </p>

            <h2>WHAT WE COLLECT & HOW WE USE IT</h2>
            <p>We receive and store information about Visitors in a range of ways.</p>
            <ol type="a">
              <li>
                <b>Information Automatically Collected. </b>
                We automatically log information about you and your computer. For example,
                when visiting our Site, we log IP address, date and time of access,
                location information, browser information, operating system information
                and information about your use of and actions on our Site. While you use
                our site, we also collect information related to how you interact with us,
                such as your path through our Site, search terms you may use on the Site,
                or how long you interact with our Site. This information allows us to
                update our services and learn how we can better equip the Site to serve
                you and our Users.
              </li>
              <li>
                <b>Cookies. </b>
                We log information using &quot;cookies.&quot; Cookies are small data files
                stored on your hard drive by a website. We may use both session Cookies
                (which expire once you close your web browser) and persistent Cookies
                (which stay on your computer until you delete them) to provide you with a
                more personal and interactive experience on our Site. We also use flash
                cookies (to collect and store information about your preferences and
                navigation to, from, and on our Website) and web beacons (to count users
                who have visited those pages or opened an email and for other related
                website statistics). We may also use the information we obtain to show you
                target advertising. The information we collect automatically through
                cookies is statistical data and does not include personal information, but
                we may maintain it or associate it with personal information we collect in
                other ways or receive from third parties. This type of information is
                collected to make the Site more useful to you and to tailor the experience
                with us to meet your special interests and needs. We may also use the
                information we collect to speed up your searches and help estimate our
                audience and usage patterns. Any data or information we collect will never
                be shared except under the circumstances discussed in this Policy.
              </li>
            </ol>

            <h2>SHARING OF PERSONAL INFORMATION</h2>
            <p>
              The goal of Gratavid is to provide a resource to create, share and request
              personalized videos by the users of the Site. If you upload a video to the
              Site, this content may also be viewable by other users of this Site. We do
              not sell or trade your personal information with others. We may share
              personal information with third parties for the following reasons:
            </p>
            <ol type="a">
              <li>
                We may share personal information with your consent. For example, if you
                submit a video or User Contribution, we may share that on our site or with
                your Contacts and/or Distribution List.
              </li>
              <li>
                We may share with permitted third party contractors of Gratavid for the
                purposes of performing our Services and to help us to deliver your videos
                or messages to your contacts (“Data Processors”).
              </li>
              <li>
                We may share aggregate or anonymous data (data that has had personally
                identifying characteristics, like your name or address, removed so that
                you cannot be identified) with third parties for advertising purposes or
                as part of providing our Services.
              </li>
              <li>
                We may share aggregate or anonymous data (data that has had personally
                identifying characteristics, like your name or address, removed so that
                you cannot be identified) with third parties for any lawful purpose,
                without restriction.
              </li>
              <li>
                We may share personal information for legal, protection, and safety
                purposes.
                <ol type="i">
                  <li>We may share information to comply with laws.</li>
                  <li>
                    We may share information to respond to lawful requests and legal
                    processes.
                  </li>
                  <li>
                    We may share information to protect the rights and property of
                    Gratavid, our agents, customers, and others. This includes enforcing
                    our agreements, policies, and terms of use.
                  </li>
                  <li>
                    We may share information to a buyer or other successor in the event of
                    a merger, divestiture, restructuring, reorganization, dissolution, or
                    other sale or transfer of some or all of Gratavid’s assets, whether as
                    a going concern or as part of bankruptcy, liquidation, or similar
                    proceeding, in which personal information held by Gratavid about our
                    Users is among the assets transferred.
                  </li>
                  <li>
                    We may share information in an emergency. This includes protecting the
                    safety of our employees and agents, our customers, or any person.
                  </li>
                </ol>
              </li>
              <li>We may share information with those who need it to do work for us.</li>
            </ol>
            <h2>SHARING OF PERSONAL INFORMATION</h2>
            <p>
              We do not sell or trade your personal information with others. We may share
              personal information with third parties for the following reasons:
            </p>
            <ol type="a">
              <li>
                We may share aggregate or anonymous data (data that has had personally
                identifying characteristics, like your name or address, removed so that
                you cannot be identified) with third parties for advertising purposes or
                as part of providing our Services
              </li>
              <li>
                We may share personal information for legal, protection, and safety
                purposes.
                <ol type="i">
                  <li>We may share information to comply with laws.</li>
                  <li>
                    We may share information to respond to lawful requests and legal
                    processes.
                  </li>
                  <li>
                    We may share information to protect the rights and property of
                    Gratavid, our agents, customers, and others. This includes enforcing
                    our agreements, policies, and terms of use.
                  </li>
                  <li>
                    We may share information to a buyer or other successor in the event of
                    a merger, divestiture, restructuring, reorganization, dissolution, or
                    other sale or transfer of some or all of Gratavid’s assets, whether as
                    a going concern or as part of bankruptcy, liquidation, or similar
                    proceeding, in which personal information held by Gratavid about our
                    Users is among the assets transferred.
                  </li>
                  <li>
                    We may share information in an emergency. This includes protecting the
                    safety of our employees and agents, our customers, or any person.
                  </li>
                </ol>
              </li>
              <li>We may share information with those who need it to do work for us.</li>
            </ol>

            <h2>INFORMATION CHOICES AND CHANGES</h2>
            <p>
              The information we collect and process about Visitors to the Site is
              provided to us as aggregate information without personally identifying
              information. If you wish to exercise any data protection rights that may be
              available to you under applicable law or have questions or concerns about
              how your information collected, stored, or processed by Gratavid as a
              processor on behalf of our Visitors, you may send requests about personal
              information to support@gratavid.com.
            </p>
            <p>
              Some users may not like the use of cookies to collect information or may
              choose to change their privacy settings when they use the internet. You can
              typically remove and reject cookies from our Site with your browser
              settings. Many browsers are set to accept cookies until you change your
              settings. If you remove or reject our cookies, it could affect how our Site
              works for you.
            </p>

            <h1>GENERAL INFORMATION</h1>
            <h2>STORAGE OF INFORMATION</h2>
            <p>
              Your information collected through the Site is processed in the United
              States. If you are based in the United States and use our Site, you consent
              to have your information processed by Gratavid in the United States.
              International clients have differing needs based on their country of origin
              and we provide custom storage and processing solutions to meet those
              requirements.
            </p>
            <p>
              The security of your information is important to us. We use commercially
              reasonable technical and organizational safeguards to protect your
              information from unauthorized use, disclosure, and loss.
            </p>
            <p>
              We encourage you to take reasonable measures to protect your password and
              your computer to prevent unauthorized access to your account. Data
              transmission over the Internet, mobile networks, wireless transmission or
              electronic storage of information is a risk and cannot be 100% secure. As a
              result, we cannot guarantee or warrant the security of any information you
              transmit to or from our Site, and you provide us with your information at
              your own risk.
            </p>

            <h2>CHILDREN</h2>
            <p>
              The Site exists to provide users of the Site and Services with a resource to
              create, share and request personalized videos. Our Site is not directed to
              children under 13 and we do not knowingly collect personally identifiable
              information directly from children. Please contact us at
              support@gratavid.com if you believe we have inadvertently collected personal
              information from a child under 13 so that we may delete such data as soon as
              possible.
            </p>

            <h2>LICENSE YOU GRANT TO GRATAVID OVER USER CONTENT</h2>
            <p>
              Gratavid allows users, contacts and visitors to create and upload your own
              video messages and replies (“User Content”). User Content posted or uploaded
              to Gratavid belongs to you. However, we need your permission to use User
              Content in order for Gratavid to function. To learn more, visit{" "}
              <a href="/userContentTerms">http://account.gratavid.com/userContentTerms</a>
              .
            </p>

            <h2>DMCA TAKEDOWN POLICY</h2>
            <p>
              If a user has uploaded any information, including but not limited to music,
              sound recordings, videos, and/or photos, to Gratavid that infringes your
              intellectual property rights, you should send us a request to take down the
              content at supprt@gratavid.com. Any such complaints or requests should
              include your name, mailing address, telephone number, email address, and
              physical or electronic signature.
            </p>

            <h2>COPYRIGHT INFRINGEMENT</h2>
            <p>
              If you send us a request to take down content that infringes upon your
              copyright, your request should include:
            </p>
            <ol type="i">
              <li>
                A description of the content that you believe infringes upon your
                copyright
              </li>
              <li>
                The name of the copyrighted work that you believe is being infringed
              </li>
              <li>
                The website URL that contains or is associated with the infringing content
              </li>
              <li>A short explanation of how the content infringes your rights</li>
              <li>
                A statement that you have a good faith belief that the use of the
                copyrighted work is not authorized by the copyright owner, its agent, or
                under the law
              </li>
              <li>
                A statement that the information included in the notification is accurate,
                and under penalty of perjury, that you are authorized to act on behalf of
                the owner of the copyright.
              </li>
            </ol>

            <p>
              Please note that we have designated the following agent for such copyright
              complaints or requests under the Digital Millennium Copyright Act (“DMCA”):
              Gravyty. Copyright complaints and requests can be sent directly to this
              agent at support@gratavid.com. To learn more about the policies and
              requirements of the DMCA, please visit
              http://www.copyright.gov/legislation/dmca.pdf.
            </p>

            <h1>
              INFORMATION FOR VISITORS FROM CALIFORNIA AND OUTSIDE OF THE UNITED STATES
            </h1>
            <h2>PRIVACY RIGHTS FOR CALIFORNIA RESIDENTS</h2>
            <p>
              This Policy describes the categories of personal information we collect from
              you, the methods and business purposes of collecting such personal
              information, the categories of third parties with whom we may share it, and
              the rights you have regarding the personal information.
            </p>
            <p>
              California residents have the right to request that Gratavid disclose what
              personal information it collects, uses, discloses or sells. Gratavid does
              not sell your personal information to third parties. However, under some
              circumstances a transfer of personal information to a third party without
              monetary consideration may be considered a “sale” under California law. If
              you submit a request to stop selling your personal information, we will stop
              making such transfers. If you are a California resident, to opt-out of the
              sale of your personal information, email support@gratavid.com.
            </p>
            <p>
              California residents also have the right to request the deletion of their
              personal information collected or maintained by Gratavid. If you wish to
              make a request for information or to delete your personal information, or
              for any questions about our privacy practices and compliance with California
              law, please contact us via email at support@gratavid.com. Once we receive a
              request for deletion, we will delete the personal information (to the extent
              required by law) we hold about you as of the date of your request from our
              records. In some cases, deletion may be accomplished through
              de-identification of the information. If you choose to delete your personal
              information, you may not be able to use certain website functions that
              require your personal information to operate.
            </p>
            <p>
              We will not discriminate against you for exercising your rights. This means
              we will not deny you products, charge different prices or rates, provide a
              different level of service or quality of products, or suggest that you might
              receive a different price or level of quality for products. Please know, if
              you ask us to delete your information or stop transferring your information,
              it may impact your experience using our Site.
            </p>

            <h2>USERS, CONTACTS, OR VISITORS FROM OUTSIDE OF THE UNITED STATES</h2>
            <p>
              The Site is hosted in the United States. If you are visiting the Site from
              outside the United States, your information may be transferred to, stored
              and processed in the United States in accordance with this Policy and
              applicable U.S. laws. Please note that data protection and other applicable
              laws of the United States may differ from the data protection or consumer
              protection laws in your country. By using the Service, you consent to
              transfer of your information to our facilities as described in this Policy.
            </p>

            <h2>CHANGES TO THIS PRIVACY POLICY</h2>
            <p>
              The Services and our business may change from time to time. As a result, at
              times it may be necessary for Gratavid to make changes to this Privacy
              Policy. Gratavid reserves the right to update or modify this Privacy Policy
              at any time and from time to time. We will notify you by means of a notice
              on this Site of any material changes to this Privacy Policy. Your continued
              use of the Services after any changes or revisions to this Privacy Policy
              shall indicate your agreement with the terms of such revised Privacy Policy.
            </p>

            <h2>END OF PRIVACY POLICY</h2>
            <p>
              Page last modified on August 23rd, 2020. Contact Information:
              support@gratavid.com
            </p>
            <div style={{ height: "40px" }} />
          </div>
        </div>
      </div>
    </>
  );
}
