import React, { useRef, useState } from "react";

export default function VideoPlayer(props) {
  const videoRef = useRef(null);
  let [errorMessage, setErrorMessage] = useState(null);
  let [videoPlaying, setVideoPlaying] = useState(null);
  let [videoDuration, setVideoDuration] = useState(0);
  let [currentTime, setCurrentTime] = useState(0);

  let playVideo = async () => {
    try {
      let video = videoRef.current;
      video.currentTime = currentTime;
      await video.play();
    } catch (err) {
      console.error(err.message);
      setErrorMessage(err.message);
    }
  };

  let stopVideo = async () => {
    try {
      let video = videoRef.current;
      await video.pause();
    } catch (err) {
      console.error(err.message);
      setErrorMessage(err.message);
    }
  };

  //get duration of video
  let videoMetaLoaded = (e) => {
    try {
      let duration = e.target.duration ? e.target.duration : 0;
      setVideoDuration(duration);
    } catch (err) {
      console.error(err.message);
      setErrorMessage(err.message);
    }
  };

  //update play currentTime
  let videoPlayTimeUpdated = (e) => {
    try {
      let time = e.target.currentTime ? e.target.currentTime : 0;
      setCurrentTime(time);
    } catch (err) {
      console.error(err.message);
      setErrorMessage(err.message);
    }
  };

  let updateVideoCurrentTime = (event) => {
    try {
      let time = event.target.value;
      let video = videoRef.current;
      video.currentTime = time;
      setCurrentTime(time);
    } catch (err) {
      console.error(err.message);
      setErrorMessage(err.message);
    }
  };

  const { mediaURL, thumbnail } = props;
  let vidPlayPauseButtonClass;
  let vidRangeInputClass;
  let playButtonWrapperAction;

  if (videoPlaying) {
    vidPlayPauseButtonClass = "videoPlaying_playPauseButton";
    vidRangeInputClass = "videoPlaying_rangeInput";
    playButtonWrapperAction = stopVideo;
  } else {
    vidPlayPauseButtonClass = "videoPaused_playPauseButton";
    vidRangeInputClass = "videoPaused_rangeInput";
    playButtonWrapperAction = playVideo;
  }

  //if any error just default to regualar video player
  if (errorMessage) {
    return (
      <div className="col-12 justify-content-center" style={{ padding: "0px 15px" }}>
        <div
          className="videoContainer"
          style={{ padding: "0!important", borderRadius: "8px!important" }}>
          <div style={{ borderRadius: "8px", position: "relative", overflow: "hidden" }}>
            <div>
              <video
                ref={videoRef}
                id="vidplayer"
                poster={thumbnail}
                controls
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: "500px",
                  borderRadius: "14px",
                  display: "block",
                  position: "relative",
                  visibility: "initial",
                  objectFit: "cover",
                }}>
                <source src={mediaURL} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    );
  }

  let duration = videoDuration ? videoDuration : 0;
  duration = Math.round(duration * 1) / 1;
  let durationMinutes = Math.floor(duration / 60);
  let durationSeconds = duration - durationMinutes * 60;
  durationSeconds = (durationSeconds < 10 ? "0" : "") + durationSeconds;

  let minutesSecondsTimed = Math.round(currentTime * 1) / 1;
  let currentMinutes = Math.floor(minutesSecondsTimed / 60);
  let currentSeconds = minutesSecondsTimed - currentMinutes * 60;
  currentSeconds = (currentSeconds < 10 ? "0" : "") + currentSeconds;

  return (
    <div className="row col-12 justify-content-center" style={{ height: "100%" }}>
      <div
        style={{
          position: "relative",
          cursor: "pointer",
          zIndex: 999,
          height: "100%",
          width: "100%",
          borderRadius: "20px",
          overflow: "hidden",
        }}
        onClick={playButtonWrapperAction}>
        <video
          ref={videoRef}
          id="vidplayer"
          style={{
            width: "100%",
            height: "100%",
            maxHeight: "700px",
            display: "block",
            position: "relative",
            visibility: "initial",
            objectFit: "cover",
            backgroundColor: "lightgrey",
            overflow: "hidden",
          }}
          onLoadedMetadata={(e) => videoMetaLoaded(e)}
          onTimeUpdate={(e) => videoPlayTimeUpdated(e)}
          onPlay={() => setVideoPlaying(true)}
          onPause={() => setVideoPlaying(false)}>
          <source src={mediaURL} type="video/mp4" />
        </video>

        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <div
            style={{
              height: "75px",
              color: "#fff",
              WebkitFilter: "drop-shadow(0 6px 22px rgba(0,0,0,.3))",
              filter: "drop-shadow(0 6px 22px rgba(0,0,0,.3))",
              zIndex: 998,
            }}>
            {videoPlaying ? (
              <img
                className={vidPlayPauseButtonClass + " videoPlayPauseButton"}
                src="https://gratavid.com/static/img/pauseButtonWhite.svg"
                alt="play icon"
                height="75px"
              />
            ) : (
              <img
                className={vidPlayPauseButtonClass + " videoPlayPauseButton"}
                src="https://gratavid.com/static/img/playButtonWhite.svg"
                alt="pause icon"
                height="75px"
              />
            )}
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 10,
            height: "70px",
            display: "flex",
            justifyContent: "center",
            zIndex: 997,
          }}>
          <input
            className={vidRangeInputClass + " videoRangeInput"}
            style={{
              right: "30px",
              width: "85%",
              background: "transparent",
              WebkitAppearance: "none",
            }}
            min="0"
            max={videoDuration}
            type="range"
            step="0.1"
            value={currentTime}
            onChange={updateVideoCurrentTime}
          />
        </div>

        <div
          className={vidRangeInputClass + " videoRangeInput"}
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 10,
            height: "20px",
            display: "flex",
          }}>
          <span
            style={{
              margin: "10px 0px 0px 30px",
              color: "#fff",
              fontWeight: "700",
              fontSize: "15px",
              textShadow: "0 4px 16px rgba(0,0,0,.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            {currentMinutes + ":" + currentSeconds}
          </span>
        </div>

        <div
          className={vidRangeInputClass + " videoRangeInput"}
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 10,
            height: "20px",
            display: "flex",
            justifyContent: "flex-end",
          }}>
          <span
            style={{
              margin: "10px 30px 0px 0px",
              color: "#fff",
              fontWeight: "700",
              fontSize: "15px",
              textShadow: "0 4px 16px rgba(0,0,0,.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            {durationMinutes + ":" + durationSeconds}
          </span>
        </div>
      </div>
    </div>
  );
}
