import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import Branding from "./branding/branding";
import Text from "./text/text";
import { Tabs, Tab } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { getSearchParams } from "util/helpers";
import MergeTags from "./mergeTags";

const AntTabs = withStyles({
  indicator: {
    backgroundColor: "#f76c6c",
  },
})(Tabs);

const AntTab = withStyles(() => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    marginRight: "10px",
  },
  selected: {},
}))((props) => <Tab {...props} />);

export default function ManageSettings(props) {
  const queryParams = getSearchParams();
  const tempTab = queryParams.get("tab") || "branding";
  const [tabSelected, setTabSelected] = useState(tempTab);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!props.userRole.isAdmin) {
      setErrorMessage(
        "You can not view this page. Only admins can manage account settings such as branding."
      );
    }
  }, [props]);

  function a11yProps(index) {
    return {
      id: `task-tab-${index}`,
      "aria-controls": `task-tabpanel-${index}`,
    };
  }

  let tabTextStyle = {
    textTransform: "none",
    color: "rgb(71, 98, 130)",
    fontSize: "14px",
    fontWeight: 600,
  };

  return (
    <div className="row col-12 pagePadding">
      {errorMessage ? (
        <div className="errorMessageBox">
          <b>{errorMessage}</b>
        </div>
      ) : (
        <>
          <div className="row col-12 " style={{ marginBottom: "10px" }}>
            <h1>Account Settings</h1>
          </div>

          <div className="col-12" style={{ borderBottom: "1px #ddd solid" }}>
            <AntTabs
              value={tabSelected}
              onChange={(e, newValue) => setTabSelected(newValue)}
              aria-label="edit video tabs">
              <AntTab
                label="Branding"
                {...a11yProps(0)}
                value="branding"
                style={tabTextStyle}
              />
              <AntTab
                label="Texting"
                {...a11yProps(0)}
                value="text"
                style={tabTextStyle}
              />
              <AntTab
                label="Merge Tags"
                {...a11yProps(0)}
                value="mergeTags"
                style={tabTextStyle}
              />
            </AntTabs>
          </div>

          {tabSelected === "branding" && <Branding {...props} />}
          {tabSelected === "text" && <Text {...props} />}
          {tabSelected === "mergeTags" && <MergeTags {...props} />}
        </>
      )}
    </div>
  );
}

ManageSettings.propTypes = {
  userRole: PropTypes.object,
};
