import React, { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import TextInputToApi from "components/util/hooks/input/textInputToApi";

export default function TextSettings(props) {
  let [open, setOpen] = useState(false);
  let expansionPanelClass = open ? "expansionPanel" : "expansionPanel collapsible";

  return (
    <div className="col-12 row adSectionBox" style={{ marginTop: "10px" }}>
      <div
        className="col-12 row"
        onClick={() => setOpen(!open)}
        style={{ cursor: "pointer" }}>
        <div style={{ flex: 1 }}>
          <h2>Text Settings</h2>
          <span className="greyDesc">Text message body</span>
        </div>
        <div style={{ width: "50px" }}>
          <IconButton onClick={() => setOpen(!open)}>
            <i className="material-icons">{open ? "expand_less" : "expand_more"}</i>
          </IconButton>
        </div>
      </div>

      <div className={"col-12 row " + expansionPanelClass}>
        <div className="row col-12" style={{ margin: "20px 0px 10px 0px" }}>
          <TextInputToApi
            initialValue={props.textMessageBody}
            maxLength={70}
            multiline
            apiCall={props.saveNoteState}
            field="textMessageBody"
            label="Text Message Body"
            customContactProperties={props.customContactProperties}
            variant="standard"
          />
        </div>
      </div>
    </div>
  );
}

TextSettings.propTypes = {
  accountInfo: PropTypes.shape({ id: PropTypes.string }),
  textMessageBody: PropTypes.string,
  customContactProperties: PropTypes.array,
  saveNoteState: PropTypes.func.isRequired,
};
