import React, { useState, useEffect } from "react";
import { Chip } from "@material-ui/core";
import AssigneeUsersDialog from "../assignees/assigneeUsersDialog";
import style from "./assigneeChips.module.css";

export default function AssigneeChips(props) {
  let {
    assignedToUserUID = "",
    selectedCount,
    userId,
    userInfo = {},
    calledFromHistory = false,
  } = props;
  let { fullName = "", email = "" } = userInfo;
  let userLabel = fullName ? fullName : email;
  let [assignTaskDialogOpen, setAssignTaskDialogOpen] = useState(null);
  let [assigneesChips, setAssigneesChips] = useState([
    { userUID: "", label: calledFromHistory ? "All senders" : "All assignees" },
    { userUID: props.userInfo.id, label: userLabel },
  ]);

  useEffect(() => {
    let getData = async () => {
      let initialAssignedToUID = assignedToUserUID;

      try {
        if (initialAssignedToUID === userId || initialAssignedToUID === "") return;

        //if initialAssignedToUID exist and doesn't equal the current user, then a uid was passed in via url
        const user = props.users.find((user) => user.id === initialAssignedToUID);
        setAssigneesChips([
          ...assigneesChips,
          {
            label: user.fullName || user.email,
            userUID: initialAssignedToUID,
          },
        ]);
      } catch (err) {
        console.error(err.message);
        let tempAssignees = [...assigneesChips];
        tempAssignees.push({
          userUID: initialAssignedToUID,
          label: "Deleted User",
          error: true,
        });
        setAssigneesChips(tempAssignees);
      }
    };

    getData();
  }, []);

  return (
    <>
      <div className={style.chips}>
        {assigneesChips.map((current) => {
          let selected = assignedToUserUID === current.userUID;
          return (
            <Chip
              label={current.label}
              key={current.userUID}
              icon={current.error ? <i className="material-icons">error</i> : null}
              className={selected ? style.chipSelected : style.chip}
              onClick={() => props.setAssignedToUserUID(current.userUID)}
            />
          );
        })}

        <Chip
          label="Add"
          className={style.chip}
          icon={<i className="material-icons">add</i>}
          onClick={() => setAssignTaskDialogOpen(true)}
        />
      </div>

      {assignTaskDialogOpen && (
        <AssigneeUsersDialog
          {...props}
          dialogOpen={true}
          closeDialog={() => setAssignTaskDialogOpen(null)}
          selectedCount={selectedCount}
          assigneesChips={assigneesChips}
          setAssigneesChips={setAssigneesChips}
        />
      )}
    </>
  );
}
