import React from "react";
import PropTypes from "prop-types";
import { FormControl, Select, IconButton, InputLabel } from "@material-ui/core";
import SelectItem from "../../util/selectItem";
import SelectList from "../../util/selectList";
import GetListSelectList from "../../util/getListSelectList";
import SelectSinceMonthAndTier from "../../util/selectSinceMonthAndTier";
import transactionAmountsOptions from "../../util/data/transactionAmountsOptions";
import milestoneTransactionTierOptions from "../../util/data/milestoneTransactionTierOptions";
import milestoneYearsOptions from "../../util/data/milestoneYearsOptions";
import milestoneCountsOptions from "../../util/data/milestoneCountsOptions";

export default function Condition(props) {
  let { current, actionIndex, conditionId, actions } = props;
  let { metric, metricValue = "" } = current;

  let handleMetricValueChange = (metric) => {
    if (metric === "transactionAmount")
      handleItemChange("transactionAmount", "", "greaterEqual");
    if (metric === "isFirstTransaction")
      handleItemChange("isFirstTransaction", true, "equal");
    if (metric === "didTransactionAmountIncrease")
      handleItemChange("didTransactionAmountIncrease", true, "equal");
    if (metric === "transactionTypes")
      handleListChange("transactionTypes", [], "includes");
    if (metric === "projects") handleListChange("projects", [], "includes");
    if (metric === "segments") handleListChange("segments", [], "includes");
    if (metric === "milestoneSinceMonthAmountReached")
      handleItemChange(
        "milestoneSinceMonthAmountReached",
        "",
        "milestoneSinceMonthAmountReached"
      );
    if (metric === "milestoneLifetimeAmountReached")
      handleItemChange(
        "milestoneLifetimeAmountReached",
        "",
        "milestoneLifetimeAmountReached"
      );
    if (metric === "milestoneConsecYearsGivingReached")
      handleItemChange(
        "milestoneConsecYearsGivingReached",
        "",
        "milestoneConsecYearsGivingReached"
      );
    if (metric === "milestoneGiftCountReached")
      handleItemChange("milestoneGiftCountReached", "", "milestoneGiftCountReached");
  };

  let handleListChange = (metric, newSelectedItems, operator) => {
    let metricValue = {};
    newSelectedItems.forEach(({ id, label }) => {
      metricValue[id] = { id, label };
    });

    let item = { metric, metricValue, operator };
    let tempActions = { ...actions };
    tempActions.list[actionIndex].conditions[conditionId] = item;
    props.setActions(tempActions);
  };

  let handleItemChange = (metric, value, operator) => {
    let item = { metric, metricValue: value, operator };
    let tempActions = { ...actions };
    tempActions.list[actionIndex].conditions[conditionId] = item;
    props.setActions(tempActions);
  };

  let deleteCondition = () => {
    let tempActions = { ...actions };
    delete tempActions.list[actionIndex].conditions[conditionId];
    props.setActions(tempActions);
  };

  let currentCondition = actions.list[actionIndex].conditions[conditionId];
  let selectedItems =
    currentCondition && currentCondition.metricValue ? currentCondition.metricValue : [];

  let giftAmountOptions = transactionAmountsOptions();
  let tierAmountOptions = milestoneTransactionTierOptions();
  let yearsGivenOptions = milestoneYearsOptions();
  let giftCountsOptions = milestoneCountsOptions("gifts");

  return (
    <div style={{ display: "flex", paddingTop: "5px" }}>
      <div
        style={{
          width: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
          {props.order === 0 ? "IF" : "AND IF"}
        </h3>
      </div>

      <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
        <FormControl style={{ width: "350px", paddingRight: "15px" }} variant="filled">
          <InputLabel id="conditionType">Select Condition</InputLabel>
          <Select
            value={metric}
            native
            onChange={(e) => handleMetricValueChange(e.target.value)}
            inputProps={{
              "aria-label": "Select condition type",
              name: "conditionType",
              id: "conditionType",
            }}>
            <optgroup label="Recommended">
              <option value="transactionAmount">
                The gift amount is greater than or equal to
              </option>
              <option value="isFirstTransaction">
                It&apos;s a households first gift
              </option>
            </optgroup>
            <optgroup label="Milestone reached (i.e. will only trigger once when milestone reached)">
              <option value="milestoneGiftCountReached">
                Households number of gifts reached any of these milestones
              </option>
              <option value="milestoneLifetimeAmountReached">
                Households total lifetime giving reached any of these milestones
              </option>
              <option value="milestoneSinceMonthAmountReached">
                Since MONTH, households cumulative giving reached any of these milestones
              </option>
              <option value="milestoneConsecYearsGivingReached">
                Household has consecutively given for any of these yearly milestones
              </option>
            </optgroup>
            <optgroup label="Additional filtering">
              <option value="didTransactionAmountIncrease">
                The gifts amount is greater than the households&apos;s previous gift
                amount
              </option>
              <option value="projects">The gift is from any of these projects</option>
              <option value="segments">The gift is from any of these segments</option>
            </optgroup>
          </Select>
        </FormControl>

        {metric === "transactionAmount" && (
          <SelectItem
            options={giftAmountOptions}
            selectedItem={metricValue}
            handleChange={(value) =>
              handleItemChange("transactionAmount", value, "greaterEqual")
            }
            label="Amount"
            alt="Select gift amount"
            width="150px"
          />
        )}

        {metric === "projects" && (
          <GetListSelectList
            initialOptions="loading"
            optionsFetchNeeded={true}
            requestData={{ source: "virtuous", event: "getProjectsList" }}
            accountId={props.accountId}
            selectedItems={selectedItems}
            handleChange={(value) => handleListChange("projects", value, "includes")}
            label="Projects"
            width="50%"
          />
        )}
        {metric === "segments" && (
          <GetListSelectList
            initialOptions="loading"
            optionsFetchNeeded={true}
            requestData={{ source: "virtuous", event: "getSegmentsList" }}
            accountId={props.accountId}
            selectedItems={selectedItems}
            handleChange={(value) => handleListChange("segments", value, "includes")}
            label="Segments"
            width="50%"
          />
        )}

        {metric === "milestoneSinceMonthAmountReached" && (
          <SelectSinceMonthAndTier
            selectedItems={selectedItems}
            actions={actions}
            setActions={props.setActions}
            currentCondition={current}
            actionIndex={actionIndex}
            conditionId={conditionId}
          />
        )}
        {metric === "milestoneConsecYearsGivingReached" && (
          <SelectList
            options={yearsGivenOptions}
            selectedItems={selectedItems}
            handleChange={(value) =>
              handleListChange(
                "milestoneConsecYearsGivingReached",
                value,
                "milestoneConsecYearsGivingReached"
              )
            }
            label="Milestones"
            width="50%"
          />
        )}
        {metric === "milestoneGiftCountReached" && (
          <SelectList
            options={giftCountsOptions}
            selectedItems={selectedItems}
            handleChange={(value) =>
              handleListChange(
                "milestoneGiftCountReached",
                value,
                "milestoneGiftCountReached"
              )
            }
            label="Milestones"
            width="50%"
          />
        )}
        {metric === "milestoneLifetimeAmountReached" && (
          <SelectList
            options={tierAmountOptions}
            selectedItems={selectedItems}
            handleChange={(value) =>
              handleListChange(
                "milestoneLifetimeAmountReached",
                value,
                "milestoneLifetimeAmountReached"
              )
            }
            label="Milestones"
            width="50%"
          />
        )}
      </div>
      <div
        style={{
          width: "70px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        {props.order > 0 && (
          <IconButton onClick={() => deleteCondition()} style={{ marginRight: "8px" }}>
            <i className="material-icons" style={{ fontSize: "18px" }}>
              close
            </i>
          </IconButton>
        )}
      </div>
    </div>
  );
}
Condition.propTypes = {
  accountId: PropTypes.string,
  current: PropTypes.shape({
    metric: PropTypes.string,
    metricValue: PropTypes.number,
  }),
  actionIndex: PropTypes.number,
  conditionId: PropTypes.string,
  actions: PropTypes.shape({
    list: PropTypes.array,
  }),
  setActions: PropTypes.func,
  order: PropTypes.number,
};
