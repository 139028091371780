import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import ButtonItem from "./buttonItem";
import TextInputToApi from "components/util/hooks/input/textInputToApi";
import CheckBoxToApi from "components/util/hooks/input/checkBoxToApi";
import QuestionToLink from "../../../../util/explainers/questionToLink";
import { sortableContainer, sortableElement } from "react-sortable-hoc";

const SortableButtonItem = sortableElement((props) => <ButtonItem {...props} />);
const SortableContainer = sortableContainer(({ children }) => (
  <div className="row col-12">{children}</div>
));

export default function CallToAction(props) {
  function addButton() {
    const buttons = [
      ...props.buttons,
      {
        id: crypto.randomUUID(),
        label: "Edit Label",
        link: props.website || "https://example.com",
        color: props.primaryColor,
      },
    ];

    return props.passedSetState("buttons", buttons);
  }

  function deleteButton(index) {
    return props.passedSetState("buttons", props.buttons.toSpliced(index, 1));
  }

  function updateButton(index, field, value) {
    const buttons = [...props.buttons];
    buttons[index][field] = value;
    return props.passedSetState("buttons", buttons);
  }

  function onSortEnd({ oldIndex, newIndex }) {
    if (oldIndex === newIndex) return;
    const buttons = [...props.buttons];
    buttons[oldIndex] = props.buttons[newIndex];
    buttons[newIndex] = props.buttons[oldIndex];
    return props.passedSetState("buttons", buttons);
  }

  //only allow drag is dragging click on the icon
  let cancelDrag = (e) => {
    if (e.srcElement.id === "gvDragHandleIcon") return false;
    return true;
  };

  return (
    <div className="row col-12 adSectionBox" style={{ marginTop: "10px" }}>
      <div className="col-12 row align-items-center">
        <h2 style={{ marginRight: "2px" }}>Call To Action</h2>
        <QuestionToLink
          id="noteCallToAction"
          desc="call to action settings"
          size="small"
        />
      </div>

      <div className="col-12">
        <CheckBoxToApi
          initialValue={props.showEmbeddedForm}
          field="showEmbeddedForm"
          apiCall={props.passedSetState}
          label="Use embedded form"
        />
      </div>

      {props.showEmbeddedForm ? (
        <div className="col-12" style={{ margin: "15px 0px" }}>
          <TextInputToApi
            initialValue={props.embeddedFormCode}
            multiline
            apiCall={props.passedSetState}
            field="embeddedFormCode"
            placeholder="<script><div><div>...."
            label="Embedded Form Code"
            variant="outlined"
          />
        </div>
      ) : (
        <>
          <SortableContainer
            onSortEnd={onSortEnd}
            lockAxis="y"
            distance={1}
            shouldCancelStart={cancelDrag}>
            {props.buttons.map((button, index) => (
              <SortableButtonItem
                key={button.id}
                index={index}
                current={button}
                onDelete={() => deleteButton(index)}
                onChange={(field, value) => updateButton(index, field, value)}
              />
            ))}
          </SortableContainer>

          <div className="col-12" style={{ marginTop: "5px" }}>
            <Button
              variant={props.buttons.length === 0 ? "outlined" : "text"}
              onClick={addButton}>
              <i className="material-icons">add</i>
              Add Button
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

CallToAction.propTypes = {
  website: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
      color: PropTypes.string,
    })
  ),
  primaryColor: PropTypes.string,
  showEmbeddedForm: PropTypes.bool,
  embeddedFormCode: PropTypes.string,
  passedSetState: PropTypes.func,
};
