import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  withMobileDialog,
  IconButton,
  Radio,
  ButtonBase,
} from "@material-ui/core";
import timeSinceDate from "../../util/functions/timeSinceDate";
import LoadingButton from "../../util/buttons/loadingbutton";
import { getNoteHistory } from "services/api.service";

function SendsDialog(props) {
  let { dialogOpen, gvSendId } = props;
  let [sendItems, setSendItems] = useState([]);
  let [count, setCount] = useState(0);
  let [isLoading, setIsLoading] = useState(false);

  const getData = useCallback(
    (offset) => {
      setIsLoading(true);
      getNoteHistory(props.noteId, 5, offset)
        .then((res) => {
          setSendItems((current) => [...current, ...res.rows]);
          setCount(res.count);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [props.accountInfo.id, props.noteId]
  );

  useEffect(() => {
    if (!dialogOpen) {
      setSendItems([]);
      return;
    }
    getData(0);
  }, [dialogOpen, getData]);

  return (
    <Dialog
      fullScreen={props.fullScreen}
      open={dialogOpen}
      onClose={props.closeDialog}
      aria-labelledby="assign task to a user dialog">
      <DialogContent
        style={{ minHeight: "500px", width: props.fullScreen ? "100%" : "600px" }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "18px" }}>Filter by note send history</span>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton aria-label="close" onClick={props.closeDialog}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        <div className="col-12 row" style={{ padding: "5px 0px 20px 0px" }}>
          {isLoading ? (
            <>
              <div
                style={{ width: "60%", height: "20px", marginTop: "12px" }}
                className="col-7 loading-pulse"
              />
              <div
                style={{ width: "60%", height: "20px", marginTop: "12px" }}
                className="col-7 loading-pulse"
              />
              <div
                style={{ width: "60%", height: "20px", marginTop: "12px" }}
                className="col-7 loading-pulse"
              />
            </>
          ) : (
            <>
              <ButtonBase
                className="col-12 dialogSearchItem"
                style={{
                  padding: "10px 0px",
                  margin: "2px 0px",
                  display: "flex",
                  borderRadius: "3px",
                }}
                onClick={() => props.handleAnalyticsSendItemSelected(null, null)}>
                <div
                  style={{
                    width: "36px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <Radio
                    checked={!gvSendId}
                    color="secondary"
                    name="radio-button-demo"
                    inputProps={{ "aria-label": "All note sends" }}
                    size="small"
                  />
                </div>
                <div className="col-12 row align-items-center" style={{ flex: 1 }}>
                  <h3 style={{ marginRight: "2px", fontSize: "14px" }}>All note sends</h3>
                </div>
              </ButtonBase>

              {sendItems.map((item) => {
                const lastStatusUpdate = new Date(item.updatedDate).getTime();
                let formattedLastStatusUpdate = timeSinceDate(lastStatusUpdate, "", "");

                let sentToNumbers = "";
                if (item.uniqueEmails)
                  sentToNumbers = " to " + item.uniqueEmails + " emails";
                if (item.uniqueEmails > 0 && item.uniqueTexts)
                  sentToNumbers += " and to " + item.uniqueTexts + " texts";
                else if (item.uniqueTexts > 0)
                  sentToNumbers = " to " + item.uniqueTexts + " texts";

                let label =
                  (item.resending ? "Resent " : "Sent ") +
                  formattedLastStatusUpdate +
                  sentToNumbers;

                return (
                  <ButtonBase
                    key={item.id}
                    className="col-12 dialogSearchItem"
                    style={{
                      padding: "10px 0px",
                      margin: "2px 0px",
                      display: "flex",
                      borderRadius: "3px",
                    }}
                    onClick={() =>
                      props.handleAnalyticsSendItemSelected(item.gvSendId, label)
                    }>
                    <div
                      style={{
                        width: "36px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}>
                      <Radio
                        checked={gvSendId === item.gvSendId}
                        color="secondary"
                        name="radio-button-demo"
                        inputProps={{ "aria-label": label }}
                        size="small"
                      />
                    </div>

                    <div className="col-12 row align-items-center" style={{ flex: 1 }}>
                      <h3 style={{ marginRight: "2px", fontSize: "14px" }}>{label}</h3>
                      {item.taskId && (
                        <div
                          style={{
                            display: "flex",
                            color: "#636363",
                            fontSize: "12px",
                            background: "#ebebeb",
                            borderRadius: "2px",
                            padding: "5px 8px",
                            marginRight: "7px",
                            alignItems: "center",
                          }}>
                          Task
                        </div>
                      )}
                      {item.resending && (
                        <div
                          style={{
                            display: "flex",
                            color: "#636363",
                            fontSize: "12px",
                            background: "#ebebeb",
                            borderRadius: "2px",
                            padding: "5px 8px",
                            marginRight: "7px",
                            alignItems: "center",
                          }}>
                          Resend
                        </div>
                      )}
                    </div>
                  </ButtonBase>
                );
              })}
            </>
          )}
        </div>

        {count > sendItems.length && (
          <div
            className="col-12 justify-content-center"
            style={{ margin: "10px 0px 20px 0px" }}>
            <LoadingButton
              type="button"
              variant="outlined"
              size="small"
              color="default"
              buttonState={isLoading ? "loading" : null}
              savedLabel={"Loaded"}
              label="Show more"
              clickedRegularButton={() => getData(sendItems.length)}
              clickedSavedButton={() => {
                return null;
              }}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default withMobileDialog()(SendsDialog);

SendsDialog.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  noteId: PropTypes.string.isRequired,
  gvSendId: PropTypes.string,
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  handleAnalyticsSendItemSelected: PropTypes.func.isRequired,
};
