import React from "react";
import { Chip } from "@material-ui/core";

export default function ShowProperties(props) {
  let { properties, id = "bulkImportContacts" } = props;
  let propertyKeys = Object.keys(properties);

  let recommendedProperties = propertyKeys.map((key) => {
    let current = properties[key];
    if (current.recommended !== true) return null;
    return <Chip key={key} label={current.label} style={{ margin: "2px" }} disabled />;
  });

  let optionalColumns = propertyKeys.map((key) => {
    let current = properties[key];
    if (current.recommended === true) return null;
    return <Chip key={key} label={current.label} style={{ margin: "2px" }} disabled />;
  });

  let title = "Prepare a csv file with your customer data (properties)";
  let helpCenterLink =
    "https://graduwayhelp.zendesk.com/hc/en-us/articles/6656237909916-Bulk-Import-Contact-Columns";
  if (id === "bulkImportTasks") {
    title = "Prepare a csv file with your task data";
    helpCenterLink =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6616142484124-Bulk-Upload-Tasks";
  } else if (id === "bulkImportVideoRequest") {
    title = "Prepare a csv file with bulk request data";
    helpCenterLink =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6656199512604-Bulk-Video-Request-Columns";
  } else if (id === "bulkImportRecurringContactItems") {
    title = "Prepare a csv file with your customer data";
    helpCenterLink =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6656237909916-Bulk-Import-Contact-Columns";
  }

  return (
    <>
      <div className="col-12" style={{ padding: "20px 0px 10px 0px" }}>
        <h2>1) {title}</h2>
      </div>

      <div className="col-12" style={{ marginTop: "10px" }}>
        <p style={{ color: "grey", fontSize: "12px", display: "flex" }}>
          Recommended columns
          <a
            href={helpCenterLink}
            rel="noopener noreferrer"
            title="Learn more about the columns you can import"
            target="_blank"
            className="align-items-center">
            <i
              style={{ fontSize: "15px", color: "#8E949D", marginLeft: "4px" }}
              className="material-icons">
              info
            </i>
          </a>
        </p>
        {recommendedProperties}
      </div>
      <div className="col-12" style={{ marginTop: "10px" }}>
        <p style={{ color: "grey", fontSize: "12px", display: "flex" }}>
          Optional columns
          <a
            href={helpCenterLink}
            rel="noopener noreferrer"
            title="Learn more about the columns you can import"
            target="_blank"
            className="align-items-center">
            <i
              style={{ fontSize: "15px", color: "#8E949D", marginLeft: "4px" }}
              className="material-icons">
              info
            </i>
          </a>
        </p>
        {optionalColumns}
      </div>
    </>
  );
}
