import React, { useState, useRef } from "react";
import {
  Button,
  ButtonGroup,
  withMobileDialog,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from "@material-ui/core";
import SendNowDialog from "./sendNowDialog";
import ScheduleDialog from "./scheduleDialog";

function SendNowButton(props) {
  let [dialogOpen, setDialogOpen] = useState(false);
  let [sendOptionOpen, setSendOptionOpen] = useState(false);
  const anchorRef = useRef(null);

  let closeDialog = () => {
    setDialogOpen(false);
  };

  return (
    <div>
      <ButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="send now options">
        <Button onClick={() => setDialogOpen("sendNow")}>Send Now</Button>
        <Button
          style={{ width: "30px", minWidth: "30px" }}
          aria-controls={sendOptionOpen ? "send-options-menu" : undefined}
          aria-expanded={sendOptionOpen ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={() => setSendOptionOpen(!sendOptionOpen)}>
          <i className="material-icons">arrow_drop_down</i>
        </Button>
      </ButtonGroup>
      <Popper
        open={sendOptionOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={{ zIndex: "1301" }}
        placement="top-end">
        <Paper style={{ zIndex: "1302" }}>
          <ClickAwayListener onClickAway={() => setSendOptionOpen(false)}>
            <MenuList id="split-button-menu">
              <MenuItem
                button
                style={{ width: "168px" }}
                onClick={() => {
                  setDialogOpen("scheduleNow");
                  setSendOptionOpen(false);
                }}>
                <i className="material-icons" style={{ marginRight: "5px" }}>
                  schedule_send
                </i>{" "}
                Schedule Send
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>

      {dialogOpen === "sendNow" && (
        <SendNowDialog
          {...props}
          fullScreen={props.fullScreen}
          closeDialog={closeDialog}
          dialogOpen={true}
        />
      )}
      {dialogOpen === "scheduleNow" && (
        <ScheduleDialog
          {...props}
          fullScreen={props.fullScreen}
          closeDialog={closeDialog}
          dialogOpen={true}
        />
      )}
    </div>
  );
}

export default withMobileDialog()(SendNowButton);
