import React, { useState } from "react";
import {
  Button,
  DialogActions,
  Dialog,
  DialogContent,
  IconButton,
  withMobileDialog,
} from "@material-ui/core";
import LoadingButton from "../../../../util/buttons/loadingbutton";
import { updateAccountIntegrations } from "services/api.service";

function DeAuthenticateDialog(props) {
  const { selectedSiteId, selectedSiteDomain } = props;
  const [state, setState] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);

  const disconnectNow = async () => {
    try {
      setState({ buttonState: "loading" });

      // delete connection, but keep events and webhooks
      const updates = [
        {
          path: `endpoint/advance/sites/${selectedSiteId}/connectionInfo`,
          value: null,
        },
        {
          path: `auth/advance/sites/${selectedSiteId}`,
          value: null,
        },
      ];

      await updateAccountIntegrations(updates);

      const tempSites = { ...props.sites };
      delete tempSites[selectedSiteId];

      const siteIds = tempSites ? Object.keys(tempSites) : null;
      const newSelectedMySiteId = siteIds ? siteIds[0] : null;

      props.setSelectedSiteId(newSelectedMySiteId);
      props.setSites(tempSites);
      setState({});
      setDialogOpen(false);
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  const { errorMessage } = state;

  return (
    <>
      <div>
        <Button variant="text" onClick={() => setDialogOpen(true)}>
          <span style={{ textTransform: "none", textDecoration: "underline" }}>
            Disconnect from {selectedSiteDomain}
          </span>
        </Button>
      </div>

      {dialogOpen && (
        <Dialog
          fullScreen={props.fullScreen}
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          aria-labelledby="edit task dialog">
          <DialogContent>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                <span style={{ fontSize: "18px" }}>Disconnect From Advance</span>
              </div>
              <div style={{ width: "48px" }}>
                <IconButton aria-label="close" onClick={() => setDialogOpen(false)}>
                  <i className="material-icons">close</i>
                </IconButton>
              </div>
            </div>

            <p style={{ padding: "20px 0px" }}>
              Are you sure you want to disconnect Gratavid and Advance One? You will lose
              all current integrations and have to login again to reconnect.
            </p>

            {errorMessage && (
              <div className="col-12 errorMessageBox">
                <b>{errorMessage}</b>
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{
              padding: "12px",
              backgroundColor: "hsl(220, 12%, 95%)",
            }}>
            <Button
              variant="outlined"
              color="default"
              onClick={() => setDialogOpen(false)}>
              Close
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              buttonState={state.buttonState}
              savedLabel={"Saved"}
              label={"Disconnect Now"}
              clickedRegularButton={disconnectNow}
              clickedSavedButton={() => {
                setState({});
              }}
            />
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default withMobileDialog()(DeAuthenticateDialog);
