import React from "react";
import PropTypes from "prop-types";
import styles from "./mobile.module.css";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import tutorialConstants from "./tutorial-constants";
import DrawerItem from "./drawerItem";
import { Divider } from "@material-ui/core";
import AccountSwitcher from "components/account/account-switcher";

export default function MobileDrawer(props) {
  const { container, theme, mobileOpen, handleDrawerToggle, listDrawerItems } = props;
  let { title = null, href = null } = tutorialConstants?.[props.pageId] ?? {};

  return (
    <Hidden smUp implementation="css">
      <Drawer
        container={container}
        variant="temporary"
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        className={styles.drawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}>
        <div className={styles.accountSwitcher}>
          <i className="material-icons" title="Available Accounts">
            switch_account
          </i>
          <AccountSwitcher className="mobile-switcher" {...props} />
        </div>
        {listDrawerItems}
        <Divider variant="middle" />
        <DrawerItem
          to="https://graduwayhelp.zendesk.com/hc/en-us/sections/6614353265948-How-To-Guides-"
          icon="help"
          label="Help Center"
          isDesktopOpen={props.desktopOpen}
        />
        <DrawerItem
          to="https://graduwayhelp.zendesk.com/hc/en-us/requests/new"
          icon="support_agent"
          label="Contact Support"
          isDesktopOpen={props.desktopOpen}
        />
        {title && (
          <>
            <Divider variant="middle" />
            <DrawerItem
              to={href}
              icon="play_circle"
              label={title}
              isDesktopOpen={props.desktopOpen}
              isOutlinedIcon={false}
              howTo
            />
          </>
        )}
      </Drawer>
    </Hidden>
  );
}

MobileDrawer.propTypes = {
  isInternalUser: PropTypes.bool,
  accountInfo: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  userInfo: PropTypes.shape({
    roles: PropTypes.array,
  }),
  passedSetStateFromApp: PropTypes.func.isRequired,
  handleAccountChange: PropTypes.func,
};
