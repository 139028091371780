import React, { useState } from "react";
import SelectVideo from "./selectVideo";
const { updateAccountIntegrations } = require("services/api.service");

export default function CampaignItem(props) {
  let { id: campaignId, label = "", accountInfo, matchedVideos, selectedSiteId } = props;
  let [state, setState] = useState({});
  let initCurrentCampaignVideo =
    matchedVideos && matchedVideos[campaignId] && matchedVideos[campaignId][0]
      ? matchedVideos[campaignId][0]
      : null;
  let [currentCampaignVideo, setCurrentCampaignVideo] = useState(
    initCurrentCampaignVideo
  );

  //runs when video is selected or deleted
  let itemSelected = async (item) => {
    let videoData = null;
    if (item) {
      let { title, id, thumbnailUrl } = item;
      videoData = { title, videoId: id, thumbnailUrl };
    }

    try {
      setCurrentCampaignVideo(videoData);

      setState({ buttonState: "loading" });
      const path = `endpoint/advance/sites/${selectedSiteId}/matchedVideos/campaigns/${campaignId}/0/`;
      const value = videoData;
      await updateAccountIntegrations([{ path, value }]);

      let tempSites = { ...props.sites };
      tempSites[selectedSiteId].matchedVideos = tempSites[selectedSiteId].matchedVideos
        ? tempSites[selectedSiteId].matchedVideos
        : {};
      tempSites[selectedSiteId].matchedVideos.campaigns = tempSites[selectedSiteId]
        .matchedVideos.campaigns
        ? tempSites[selectedSiteId].matchedVideos.campaigns
        : {};
      tempSites[selectedSiteId].matchedVideos.campaigns[campaignId] = tempSites[
        selectedSiteId
      ].matchedVideos.campaigns[campaignId]
        ? tempSites[selectedSiteId].matchedVideos.campaigns[campaignId]
        : {};
      tempSites[selectedSiteId].matchedVideos.campaigns[campaignId] = { 0: videoData };

      props.setSites(tempSites);

      setState({ buttonState: "saved" });
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  return (
    <div
      className="col-12 row"
      style={{ padding: 5, borderBottom: "1px solid rgb(221, 221, 221)" }}>
      <div className="col-6" style={{ display: "flex", alignItems: "center" }}>
        {label}
      </div>
      <div className="col-6" style={{ marginBottom: 5 }}>
        <SelectVideo
          {...props}
          itemSelected={itemSelected}
          currentCampaignVideo={currentCampaignVideo}
        />
      </div>
    </div>
  );
}
