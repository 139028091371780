import React from "react";
import LoadingSwitch from "../../util/buttons/loadingSwitch";
import { Tooltip } from "@material-ui/core";

export default function SignInMethods(props) {
  let { signInProviders, setSignInProviders } = props;

  let updateProvider = async (providerKey, newStatus) => {
    setSignInProviders({ ...signInProviders, [providerKey]: newStatus });
  };

  if (!props.isUserAdmin) {
    return (
      <div style={{ paddingTop: "20px" }}>Only admins can manage sign-in methods.</div>
    );
  }

  return (
    <div className="row col-12 align-items-start">
      <div className="col-12" style={{ display: "flex", margin: "20px 0px 5px 0px" }}>
        <div style={{ width: "20px" }} />
        <div style={{ flex: 1 }}>
          <h3 style={{ fontSize: "14px", fontWeight: "bold" }}>Provider</h3>
        </div>
        <div style={{ width: "100px", display: "flex" }}>
          <h3 style={{ fontSize: "14px", fontWeight: "bold" }}>Enabled</h3>
        </div>
      </div>

      <div className="col-12">
        <div
          className="col-12"
          style={{
            display: "flex",
            border: "1px #ddd solid",
            borderRadius: "6px",
            padding: "20px 5px",
            alignItems: "center",
            backgroundColor: "white",
          }}>
          <div className="justify-content-center" style={{ width: "50px" }}>
            <i className="material-icons">vpn_key</i>
          </div>

          <div style={{ flex: 1, display: "flex" }}>
            Email/password
            <Tooltip title="Allow users to sign in using their email address and password. You can't disable this option.">
              <i
                className="material-icons"
                style={{
                  marginLeft: "5px",
                  fontSize: "18px",
                  cursor: "pointer",
                  color: "rgb(142, 148, 157)",
                }}>
                info
              </i>
            </Tooltip>
          </div>
          <div style={{ width: "100px" }}>
            <LoadingSwitch checked={true} disabled={true} />
          </div>
        </div>

        <div
          className="col-12 row"
          style={{
            border: "1px #ddd solid",
            borderRadius: "6px",
            padding: "20px 5px",
            backgroundColor: "white",
          }}>
          <div className="col-12 row" style={{ display: "flex", alignItems: "center" }}>
            <div className="justify-content-center" style={{ width: "50px" }}>
              <img width="20px" alt="Google login" src="/static/img/microsoftLogo.svg" />
            </div>

            <div style={{ flex: 1, display: "flex" }}>
              Microsoft
              <Tooltip title="Allow users to sign in using their their Microsoft work email (single sign-on).">
                <i
                  className="material-icons"
                  style={{
                    marginLeft: "5px",
                    fontSize: "18px",
                    cursor: "pointer",
                    color: "rgb(142, 148, 157)",
                  }}>
                  info
                </i>
              </Tooltip>
            </div>
            <div style={{ width: "100px" }}>
              <LoadingSwitch
                checked={props.signInProviders.microsoftSso}
                handleSwitch={() =>
                  updateProvider("microsoftSso", !props.signInProviders.microsoftSso)
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="col-12 row"
        style={{
          border: "1px #ddd solid",
          borderRadius: "6px",
          padding: "20px 5px",
          backgroundColor: "white",
        }}>
        <div className="col-12 row" style={{ display: "flex", alignItems: "center" }}>
          <div className="justify-content-center" style={{ width: "50px" }}>
            <img width="20px" alt="Google login" src="/static/img/googleIcon.svg" />
          </div>

          <div style={{ flex: 1, display: "flex" }}>
            Google
            <Tooltip title="Allow users to sign in using their their Google work email (single sign-on).">
              <i
                className="material-icons"
                style={{
                  marginLeft: "5px",
                  fontSize: "18px",
                  cursor: "pointer",
                  color: "rgb(142, 148, 157)",
                }}>
                info
              </i>
            </Tooltip>
          </div>
          <div style={{ width: "100px" }}>
            <LoadingSwitch
              checked={props.signInProviders.googleSso}
              handleSwitch={() =>
                updateProvider("googleSso", !props.signInProviders.googleSso)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
