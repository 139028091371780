import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import LoadingButton from "components/util/buttons/loadingbutton";
import { Dialog, TextField, DialogContent, DialogActions } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { cloneNote } from "services/api.service";

function CloneNoteDialog(props) {
  const [state, setState] = useState({});
  const [noteTitle, setNoteTitle] = useState("COPY: " + props.currentItem.title);
  const history = useHistory();

  let submitCloneNote = async () => {
    try {
      if (!noteTitle.trim()) throw new Error("Provide note title");
      if (!props.currentItem.id) throw new Error("Select a note");

      setState({ buttonState: "loading" });
      const data = { title: noteTitle };
      const newNote = await cloneNote(props.currentItem.id, data);
      await history.push(`/a/${props.accountInfo.id}/notes/` + newNote.id);
    } catch (err) {
      console.log(err);
      setState({ errorMessage: err.message });
    }
  };

  return (
    <Dialog
      fullScreen={props.fullScreen}
      open={props.dialogOpen}
      onClose={() => props.setDialogOpen(false)}
      aria-labelledby="clone note dialog">
      <DialogContent
        style={{
          height: props.fullScreen ? "auto" : "550px",
          width: props.fullScreen ? "auto" : "600px",
        }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "18px" }}>Clone Note</span>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton aria-label="close" onClick={() => props.setDialogOpen(false)}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        <div className="col-12" style={{ padding: "10px 0px" }}>
          <TextField
            label="New Note Title"
            id="noteTitle"
            variant="outlined"
            style={{ marginTop: "25px" }}
            onChange={(e) => setNoteTitle(e.target.value)}
            fullWidth
            value={noteTitle}
          />
        </div>
      </DialogContent>

      {state.errorMessage && (
        <div className="errorMessageBox">
          <b>{state.errorMessage}</b>
        </div>
      )}

      <DialogActions style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
        <LoadingButton
          variant="contained"
          color="primary"
          buttonState={state.buttonState}
          savedLabel={"View Note"}
          label={"Create Note"}
          clickedRegularButton={submitCloneNote}
          clickedSavedButton={() => {
            window.open(`/a/${props.accountInfo.id}/notes/` + state.noteId);
          }}
        />
      </DialogActions>
    </Dialog>
  );
}
CloneNoteDialog.propTypes = {
  accountInfo: PropTypes.shape({ id: PropTypes.string }).isRequired,
  currentItem: PropTypes.shape({ id: PropTypes.string, title: PropTypes.string })
    .isRequired,
  fullScreen: PropTypes.bool,
  dialogOpen: PropTypes.bool.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
};

export default withMobileDialog()(CloneNoteDialog);
