import axios from "axios";
const http = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

let idToken = null;

http.interceptors.request.use((config) => {
  if (idToken && idToken.expiration > new Date() && !config.url.startsWith("/auth")) {
    config.headers.common["Authorization"] = "Bearer " + idToken.token;
  }
  return config;
});

http.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (err) => {
    let response;
    if (err.response) {
      // Got response that is not 2xx
      response = err.response;
      response.message = err.data?.message;
    } else if (err.request) {
      // The request was made but no response was received
      response = {
        status: 0,
      };
    } else {
      // Something happened in setting up the request that triggered an Error
      response = {};
      console.error("Error", err.message);
    }
    return Promise.reject(response);
  }
);

export default http;

export function setToken(data) {
  idToken = data;
  if (idToken?.expiration) {
    idToken.expiration = new Date(idToken.expiration);
  }
}
