import React from "react";
import PropTypes from "prop-types";
import styles from "./accountDetailsForm.module.css";

import TextField from "@material-ui/core/TextField";
import { Controller } from "react-hook-form";

const AccountDetailsForm = ({
  isEditMode,
  labels,
  errors,
  touchedFields,
  control,
  isSubmitted,
}) => {
  return (
    <div className={styles.accountDetailsForm}>
      {Object.entries(labels).map(([key, value]) => {
        if (!isEditMode || !value?.hideInEditMode) {
          return (
            <Controller
              key={key}
              name={key}
              control={control}
              render={({ field }) => {
                const onBlur = (e) => {
                  const value = e.target.value;
                  if (typeof value === "string") {
                    const trimmed = value.trim();

                    if (trimmed !== value) {
                      e.target.value = trimmed;
                      field.onChange(e);
                    }
                  }
                  field.onBlur(e);
                };
                const fieldTransform = { ...field, onBlur };
                return (
                  <TextField
                    variant="outlined"
                    size="small"
                    label={value.label || value}
                    error={(isSubmitted || touchedFields[key]) && !!errors[key]}
                    helperText={touchedFields[key] && errors[key]?.message}
                    required={labels[key]?.required}
                    {...fieldTransform}
                  />
                );
              }}
            />
          );
        }
        return "";
      })}
    </div>
  );
};

AccountDetailsForm.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  labels: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  touchedFields: PropTypes.object,
  errors: PropTypes.object,
};

export default AccountDetailsForm;
