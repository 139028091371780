import React from "react";
import "../../../../../style/note.css";
import DisplayNote from "./displayNote";
import PreviewOrEmbedByContact from "../../../../util/shareOptions/previewOrEmbedByContact";

export default function PreviewNote(props) {
  let { noteId, taskId = null, contentId = null, contacts = {} } = props;
  return (
    <>
      <div className="row">
        <div className="col-6">
          <h2
            className="greyDesc"
            style={{ padding: "10px 0px", fontSize: "14px", fontWeight: 500 }}>
            Preview
          </h2>
        </div>
        <div className="col-6 justify-content-end">
          <PreviewOrEmbedByContact
            type="preview"
            title="Preview By Contact"
            accountId={props.accountId}
            noteId={noteId}
            taskId={taskId}
            contentId={contentId}
            taskContacts={contacts}
          />
        </div>
      </div>

      <div
        className="row col-12"
        style={{ padding: "0px", borderRadius: "4px", position: "relative" }}>
        <DisplayNote {...props} />
      </div>
    </>
  );
}
