import React, { useRef, useState } from "react";
import { Fab } from "@material-ui/core";

export default function TrimVideo(props) {
  const playbackBarRef = useRef(null);
  const progressBarRef = useRef(null);
  const videoWrapperRef = useRef(null);
  let [videoPlaying, setVideoPlaying] = useState(false);

  let {
    frontTrimmedRatio,
    setFrontTrimmedRatio,
    endTrimmedRatio,
    setEndTrimmedRatio,
    videoRef,
    rotate,
    setRotate,
  } = props;

  let playVideo = () => {
    let video = videoRef.current;
    let currentTime = video.currentTime;
    let duration = video.duration;
    let startVideoAt = duration * frontTrimmedRatio;
    let stopVideoAt = duration * endTrimmedRatio;

    //if new value is between our two values then update
    if (startVideoAt <= currentTime && currentTime < stopVideoAt) {
      video.currentTime = currentTime;
    } else {
      video.currentTime = startVideoAt;
    }

    setVideoPlaying(true);
    video.play();
  };

  //update play currentTime
  let videoPlayTimeUpdated = (e) => {
    updateProgressBarWidth();

    let video = videoRef.current;
    let seekRatio = video.currentTime / video.duration;
    if (seekRatio >= endTrimmedRatio) {
      setVideoPlaying(false);
      video.pause();
    }
  };

  let seekVideo = (seekRatio) => {
    let video = videoRef.current;
    video.currentTime = video.duration * seekRatio;
  };

  let updateProgressBarWidth = () => {
    let video = videoRef.current;
    let seekRatio = video.currentTime / video.duration;

    let progressBar = progressBarRef.current;
    progressBar.style.width = (seekRatio - frontTrimmedRatio) * 100 + "%";
  };

  //when moused is clicked we have to
  //1) is setup event listersn
  let startGrabberPressed = (event) => {
    window.addEventListener("touchstart", startGrabberMove);
    window.addEventListener("touchmove", startGrabberMove);
    window.addEventListener("touchend", startGrabberDrop);
    window.addEventListener("mousemove", startGrabberMove);
    window.addEventListener("mouseup", startGrabberDrop);
  };

  //2) update video seek
  let startGrabberMove = (event) => {
    let playbackBar = playbackBarRef.current;
    let playbackRect = playbackBar.getBoundingClientRect();
    var ratio =
      ((event.clientX || event.changedTouches?.[0]?.clientX) - playbackRect.left) /
      playbackRect.width;

    let tempFrontTrimmedRatio = frontTrimmedRatio;
    if (ratio <= 0) {
      tempFrontTrimmedRatio = 0;
    } else if (ratio >= endTrimmedRatio) {
      tempFrontTrimmedRatio = endTrimmedRatio;
    } else {
      tempFrontTrimmedRatio = ratio;
    }

    //update position of start grabber
    setFrontTrimmedRatio(tempFrontTrimmedRatio);

    //change witdth of seekbar
    seekVideo(tempFrontTrimmedRatio);
  };

  //3) when released remove listerns
  let startGrabberDrop = (event) => {
    window.removeEventListener("touchstart", startGrabberMove);
    window.removeEventListener("touchmove", startGrabberMove);
    window.removeEventListener("touchend", startGrabberDrop);
    window.removeEventListener("mousemove", startGrabberMove);
    window.removeEventListener("mouseup", startGrabberDrop);
  };

  //when moused is clicked we have to
  //1) is setup event listersn
  let endGrabberPressed = (event) => {
    window.addEventListener("touchstart", endGrabberMove);
    window.addEventListener("touchmove", endGrabberMove);
    window.addEventListener("touchend", endGrabberDrop);
    window.addEventListener("mousemove", endGrabberMove);
    window.addEventListener("mouseup", endGrabberDrop);
  };

  //2) update video seek
  let endGrabberMove = (event) => {
    let playbackBar = playbackBarRef.current;
    let playbackRect = playbackBar.getBoundingClientRect();
    var ratio =
      ((event.clientX || event.changedTouches?.[0]?.clientX) - playbackRect.left) /
      playbackRect.width;

    let tempEndTrimmedRatio = endTrimmedRatio;
    if (ratio >= 1) {
      tempEndTrimmedRatio = 1;
    } else if (ratio <= frontTrimmedRatio) {
      tempEndTrimmedRatio = frontTrimmedRatio;
    } else {
      tempEndTrimmedRatio = ratio;
    }

    //update position of end grabber
    setEndTrimmedRatio(tempEndTrimmedRatio);
  };

  //3) when released remove listerns
  let endGrabberDrop = (event) => {
    window.removeEventListener("touchstart", endGrabberMove);
    window.removeEventListener("touchmove", endGrabberMove);
    window.removeEventListener("touchend", endGrabberDrop);
    window.removeEventListener("mousemove", endGrabberMove);
    window.removeEventListener("mouseup", endGrabberDrop);
  };

  let seekableBarClicked = (event) => {
    let playbackBar = playbackBarRef.current;
    let playbackRect = playbackBar.getBoundingClientRect();
    var ratio = (event.clientX - playbackRect.left) / playbackRect.width;
    seekVideo(ratio);
  };

  let goBackToStart = () => {
    let video = videoRef.current;
    let startVideoAt = video.duration * frontTrimmedRatio;
    video.currentTime = startVideoAt;

    let progressBar = progressBarRef.current;
    progressBar.style.width = "0%";
  };

  let stopVideo = () => {
    let video = videoRef.current;
    setVideoPlaying(false);
    video.pause();
  };

  let handleRotate = () => {
    let tempRotate = rotate; //0, 90, 180, 270
    if (rotate === "0") tempRotate = "270";
    else if (rotate === "270") tempRotate = "180";
    else if (rotate === "180") tempRotate = "90";
    else tempRotate = "0";

    //change width of video
    let videoWrapper = videoWrapperRef.current;
    let videoWrapperRect = videoWrapper.getBoundingClientRect();
    let padding = 20;
    let width = videoWrapperRect.width - padding;
    //let height = videoWrapperRect.height - padding

    let video = videoRef.current;
    video.style.transform = "rotate(" + tempRotate + "deg)";
    if (tempRotate === "270" || tempRotate === "90") {
      video.height = width;
    } else {
      video.width = width;
    }

    setRotate(tempRotate);
  };

  let mediaURL = props.mediaURL;

  let seekableBarStyleLeft = frontTrimmedRatio * 100 + "%";
  let seekableBarStyleRight = (1 - endTrimmedRatio) * 100 + "%";

  let progressBarStyleLeft = frontTrimmedRatio * 100 + "%";
  let progressBarStyleRight = (1 - endTrimmedRatio) * 100 + "%";

  return (
    <div className="col-12">
      <div className="trimmer-wrapper" ref={videoWrapperRef}>
        <video
          className="video"
          width="100%"
          preload="metadata"
          ref={videoRef}
          onTimeUpdate={videoPlayTimeUpdated}>
          <source src={mediaURL} type="video/mp4" />
        </video>

        <div className="trimmer-playback" ref={playbackBarRef}>
          <div
            className="trimmer-grabber trimmer-start"
            style={{ left: frontTrimmedRatio * 100 + "%" }}
            onMouseDown={startGrabberPressed}
            onTouchStart={startGrabberPressed}>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0"
              y="0"
              width="10"
              height="14"
              viewBox="0 0 10 14"
              xmlSpace="preserve">
              <path
                style={{ fill: "rgba(0,0,0,.2)" }}
                d="M1 14L1 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C2 13.6 1.6 14 1 14zM5 14L5 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C6 13.6 5.6 14 5 14zM9 14L9 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C10 13.6 9.6 14 9 14z"
              />
            </svg>
          </div>
          <div
            className="trimmer-grabber trimmer-end"
            style={{ left: endTrimmedRatio * 100 + "%" }}
            onMouseDown={endGrabberPressed}
            onTouchStart={endGrabberPressed}>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0"
              y="0"
              width="10"
              height="14"
              viewBox="0 0 10 14"
              xmlSpace="preserve">
              <path
                style={{ fill: "rgba(0,0,0,.2)" }}
                d="M1 14L1 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C2 13.6 1.6 14 1 14zM5 14L5 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C6 13.6 5.6 14 5 14zM9 14L9 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C10 13.6 9.6 14 9 14z"
              />
            </svg>
          </div>
          <div
            className="trimmer-seekable"
            style={{ left: seekableBarStyleLeft, right: seekableBarStyleRight }}
            onClick={seekableBarClicked}></div>
          <div
            className="trimmer-progress"
            ref={progressBarRef}
            style={{
              left: progressBarStyleLeft,
              right: progressBarStyleRight,
              width: "0%",
            }}></div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            textAlign: "center",
          }}>
          <div style={{ width: "50px" }} />
          <div className="player-controls">
            <Fab
              aria-label="Skip back to start"
              color="default"
              size="small"
              onClick={goBackToStart}>
              <i className="material-icons" style={{ color: "black" }}>
                skip_previous
              </i>
            </Fab>
            {videoPlaying ? (
              <Fab
                aria-label="Play"
                color="default"
                size="small"
                onClick={stopVideo}
                style={{ marginLeft: "10px" }}>
                <i className="material-icons" style={{ color: "black" }}>
                  pause
                </i>
              </Fab>
            ) : (
              <Fab
                aria-label="pause"
                color="default"
                size="small"
                onClick={playVideo}
                style={{ marginLeft: "10px" }}>
                <i className="material-icons" style={{ color: "black" }}>
                  play_arrow
                </i>
              </Fab>
            )}
          </div>
          <div>
            <Fab
              aria-label="pause"
              color="default"
              size="small"
              onClick={handleRotate}
              style={{ marginLeft: "10px" }}>
              <i className="material-icons" style={{ color: "black" }}>
                screen_rotation
              </i>
            </Fab>
          </div>
        </div>
      </div>
    </div>
  );
}
