import React, { useRef, useState, useEffect, useCallback } from "react";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  Dialog,
  withMobileDialog,
} from "@material-ui/core";
import { debounce } from "util/helpers";

function TextFieldQuestion(props) {
  let inputRef = useRef(null);
  let [modalOpen, setModalOpen] = useState(false);
  const [state, setState] = useState(props.value || "");

  const debouncedSetState = useCallback(
    debounce((value) => props.passedSetState(value)),
    [props]
  );

  function onChange(e) {
    const value = e.target.value;
    setState(value);
    debouncedSetState(value);
  }

  let loadingComp;
  if (!props.isLoading) {
    loadingComp = <></>;
  } else if (props.isLoading === "saved") {
    loadingComp = (
      <i
        className="material-icons"
        style={{ color: "green", fontSize: "15px", margin: "0px 4px 0px 6px" }}>
        check_circle
      </i>
    );
  } else if (props.isLoading) {
    loadingComp = (
      <CircularProgress
        style={{ width: "15px", height: "15px", margin: "0px 4px 0px 6px" }}
      />
    );
  }

  return (
    <>
      <div className="col-12" style={{ marginTop: "10px" }}>
        <FormControl fullWidth={props.fullWidth}>
          <InputLabel htmlFor={props.for}>
            {
              <div className="align-items-center">
                <>{props.label}</>
                <>{loadingComp}</>
              </div>
            }
          </InputLabel>
          <Input
            type="text"
            multiline={props.multiline}
            rows={props.rows}
            inputRef={inputRef}
            variant={props.variant}
            fullWidth
            onChange={onChange}
            value={state}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  aria-label="help button, view more info"
                  onClick={() => setModalOpen(true)}>
                  <i
                    className="material-icons"
                    style={{ fontSize: "16px", color: "rgb(142, 148, 157)" }}>
                    help
                  </i>
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>

      {modalOpen && (
        <Dialog
          open={modalOpen}
          fullScreen={props.fullScreen}
          onClose={() => setModalOpen(false)}>
          <div style={{ minWidth: "250px", padding: "10px" }}>
            <div
              className="row col-12 pagePadding"
              style={{ padding: "20px", minWidth: "250px" }}>
              <div className="col-10 align-items-center">
                <h2>{props.dialogTitle}</h2>
              </div>

              <div className="col-2 justify-content-end">
                <IconButton aria-label="close" onClick={() => setModalOpen(false)}>
                  <i className="material-icons">close</i>
                </IconButton>
              </div>

              <div className="col-12">
                <p style={{ marginBottom: "10px" }}>{props.dialogDesc}</p>
                {props.dialogImgSrc && (
                  <img
                    style={{ border: "2px #ddd solid" }}
                    src={props.dialogImgSrc}
                    width="100%"
                    height="auto"
                    alt={props.Name + " example"}
                  />
                )}
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
}

export default withMobileDialog()(TextFieldQuestion);
