import React from "react";
import PropTypes from "prop-types";

export default function Progress(props) {
  const showBubbles = props.items.map((_, index) => {
    let percentage;

    if (props.index === index) {
      percentage = props.percentage + "%";
    } else if (index < props.index) {
      percentage = "100%";
    } else {
      percentage = "0%";
    }

    return (
      <div
        key={index}
        className="progressBubbleShadow"
        style={{
          flex: 1,
          backgroundColor: "rgba(255,255,255,0.4)",
          borderRadius: "100px",
          marginRight: "4px",
        }}>
        <div
          style={{
            display: "block",
            width: percentage,
            height: "100%",
            willChange: "width",
            transitionDuration: "0.2s",
            transitionProperty: "width",
            borderRadius: "100px",
            backgroundColor: "white",
          }}
        />
      </div>
    );
  });

  return (
    <div
      style={{
        position: "absolute",
        top: 12,
        left: 12,
        right: 12,
        height: 6,
        width: "calc(100% - 24px)",
        zIndex: 900,
        display: "flex",
        borderRadius: "100px",
      }}>
      {showBubbles}
    </div>
  );
}

Progress.propTypes = {
  index: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  percentage: PropTypes.number.isRequired,
};
