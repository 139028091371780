import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import { csvToObject } from "util/csv";
import RuleKeys from "./rule-keys";
import { isValidEmail } from "util/helpers";

const MAX_FILE_SIZE = 209_715_200;

const validateRow = function (row, index) {
  if (!isValidEmail(row.Email)) {
    return `Row ${index + 1}: has an invalid email.`;
  }
  return null;
};

const parseGraduway = (rows) => {
  const affiliations = {};
  const industries = {};
  const functions = {};
  const errors = [];

  const headers = Object.keys(rows[0]);
  const groups = headers.filter((header) => header.toLowerCase().includes("group"));

  rows.forEach((row, index) => {
    const error = validateRow(row, index);
    if (error) {
      errors.push(error);
    }
    row[RuleKeys.affiliations].split(" | ").forEach((value) => {
      value && (affiliations[value] = true);
    });
    row[RuleKeys.industries].split(" | ").forEach((value) => {
      value && (industries[value] = true);
    });
    row[RuleKeys.functions].split(" | ").forEach((value) => {
      value && (functions[value] = true);
    });
  });
  return {
    [RuleKeys.affiliations]: Object.keys(affiliations),
    [RuleKeys.industries]: Object.keys(industries),
    [RuleKeys.functions]: Object.keys(functions),
    [RuleKeys.groups]: groups,
    errors,
  };
};

export default function UploadFile(props) {
  const [buttonState, setButtonState] = useState("regular");
  const [errorMessages, setErrorMessages] = useState([]);

  const uploadFileNow = (e) => {
    try {
      const file = e.target.files[0];
      const fileName = file.name;
      const blobSize = file.size;
      setErrorMessages([]);

      if (blobSize > MAX_FILE_SIZE) {
        setErrorMessages(["File is too large. Must be smaller than 200mb."]);
      }

      setButtonState("loading");
      const reader = new FileReader();
      reader.onload = (event) => {
        const csvString = event.target.result;
        const result = csvToObject(csvString);
        const contacts = result.data;
        const errors = result.errors;
        if (contacts.length < 0) {
          setErrorMessages(["CSV file is empty, or using incorrect format"]);
        }

        const parsed = parseGraduway(contacts);

        if (parsed.errors.length) {
          setErrorMessages(parsed.errors);
          return;
        }
        props.setCsvData({
          fileName,
          contacts,
          errors,
          ...parsed,
        });
        props.setActiveStep(1);
      };
      reader.readAsText(file);
    } catch (err) {
      console.error(err.message);
      setErrorMessages([err.message]);
    } finally {
      setButtonState("regular");
    }
  };

  const inputStyle = {
    uploadInput: {
      cursor: "pointer",
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      width: "100%",
      opacity: 0,
    },
  };

  return (
    <>
      <div className="row col-12" style={{ marginTop: "15px" }}>
        <div className="col-12 row" style={{ padding: "10px 0px", marginTop: "10px" }}>
          <h2>1) Import your Engage Registered User File</h2>
        </div>

        <div className="col-12" style={{ marginTop: 25 }}>
          <p className="greyDesc">
            Download your{" "}
            <a
              href="https://graduwayhelp.zendesk.com/hc/en-us/articles/360012198059-Updating-or-Deleting-User-Data-on-the-Graduway-platform"
              target="_blank"
              rel="noopener noreferrer">
              Registered User File
            </a>{" "}
            from Graduway, and upload the file below.
          </p>
        </div>

        <div className="col-12" style={{ marginTop: 10 }}>
          {buttonState === "loading" ? (
            <LinearProgress />
          ) : (
            <Button variant="contained" color="primary">
              <i className="material-icons" style={{ marginRight: "10px" }}>
                file_upload
              </i>
              <span>Upload File</span>
              <input
                type="file"
                accept=".csv"
                id="uploadFile"
                style={inputStyle.uploadInput}
                onClick={(e) => (e.target.value = null)}
                onChange={uploadFileNow}
              />
            </Button>
          )}
        </div>

        {!!errorMessages.length &&
          errorMessages.map((errorMessage, index) => (
            <div className="errorMessageBox" key={index}>
              <b>{errorMessage}</b>
            </div>
          ))}
      </div>
      <div className="col-12" style={{ height: "70px" }} />
    </>
  );
}

UploadFile.propTypes = {
  setCsvData: PropTypes.func.isRequired,
  setActiveStep: PropTypes.func.isRequired,
};
