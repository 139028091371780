import React from "react";
import PropTypes from "prop-types";
import styles from "./inputImage.module.css";

import IconButton from "@material-ui/core/IconButton";

// 10 MB
const MAX_FILE_BYTES = 10485760;
const ALLOWED_FILE_TYPES = ["image/png", "image/jpeg"];

class InputImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadedImage: props.value || null,
    };
    this.hiddenInput = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleClick() {
    this.hiddenInput.current.click();
  }

  handleChange($event) {
    const { files } = $event.target;
    const file = files.item(0);
    if (file) {
      if (file.size > MAX_FILE_BYTES) {
        this.props.onError("File size must not exceed 10MB.");
        return;
      }
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        this.props.onError(
          `File format must be on of the following: ${ALLOWED_FILE_TYPES.join(" , ")}.`
        );
        return;
      }
      const fr = new FileReader();
      fr.onload = () => {
        this.setState({ uploadedImage: fr.result });
        // insert file name into data URI
        const base64Uri = fr.result.replace(
          ";base64,",
          ";" + encodeURIComponent(file.name) + ";base64,"
        );
        this.props.onChange(base64Uri);
      };
      fr.readAsDataURL(file);
    }
  }

  render() {
    const { uploadedImage } = this.state;
    return (
      <div>
        {uploadedImage ? (
          <div className={styles.logo_wrapper} onClick={this.handleClick}>
            <img className={styles.logo} src={uploadedImage} alt="logo" />
            <i className="material-icons">edit</i>
          </div>
        ) : (
          <div className={styles.wrapper}>
            <div className={styles.inner_circle}>
              <IconButton
                className={styles.button}
                aria-label="delete"
                onClick={this.handleClick}>
                <i className="material-icons">camera_alt</i>
              </IconButton>
            </div>
          </div>
        )}
        <input
          className={styles.hidden_input}
          ref={this.hiddenInput}
          onChange={this.handleChange}
          type="file"
          id="img"
          name="img"
          accept="image/*"
        />
      </div>
    );
  }
}

InputImage.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

export default InputImage;
