import React from "react";
import PropTypes from "prop-types";
import { IconButton, Dialog, DialogTitle, Slide, DialogContent } from "@material-ui/core";
import EditNote from "../editNote";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditContentDialog(props) {
  let { noteId, contentId, taskId, dialogOpen, setDialogOpen, editNoteShowSettings } =
    props;

  let handleClose = () => {
    if (props.setForceLoadFreshNoteContent)
      props.setForceLoadFreshNoteContent(Date.now()); //is used by previewNoteButton to change the note content after it's been edited by this dialog
    setDialogOpen(false);
  };

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      style={{ top: 60 }}
      scroll="paper">
      <DialogTitle
        id="scroll-dialog-title"
        style={{
          boxShadow:
            "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
          zIndex: 9999,
          backgroundColor: "#24305e",
        }}>
        <div style={{ display: "flex" }}>
          <div style={{ width: "48px" }}>
            <IconButton aria-label="close" onClick={handleClose}>
              <i className="material-icons" style={{ color: "white" }}>
                close
              </i>
            </IconButton>
          </div>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "18px", color: "white" }}>Edit content</span>
          </div>
        </div>
      </DialogTitle>

      <DialogContent style={{ padding: "0px" }}>
        <EditNote
          noteId={noteId}
          contentId={contentId}
          taskId={taskId}
          accountInfo={props.accountInfo}
          userInfo={props.userInfo}
          userRole={props.userRole}
          showSettings={editNoteShowSettings}
        />

        <div style={{ height: "100px", backgroundColor: "#f3f4f4" }} />
      </DialogContent>
    </Dialog>
  );
}

EditContentDialog.propTypes = {
  userInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};
