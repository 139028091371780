/**
 * Prevent a function from running more that once per specified delay
 * REACT COMPONENTS - must wrap the function in "useCallback(() => {}, [props])"
 *    to prevent the loss of the timeoutId between state rerenders unless props are changed
 * @param {function} func - Function to be debounced
 * @param {number} delay - Milliseconds delay
 * @returns Debounced function with reference to timeoutId
 */
export function debounce(func, delay = 300) {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
}

export function capitalize(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function getSearchParams() {
  return new URLSearchParams(window.location.search);
}

/**
 * Delete "undefined" values from a URLSearchParams
 * @param {URLSearchParams} params
 */
export function cleanSearchParams(params) {
  const toDelete = [];
  for (const [key, value] of params) {
    if (value === "undefined") {
      toDelete.push(key);
    }
  }
  for (const key of toDelete) {
    params.delete(key);
  }
  return params;
}

/**
 * Combine the first and last name, with optional fallback
 * @param {*} first
 * @param {*} last
 * @param {*} fallback
 * @returns return a combined full name string
 */
export function toFullName(first, last, fallback) {
  first = first || "";
  last = last || "";
  const name = (first + " " + last).trim() || fallback;
  return name;
}

/**
 * Determine if an email is valid using legacy logic found in code
 * @param {*} strEmail
 * @returns Bool representing if the given string is valid
 */
export function isValidEmail(strEmail) {
  return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    strEmail
  );
}

/**
 * Joins non-falsy strings with separator and trims
 * @param {string} separator
 * @param  {...string} args
 * @returns Joined string
 */
export function joinStrings(separator, ...args) {
  return args
    .filter((x) => x)
    .join(separator)
    .trim();
}

/**
 * Converts a javascript date to YYYY-MM-DD HH:MI:SS
 * @param {Date} date
 * @returns Postgres date string
 */
export function toPostgresDateString(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

/**
 * Calculate the percentage and return as a string
 * @param {*} count numerator
 * @param {*} total denominator
 * @returns ##.##% or NA if total is 0
 */
export function toPercent(count = 0, total = 0) {
  return total === 0 ? "NA" : ((count / total) * 100).toFixed(2) + "%";
}
