import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import SortVids from "../../../videos/sortVids/sort/sortVids";
import AddVideo from "../../../videos/sortVids/addVideo/addVideo";
import { updateTaskVideosIds } from "services/api.service";

export default function Videos(props) {
  let [state, setState] = useState({});

  //videos is the array used by sortVids
  //and this is what is saved under notes rtdb
  let { currentTask, firstContactData = {}, forceShowAddVideoVsRecord } = props;
  let initialVideoIds = currentTask && currentTask.videoIds ? currentTask.videoIds : [];
  let [videoIds] = useState(initialVideoIds);
  let [addState, setAddState] = useState(null);

  //get video data.
  useEffect(() => {
    props.setVideoIdsProcessing(
      props.currentTask.taskVideos
        .filter(({ video }) => video.status === "webMUploaded")
        .map(({ video }) => video.id)
    );
  }, [props.currentTask.taskVideos]);

  const saveVideos = async (taskVideos) => {
    //update task video links
    setState({ buttonState: "loading" });
    const updatedTaskVideos = await updateTaskVideosIds(
      props.currentTask.id,
      taskVideos.map((taskVideo) => taskVideo.videoId)
    ).catch((err) => {
      let errorMessage = err.message;
      errorMessage = props.noteId
        ? err.message +
          ". You might be seeing this error if you deleted the note selected in step #2. Select a new note and see if problem persist."
        : err.message;
      setState({ errorMessage: errorMessage });
      console.error(err.message);
      console.error(err);
    });

    const tasks = props.tasks.map((task) => {
      if (task.id === props.currentTask.id) {
        return { ...props.currentTask, taskVideos: updatedTaskVideos };
      }

      return task;
    });

    props.setTaskVideos(updatedTaskVideos);

    props.setTasks(tasks);
    setState({});
  };

  const removeVideo = (taskVideo) => {
    //this will trigger useEffect to get new video Data
    saveVideos(
      props.currentTask.taskVideos.filter(
        (currentTaskVideo) => currentTaskVideo.videoId !== taskVideo.videoId
      )
    );
  };

  const videoAdded = (video) => {
    if (
      props.currentTask.taskVideos.some(
        (currentTaskVideo) => currentTaskVideo.videoId === video.id
      )
    ) {
      removeVideo({ videoId: video.id });
    } else {
      saveVideos([
        ...props.currentTask.taskVideos,
        { taskId: props.currentTask.id, videoId: video.id },
      ]);
    }
  };

  function taskUpdated(field, value) {
    // don't need to update state, just need for future loads
    // can be removed if task is fetched when selected
    props.currentTask[field] = value;
  }

  return (
    <div className="col-12 row pagePadding">
      {state.errorMessage && (
        <div className="errorMessageBox">
          <b>{state.errorMessage}</b>
        </div>
      )}

      {addState === "record" ? null : (
        <div className="col-12 row">
          <SortVids
            accountInfo={props.accountInfo}
            removeVideo={removeVideo}
            videos={props.taskVideos}
            calledFromTask={true}
            saveVideos={saveVideos}
            passedSetState={taskUpdated}
          />
        </div>
      )}

      <div className="col-12 row" style={{ marginTop: "15px" }}>
        <AddVideo
          {...props}
          accountId={props.accountInfo.id}
          videos={props.taskVideos}
          contactData={firstContactData}
          setAddState={setAddState}
          addState={addState}
          videoAdded={videoAdded}
          // Todo remove
          videoIds={videoIds}
          calledFromTask={true}
          forceShowAddVideoVsRecord={forceShowAddVideoVsRecord}
          currentTask={currentTask}
        />
      </div>

      {props.currentTask.taskVideos.length > 0 && addState !== "record" && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.setActiveStep(1)}
          style={{ marginTop: "25px" }}>
          Continue
        </Button>
      )}
    </div>
  );
}
