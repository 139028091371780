import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteSearchItemDialog from "../../util/search/deleteSearchItemDialog";
import CloneNoteDialog from "./cloneNote/cloneNoteDialog";
import EditItemFolders from "../../util/folders/search/editItemFolders";
import Checkbox from "@material-ui/core/Checkbox";

export default function NoteItem(props) {
  const { current } = props;
  let [anchorEl, setAnchorEl] = useState(null);
  let [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  let [cloneDialogOpen, setCloneDialogOpen] = useState(false);
  let [editItemFoldersDialogOpen, setEditItemFoldersDialogOpen] = useState(false);

  const history = useHistory();

  let editNote = () => history.push(`/a/${props.accountInfo.id}/notes/` + current.id);
  let cloneNote = () => setCloneDialogOpen(true);
  let deleteNote = () => setDeleteDialogOpen(true);

  let editItemFolders = () => {
    setEditItemFoldersDialogOpen(true);
    setAnchorEl(null);
  };

  let color = current.status === "sent" ? "green" : "#cbd6e2";
  const checked = props.selectedItems.some((item) => item.id === current.id);

  if (!props.userRole.isNoteEditor) {
    return (
      <div
        className="col-12 listItem-item"
        style={{
          display: "flex",
          height: "50px",
          padding: "4px",
          borderBottom: "1px #ddd solid",
          borderLeft: "2px transparent solid",
          borderRight: "2px transparent solid",
        }}>
        <div
          className="align-items-center justify-content-center"
          style={{ width: "30px" }}>
          <div
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: color,
            }}
          />
        </div>
        <Link
          to={`/a/${props.accountInfo.id}/notes/` + current.id}
          className="listItem-item mainlistItemTitle align-items-center link"
          style={{ flex: 1, paddingLeft: "10px", overflow: "hidden" }}>
          {current.title}
        </Link>
      </div>
    );
  }

  let menuOptions = [
    { label: "Edit Note", action: editNote },
    { label: "Clone Note", action: cloneNote },
    { label: "Edit Tags", action: editItemFolders },
    { label: "Delete Note", action: deleteNote },
  ];

  let menuList = menuOptions.map((item, index) => {
    return (
      <MenuItem key={index} button onClick={item.action}>
        {item.label}
      </MenuItem>
    );
  });

  return (
    <div
      className="col-12 listItem-item"
      style={{
        display: "flex",
        height: "50px",
        padding: "4px",
        borderBottom: "1px #ddd solid",
        borderLeft: "2px transparent solid",
        borderRight: "2px transparent solid",
      }}>
      <div className="align-items-center" style={{ width: "40px" }}>
        <Checkbox
          checked={checked}
          size="small"
          color="default"
          style={{ padding: "5px" }}
          onClick={() => props.itemSelected(current.id, !checked)}
        />
      </div>

      <div
        className="align-items-center justify-content-center"
        style={{ width: "30px" }}>
        <div
          style={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            backgroundColor: color,
          }}
        />
      </div>

      <Link
        to={`/a/${props.accountInfo.id}/notes/` + current.id}
        className="listItem-item mainlistItemTitle align-items-center link"
        style={{ flex: 1, paddingLeft: "10px", overflow: "hidden" }}>
        {current.title}
      </Link>

      <div
        className="align-items-center justify-content-end"
        style={{ width: "48px", marginRight: "10px" }}>
        <IconButton
          aria-label="More"
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}
          style={{ padding: "0px" }}>
          <i className="material-icons">more_vert</i>
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={anchorEl ? true : false}
          onClose={() => setAnchorEl(null)}
          PaperProps={{ style: { maxHeight: 200, width: 200 } }}>
          {menuList}
        </Menu>
      </div>

      {deleteDialogOpen && (
        <DeleteSearchItemDialog
          objectIdsToUpdate={[current.id]}
          indexName="notes"
          {...props}
          accountId={props.accountInfo.id}
          deleteDialogOpen={deleteDialogOpen}
          setDeleteDialogOpen={setDeleteDialogOpen}
        />
      )}
      {editItemFoldersDialogOpen && (
        <EditItemFolders
          accountId={props.accountInfo.id}
          currentItem={current}
          objectIdsToUpdate={[current.id]}
          calledFromBulkEdit={false}
          dialogTitle="Note"
          indexName="notes"
          {...props}
          selectedItems={[]}
          dialogOpen={editItemFoldersDialogOpen}
          setDialogOpen={setEditItemFoldersDialogOpen}
        />
      )}
      {cloneDialogOpen && (
        <CloneNoteDialog
          currentItem={current}
          {...props}
          dialogOpen={cloneDialogOpen}
          setDialogOpen={setCloneDialogOpen}
        />
      )}
    </div>
  );
}

NoteItem.propTypes = {
  accountInfo: PropTypes.shape({ id: PropTypes.string }).isRequired,
  current: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  selectedItems: PropTypes.array.isRequired,
  itemSelected: PropTypes.func.isRequired,
  userRole: PropTypes.shape({ isNoteEditor: PropTypes.bool }).isRequired,
};
