import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";

export default function AddAction(props) {
  let clickedAddAction = () => {
    let actionId = crypto.randomUUID();
    let conditionId = crypto.randomUUID();

    let firstCondition = {};
    if (props.event === "formSubmitted") {
      firstCondition = {
        metric: "forms",
        metricValue: null,
        operator: "includes",
      };
    } else if (props.event === "callCompleted") {
      firstCondition = {
        metric: "callResults",
        metricValue: null,
        operator: "includes",
      };
    } else if (props.event === "pollCustomObject") {
      firstCondition = {
        metric: "gratavidCustomAmount1__c",
        metricValue: 100,
        operator: "greaterEqual",
      };
    } else if (props.event === "newEventSignup") {
      firstCondition = {
        metric: "events",
        metricValue: null,
        operator: "includes",
      };
    } else if (props.event === "newGroupSignup") {
      firstCondition = {
        metric: "groups",
        metricValue: null,
        operator: "includes",
      };
    } else if (props.event === "newMentorProgramMatch") {
      firstCondition = {
        metric: "programs",
        metricValue: null,
        operator: "includes",
      };
    } else {
      firstCondition = {
        metric: "transactionAmount",
        metricValue: 100,
        operator: "greaterEqual",
      };
    }

    let item = {
      actionId: actionId,
      conditions: {
        [conditionId]: firstCondition,
      },
      successFunc: "doNothing",
    };

    let tempActions = { ...props.actions };
    let tempList = tempActions.list ? tempActions.list : [];
    tempList.push(item);
    tempActions.list = tempList;
    props.setActions(tempActions);
  };

  return (
    <div className="row col-12">
      <div
        style={{
          width: "70px",
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
        }}></div>
      <div style={{ flex: 1, marginTop: "6px" }}>
        <Button size="small" onClick={() => clickedAddAction()} variant="outlined">
          <i className="material-icons" style={{ fontSize: "18px" }}>
            add
          </i>
          <span
            style={{ textTransform: "capitalize", fontSize: "14px", marginLeft: "3px" }}>
            Add Rule
          </span>
        </Button>
      </div>
    </div>
  );
}
AddAction.propTypes = {
  event: PropTypes.string,
  actions: PropTypes.any,
  setActions: PropTypes.func,
};
