import React from "react";
import PropTypes from "prop-types";
import SortItem from "./sortItem";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

const SortableItem = sortableElement(
  ({
    accountInfo,
    video,
    arrayIndex,
    index,
    videos,
    removeVideo,
    calledFromTask,
    passedSetState,
  }) => (
    <SortItem
      accountInfo={accountInfo}
      video={video}
      videos={videos}
      key={video.videoId}
      arrayIndex={arrayIndex}
      order={arrayIndex + 1}
      removeVideo={removeVideo}
      calledFromTask={calledFromTask}
      passedSetState={passedSetState}
    />
  )
);
const SortableContainer = sortableContainer(({ children }) => (
  <div className="row col-12">{children}</div>
));

export default function SortVids(props) {
  let { calledFromTask = false } = props;

  const showVids = props.videos
    .sort((a, b) => a.order - b.order)
    .map((current, index) => {
      return (
        <SortableItem
          key={current.videoId ?? index}
          index={index}
          arrayIndex={index}
          accountInfo={props.accountInfo}
          video={current}
          videos={props.videos}
          removeVideo={props.removeVideo}
          calledFromTask={calledFromTask}
          passedSetState={props.passedSetState}
        />
      );
    });

  let onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;
    let newList = arrayMove(props.videos, oldIndex, newIndex);
    props.saveVideos(newList);
  };

  //only allow drag is dragging click on the icon
  let cancelDrag = (e) => {
    if (e.srcElement.id === "gvDragHandleIcon") return false;
    return true;
  };

  return (
    <SortableContainer
      onSortEnd={onSortEnd}
      lockAxis="y"
      distance={1}
      shouldCancelStart={cancelDrag}>
      {showVids}
    </SortableContainer>
  );
}

SortVids.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      videoId: PropTypes.string,
      order: PropTypes.number,
      video: PropTypes.object,
    })
  ).isRequired,
  calledFromTask: PropTypes.bool,
  saveVideos: PropTypes.func,
  removeVideo: PropTypes.func.isRequired,
  passedSetState: PropTypes.func,
};
