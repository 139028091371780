import React, { useState, useEffect } from "react";
import Authenticate from "./authenticate";
import AuthenticateDialog from "./authenticateDialog";
import DeAuthenticateDialog from "./deAuthenticateDialog";
import IntegrationHeader from "../integration-header";
import DonationCreated from "./actions/donationCreated";
import MatchedVideos from "./matchedVideos/matchedVideos";
import { withStyles } from "@material-ui/core/styles";
import { Tabs, Tab, FormControl, InputLabel, Select } from "@material-ui/core";

export default function Advance(props) {
  let { integrationDetails, data = {} } = props;
  let { sites: initialSites = {} } = data;
  let [sites, setSites] = useState({});
  let [selectedSiteId, setSelectedSiteId] = useState(null);
  function buildSiteList(items) {
    const siteList = {};
    for (const siteId in initialSites) {
      // Remove empty sites
      if (items[siteId]?.connectionInfo) {
        siteList[siteId] = items[siteId];
      }
    }

    if (!selectedSiteId && siteList) {
      setSelectedSiteId(Object.keys(siteList)[0]);
    }

    setSites(siteList);
  }

  useEffect(() => {
    const advanceIntegrationData = props.accountInfo?.integrations?.endpoint?.advance;

    if (advanceIntegrationData?.sites) {
      buildSiteList(advanceIntegrationData.sites);
    }
  }, [props.accountInfo]);

  const selectedSite = sites?.[selectedSiteId];
  const connectionInfo = selectedSite?.connectionInfo || {};
  const selectedSiteDomain = selectedSite?.connectionInfo.siteDomain;

  let [tabSelected, setTabSelected] = useState(0);

  if (!selectedSiteDomain || Object.keys(sites).length === 0) {
    return (
      <div className="row col-12 pagePadding">
        <Authenticate
          showHeader={true}
          sites={sites}
          setSites={setSites}
          setSelectedSiteId={setSelectedSiteId}
          {...props}
        />
      </div>
    );
  }

  let { events = {}, matchedVideos = {} } =
    sites && sites[selectedSiteId] ? sites[selectedSiteId] : {};

  const AntTabs = withStyles({
    indicator: {
      backgroundColor: "#f76c6c",
    },
  })(Tabs);

  const AntTab = withStyles((theme) => ({
    root: {
      textTransform: "none",
      minWidth: 72,
      marginRight: "10px",
    },
    selected: {},
  }))((props) => <Tab {...props} />);

  function a11yProps(index) {
    return {
      id: `task-tab-${index}`,
      "aria-controls": `task-tabpanel-${index}`,
    };
  }

  return (
    <div className="col-12 row">
      <IntegrationHeader
        integrationDetails={integrationDetails}
        connectionInfo={connectionInfo}>
        <DeAuthenticateDialog
          {...props}
          sites={sites}
          setSites={setSites}
          selectedSiteId={selectedSiteId}
          setSelectedSiteId={setSelectedSiteId}
          selectedSiteDomain={selectedSiteDomain}
        />
      </IntegrationHeader>

      {Object.keys(sites).length > 1 && (
        <div className="col-12">
          <FormControl style={{ marginTop: 25 }} variant="filled">
            <InputLabel id="selectedSiteId">Select Site</InputLabel>
            <Select
              value={selectedSiteId}
              native
              onChange={(e) => setSelectedSiteId(e.target.value)}
              inputProps={{
                "aria-label": "Select Site",
                name: "selectedSiteId",
                id: "selectedSiteId",
              }}>
              {Object.keys(sites).map((siteId) => {
                let siteDate = sites[siteId];
                let { siteDomain } = siteDate && siteDate.connectionInfo;
                return (
                  <option value={siteId} key={siteId}>
                    {siteDomain}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </div>
      )}

      <div className="row col-12" style={{ marginTop: 15 }}>
        <div className="col-12">
          <AuthenticateDialog
            {...props}
            sites={sites}
            setSites={setSites}
            setSelectedSiteId={setSelectedSiteId}
          />
        </div>
      </div>

      <div
        className="col-12"
        style={{
          borderBottom: "1px #ddd solid",
          padding: "0px 8.5px",
          maxWidth: "100vw",
          marginTop: 25,
        }}>
        <AntTabs
          value={tabSelected}
          onChange={(e, newValue) => setTabSelected(newValue)}
          aria-label="task tabs"
          variant="scrollable"
          scrollButtons="auto">
          <AntTab
            label="Triggers"
            {...a11yProps("schedule")}
            value={0}
            style={{
              textTransform: "none",
              color: "rgb(71, 98, 130)",
              fontSize: "14px",
              fontWeight: 600,
            }}
          />
          <AntTab
            label="Map Campaign Videos"
            {...a11yProps("history")}
            value={1}
            style={{
              textTransform: "none",
              color: "rgb(71, 98, 130)",
              fontSize: "14px",
              fontWeight: 600,
            }}
          />
        </AntTabs>
      </div>

      {tabSelected === 0 && (
        <DonationCreated
          {...props}
          eventData={events && events.donationCreated ? events.donationCreated : {}}
          selectedSiteId={selectedSiteId}
          sites={sites}
          setSites={setSites}
        />
      )}
      {tabSelected === 1 && (
        <MatchedVideos
          {...props}
          matchedVideos={
            matchedVideos && matchedVideos.campaigns ? matchedVideos.campaigns : {}
          }
          selectedSiteId={selectedSiteId}
          sites={sites}
          setSites={setSites}
        />
      )}

      <div className="col-12" style={{ height: "100px" }} />
    </div>
  );
}
