import React from "react";
import ContactItem from "./contactItem";
import BulkAction from "./bulkAction";
import Checkbox from "@material-ui/core/Checkbox";

export default function ListContacts(props) {
  let contacts = props.searchList;
  if (contacts === "loading") return null;

  let selectAll = () => {
    props.setSelectedContacts({});
    props.setAllSelected(!props.allSelected);
  };

  let contactsKeys = Object.keys(contacts) || [];

  //turn table background red if something selected
  let selectedContactsKeys = Object.keys(props.selectedContacts) || [];
  let tableHeadBackgroundColor =
    props.allSelected || selectedContactsKeys.length > 0 ? "#fbe0ea" : null;

  return (
    <div className="row col-12">
      <div className="row col-12" style={{ padding: "5px 0px" }}>
        <p style={{ color: "grey" }}>
          Showing {contactsKeys.length} out of {props.searchState.nbHits} results
        </p>
      </div>

      <div
        className="row col-12"
        style={{
          display: "flex",
          padding: "4px",
          border: "2px #999 solid",
          borderRadius: "2px",
          backgroundColor: tableHeadBackgroundColor,
        }}>
        <div style={{ width: "40px" }} className="align-items-center">
          <Checkbox
            checked={props.allSelected}
            onClick={() => selectAll()}
            size="small"
            style={{ padding: "5px" }}
          />
        </div>

        <div style={{ flex: 1 }} className="align-items-center">
          <b style={{ color: "#999" }}>Name</b>
        </div>

        <div style={{ width: "150px" }} className="row">
          <BulkAction {...props} />
        </div>
      </div>

      <div className="row col-12">
        {contactsKeys.map((key) => (
          <ContactItem
            currentContact={contacts[key]}
            {...props}
            key={key}
            accountId={props.accountInfo.id}
          />
        ))}
      </div>
    </div>
  );
}
