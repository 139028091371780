import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { LinearProgress, List, ListItem, Button } from "@material-ui/core";
import AnalyticsEventLookup from "../../../../../util/data/analyticsEventLookup.json";
import RecipientItem from "./recipientItem";
import ListActions from "./listActions";
import InfoBox from "../../../../../util/explainers/infoBox";
import LoadingButton from "components/util/buttons/loadingbutton";
import { getNoteAnalytics, getNoteAnalyticsEvent } from "services/api.service";

export default function NoteRecipients(props) {
  let {
    recipientsEventSelected: eventSelected,
    setRecipientsEventSelected: setEventSelected,
  } = props;
  const [sortKey, setSortKey] = useState("timestamp");
  const [sortDirection, setSortDirection] = useState("desc");
  const [contacts, setContacts] = useState([]);
  const [events, setEvents] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState("");

  const getEvents = useCallback(
    (limit = 10, offset = 0, append) => {
      setError("");

      if (!props.noteId || !eventSelected) {
        return;
      }
      getNoteAnalyticsEvent(
        props.noteId,
        eventSelected,
        limit,
        offset,
        sortKey,
        sortDirection
      )
        .then((data) => {
          if (append) {
            setContacts((current) => [...current, ...data]);
          } else {
            setContacts(data);
          }
          if (data.length < 10) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
        })
        .catch((err) => {
          console.error(err);

          if (!append) {
            setContacts([]);
          }

          if (err.status === 0) {
            setError("Unable to retrieve analytics data");
          } else {
            setError("Something went wrong");
          }
        });
    },
    [props.noteId, eventSelected, sortKey, sortDirection]
  );

  useEffect(() => {
    getEvents(pageSize, 0);
  }, [getEvents, pageSize]);

  useEffect(() => {
    getNoteAnalytics(props.noteId, null).then((data) => {
      //get analytics stored under note and contact
      const uniqueTotals = data.uniqueTotals ?? {};

      //get highest event total
      let highestCount = 0;
      let highestEvent = "email_opened";
      var sortEvents = [];

      Object.keys(uniqueTotals).forEach((event) => {
        if (
          event === "email_dropped" ||
          event === "text_delivered" ||
          event === "text_receiving" ||
          event === "email_deferred"
        ) {
          return;
        }
        const currentCount = uniqueTotals[event];
        if (!currentCount) {
          return;
        }
        if (currentCount > highestCount) {
          highestCount = currentCount;
          highestEvent = event;
        }

        const { label, noun, pluralNoun, desc } = AnalyticsEventLookup[event] || {};
        if (!label) {
          return null;
        }
        sortEvents.push({
          eventKey: event,
          count: currentCount,
          label,
          noun,
          pluralNoun,
          desc,
        });
      });

      sortEvents.sort((a, b) => {
        return b.count - a.count;
      });

      setEvents(sortEvents);
      setEventSelected(highestEvent);
    });
  }, [props.noteId, setEvents, setEventSelected]);

  let handleEventSelected = (eventKey) => {
    setEventSelected(eventKey);
    setPageSize(10);
  };

  const handleSort = (key) => {
    const direction =
      sortKey === key ? (sortDirection === "desc" ? "asc" : "desc") : "desc";
    setSortDirection(direction);
    setSortKey(key);
    setPageSize(contacts.length);
  };

  function showMore() {
    getEvents(10, contacts.length, true);
  }

  if (!events || !props.noteTitle) {
    return (
      <div className="col-12" style={{ padding: "20px" }}>
        <LinearProgress />
      </div>
    );
  }

  if (events.length === 0) {
    return (
      <div className="col-12" style={{ padding: "20px" }}>
        No history in past 90 days
      </div>
    );
  }

  let eventTitle, eventNoun, eventPluralNoun, eventCount, eventDesc;

  let listEventsHTML = events.map((current) => {
    let { count, eventKey, label, noun, pluralNoun, desc = "" } = current;
    let selected = eventSelected === eventKey;
    if (selected) {
      eventTitle = label;
      eventCount = count;
      eventNoun = noun;
      eventPluralNoun = pluralNoun;
      eventDesc = desc;
    }
    return (
      <ListItem
        button
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontWeight: selected ? "bold" : "normal",
          borderRadius: "3px",
          padding: "12px",
        }}
        selected={selected}
        key={eventKey}
        onClick={() => handleEventSelected(eventKey)}>
        <div style={{ fontSize: "14px" }}>{label}</div>
        <div style={{ fontSize: "14px" }}>{count}</div>
      </ListItem>
    );
  });

  return (
    <div
      className="row col-12"
      style={{
        padding: "10px",
        minHeight: "100vh",
      }}>
      <div className="col-12 col-md-4 col-lg-3 col-xl-2" style={{ paddingRight: "10px" }}>
        <List component="nav" aria-label="main mailbox folders">
          {listEventsHTML}
        </List>
      </div>

      <div
        className="row col-12 col-md-8 col-lg-9 col-xl-10"
        style={{ marginTop: "8px" }}>
        <div
          className="row col-12 adSectionBox"
          style={{ alignContent: "flex-start", padding: "0px" }}>
          <div className="col-12" style={{ display: "flex", padding: "20px 24px" }}>
            <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
              <h2
                style={{
                  fontSize: "24px",
                  fontWeight: 100,
                  textTransform: "capitalize",
                  margin: "0px",
                }}>
                {eventTitle} {eventCount}
              </h2>
              <InfoBox
                size="medium"
                title={eventTitle}
                desc={eventDesc}
                href="https://graduwayhelp.zendesk.com/hc/en-us/articles/6653626865308-Understanding-Analytics"
              />
            </div>
            <div style={{ width: "115px" }} className="hidden-md-down">
              <ListActions
                eventSelected={eventSelected}
                eventTitle={eventTitle}
                eventNoun={eventNoun}
                eventPluralNoun={eventPluralNoun}
                analytics={contacts}
                noteTitle={props.noteTitle}
                noteId={props.noteId}
              />
            </div>
          </div>

          <div
            className="row col-12"
            style={{ display: "flex", padding: "0px 24px", alignItems: "center" }}>
            <div className="col-4">
              <h4 style={{ fontSize: "12px", fontWeight: 100, margin: "0px" }}>
                CONTACTS ({eventCount})
              </h4>
            </div>
            <div className="col-4 col-md-3">
              <Button onClick={() => handleSort("timestamp")} style={{ padding: "8px" }}>
                <span style={{ fontSize: "12px", fontWeight: 100 }}>
                  LAST {eventNoun}
                </span>
                {sortKey === "timestamp" && (
                  <i
                    className="material-icons"
                    style={{ fontSize: "12px", marginLeft: "5px", paddingBottom: "3px" }}>
                    {sortDirection === "asc" ? "arrow_upward" : "arrow_downward"}
                  </i>
                )}
              </Button>
            </div>
            <div className="col-4 col-md-3">
              <Button onClick={() => handleSort("count")} style={{ padding: "8px" }}>
                <span style={{ fontSize: "12px", fontWeight: 100 }}>
                  Total {eventPluralNoun}
                </span>
                {sortKey === "count" && (
                  <i
                    className="material-icons"
                    style={{ fontSize: "12px", marginLeft: "5px", paddingBottom: "3px" }}>
                    {sortDirection === "asc" ? "arrow_upward" : "arrow_downward"}
                  </i>
                )}
              </Button>
            </div>
            <div className="hidden-md-down col-2">
              <h4
                style={{
                  fontSize: "12px",
                  fontWeight: 100,
                  paddingLeft: "5px",
                  margin: "0px",
                }}>
                EVENTS
              </h4>
            </div>
          </div>

          {error && (
            <div className="errorMessageBox" style={{ margin: "10px" }}>
              {error}
            </div>
          )}

          {!contacts ? (
            <div className="row col-12" style={{ borderTop: "1px solid #ddd" }}>
              <div
                className="col-12"
                style={{
                  height: "60px",
                  padding: "12px 24px",
                  borderBottom: "1px solid #ddd",
                }}>
                <div
                  className="loading-pulse"
                  style={{
                    height: "20px",
                    maxWidth: "200px",
                    backgroundColor: "#ddd",
                    borderRadius: "8px",
                  }}
                />
                <div
                  className="loading-pulse"
                  style={{
                    height: "10px",
                    maxWidth: "180px",
                    backgroundColor: "#ddd",
                    borderRadius: "8px",
                    marginTop: "4px",
                  }}
                />
              </div>
              <div
                className="col-12"
                style={{
                  height: "60px",
                  padding: "12px 24px",
                  borderBottom: "1px solid #ddd",
                }}>
                <div
                  className="loading-pulse"
                  style={{
                    height: "20px",
                    maxWidth: "200px",
                    backgroundColor: "#ddd",
                    borderRadius: "8px",
                  }}
                />
                <div
                  className="loading-pulse"
                  style={{
                    height: "10px",
                    maxWidth: "180px",
                    backgroundColor: "#ddd",
                    borderRadius: "8px",
                    marginTop: "4px",
                  }}
                />
              </div>
              <div
                className="col-12"
                style={{
                  height: "60px",
                  padding: "12px 24px",
                  borderBottom: "1px solid #ddd",
                }}>
                <div
                  className="loading-pulse"
                  style={{
                    height: "20px",
                    maxWidth: "200px",
                    backgroundColor: "#ddd",
                    borderRadius: "8px",
                  }}
                />
                <div
                  className="loading-pulse"
                  style={{
                    height: "10px",
                    maxWidth: "180px",
                    backgroundColor: "#ddd",
                    borderRadius: "8px",
                    marginTop: "4px",
                  }}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="row col-12" style={{ borderTop: "1px solid #ddd" }}>
                {contacts.map((current) => {
                  return (
                    <RecipientItem
                      accountInfo={props.accountInfo}
                      analytics={current}
                      key={current.contactId}
                    />
                  );
                })}
              </div>
              {hasMore && (
                <div
                  className="col-12 justify-content-center"
                  style={{ margin: "15px 0px" }}>
                  <LoadingButton
                    type="button"
                    variant="outlined"
                    size="small"
                    color="default"
                    savedLabel={"Loaded"}
                    label="Load more"
                    clickedRegularButton={showMore}
                    clickedSavedButton={() => {}}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

NoteRecipients.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  noteId: PropTypes.string.isRequired,
  noteTitle: PropTypes.string,
  recipientsEventSelected: PropTypes.string.isRequired,
  setRecipientsEventSelected: PropTypes.func.isRequired,
};
