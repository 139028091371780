import React from "react";
import PropTypes from "prop-types";
import styles from "./ruleMaker.module.css";
import Rule from "./rule";
import { Button } from "@material-ui/core";
import LoadingButton from "components/util/buttons/loadingbutton";
import RuleKeys from "./rule-keys";

export default function RuleMaker(props) {
  const onDelete = (index) => {
    props.rules.splice(index, 1);
    props.setRules([...props.rules]);
  };

  const onAdd = () => {
    props.setRules([
      ...props.rules,
      [{ key: RuleKeys.affiliations, index: 0, values: [], folders: [] }],
    ]);
  };

  const onChange = (ruleIndex, newRule) => {
    props.rules[ruleIndex] = newRule;
    props.setRules([...props.rules]);
  };

  return (
    <div className={`col-12 ${styles.wrapper}`}>
      <div className={`col-12 ${styles.description}`}>
        <p className="greyDesc">
          Create one or more Gratavid lists using the data from your Engage file.
        </p>
      </div>

      {props.rules.map((rule, ruleIndex) => (
        <Rule
          key={ruleIndex}
          rule={rule}
          onChange={(newRule) => onChange(ruleIndex, newRule)}
          onDelete={() => onDelete(ruleIndex)}
          onAdd={onAdd}
          conditions={props.conditions}
          accountInfo={props.accountInfo}
        />
      ))}

      <div className={styles.buttonWrapper}>
        <Button onClick={onAdd} variant="outlined">
          Create another list
          <i className="material-icons" style={{ marginLeft: 5 }}>
            add
          </i>
        </Button>

        <LoadingButton
          mode="contained"
          buttonState="regular"
          savedLabel="Continue"
          label="Continue"
          clickedRegularButton={props.onContinue}
          color="primary"
          icon="arrow_forward"
          iconColor="white"
        />
      </div>
    </div>
  );
}

const conditionType = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
};

RuleMaker.propTypes = {
  rules: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        values: PropTypes.arrayOf(PropTypes.string).isRequired,
        folders: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
          })
        ).isRequired,
      })
    )
  ).isRequired,
  setRules: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  accountInfo: PropTypes.object.isRequired,
  conditions: PropTypes.shape({
    [RuleKeys.affiliations]: PropTypes.shape(conditionType),
    [RuleKeys.industries]: PropTypes.shape(conditionType),
    [RuleKeys.functions]: PropTypes.shape(conditionType),
    [RuleKeys.groups]: PropTypes.shape(conditionType),
  }).isRequired,
};
