import React from "react";
import CopyTextInput from "../../../util/copyTextInput";

export default function Endpoint(props) {
  let { integrationDetails, apiKey } = props;
  let { header, desc, logoSrc, brandHex } = integrationDetails;

  return (
    <>
      <div className="col-12 row">
        <div
          style={{
            width: "70px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <div
            style={{
              marginRight: "8px",
              width: "45px",
              height: "45px",
              backgroundColor: brandHex,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img src={logoSrc} width="35px" alt="logo" />
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <h1>{header}</h1>
          <span className="greyDesc">{desc}</span>
        </div>
      </div>

      <div className="row col-12" style={{ marginTop: "30px" }}>
        <div
          style={{
            width: "70px",
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
          }}>
          <span className="circleStep">1</span>
        </div>
        <div style={{ flex: 1, marginTop: "6px" }}>
          <h2 style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "5px" }}>
            Private Api Key
          </h2>
          <p style={{ marginTop: "10px" }}>
            Paste this value in when setting up your Zapier Gratavid account. Do not share
            this information publicly.
          </p>
          <div style={{ marginTop: "10px" }}>
            <CopyTextInput label="Api Key" copyURL={apiKey} />
          </div>
        </div>
      </div>

      <div className="row col-12" style={{ marginTop: "30px" }}>
        <div
          style={{
            width: "70px",
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
          }}>
          <span className="circleStep">2</span>
        </div>
        <div style={{ flex: 1, marginTop: "6px" }}>
          <h2 style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "5px" }}>
            Account Identifier
          </h2>
          <p style={{ marginTop: "10px" }}>
            Paste this value in when setting up your Zapier Gratavid account. Do not share
            this information publicly.
          </p>
          <div style={{ marginTop: "10px" }}>
            <CopyTextInput label="Account Identifier" copyURL={props.accountInfo.id} />
          </div>
        </div>
      </div>
    </>
  );
}
