import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  withMobileDialog,
  IconButton,
} from "@material-ui/core";
import LoadingButton from "../../../../util/buttons/loadingbutton";
import { cancelSendTask, cancelSendNote } from "services/api.service";

function CancelResendDialog(props) {
  let { dialogOpen, resendNoteAtTimestamp } = props;

  let [state, setState] = useState({});

  let cancelNow = async () => {
    try {
      setState({ buttonState: "loading" });
      if (props.taskId) {
        await cancelSendTask(props.taskId, true).catch(() => {
          setState({
            errorMessage:
              "Failed to cancel scheduled task. It may have already been cancelled.",
          });
        });
      } else {
        await cancelSendNote(props.itemId).catch(() => {
          setState({
            errorMessage:
              "Failed to cancel scheduled note. It may have already been cancelled.",
          });
        });
      }

      props.handleResendCanceled();

      setState({ buttonState: "saved" });
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.error(errorMessage);
      setState({ errorMessage });
    }
  };

  let sendAtDateString = resendNoteAtTimestamp
    ? new Date(resendNoteAtTimestamp).toLocaleString()
    : "";

  return (
    <Dialog
      fullScreen={props.fullScreen}
      open={dialogOpen}
      onClose={() => props.setDialogOpen(false)}
      aria-labelledby="cancel scheduled send dialog">
      <DialogContent>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "18px" }}>Cancel Automatic Resend</span>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton aria-label="close" onClick={() => props.setDialogOpen(false)}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        {state.errorMessage && (
          <div className="col-12 errorMessageBox">
            <b>{state.errorMessage}</b>
          </div>
        )}

        <div style={{ display: "flex", padding: "20px 0px" }}>
          <p>
            We will automatically resend your message on {sendAtDateString}. Are you sure
            you want to cancel this scheduled send?
          </p>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
        <LoadingButton
          type="button"
          variant="contained"
          color="primary"
          buttonState={state.buttonState}
          savedLabel={"Canceled"}
          label="Cancel Now"
          clickedRegularButton={cancelNow}
          clickedSavedButton={() => props.setDialogOpen(false)}
        />
      </DialogActions>
    </Dialog>
  );
}

export default withMobileDialog()(CancelResendDialog);
