import React, { useState } from "react";
import LoadingButton from "../../../../../util/buttons/loadingbutton";
import GetListSelectItem from "../../util/getListSelectItem";
import { updateAccountIntegrations } from "services/api.service";

export default function SaveConfig(props) {
  let { eventData, event = "" } = props;
  let { activity: initActivity = { id: "Gratavid", label: "Gratavid" } } = eventData;

  let [state, setState] = useState({});
  let [activity, setActivity] = useState(initActivity);

  let saveData = async () => {
    try {
      setState({ buttonState: "loading" });
      await updateAccountIntegrations([
        { path: `endpoint/donorPerfect/events/${event}/activity`, value: activity },
      ]);
      setState({ buttonState: "saved" });
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  return (
    <>
      <div style={{ marginRight: "15px" }}>
        <GetListSelectItem
          initialOptions="loading"
          optionsFetchNeeded={true}
          requestData={{ source: "donorPerfect", event: "getActivityList" }}
          accountId={props.accountInfo.id}
          selectedItem={activity}
          handleChange={(value) => setActivity(value)}
          label="Activity"
          width="250px"
        />
      </div>

      <div className="col-12 justify-content-center" style={{ padding: "20px 0px" }}>
        <LoadingButton
          variant="contained"
          color="primary"
          buttonState={state.buttonState}
          savedLabel={"Saved"}
          label={"Save"}
          clickedRegularButton={saveData}
          clickedSavedButton={() => {
            setState({});
          }}
        />
      </div>
    </>
  );
}
