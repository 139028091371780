import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";

export default function SsoLogins(props) {
  let loginWithProvider = async (provider) => {
    try {
      let fullAuthURL;
      const redirectUri = window.location.origin + "/openid/redirect";
      const state = provider + "_" + crypto.randomUUID();

      if (provider === "google") {
        //hd=* makes sure only works with G Suite accounts not personal ones
        const scopes = "openid email profile https://www.googleapis.com/auth/gmail.send";
        const url = new URL("https://accounts.google.com/o/oauth2/v2/auth");
        url.searchParams.set("client_id", process.env.REACT_APP_GOOGLE_CLIENT_ID);
        url.searchParams.set("response_mode", "query");
        url.searchParams.set("hd", "*");
        url.searchParams.set("response_type", "code");
        url.searchParams.set("redirect_uri", redirectUri);
        url.searchParams.set("scope", scopes);
        url.searchParams.set("prompt", "consent");
        url.searchParams.set("state", state);
        url.searchParams.set("access_type", "offline");
        fullAuthURL = url.href;
      } else if (provider === "microsoft") {
        const scopes =
          "openid email profile offline_access https://graph.microsoft.com/mail.send";
        const url = new URL(
          "https://login.microsoftonline.com/common/oauth2/v2.0/authorize"
        );
        url.searchParams.set("client_id", process.env.REACT_APP_MICROSOFT_CLIENT_ID);
        url.searchParams.set("response_type", "code");
        url.searchParams.set("redirect_uri", redirectUri);
        url.searchParams.set("response_mode", "query");
        url.searchParams.set("scope", scopes);
        url.searchParams.set("state", state);
        url.searchParams.set("prompt", "select_account");
        fullAuthURL = url.href;
      }

      // save state to local storage to be referenced in redirect
      window.localStorage.setItem("loginWithProviderState", state);

      //see if url was called from createTaskFromHyperlink. if yes, save full url to be used once oauth is done
      if (window.location?.pathname.includes("createTaskFromHyperlink")) {
        let fullPath = window.location.pathname + window.location.search;
        window.localStorage.setItem("ssoRedirectURL", fullPath);
      }

      window.location.href = fullAuthURL;
    } catch (err) {
      console.error(err.message);
    }
  };

  const showGoogle = !props.signInProviders || props.signInProviders?.includes("google");
  const showMicrosoft =
    !props.signInProviders || props.signInProviders?.includes("microsoft");

  return (
    <div className="row col-12" style={{ margin: "20px 0px 10px 0px" }}>
      {showGoogle && (
        <div className="col-12">
          <Button
            variant="outlined"
            type="button"
            fullWidth
            onClick={() => loginWithProvider("google")}>
            <img
              width="20px"
              style={{ marginRight: "10px" }}
              alt="Google login"
              src="/static/img/googleIcon.svg"
            />
            <span style={{ textTransform: "initial" }}>Sign in with Google</span>
          </Button>
        </div>
      )}
      {showMicrosoft && (
        <div className="col-12" style={{ padding: "10px 0px 20px 0px" }}>
          <Button
            variant="outlined"
            type="button"
            fullWidth
            onClick={() => loginWithProvider("microsoft")}>
            <img
              width="20px"
              style={{ marginRight: "10px" }}
              alt="Microsoft login"
              src="/static/img/microsoftLogo.svg"
            />
            <span style={{ textTransform: "initial" }}>Sign in with Microsoft</span>
          </Button>
        </div>
      )}
    </div>
  );
}

SsoLogins.propTypes = {
  signInProviders: PropTypes.arrayOf(
    PropTypes.oneOf(["google", "microsoft", "emailPassword"])
  ),
};
