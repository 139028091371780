import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import UserItem from "./externalUserItem";
import { LinearProgress } from "@material-ui/core";
import SelectTable from "../../../util/search/table/selectTable";
import { getAccountUsers } from "services/api.service";
import DeleteUsersDialog from "../deleteUsersDialog";

export default function ListExternalUsers(props) {
  let { users, setUsers } = props;
  let [deleteDialogOpen, setDeleteDialogOpen] = useState(null);
  let [selectedItems, setSelectedItems] = useState([]);
  let [state, setState] = useState({ buttonState: "regular" });

  useEffect(() => {
    let getData = async () => {
      try {
        const users = await getAccountUsers("external");

        let tempUsers = {};
        users.forEach((user) => {
          const { id, email, fullName } = user;

          tempUsers[id] = {
            email,
            userUID: id,
            fullName,
          };
        });

        setUsers(tempUsers);
      } catch (err) {
        console.error(err.message);
      }
    };

    getData();
  }, [setUsers]);

  let selectItem = (userUID) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(userUID)) {
        return prevSelectedItems.filter((item) => item !== userUID);
      } else {
        return [...prevSelectedItems, userUID];
      }
    });
  };

  let selectAll = () => {
    let itemsKeys = Object.keys(users);
    setSelectedItems(itemsKeys);
  };

  let unSelectAll = () => {
    setSelectedItems([]);
  };

  let load100More = () => {};

  if (users === "loading")
    return (
      <div className="pagePadding">
        <LinearProgress />
      </div>
    );

  let tableActions = [
    { label: "Delete", icon: "delete", actionFunc: () => setDeleteDialogOpen(true) },
  ];
  let tableHeader = [{ label: "USER", style: { flex: 1 } }];

  return (
    <div className="row col-12 align-items-start">
      {state.errorMessage && (
        <div className="errorMessageBox">
          <b>{state.errorMessage}</b>
        </div>
      )}

      <div style={{ marginTop: "5px" }} className="row col-12">
        <SelectTable
          items={users}
          selectAll={selectAll}
          unSelectAll={unSelectAll}
          selectedItems={selectedItems}
          selectItem={selectItem}
          nextCursorDoc={null}
          ItemComponent={UserItem}
          load100More={load100More}
          tableActions={tableActions}
          tableHeader={tableHeader}
          userUID={props.userId}
        />
      </div>

      {deleteDialogOpen && (
        <DeleteUsersDialog
          {...props}
          dialogOpen={deleteDialogOpen}
          closeDialog={() => setDeleteDialogOpen(false)}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          users={users}
          setUsers={setUsers}
        />
      )}
    </div>
  );
}

ListExternalUsers.propTypes = {
  accountId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  users: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  setUsers: PropTypes.func.isRequired,
};
