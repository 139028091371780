import React from "react";
import PropTypes from "prop-types";
import styles from "./integration.module.css";
import InputSwitch from "components/util/inputSwitch/inputSwitch.js";

import TextField from "@material-ui/core/TextField";
import { Controller } from "react-hook-form";

const Integration = ({ name, title, logoSrc, apiKey, control }) => (
  <div className={styles.integration}>
    <div className={styles.header}>
      <img className={styles.logo} src={logoSrc} alt="Logo" />
      <h5 className={styles.title}>{title}</h5>
    </div>
    <div className={styles.switch}>
      <Controller
        name={`integrations.${name}.active`}
        control={control}
        render={({ field }) => <InputSwitch {...field} checked={field.value} />}
      />
    </div>
    {apiKey && (
      <Controller
        name={`integrations.${name}.apiKey`}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            className={styles.key_field}
            key={name}
            placeholder="Key"
            variant="outlined"
            size="small"
            required
          />
        )}
      />
    )}
  </div>
);

Integration.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  logoSrc: PropTypes.string.isRequired,
  apiKey: PropTypes.string,
};

Integration.defaultProps = {
  apiKey: "",
};

export default Integration;
