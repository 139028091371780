import React from "react";
import ItemAnalytics from "../../../../util/analytics/itemAnalytics";
import { toFullName } from "util/helpers";

export default function ReplyItem(props) {
  const showDate = new Date(props.createdDate).toLocaleString();

  let { firstName, lastName, email } = props.contactData;
  let dateReplySent = props.createdDate ? new Date(props.createdDate).toString() : "";
  let emailToReplyText = props.replyText.replace(/(?:\r\n|\r|\n)/g, "%0d%0a");
  let subject = props?.current?.emailSubject ?? "Re: Video Reply";
  let mailBody = `%0d%0a%0d%0aFrom: ${toFullName(
    firstName,
    lastName,
    "Gratavid"
  )} <${email}>%0d%0aSent: ${dateReplySent}%0d%0a%0d%0a${emailToReplyText}`;

  let noteHref = null;
  if (props?.current?.noteId)
    noteHref =
      `${process.env.REACT_APP_FRONTEND}/note?noteId=` +
      props.current.noteId +
      "%26cId=" +
      props.contactId +
      "%26preview=true";
  if (noteHref && props?.current?.taskId) {
    noteHref += "%26tId=" + props.current.taskId;
  }

  if (noteHref) mailBody += `%0d%0a%0d%0aReplying to: ${noteHref}`;

  let mailToLink = "mailto:" + email + "?subject=" + subject + "&body=" + mailBody;

  if (props.current.type === "userReply") {
    return (
      <div className="row col-12" style={{ marginBottom: "25px" }}>
        <div className="col-12 justify-content-center" style={{ margin: "12px 0px" }}>
          <span
            style={{ fontSize: "12px", color: "rgba(0, 0, 0, .40)", fontWeight: 500 }}>
            {showDate}
          </span>
        </div>

        <div className="col-12 justify-content-start">
          <div className="align-items-center" style={{ marginRight: "5px" }}>
            <img
              src="/static/img/profile.svg"
              style={{ width: "28px" }}
              alt="generic contact profile pic"
            />
          </div>

          <div
            style={{
              backgroundColor: "#E5E5EA",
              borderRadius: "1.3em",
              padding: "6px 12px",
              maxWidth: "70%",
              borderBottomLeftRadius: "5px",
            }}>
            <span
              style={{
                fontSize: "15px",
                color: "#000",
                wordWrap: "break",
                whiteSpace: "pre-line",
              }}>
              {props.replyText}
            </span>
            {props?.current?.replyingAs ? (
              <span style={{ fontSize: "12px", color: "grey" }}>
                <br />
                replying as{" "}
                <span style={{ fontWeight: "bold", fontSize: "12px" }}>
                  {props.current.replyingAs}
                </span>
              </span>
            ) : null}
          </div>
        </div>

        <div className="col-12" style={{ marginLeft: "32px", marginTop: "5px" }}>
          <p className="greyDesc" style={{ fontSize: "12px" }}>
            Replied via {props.current.source === "lp" ? "note" : props.current.source}
            {props.current.source !== "text" && (
              <a
                className="link"
                style={{ fontSize: "12px", marginLeft: "5px" }}
                href={mailToLink}
                target="_blank"
                rel="noopener noreferrer">
                Open email
              </a>
            )}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="row col-12" style={{ marginBottom: "25px" }}>
      <div className="col-12 justify-content-center" style={{ margin: "12px 0px" }}>
        <span style={{ fontSize: "12px", color: "rgba(0, 0, 0, .40)", fontWeight: 500 }}>
          {showDate}
        </span>
      </div>

      <div className="col-12 justify-content-end">
        <div
          style={{
            backgroundColor: "#0099FF",
            borderRadius: "1.3em",
            padding: "6px 12px",
            maxWidth: "70%",
            borderBottomRightRadius: "5px",
          }}>
          <span
            style={{
              fontSize: "15px",
              color: "#FFF",
              wordWrap: "break",
              whiteSpace: "pre-line",
            }}>
            {props.replyText}
          </span>
        </div>
      </div>

      <div className="col-12 justify-content-end" style={{ marginTop: "5px" }}>
        <ItemAnalytics current={props.current} analytics={props.current} />
      </div>
    </div>
  );
}
