import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import styles from "./admin-account.module.css";

import Button from "components/util/button/button";
import { getAdminAccount } from "services/adminApi.service.js";
import AccountForm from "../components/accountForm.js";
import IntegrationsForm from "../components/integrationsForm.js";

const AdminAccount = (props) => {
  const [accountUID, setAccountUID] = useState(null);
  const [accountInfo, setAccountInfo] = useState(null);
  const [isEditMode, setEditMode] = useState(false);
  const [ready, setReady] = useState(false);
  const integrationsRef = useRef(null);

  async function getAccountInfo(accountUID = null) {
    getAdminAccount(accountUID || props.accountUID)
      .then((response) => {
        setAccountInfo(response);
        setAccountUID(accountUID || props.accountUID);
        setEditMode(true);
        setReady(true);
      })
      .catch(() => {
        setAccountUID(null);
        console.error("Failed to get account info");
        setReady(true);
      });
  }

  useEffect(() => {
    if (props.accountUID) {
      getAccountInfo();
    } else {
      setReady(true);
    }
  }, [props.accountUID]);

  const onComplete = (account) => {
    getAccountInfo(account?.id);
    if (!isEditMode) {
      integrationsRef.current.scrollIntoView();
    }
  };

  return (
    ready && (
      <div className={styles.account}>
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h1 className={styles.title}>
              {isEditMode ? accountInfo?.name : "Create New Account"}
            </h1>
            <div className={styles.actionBarContainer}>
              {accountInfo?.stripeId && (
                <img
                  className={styles.stripeLogo}
                  title="Account subscription managed by stripe"
                  src="/static/img/logos/stripeLogoBlue.svg"
                  alt="Stripe Logo"
                />
              )}
              {accountUID && props.isInternalUser && (
                <Button
                  onClick={() => props.changeAccount(accountUID, accountInfo.name)}
                  variant="outlined"
                  size="small">
                  View Account
                </Button>
              )}
            </div>
          </div>
          <p className={styles.subtext}>
            Please fill in the information for both steps bellow. Step 1 will create the
            new account and step 2 will enable all additional features.
          </p>
        </div>
        <AccountForm
          accountUID={accountUID}
          accountInfo={accountInfo}
          isEditMode={isEditMode}
          onComplete={onComplete}
          onSnackbar={props.onSnackbar}
        />
        <div ref={integrationsRef}>
          <IntegrationsForm
            accountInfo={accountInfo}
            isEditMode={isEditMode}
            accountUID={accountUID}
            isSuperAdmin={props.userInfo.isSuperAdmin}
            onSnackbar={props.onSnackbar}
            refreshAccountInfo={getAccountInfo}
          />
        </div>
      </div>
    )
  );
};

AdminAccount.propTypes = {
  onSnackbar: PropTypes.func.isRequired,
  accountUID: PropTypes.string,
  userInfo: PropTypes.object.isRequired,
  isInternalUser: PropTypes.bool,
  changeAccount: PropTypes.func,
};

export default AdminAccount;
