import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./integrationsForm.module.css";

import Integrations from "./integrations.js";
import BrandedDomain from "./brandedDomain.js";
import Description from "./description";
import Button from "components/util/button/button.js";

import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { Controller } from "react-hook-form";

import { upsertIntegrations } from "services/adminApi.service";

import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const validationSchema = Yup.object({
  domainSettings: Yup.object({
    isActive: Yup.string().required("Required"),
    hostedDomain: Yup.string().when("isActive", {
      is: "true",
      then: (schema) => schema.required("Required"),
    }),
    emailDomain: Yup.string(),
    emailLocalPart: Yup.string(),
    emailRecipient: Yup.string().email("Invalid email address"),
  }),
});

const IntegrationsForm = ({
  accountInfo,
  accountUID,
  onSnackbar,
  refreshAccountInfo,
  isSuperAdmin,
}) => {
  // Form setup
  const initialIntegration = { active: false, apiKey: "" };
  const integrations = {};
  const integrationNames = [
    "advance",
    "blackbaud",
    "bloomerang",
    "donorConnect",
    "donorPerfect",
    "graduwayEngage",
    "kindful",
    "microsoftPowerAutomate",
    "neon",
    "planningCenter",
    "salesforce",
    "slate",
    "virtuous",
    "zapier",
  ];
  integrationNames.forEach((name) => (integrations[name] = initialIntegration));
  const initialValues = {
    integrations,
    hasTextingFeature: (!!accountInfo?.textEnabled).toString(),
    domainSettings: {
      isActive: (!!accountInfo?.domain || !!accountInfo?.customDomain).toString(),
      hostedDomain: accountInfo?.customDomain || "",
      emailDomain: accountInfo?.domain || "",
      emailLocalPart: accountInfo?.domainLocalPart || "",
    },
    emailRecipient: "",
  };

  if (accountInfo?.integrations) {
    initialValues.integrations = accountInfo.integrations?.endpoint;
  }

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors, touchedFields, isSubmitted, isValid },
    getValues,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (accountInfo) {
      setValue("integrations", accountInfo.integrations?.endpoint);
      setValue("hasTextingFeature", (!!accountInfo.appTollNum).toString());

      const areBrandedDomainsActive = !!accountInfo.domain || !!accountInfo.customDomain;
      setValue("domainSettings.isActive", areBrandedDomainsActive.toString());

      if (areBrandedDomainsActive) {
        setValue("domainSettings.emailDomain", accountInfo.domain || "");
        setValue("domainSettings.emailLocalPart", accountInfo.domainLocalPart || "");
        setValue("domainSettings.hostedDomain", accountInfo.customDomain || "");
      }
    }
  }, [accountInfo, setValue]);

  const onSubmit = async (values) => {
    try {
      if (!isValid) {
        console.log("Invalid");
        return;
      }
      setLoading(true);

      if (!values.integrations) {
        values.integrations = {};
      }

      integrationNames.forEach((name) => {
        if (!values.integrations?.[name]) {
          values.integrations[name] = initialIntegration;
        }
      });
      const domainSettingsActive = getValues("domainSettings.isActive") === "true";

      await upsertIntegrations(accountUID, {
        integrations: values.integrations,
        hasTextingFeature: values.hasTextingFeature === "true",
        customDomain: domainSettingsActive ? values.domainSettings.hostedDomain : "",
        domain: domainSettingsActive ? values.domainSettings.emailDomain : "",
        domainLocalPart: domainSettingsActive ? values.domainSettings.emailLocalPart : "",
      });

      refreshAccountInfo(accountUID);
      onSnackbar("success", "All integrations were successfully saved");
    } catch (error) {
      console.error(error);
      onSnackbar("error", "One of the integrations failed");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div
      className={styles.card}
      title={
        isSuperAdmin
          ? ""
          : "You must be a super admin to edit the integrations. Please contact support to change this section"
      }>
      <div className={accountUID && isSuperAdmin ? "" : styles.disabled}>
        <p className={`${styles.subtext} ${styles.integrations_description}`}>
          Please fill out the necessary fields. Make sure to click Save changes.
        </p>
        <Integrations control={control} getValues={getValues} />
        <Divider className={styles.divider} />
        <div className={styles.text_wrapper}>
          <Description
            title="Texting"
            description="Provision a unique phone number to send Gratavids to users."
          />
          <FormControl component="fieldset">
            <Controller
              name="hasTextingFeature"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <div className={styles.text_label}>
                    <FormControlLabel value="true" control={<Radio />} label="Included" />
                    {accountInfo?.appTollNum && (
                      <TextField
                        variant="outlined"
                        size="small"
                        value={accountInfo.appTollNum}
                        disabled
                      />
                    )}
                  </div>
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Not Included"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
        </div>
        <Divider className={styles.divider} />
        <BrandedDomain
          accountInfo={accountInfo}
          onSnackbar={onSnackbar}
          control={control}
          isActive={watch("domainSettings.isActive")}
          touchedFields={touchedFields?.domainSettings}
          isSubmitted={isSubmitted}
          errors={errors?.domainSettings}
        />
        <div className={styles.button_wrapper}>
          <Button
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="primary"
            disabled={loading}>
            SAVE CHANGES
          </Button>
          {loading && <CircularProgress className={styles.button_progress} size={32} />}
        </div>
      </div>
    </div>
  );
};

IntegrationsForm.propTypes = {
  accountInfo: PropTypes.object,
  isEditMode: PropTypes.bool,
  accountUID: PropTypes.string,
  onSnackbar: PropTypes.func.isRequired,
  refreshAccountInfo: PropTypes.func.isRequired,
  isSuperAdmin: PropTypes.bool,
};

export default IntegrationsForm;
