import React from "react";
import styles from "./logo.module.css";
import PropTypes from "prop-types";

import InputImage from "components/util/inputImage/inputImage.js";
import Description from "./description.js";

const Logo = ({
  value,
  error,
  clearErrors,
  onChange,
  setError,
  isSubmitted,
  trigger,
}) => {
  const logoError = isSubmitted && !value ? "Required" : error;
  return (
    <div className={styles.logo}>
      <Description
        title="Logo"
        description="Max logo size 10MB. All images should be on JPG or PNG format."
        error={logoError}
      />
      <InputImage
        value={value}
        onChange={(imageSource) => {
          clearErrors("imageSrc");
          // Redo form validation in case an error was encountered with a previous image
          trigger();
          onChange("imageSrc", imageSource);
        }}
        onError={(e) => setError("imageSrc", { type: "custom", message: e })}
      />
    </div>
  );
};

Logo.propTypes = {
  setError: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  touched: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  error: PropTypes.string,
};

export default Logo;
