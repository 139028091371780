import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  withMobileDialog,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
} from "@material-ui/core";
import SelectTags from "../../../../util/tags/selectTags";
import LoadingButton from "../../../../util/buttons/loadingbutton";
import { Link } from "react-router-dom";
import { toFullName } from "util/helpers";
import { createVideoFromReplyVideo } from "services/api.service";
import { useHistory } from "react-router-dom";

function VideoReplyExpireDialog(props) {
  let {
    dateCreated,
    replyVideoSrc = null,
    noteId,
    replyId,
    convertedToVideoId,
    contactData,
  } = props;
  const history = useHistory();
  let { firstName, lastName, contactId } = contactData;
  const label = toFullName(firstName, lastName, "Deleted");
  let [dialogOpen, setDialogOpen] = useState(false);
  let [state, setState] = useState({});
  let [newVideoTitle, setNewVideoTitle] = useState("");
  let [selectedFoldersArray, setSelectedFoldersArray] = useState([]);

  useEffect(() => {
    setNewVideoTitle(label + " Reply");
  }, [label]);

  let createVideoObject = async (e) => {
    e.preventDefault();
    try {
      if (newVideoTitle === "") throw new Error("Insert video title");

      //only add the user selected folders to our firebase data
      let foldersFilter = selectedFoldersArray.map((current) => {
        return current.id;
      });

      setState({ buttonState: "loading" });
      let postData = {
        videoTitle: newVideoTitle,
        foldersFilter,
        replyVideoSrc,
        contactId,
        noteId,
        replyId,
      };
      let result = await createVideoFromReplyVideo(postData);

      let newVideoId = result?.videoId;
      history.push(`/a/${props.accountId}/videos/` + newVideoId);
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.log(errorMessage);
      setState({ errorMessage });
    }
  };

  //reply vids auto-delete after 90 days
  const expireDays = 90;
  const expirationDate = new Date(dateCreated);
  expirationDate.setDate(expirationDate.getDate() + expireDays);
  const videoExists = convertedToVideoId || expirationDate.getTime() > Date.now();

  return (
    <>
      {videoExists ? (
        <div className="col-12" style={{ marginTop: "6px" }}>
          <div>
            <video
              src={replyVideoSrc + "#t=0.01"}
              controls
              playsInline
              style={{ maxWidth: "250px", borderRadius: "8px" }}
            />
          </div>

          {convertedToVideoId ? (
            <p className="greyDesc" style={{ fontSize: "13px" }}>
              Video added to library.
              <Link
                to={`/a/${props.accountId}/videos/` + convertedToVideoId}
                style={{ fontSize: "13px", marginLeft: "3px" }}>
                View now
              </Link>
              .
            </p>
          ) : (
            <p className="greyDesc" style={{ fontSize: "13px" }}>
              Video will delete on {expirationDate.toLocaleDateString()} unless you
              <button
                className="link-button"
                style={{ fontSize: "13px", marginLeft: "3px" }}
                onClick={() => setDialogOpen(true)}>
                add it to your library
              </button>
              .
            </p>
          )}
        </div>
      ) : (
        <>
          <div className="col-12" style={{ marginTop: "6px" }}>
            <div
              style={{
                width: "75px",
                height: "50px",
                backgroundColor: "#ddd",
                borderRadius: "8px",
              }}
              className="align-items-center justify-content-center">
              <div>
                <i
                  className="material-icons"
                  style={{ color: "grey", justifyContent: "center", display: "flex" }}>
                  warning
                </i>
                <span style={{ fontSize: "12px", display: "absolute", bottom: "0px" }}>
                  No video
                </span>
              </div>
            </div>
          </div>
          <p className="greyDesc" style={{ fontSize: "13px", marginTop: "3px" }}>
            Video reply automatically deleted after {expireDays} days.
          </p>
        </>
      )}

      {dialogOpen && (
        <Dialog
          fullScreen={props.fullScreen}
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          aria-labelledby="edit task dialog">
          <DialogContent
            style={{
              height: props.fullScreen ? "auto" : "350px",
              width: props.fullScreen ? "auto" : "600px",
            }}>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>
                <span style={{ fontSize: "18px" }}>Add To Video Library</span>
                <br />
                <p className="greyDesc" style={{ fontSize: "12px" }}>
                  Add this video reply to your video library where you can use it like any
                  other Gratavid video.
                </p>
              </div>
              <div style={{ width: "48px" }}>
                <IconButton aria-label="close" onClick={() => setDialogOpen(false)}>
                  <i className="material-icons">close</i>
                </IconButton>
              </div>
            </div>

            <div className="col-12" style={{ marginTop: "30px" }}>
              <TextField
                variant="outlined"
                id="newVideoTitle"
                label="Video Title"
                style={{ width: "100%" }}
                value={newVideoTitle}
                onChange={(e) => setNewVideoTitle(e.target.value)}
              />
            </div>

            <div className="col-12" style={{ marginTop: "15px" }}>
              <SelectTags
                accountId={props.accountId}
                selectedFoldersArray={selectedFoldersArray}
                setSelectedFoldersArray={setSelectedFoldersArray}
                indexName="videos"
              />
            </div>

            {state.errorMessage && (
              <div className="col-12 errorMessageBox">
                <b>{state.errorMessage}</b>
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
            <LoadingButton
              variant="contained"
              color="primary"
              buttonState={state.buttonState}
              savedLabel="Added!"
              label="Add Now"
              clickedRegularButton={createVideoObject}
              clickedSavedButton={() => setDialogOpen(false)}
            />
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default withMobileDialog()(VideoReplyExpireDialog);
VideoReplyExpireDialog.propTypes = {
  accountId: PropTypes.string.isRequired,
  dateCreated: PropTypes.string,
  noteId: PropTypes.string,
  replyVideoSrc: PropTypes.string,
  replyId: PropTypes.string,
  convertedToVideoId: PropTypes.string,
  contactData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    contactId: PropTypes.string,
  }).isRequired,
  fullScreen: PropTypes.bool,
};
