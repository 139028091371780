import React, { useState } from "react";
import PropTypes from "prop-types";
import LoadingButton from "../../../../../util/buttons/loadingbutton";
import SelectItem from "../../util/selectItem";
import GetListSelectItem from "../../util/getListSelectItem";
import { updateAccountIntegrations } from "services/api.service";

export default function SaveConfig(props) {
  let { accountInfo, eventData, event = "" } = props;
  let {
    saveAs: initSaveAs = "Task",
    caseType: initCaseType = "Other",
    taskSubtype: initTaskSubtype = "Task",
  } = eventData;

  let [state, setState] = useState({});
  let [saveAs, setSaveAs] = useState(initSaveAs);
  let [caseType, setCaseType] = useState(initCaseType);
  let [taskSubtype, setTaskSubtype] = useState(initTaskSubtype);

  let saveData = async () => {
    try {
      setState({ buttonState: "loading" });
      const updates = [
        {
          path: "endpoint/salesforce/events/" + event + "/saveAs",
          value: saveAs,
        },
        {
          path: "endpoint/salesforce/events/" + event + "/caseType",
          value: caseType,
        },
        {
          path: "endpoint/salesforce/events/" + event + "/taskSubtype",
          value: taskSubtype,
        },
      ];
      await updateAccountIntegrations(updates);
      setState({ buttonState: "saved" });
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  let saveAsOptions = [
    { id: "Case", value: "Case", label: "Case" },
    { id: "Task", value: "Task", label: "Task" },
  ];
  let taskSubtypeOptions = [
    { id: "Task", value: "Task", label: "Task" },
    { id: "Email", value: "Email", label: "Email" },
  ];

  return (
    <>
      <div style={{ marginRight: "15px" }}>
        <SelectItem
          options={saveAsOptions}
          selectedItem={saveAs}
          handleChange={(value) => setSaveAs(value)}
          label="Save As"
          alt="Select save as"
          width="250px"
        />
      </div>

      {saveAs === "Case" ? (
        <GetListSelectItem
          initialOptions="loading"
          optionsFetchNeeded={true}
          requestData={{ source: "salesforce", event: "getCaseTypes" }}
          accountId={props.accountInfo.id}
          selectedItem={{ id: caseType, label: caseType }}
          handleChange={(value) => setCaseType(value && value.id ? value.id : "")}
          label="Case Type"
          width="250px"
        />
      ) : (
        <SelectItem
          options={taskSubtypeOptions}
          selectedItem={taskSubtype}
          handleChange={(value) => setTaskSubtype(value)}
          label="Task Subtype"
          alt="Select task subtype"
          width="250px"
        />
      )}

      <div className="col-12 justify-content-center" style={{ padding: "20px 0px" }}>
        <LoadingButton
          variant="contained"
          color="primary"
          buttonState={state.buttonState}
          savedLabel={"Saved"}
          label={"Save"}
          clickedRegularButton={saveData}
          clickedSavedButton={() => {
            setState({});
          }}
        />
      </div>
    </>
  );
}

SaveConfig.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  eventData: PropTypes.object,
  event: PropTypes.string.isRequired,
};
