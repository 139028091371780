import React, { useState } from "react";
import Authenticate from "./authenticate";
import DeAuthenticateDialog from "./deAuthenticateDialog";
import IntegrationHeader from "../integration-header";
import CallCompleted from "./actions/callCompleted";

export default function DonorConnect(props) {
  let { integrationDetails, data = {} } = props;
  let { events = {} } = data; //events are transactionCreated and contactAddedUpdated
  let [connectionInfo, setConnectionInfo] = useState(props.data?.connectionInfo || null);

  if (!connectionInfo || connectionInfo === "loading")
    return (
      <Authenticate
        connectionInfo={connectionInfo}
        setConnectionInfo={setConnectionInfo}
        {...props}
      />
    );

  return (
    <div className="col-12 row">
      <IntegrationHeader
        integrationDetails={integrationDetails}
        connectionInfo={connectionInfo}>
        <DeAuthenticateDialog {...props} />
      </IntegrationHeader>
      <CallCompleted {...props} eventData={events?.callCompleted} />

      <div className="col-12" style={{ height: "100px" }} />
    </div>
  );
}
