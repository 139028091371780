import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { LinearProgress, CircularProgress, InputAdornment } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import TagItemLabel from "./tagItemLabel";
import { makeStyles } from "@material-ui/core/styles";
import {
  createAccountFolder,
  getAccountFolders,
  listContactFolders,
} from "services/api.service";
import indexHelper from "util/index-helper";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      background: "white",
    },
  },
}));

export default function SelectTags(props) {
  const filter = createFilterOptions();
  let [indexFolders, setIndexFolders] = useState("loading");
  const classes = useStyles();
  let [state, setState] = useState({});

  useEffect(() => {
    let getFoldersData = async () => {
      try {
        const accountFolders = await getAccountFolders(props.indexName);
        const tempIndexFolders = { addFolder: { disabled: true, label: "" } };
        accountFolders.forEach(
          ({ id, name }) =>
            (tempIndexFolders[name] = { id, label: name, disabled: false })
        );

        setIndexFolders(tempIndexFolders);

        //get current folders from aloglia if selectedFoldersArray is fetch. otherwise SelectTags was called when creating object
        //therefore no items exist and no need to fetch
        if (props.selectedFoldersArray !== "fetch") return;

        const contactFolders = await listContactFolders(props.contactId);
        const tempSelectedFoldersArray = contactFolders.map(({ id, name }) => ({
          id,
          label: name,
          selected: true,
        }));

        props.setSelectedFoldersArray(tempSelectedFoldersArray);
      } catch (err) {
        console.error(err);
        setState({ errorMessage: err.message });
      }
    };

    getFoldersData();
  }, []);

  let getChangeSelected = async (event, newSelectedFolders, reason) => {
    try {
      let newFolderName = null;
      if (reason === "create-option") {
        //create-option means enter is clicked and therefor it will just a string that is passed in as last tag
        let lastIndex =
          newSelectedFolders && newSelectedFolders.length
            ? newSelectedFolders.length - 1
            : 0;
        newFolderName = newSelectedFolders[lastIndex];
        if (typeof newFolderName !== "string") return;
      } else {
        let selectedItem = newSelectedFolders[newSelectedFolders.length - 1];
        newFolderName =
          selectedItem && selectedItem.inputValue ? selectedItem.inputValue : null;
      }

      //new folder created
      if (newFolderName) {
        //create a new folder
        setState({ buttonState: "loading" });
        const item = await createAccountFolder({
          name: newFolderName,
          type: indexHelper[props.indexName],
        });
        item.label = item.name;

        let tempIndexFolders = { ...indexFolders };
        tempIndexFolders[item.id] = item;
        //remove addFolder and re-add it to make it show at the end
        delete tempIndexFolders["addFolder"];
        tempIndexFolders["addFolder"] = { disabled: true, label: "", selected: false };
        //this will update our drop down list
        setIndexFolders(tempIndexFolders);

        //this will update our selected values
        let tempSelectedFoldersArray = [];
        newSelectedFolders.forEach((current) => {
          if (current.inputValue) return; //this will be the new folder passed in
          if (!current.id) return; //if enter key is pressed it will give us empty option so skip if no id
          let { id, label } = current;
          tempSelectedFoldersArray.push({ id, label, selected: true });
        });

        tempSelectedFoldersArray.push({
          id: item.id,
          label: newFolderName,
          selected: true,
        });
        props.setSelectedFoldersArray(tempSelectedFoldersArray);

        setState({});
      } else {
        let tempSelectedFoldersArray = [];
        newSelectedFolders.forEach((current) => {
          let { id, label } = current;
          tempSelectedFoldersArray.push({ id, label, selected: true });
        });
        props.setSelectedFoldersArray(tempSelectedFoldersArray);
      }

      //this notifies editExistingTags that tags have been updated so can show save button
      if (props.setHasBeenUpdated) props.setHasBeenUpdated(true);
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  if (indexFolders === "loading" || props.selectedFoldersArray === "fetch") {
    return (
      <div style={{ padding: "20px", width: "100%" }}>
        <LinearProgress />
      </div>
    );
  } else {
    //we have two sets of folders
    //1) folders are all the folders for this index under the account
    //2) selected folders are only the folders selected for this input

    let indexFoldersKeys = Object.keys(indexFolders);
    let items = indexFoldersKeys.map((key) => {
      return indexFolders[key];
    });

    let {
      label = "List",
      inputVariant = "standard",
      placeholder = "Search or start typing to create a list",
      helperText = "List are groupings you create to help organize your contacts",
      addName = "list",
      startIcon = "group",
    } = props;
    if (props.indexName === "notes" || props.indexName === "videos") {
      label = "Tags";
      inputVariant = "outlined";
      placeholder = "Search or start typing to create a tag";
      helperText = "Tags are labels you create to help organize your " + props.indexName;
      addName = "tag";
      startIcon = "local_offer";
    }

    return (
      <Autocomplete
        multiple
        value={props.selectedFoldersArray}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              label: "Create new " + addName + ' "' + params.inputValue + '"',
            });
          }

          return filtered;
        }}
        id="free-solo-with-text-demo"
        options={items}
        onChange={getChangeSelected}
        //getOptionSelected is how we tell Autocomplete which items are selected in
        getOptionSelected={(option, value) => option.id === value.id && value.selected}
        getOptionLabel={(option) => {
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.label;
        }}
        getOptionDisabled={(option) => option.disabled}
        renderOption={(option, { selected }) =>
          option.disabled ? (
            <React.Fragment>
              <Checkbox
                icon={<i className="material-icons">add_box</i>}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              Start typing to create a new {addName}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Checkbox
                icon={<i className="material-icons">check_box_outline_blank</i>}
                checkedIcon={<i className="material-icons">check_box</i>}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              <TagItemLabel
                current={option}
                indexFolders={indexFolders}
                setIndexFolders={setIndexFolders}
                accountId={props.accountId}
                indexName={props.indexName}
              />
            </React.Fragment>
          )
        }
        selectOnFocus
        clearOnBlur
        disableCloseOnSelect
        freeSolo
        style={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            fullWidth
            placeholder={placeholder}
            variant={inputVariant}
            helperText={helperText}
            className={classes.root}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {state.buttonState === "loading" ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <i
                      className="material-icons"
                      style={{ fontSize: "14px", color: "grey" }}>
                      {startIcon}
                    </i>
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
      />
    );
  }
}

SelectTags.propTypes = {
  accountId: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
  setSelectedFoldersArray: PropTypes.func.isRequired,
  contactId: PropTypes.string,
  setHasBeenUpdated: PropTypes.func,
};
