import React from "react";
import CheckBoxToApi from "components/util/hooks/input/checkBoxToApi";
import SelectInputToApi from "components/util/hooks/input/selectInputToApi";

export default function Resend(props) {
  let resendOptions = [
    { value: "1", label: "Resend in 1 day" },
    { value: "2", label: "Resend in 2 days" },
    { value: "3", label: "Resend in 3 days" },
    { value: "4", label: "Resend in 4 days" },
    { value: "5", label: "Resend in 5 days" },
    { value: "6", label: "Resend in 6 days" },
    { value: "7", label: "Resend in 7 days" },
  ];

  async function setResendActive(field, value) {
    props.setResendActive(value);
    props.onUpdateTask(field, value);
  }

  return (
    <>
      <div className="col-12" style={{ marginTop: "5px" }}>
        <CheckBoxToApi
          initialValue={props.resendActive}
          field="resendActive"
          apiCall={setResendActive}
          label="Auto resend if not watched"
          variant="standard"
          bold={true}
        />
      </div>

      {props.resendActive && (
        <>
          <div className="col-12" style={{ marginTop: "15px" }}>
            <SelectInputToApi
              label="Delay"
              field="resendInXDays"
              initialValue={props.noteContent.resendInXDays}
              options={resendOptions}
              apiCall={props.onUpdateTask}
              variant="outlined"
            />
          </div>

          <span className="col-12" style={{ height: "20px" }} />
        </>
      )}
    </>
  );
}
