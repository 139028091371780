import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { getNote } from "services/api.service";

export default function TopNotesChart(props) {
  let [sortByEvent, setSortByEvent] = useState("");
  let [dataByNoteArray, setDataByNoteArray] = useState("loading");
  let [state, setState] = useState({});

  const getData = useCallback(
    async (event) => {
      try {
        if (props.loading) {
          return;
        }
        setSortByEvent(event);

        const notes = props.dataByNoteId
          ? Object.entries(props.dataByNoteId).map(([noteId, data]) => {
              data.id = noteId;
              return data;
            })
          : [];

        notes.sort((a, b) => {
          const valueA = a[event] ? a[event] : 0;
          const valueB = b[event] ? b[event] : 0;
          return valueB - valueA;
        });

        // just take first 10 notes, charts can't handle many more unless we increase size
        const firstTenNotes = notes.slice(0, 10);

        // get all titles for notes
        await Promise.all(
          firstTenNotes.map((note) => {
            return getNote(note.id)
              .then((data) => {
                note.title = data.title;
              })
              .catch((err) => {
                //note was deleted, don't do anything
              });
          })
        );

        setDataByNoteArray(firstTenNotes);
      } catch (err) {
        console.error(err.message);
        setState({ errorMessage: err.message });
      }
    },
    [props.accountId, props.dataByNoteId, props.loading]
  );

  useEffect(() => {
    getData("note_playVideo");
  }, [getData]);

  if (props.loading || dataByNoteArray === "loading") {
    return (
      <div className="col-12" style={{ padding: "10px" }}>
        <div
          className="boxShadow"
          style={{ backgroundColor: "white", padding: "20px", borderRadius: "3px" }}>
          <LinearProgress />
        </div>
      </div>
    );
  }

  return (
    <div className="row col-12" style={{ padding: "10px" }}>
      <div
        className="row col-12 boxShadow"
        style={{ backgroundColor: "white", padding: "15px", borderRadius: "3px" }}>
        {state.errorMessage && (
          <div className="errorMessageBox">
            <b>{state.errorMessage}</b>
          </div>
        )}

        <div className="col-6">
          <h2 style={{ textTransform: "capitalize" }}>Top Notes</h2>
        </div>

        <div className="col-6 justify-content-end">
          <FormControl style={{ width: "150px" }}>
            <InputLabel htmlFor="dateType">Sort By</InputLabel>
            <Select
              value={sortByEvent}
              native
              onChange={(e) => setSortByEvent(e.target.value)}
              inputProps={{ name: "dateType", id: "dateType" }}>
              <option value="note_pageView">Page Views</option>
              <option value="note_playVideo">Video Plays</option>
              <option value="note_playedHalfVideo">Half Video Plays</option>
              <option value="note_playedFullVideo">Full Video Plays</option>
              <option value="note_ctaClick">CTA Clicks</option>
              <option value="note_reply">Replies</option>
              <option value="note_share">Shares</option>

              <option value="email_delivered">Emails Delivered</option>
              <option value="email_open">Emails Opened</option>
              <option value="email_click">Email Clicks</option>

              <option value="text_delivered">Texts Delivered</option>
              <option value="text_click">Text Clicks</option>
            </Select>
          </FormControl>
        </div>

        <div className="col-12" style={{ marginTop: "25px" }}>
          <ResponsiveContainer height={400}>
            <BarChart
              layout="vertical"
              height={200}
              data={dataByNoteArray}
              margin={{ top: 10, right: 30, left: 10, bottom: 0 }}>
              <XAxis type="number" />
              <YAxis dataKey="title" type="category" width={150} />
              <Tooltip />
              <Legend />
              <Bar dataKey={sortByEvent} fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}
TopNotesChart.propTypes = {
  accountId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  dataByNoteId: PropTypes.object,
};
