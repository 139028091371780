import React from "react";
import TimeControl from "./timeControl";
import { TextField, Button, Tooltip, IconButton } from "@material-ui/core";

function getFullSeconds(timeObject) {
  const { seconds: startSeconds = 0, nanos: startNanos = 0 } = timeObject;
  const formatNanos = Math.round(startNanos * 0.000000001 * 10) / 10;
  return parseInt(startSeconds, 10) + formatNanos;
}

export default function PhraseItem(props) {
  let {
    newPhrases,
    phrasesKeys,
    arrayIndex,
    phraseId,
    videoPlaying,
    duration,
    videoCurrentTime = 0,
  } = props;
  const currentPhrase = newPhrases[phraseId];
  const { phrase, startTime = {}, endTime = {} } = currentPhrase;

  const currentStartTime = getFullSeconds(startTime);
  const currentEndTime = getFullSeconds(endTime);

  //minStartTime will be endTime of previous phrase
  let minStartTime;
  let maxStartTimeObj;
  //maxEndTime will be start of next phrase
  let maxEndTime;
  let maxEndTimeObj;

  if (phrasesKeys.length === 1) {
    //only 1 item in array. can start at front and end at duration
    minStartTime = 0;
    maxEndTime = duration;
  } else if (arrayIndex === 0) {
    //else if more than one item and on first item
    minStartTime = 0;
    maxEndTimeObj = newPhrases[phrasesKeys[1]]
      ? newPhrases[phrasesKeys[1]].startTime
      : { seconds: 0, nanos: 0 }; //next phrase start time
    maxEndTime = getFullSeconds(maxEndTimeObj);
  } else if (arrayIndex === phrasesKeys.length - 1) {
    //last item
    maxStartTimeObj = newPhrases[phrasesKeys[phrasesKeys.length - 2]].endTime; //2nd to last time
    minStartTime = getFullSeconds(maxStartTimeObj);
    maxEndTime = duration;
  } else {
    maxStartTimeObj = newPhrases[phrasesKeys[arrayIndex - 1]].endTime;
    maxEndTimeObj = newPhrases[phrasesKeys[arrayIndex + 1]].startTime;
    minStartTime = getFullSeconds(maxStartTimeObj);
    maxEndTime = getFullSeconds(maxEndTimeObj);
  }

  //next start time (aka max end time) minus current end time
  let differenceBetweenSegments = maxEndTime - currentEndTime;

  let handleTextChange = (e) => {
    let tempNewPhrases = { ...newPhrases };
    tempNewPhrases[phraseId].phrase = e.target.value;
    props.setNewPhrases(tempNewPhrases);
  };

  const addTime = (
    differenceBetweenSegments,
    newStartTime,
    newEndTime,
    atStart = false
  ) => {
    //find endTime of last phrase
    if (differenceBetweenSegments < 0.5) {
      console.log("no difference");
      console.log(differenceBetweenSegments);
      return;
    }

    const startTimeDate = new Date(newStartTime * 1000);
    const endTimeDate = new Date(newEndTime * 1000);
    const st = startTimeDate.toISOString().substr(11, 12); //"00:00:06.300
    const et = endTimeDate.toISOString().substr(11, 12);

    const stMinutes = startTimeDate.getMinutes();
    const stSeconds = startTimeDate.getSeconds() + stMinutes * 60;
    const stNanos = startTimeDate.getMilliseconds() * 1000000;

    const etMinutes = endTimeDate.getMinutes();
    const etSeconds = endTimeDate.getSeconds() + etMinutes * 60;
    const etNanos = endTimeDate.getMilliseconds() * 1000000;

    const phraseToAdd = {
      phrase: "",
      et,
      st,
      startTime: { seconds: stSeconds, nanos: stNanos },
      endTime: { seconds: etSeconds, nanos: etNanos },
    };

    //loop through phases and rebuild keys so in correct order
    const tempNewPhrases = atStart ? { [crypto.randomUUID()]: phraseToAdd } : {};
    phrasesKeys.forEach((currentLoopPhraseId) => {
      const newPhraseId = crypto.randomUUID();
      const currentLoopPhrase = newPhrases[currentLoopPhraseId];
      tempNewPhrases[newPhraseId] = currentLoopPhrase;

      //once we get to current phrase which UI shows we are adding a row after
      //then add the new phrase we created
      if (!atStart && currentLoopPhraseId === phraseId) {
        //add newly added item
        const newAddPhraseId = crypto.randomUUID();
        tempNewPhrases[newAddPhraseId] = phraseToAdd;
      }
    });

    props.setNewPhrases(tempNewPhrases);
  };

  let deleteRow = () => {
    let tempNewPhrases = { ...newPhrases };
    delete tempNewPhrases[phraseId];
    props.setNewPhrases(tempNewPhrases);
  };

  let addToolTipLabel =
    differenceBetweenSegments >= 0.5
      ? "Click to add a new caption row"
      : "You need at least half a second of free time to insert a new caption row. Adjust the timings of this segment or the segment after";
  let currentPlayTimeIsInThisPhrase =
    videoCurrentTime >= currentStartTime && videoCurrentTime <= currentEndTime
      ? true
      : false;

  let playBarWidth = 0;
  if (currentPlayTimeIsInThisPhrase) {
    let phraseDuration = currentEndTime - currentStartTime;
    playBarWidth = (videoCurrentTime - currentStartTime) / phraseDuration;
    playBarWidth = playBarWidth * 100;
  }

  return (
    <div className="row col-12" style={{ padding: "10px 10px 0px 10px" }}>
      {arrayIndex === 0 && (
        <div className="col-12" style={{ paddingLeft: "90px" }}>
          <Tooltip
            title={
              currentStartTime > 0.5
                ? "Click to add a new caption row"
                : "You need at least half a second of free time to insert a new caption row. Adjust the timings of this segment or the segment after"
            }>
            <Button onClick={() => addTime(currentStartTime, 0, currentStartTime, true)}>
              <span style={{ fontSize: "12px", textTransform: "capitalize" }}>
                +Add row
              </span>
            </Button>
          </Tooltip>
        </div>
      )}
      <div className="row col-12">
        <div style={{ width: "90px", display: "flex", alignItems: "center" }}>
          <TimeControl
            currentStartTime={currentStartTime}
            currentEndTime={currentEndTime}
            minStartTime={minStartTime}
            maxEndTime={maxEndTime}
            newPhrases={newPhrases}
            setNewPhrases={props.setNewPhrases}
            phraseId={phraseId}
          />
        </div>
        <div style={{ flex: 1 }}>
          <TextField
            value={phrase}
            fullWidth
            rows={2}
            multiline={true}
            variant="outlined"
            onChange={handleTextChange}
          />
        </div>
        <div
          className="row"
          style={{
            width: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <div className="col-12">
            <IconButton
              edge="end"
              aria-label="play or pause"
              size="small"
              onClick={() => props.pausePlayVideo(currentStartTime)}>
              <i className="material-icons">{videoPlaying ? "pause" : "play_arrow"}</i>
            </IconButton>
          </div>
          <div className="col-12">
            <IconButton edge="end" aria-label="delete" size="small" onClick={deleteRow}>
              <i className="material-icons">delete</i>
            </IconButton>
          </div>
        </div>
      </div>

      {currentPlayTimeIsInThisPhrase && (
        <div className="col-12" style={{ paddingLeft: "92px", paddingRight: "40px" }}>
          <div
            style={{
              borderRadius: "15px",
              height: "2px",
              backgroundColor: "rgba(0, 0, 0, 0.54)",
              width: playBarWidth + "%",
            }}
          />
        </div>
      )}

      <div className="col-12" style={{ paddingLeft: "90px" }}>
        <Tooltip title={addToolTipLabel}>
          <Button
            onClick={() =>
              addTime(differenceBetweenSegments, currentEndTime, maxEndTime)
            }>
            <span style={{ fontSize: "12px", textTransform: "capitalize" }}>
              +Add row
            </span>
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}
