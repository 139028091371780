import React from "react";
import Name from "./name";
import Email from "./email";
import Password from "./password";

export default function General(props) {
  return (
    <div className="row col-12" style={{ marginTop: "5px" }}>
      <Name {...props} />
      <Email {...props} />
      <Password {...props} />
    </div>
  );
}
