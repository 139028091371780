import React, { useState } from "react";
import LoadingButton from "../../../util/buttons/loadingbutton";
import QuestionToLink from "../../../util/explainers/questionToLink";
import Action from "../actions/action";
import AddAction from "../actions/addAction";
import CopyTextInput from "../../../util/copyTextInput";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { updateAccountIntegrations } from "services/api.service";

export default function Endpoint(props) {
  const SortableItem = sortableElement(({ currentAction, key, arrayIndex }) => (
    <Action
      currentAction={currentAction}
      key={key}
      arrayIndex={arrayIndex}
      order={arrayIndex + 1}
      accountInfo={props.accountInfo}
      actions={actions}
      setActions={setActions}
      users={props.users}
    />
  ));
  const SortableContainer = sortableContainer(({ children }) => (
    <div className="col-12">{children}</div>
  ));

  let { data, integrationDetails } = props;
  let initialActions = data.actions ? data.actions : {};

  let [state, setState] = useState({});
  let [actions, setActions] = useState(initialActions);

  let saveActions = async () => {
    try {
      setState({ buttonState: "loading" });

      await updateAccountIntegrations([
        {
          path: `endpoint/${integrationDetails.integrationId}/actions`,
          value: actions,
        },
      ]);

      setState({ buttonState: "saved" });
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  let { list = [] } = actions;
  let showActions = list.map((currentAction, index) => {
    return (
      <SortableItem
        key={currentAction.actionId}
        index={index}
        arrayIndex={index}
        currentAction={currentAction}
      />
    );
  });

  let onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;

    let tempActions = { ...actions };
    let newList = arrayMove(actions.list, oldIndex, newIndex);
    tempActions.list = newList;
    setActions(tempActions);
  };

  let { header, desc, logoSrc, stepOneDesc, endpointURLNoKey, brandHex } =
    integrationDetails;

  return (
    <div className="row col-12 pagePadding">
      <div className="col-12 row">
        <div
          style={{
            width: "70px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <div
            style={{
              marginRight: "8px",
              width: "45px",
              height: "45px",
              backgroundColor: brandHex,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img src={logoSrc} width="35px" alt="logo" />
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <h1>{header}</h1>
          <span className="greyDesc">{desc}</span>
        </div>
      </div>

      <div className="row col-12" style={{ marginTop: "30px" }}>
        <div
          style={{
            width: "70px",
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
          }}>
          <span className="circleStep">1</span>
        </div>
        <div style={{ flex: 1, marginTop: "6px" }}>
          <h2 style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "5px" }}>
            Setup Webhook
          </h2>
          <p style={{ marginTop: "10px" }}>{stepOneDesc}</p>
          <div style={{ marginTop: "10px" }}>
            <CopyTextInput
              label="Endpoint URL"
              copyURL={endpointURLNoKey + data.apiKey}
            />
          </div>
        </div>
      </div>

      <div className="row col-12" style={{ marginTop: "30px" }}>
        <div
          style={{
            width: "70px",
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
            marginTop: "5px",
          }}>
          <span className="circleStep">2</span>
        </div>
        <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
          <h2 style={{ fontSize: "16px", fontWeight: "bold", marginTop: "3px" }}>
            When a new transaction is made...
          </h2>
          <QuestionToLink id="integrations" desc="integrations" />
        </div>
      </div>

      <AddAction {...props} actions={actions} setActions={setActions} />

      <SortableContainer onSortEnd={onSortEnd} lockAxis="y" distance={1}>
        {showActions}
      </SortableContainer>

      <Action
        {...props}
        defaultAction={true}
        currentAction={actions}
        actions={actions}
        setActions={setActions}
      />

      {state.errorMessage && (
        <div className="errorMessageBox">
          <b>{state.errorMessage}</b>
        </div>
      )}

      <div className="col-12 justify-content-center" style={{ padding: "20px 0px" }}>
        <LoadingButton
          variant="contained"
          color="primary"
          buttonState={state.buttonState}
          savedLabel={"Saved"}
          label={"Save"}
          clickedRegularButton={saveActions}
          clickedSavedButton={() => {
            setState({});
          }}
        />
      </div>
    </div>
  );
}
