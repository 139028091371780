import React from "react";
import Button from "@material-ui/core/Button";
import ShareButtons from "./shareButtons";

export default function VideoDoneOverlay(props) {
  let { buttons } = props;
  let buttonKeys = buttons ? Object.keys(buttons) : [];
  let showButtons = buttonKeys.map((key) => {
    let current = buttons[key];
    return (
      <div
        key={key}
        className="col-12 align-items-center justify-content-center"
        style={{ padding: "0px 5px 15px 5px" }}>
        <a
          href={current.buttonLink}
          className="noteButton justify-content-center"
          target="_blank"
          rel="noopener noreferrer"
          style={{ border: "1px white solid" }}>
          <span style={{ color: "#fff" }}>{current.buttonLabel}</span>
        </a>
      </div>
    );
  });

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "black",
        zIndex: 901,
        opacity: 0.9,
      }}>
      <div className="row col-12">
        {showButtons}

        <div className="col-12 justify-content-center" style={{ marginTop: "15px" }}>
          <Button variant="outlined" onClick={() => props.restartStory()}>
            <div
              className="align-items-center justify-content-center"
              style={{ borderRadius: "50%", width: "35px", height: "35px" }}>
              <img
                src="https://gratavid.com/static/icon/replay-24px.svg"
                width="35px"
                alt="watch again"
              />
            </div>
            <span style={{ color: "white", marginLeft: "5px" }}>Watch Again</span>
          </Button>
        </div>

        <div className="col-12 justify-content-center" style={{ marginTop: "15px" }}>
          <ShareButtons {...props} calledFromVideoDone={true} />
        </div>
      </div>
    </div>
  );
}
