import React from "react";
import PropTypes from "prop-types";
import ItemAnalytics from "../../../../../util/analytics/itemAnalytics";
import timeSinceDate from "../../../../../util/functions/timeSinceDate";
import { toFullName } from "util/helpers";

export default function RecipientItem(props) {
  const { contactId, contact, timestamp, count } = props.analytics;
  const timeSinceLastSent = timeSinceDate(timestamp, "", "");

  return (
    <div
      className="row col-12"
      style={{ padding: "8px 24px", borderBottom: "1px solid #ddd" }}>
      <div className="col-4">
        {contact ? (
          <>
            <p className="paragraph-ellipsis">
              <a
                target="_blank"
                href={`/a/${props.accountInfo.id}/contacts/` + contactId}
                rel="noopener noreferrer">
                {toFullName(contact.firstName, contact.lastName)}
              </a>
            </p>
            <p className="paragraph-ellipsis" style={{ fontSize: "12px", color: "grey" }}>
              {contact.email}
            </p>
          </>
        ) : (
          <p
            className="paragraph-ellipsis"
            style={{ fontSize: "14px", color: "grey", paddingTop: "5px" }}>
            Contact deleted
          </p>
        )}
      </div>
      <div
        className="col-4 col-md-3"
        style={{ display: "flex", alignItems: "center", paddingLeft: "8px" }}>
        <div style={{ fontSize: "12px" }}>{timeSinceLastSent}</div>
      </div>
      <div
        className="col-4 col-md-3"
        style={{ display: "flex", alignItems: "center", paddingLeft: "8px" }}>
        <div style={{ fontSize: "12px" }}>{count}</div>
      </div>
      <div className="hidden-md-down col-2">
        <div style={{ display: "flex", alignItems: "center" }}>
          <ItemAnalytics analytics={props.analytics.events} />
        </div>
      </div>
    </div>
  );
}

RecipientItem.propTypes = {
  analytics: PropTypes.object,
};
