import React from "react";
import PropTypes from "prop-types";
import SelectItem from "./selectItem";
import SelectList from "./selectList";
import milestoneTransactionTierOptions from "../util/data/milestoneTransactionTierOptions";

export default function SelectSinceMonthAndTier(props) {
  let {
    actions,
    actionIndex,
    conditionId,
    currentCondition = {},
    selectedItems = {},
    currencyLabel,
  } = props; //selectedExcludes is all excludes for event ie - opportunityWon
  let { sinceMonth = "1" } = currentCondition;

  let handleMonthChange = (value) => {
    let tempActions = { ...actions };
    tempActions.list[actionIndex].conditions[conditionId].sinceMonth = value;
    props.setActions(tempActions);
  };

  let handleListChange = (metric, newSelectedItems, operator) => {
    let metricValue = {};
    newSelectedItems.forEach(({ id, label }) => {
      metricValue[id] = { id, label };
    });

    let item = { metric, metricValue, operator, sinceMonth }; //make sure to add sinceMonth back
    let tempActions = { ...actions };
    tempActions.list[actionIndex].conditions[conditionId] = item;
    props.setActions(tempActions);
  };

  let monthOptions = [
    { id: "1", value: "1", label: "January 1st" },
    { id: "2", value: "2", label: "February 1st" },
    { id: "3", value: "3", label: "March 1st" },
    { id: "4", value: "4", label: "April 1st" },
    { id: "5", value: "5", label: "May 1st" },
    { id: "6", value: "6", label: "June 1st" },
    { id: "7", value: "7", label: "July 1st" },
    { id: "8", value: "8", label: "August 1st" },
    { id: "9", value: "9", label: "September 1st" },
    { id: "10", value: "10", label: "October 1st" },
    { id: "11", value: "11", label: "November 1st" },
    { id: "12", value: "12", label: "December 1st" },
  ];

  let tierAmountOptions = milestoneTransactionTierOptions(currencyLabel);

  return (
    <div style={{ display: "flex" }}>
      <span
        style={{ marginRight: 15, width: 150, display: "flex", alignItems: "center" }}>
        <SelectItem
          options={monthOptions}
          selectedItem={sinceMonth}
          handleChange={(value) => handleMonthChange(value)}
          label="Since"
          width="100%"
        />
      </span>

      <SelectList
        options={tierAmountOptions}
        selectedItems={selectedItems}
        handleChange={(value) =>
          handleListChange(
            "milestoneSinceMonthAmountReached",
            value,
            "milestoneSinceMonthAmountReached"
          )
        }
        label="Milestones"
        width="50%"
      />
    </div>
  );
}
SelectSinceMonthAndTier.propTypes = {
  actions: PropTypes.object,
  actionIndex: PropTypes.number,
  conditionId: PropTypes.string,
  currentCondition: PropTypes.shape({}),
  selectedItems: PropTypes.shape({}),
  currencyLabel: PropTypes.string,
  setActions: PropTypes.func,
};
