import React, { useState, useRef } from "react";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Snackbar from "@material-ui/core/Snackbar";

export default function CopyTextInput(props) {
  let [snackbarOpen, setSnackbarOpen] = useState(false);
  const publicURLRef = useRef(null);

  let { label, copyURL } = props;

  return (
    <>
      <FormControl fullWidth>
        <InputLabel htmlFor="publicURL">{label}</InputLabel>
        <Input
          id="publicURL"
          inputRef={publicURLRef}
          type="text"
          value={copyURL}
          disabled
          onChange={(e) => props.passedSetState(props.stateName, e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <CopyToClipboard text={copyURL} onCopy={() => setSnackbarOpen(true)}>
                <IconButton aria-label={"copy " + label}>
                  <i className="material-icons">content_copy</i>
                </IconButton>
              </CopyToClipboard>
            </InputAdornment>
          }
        />
      </FormControl>

      <Snackbar
        autoHideDuration={2500}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        ContentProps={{ "aria-describedby": "public url was copied" }}
        message={<span id="message-id">URL copied</span>}
      />
    </>
  );
}
