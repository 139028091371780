import React from "react";
import { ButtonBase, makeStyles } from "@material-ui/core";
import { useGetNoteContent } from "../hooks/useGetNoteContent";

const useStyles = makeStyles((theme) => ({
  buttonFillOverlay: {
    backgroundColor: "rgba(82,112,143,0)",
    transition: "background-color .5s",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    "&:hover": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "background-color .5s",
      backgroundColor: "rgba(82,112,143,.85)",
    },
    "& > span": {
      transition: "color .5s",
      color: "rgba(255,255,255,0)",
    },
    "&:hover > span": {
      transition: "color .5s",
      color: "rgba(255,255,255,1)",
    },
  },
}));

export default function PreviewNoteButton(props) {
  let { contentId, taskId, noteId, contactId, forceLoadFreshNoteContent } = props;
  let [noteContent] = useGetNoteContent(
    noteId,
    taskId,
    contentId,
    forceLoadFreshNoteContent
  );
  const classes = useStyles();

  if (noteContent === "loading") {
    return (
      <div
        className="row col-12 "
        style={{
          padding: "15px 10px",
          height: "250px",
          border: "1px #ddd solid",
          borderRadius: "4px",
          position: "relative",
        }}>
        <div
          className="row col-12 align-items-start box-shadow"
          style={{
            backgroundColor: "white",
            borderRadius: "5px",
            height: "220px",
            overflow: "hidden",
          }}>
          <div
            className="col-6 justify-content-center loading-pulse"
            style={{ backgroundColor: "#ededed", borderRadius: "4px", height: "100%" }}
          />

          <div className="col-6 row" style={{ padding: "5px 10px" }}>
            <div
              style={{
                marginTop: "3px",
                width: "100%",
                height: "30px",
                backgroundColor: "#ededed",
                borderRadius: "4px",
              }}
              className="loading-pulse"
            />
            <div
              style={{
                marginTop: "3px",
                width: "100%",
                height: "20px",
                backgroundColor: "#ededed",
                borderRadius: "4px",
              }}
              className="loading-pulse"
            />
          </div>
        </div>
      </div>
    );
  }

  let { messageHtml, thumbnailUrl, senderName, logoUrl } = noteContent;

  let href =
    `${process.env.REACT_APP_FRONTEND}/note?noteId=` +
    noteId +
    "&preview=true&showPreviewNotification=1";
  if (contactId) href += "&cId=" + contactId;
  if (taskId) href += "&tId=" + taskId;
  if (contentId) href += "&contentId=" + contentId;

  return (
    <ButtonBase
      focusRipple
      className="row col-12 box-shadow"
      style={{
        border: "1px solid rgb(221, 221, 221, 0.5)",
        height: "250px",
        borderRadius: "4px",
        position: "relative",
      }}>
      <a href={href} target="_blank" rel="noopener noreferrer">
        <div
          className={classes.buttonFillOverlay}
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            borderRadius: "4px",
          }}>
          <span>
            Preview{" "}
            <i className="material-icons" style={{ fontSize: "15px" }}>
              launch
            </i>
          </span>
        </div>
      </a>

      <div
        className="row col-12 align-items-start"
        style={{
          backgroundColor: "white",
          borderRadius: "5px",
          height: "220px",
          overflow: "hidden",
        }}>
        <div className="col-6 justify-content-center" style={{ borderRadius: "4px" }}>
          <img
            src={thumbnailUrl}
            alt="first note video thumbnail"
            style={{
              borderRadius: "5px",
              maxWidth: "100%",
              height: "220px",
              objectFit: "cover",
            }}
          />
        </div>

        <div className="col-6" style={{ display: "flex", padding: "10px" }}>
          <div style={{ width: "60px" }}>
            <img
              src={logoUrl}
              width="50px"
              alt="logo"
              style={{ borderRadius: "50%", overflow: "hidden" }}
            />
          </div>
          <div style={{ flex: 1 }} className="row align-items-start">
            <div className="col-12" style={{ textAlign: "left" }}>
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: 500,
                  textAlign: "left",
                  textTransform: "none",
                }}>
                {senderName}
              </span>
            </div>
            <div
              className="col-12"
              style={{
                borderLeft: "2px #3F99F8 solid",
                paddingLeft: "10px",
                marginTop: "5px",
                textAlign: "left",
              }}>
              <span
                style={{
                  fontSize: "16px",
                  whiteSpace: "pre-line",
                  textTransform: "none",
                }}>
                {messageHtml}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ButtonBase>
  );
}
