import { useEffect, useState } from "react";
import { getAccountUser } from "services/api.service";

export const useGetUserContactData = (userUID) => {
  // State and setter for search term
  let [userData, setUserData] = useState("loading");

  useEffect(() => {
    const getData = async () => {
      try {
        let { email, fullName } = await getAccountUser(userUID);
        let label = fullName ? fullName : email;
        setUserData({ label, email, fullName });
      } catch (err) {
        console.error(err.message);
        setUserData({ label: "Someone" });
      }
    };

    getData();
  }, [userUID]);

  return [userData];
};
