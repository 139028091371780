import React from "react";
import SendRequest from "./send/sendRequest";
import ManageVideoIn from "../manageVideo";
import Breadcrumb from "../../../../util/breadcrumb/breadcrumb";

export default function Request(props) {
  if (props.mediaURL) {
    return <ManageVideoIn {...props} />;
  } else {
    return (
      <div className="row col-12 pagePadding">
        <Breadcrumb
          indexName="videos"
          id={props.videoId}
          accountId={props.accountInfo.id}
          title={props.title}
          titleKey="title"
          passedSetState={props.passedSetEditVideoState}
          history={props.history}
        />

        <SendRequest {...props} />
      </div>
    );
  }
}
