import React from "react";
import ItemAnalytics from "../../../../util/analytics/itemAnalytics";
import getMergedString from "../../../../util/contacts/getMergedString";

export default function NoteItem(props) {
  let { current, contactData, accountCustomProperties = {} } = props;
  let { noteId, taskId, analytics, note } = current;
  let { contactId } = contactData;

  let { resendEmailBody, senderName = "" } = note;
  if (!resendEmailBody) return null;

  let noteURL =
    `${process.env.REACT_APP_FRONTEND}/note?noteId=` +
    noteId +
    "&cId=" +
    contactId +
    "&preview=true&showPreviewNotification=1";
  if (taskId) noteURL += "&tId=" + taskId;

  resendEmailBody = getMergedString(
    accountCustomProperties,
    resendEmailBody,
    contactData
  );
  let regex = new RegExp("%gratavidLink%", "g");
  resendEmailBody = resendEmailBody.replace(regex, "Watch Now");

  let senderNameRegex = new RegExp("%senderName%", "g");
  resendEmailBody = resendEmailBody.replace(senderNameRegex, senderName);

  let date = new Date(current.dateCreated);
  let showDate = date.toLocaleString();

  return (
    <div className="row col-12" style={{ marginBottom: "25px" }}>
      <div className="col-12 justify-content-center" style={{ margin: "12px 0px" }}>
        <span style={{ fontSize: "12px", color: "rgba(0, 0, 0, .40)", fontWeight: 500 }}>
          {showDate}
        </span>
      </div>

      <div className="col-12 justify-content-end">
        <a
          href={noteURL}
          target="_blank"
          rel="noopener noreferrer"
          style={{ fontWeight: "normal" }}>
          <div
            style={{
              backgroundColor: "#0099FF",
              borderRadius: "1.3em",
              padding: "6px 12px",
              borderBottomRightRadius: "5px",
            }}>
            <span style={{ fontSize: "15px", color: "white", whiteSpace: "pre-line" }}>
              {resendEmailBody}
            </span>
          </div>
        </a>
      </div>

      <div className="col-12 justify-content-end" style={{ marginTop: "5px" }}>
        <ItemAnalytics analytics={analytics?.d?.asc} />
      </div>
    </div>
  );
}
