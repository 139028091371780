import React from "react";
import PropTypes from "prop-types";
import styles from "./account-switcher.module.css";
import { FormControl, InputLabel, Menu, MenuItem, Select } from "@material-ui/core";

export default function AccountSwitcher(props) {
  return (
    <div className={styles.accountSwitcher}>
      {props.isInternalUser &&
        props.accountInfo &&
        props.userInfo?.roles?.length === 1 && (
          <a
            className={styles.accountName}
            href={`/a/${props.accountInfo.id}/admin/accounts/${props.accountInfo.id}`}>
            {props.accountInfo.name}
          </a>
        )}

      {!props.isInternalUser &&
        props.accountInfo &&
        props.userInfo?.roles?.length === 1 && (
          <div className={styles.accountName}>{props.accountInfo.name}</div>
        )}

      {props.userInfo?.roles?.length > 1 && (
        <div className={styles.accountName}>
          <FormControl>
            <InputLabel id="account-switcher">Account</InputLabel>
            <Select
              labelId="account-switcher"
              className={styles.accountSwitcher}
              value={props.accountInfo.id}
              onChange={props.handleAccountChange}
              title={props.accountInfo.name}
              MenuProps={{ disableScrollLock: true }}>
              {props.userRole?.account?.name && (
                <MenuItem value={props.userRole.accountId}>
                  {props.userRole.account.name}
                </MenuItem>
              )}
              {props.userInfo?.roles?.map(
                (role) =>
                  role.accountId !== props.userRole.accountId && (
                    <MenuItem key={role.accountId} value={role.accountId}>
                      {role.account.name}
                    </MenuItem>
                  )
              )}
            </Select>
          </FormControl>
        </div>
      )}
    </div>
  );
}

AccountSwitcher.propTypes = {
  isInternalUser: PropTypes.bool,
  accountInfo: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  userInfo: PropTypes.shape({
    roles: PropTypes.array,
  }),
  userRole: PropTypes.object,
  passedSetStateFromApp: PropTypes.func.isRequired,
  handleAccountChange: PropTypes.func,
};
