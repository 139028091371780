import React, { useEffect, useState } from "react";
import GrataStripes from "../../stripes/grataStripes";
import EmailPassword from "./emailPassword";
import SSOLogins from "../ssoLogins";
import { getSearchParams } from "util/helpers";

export default function LoginFromInvite() {
  let [signInProviders, setSignInProviders] = useState(["emailPassword"]);
  const [signInProviderSignInText, setSignInProviderSignInText] =
    useState("Google or Microsoft");
  useEffect(() => {
    const searchParams = getSearchParams();
    const providers = searchParams.getAll("signInProvider") || ["emailPassword"];

    setSignInProviderSignInText(
      providers
        .map((ssoProvider) => ssoProvider[0].toUpperCase() + ssoProvider.slice(1))
        .join(" or ")
    );

    if (searchParams.get("token")) {
      providers.push("emailPassword");
    }

    setSignInProviders(providers);
  }, []);

  return (
    <>
      <GrataStripes />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          minHeight: "100vh",
        }}>
        <div className="row justify-content-center" style={{ marginTop: "64px" }}>
          <div
            className="col-11 col-sm-10 col-md-8 col-lg-6 col-xl-5 box-shadow"
            style={{
              position: "relative",
              minHeight: "400px",
              backgroundColor: "#fff",
              borderRadius: "4px",
            }}>
            <div
              className="justify-content-center"
              style={{ width: "100%", backgroundColor: "#fff" }}>
              <div
                className="box-shadow"
                style={{
                  zIndex: 998,
                  position: "absolute",
                  width: "64px",
                  height: "64px",
                  top: "-35px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  border: "1px #fff solid",
                  backgroundColor: "#fff",
                  display: "flex",
                  alignItems: "center",
                }}>
                <img
                  src="https://gratavid.com/static/img/icon_padded.jpg"
                  width="100%"
                  alt="logo"
                />
              </div>
            </div>

            <div className="row col-12" style={{ padding: "0px" }}>
              <div className="col-12" style={{ margin: "42px 0px 20px 0px" }}>
                <h1 style={{ fontSize: "22px", textAlign: "center" }}>
                  Welcome To Gratavid!
                </h1>
                <p
                  className="greyDesc"
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  <i
                    className="material-icons"
                    title="assignment icon"
                    style={{ fontSize: "18px", marginRight: "5px" }}>
                    assignment
                  </i>
                  Sign Up Form
                </p>
              </div>

              {signInProviders.includes("emailPassword") && <EmailPassword />}

              {(signInProviders.includes("google") ||
                signInProviders.includes("microsoft")) && (
                <div className="row col-12" style={{ padding: "20px" }}>
                  <p style={{ textAlign: "center" }}>
                    Your organization has enabled single sign-on with{" "}
                    {signInProviderSignInText} and Gratavid. Sign in with your{" "}
                    {signInProviderSignInText} work account to continue.
                  </p>
                  <SSOLogins signInProviders={signInProviders} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
