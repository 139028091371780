import React, { useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import LoadingButton from "../../../../../util/buttons/loadingbutton";
import LoadingSwitch from "../../../../../util/buttons/loadingSwitch";
import DefaultAction from "../../../actions/action";
import BlackbaudAction from "./action";
import AddAction from "../../../actions/addAction";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import Exclude from "../../util/exclude";
import { updateAccountIntegrations, manageIntegrations } from "services/api.service";

const SortableItem = sortableElement(
  ({
    currentAction,
    key,
    actionIndex,
    accountInfo,
    actions,
    setActions,
    users,
    currencyLabel,
  }) => (
    <BlackbaudAction
      currentAction={currentAction}
      key={key}
      actionIndex={actionIndex}
      order={actionIndex + 1}
      accountInfo={accountInfo}
      actions={actions}
      setActions={setActions}
      users={users}
      currencyLabel={currencyLabel}
    />
  )
);
const SortableContainer = sortableContainer(({ children }) => (
  <div className="col-12">{children}</div>
));

export default function GiftAdded(props) {
  let { eventData = {}, users = [], data = {} } = props;
  eventData = eventData || {};
  let initialActions = eventData.actions || {};
  let [actions, setActions] = useState(initialActions);
  let initialExcludes = eventData.excludes ? eventData.excludes : {};
  let [selectedExcludes, setSelectedExcludes] = useState(initialExcludes);
  let [state, setState] = useState({});
  let initialActive = eventData.active ? eventData.active : false;
  let [active, setActive] = useState(initialActive);
  let [open, setOpen] = useState(false);
  let currencyLabel =
    data && data.settings && data.settings.currencyLabel
      ? data.settings.currencyLabel
      : "$";

  let saveActions = async () => {
    try {
      let updates = [];
      setState({ buttonState: "loading" });
      updates.push({
        path: "endpoint/blackbaud/events/giftAdded/actions",
        value: actions,
      });
      updates.push({
        path: "endpoint/blackbaud/events/giftAdded/excludes",
        value: selectedExcludes,
      });

      await updateAccountIntegrations(updates);

      setState({ buttonState: "saved" });
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  let { list = [] } = actions;
  let showActions = list.map((currentAction, index) => {
    return (
      <SortableItem
        key={currentAction.actionId}
        index={index}
        actionIndex={index}
        currentAction={currentAction}
        accountInfo={props.accountInfo}
        actions={actions}
        setActions={setActions}
        users={users}
        currencyLabel={currencyLabel}
      />
    );
  });

  let onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;

    let tempActions = { ...actions };
    let newList = arrayMove(actions.list, oldIndex, newIndex);
    tempActions.list = newList;
    setActions(tempActions);
  };

  const handleChange = (event, isExpanded) => {
    setOpen(isExpanded ? true : false);
  };

  const handleSwitch = async (e) => {
    e.stopPropagation();
    let newActiveValue = e.target.checked;

    try {
      setState({ buttonState: "loading" });
      let subscribe = newActiveValue;
      await manageIntegrations(
        { source: "blackbaud", accountId: props.accountInfo.id },
        { event: "giftAdded", subscribe }
      );
      setState({});
      setActive(newActiveValue);
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.error(errorMessage);
      setState({ errorMessage });
    }
  };

  let giftTypeOptions = [
    { id: "Other", label: "Other" },
    { id: "RecurringGiftPayment", label: "Recurring Gift Payment" },
    { id: "RecurringGift", label: "Recurring Gift" },
    { id: "Amendment", label: "Amendment" },
    { id: "Donation", label: "Donation" },
    { id: "GiftInKind", label: "Gift In Kind" },
    { id: "GeneralLedgerReversal", label: "General Ledger Reversal" },
    { id: "MatchingGiftPledge", label: "Matching Gift Pledge" },
    { id: "MatchingGiftPayment", label: "Matching Gift Payment" },
    { id: "MatchingGiftWriteOff", label: "Matching Gift Write Off" },
    { id: "PlannedGift", label: "Planned Gift" },
    { id: "Pledge", label: "Pledge" },
    { id: "PledgePayment", label: "Pledge Payment" },
    { id: "PledgeWriteOff", label: "Pledge Write Off" },
    { id: "Stock", label: "Stock" },
    { id: "SoldStock", label: "Sold Stock" },
  ];

  return (
    <Accordion
      expanded={open}
      onChange={handleChange}
      style={{
        cursor: "pointer",
        borderRadius: "8px",
        marginTop: "25px",
        width: "100%",
      }}>
      <AccordionSummary
        expandIcon={<i className="material-icons">expand_less</i>}
        aria-controls="panel1bh-content"
        id="panel1bh-header">
        <div style={{ width: "80px", display: "flex", alignItems: "center" }}>
          <LoadingSwitch
            checked={active}
            handleSwitch={handleSwitch}
            buttonState={state && state.buttonState}
          />
        </div>
        <div style={{ flex: 1 }}>
          <h2>New Gift Added In Blackbaud</h2>
          <span className="greyDesc">
            Create a task or send a note when a new gift is added in Blackbaud.
          </span>
        </div>
      </AccordionSummary>
      <AccordionDetails className="row col-12" style={{ cursor: "auto" }}>
        <div className="row col-12">
          <div
            style={{
              width: "70px",
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              marginTop: "5px",
            }}>
            <span className="circleStep">1</span>
          </div>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <h2 style={{ fontSize: "16px", fontWeight: "bold", marginTop: "3px" }}>
              When a new gift is added in Blackbaud...
            </h2>
          </div>
        </div>

        <SortableContainer onSortEnd={onSortEnd} lockAxis="y" distance={1}>
          {showActions}
        </SortableContainer>

        <AddAction {...props} actions={actions} setActions={setActions} />

        <DefaultAction
          {...props}
          users={users}
          defaultAction={true}
          currentAction={actions}
          actions={actions}
          setActions={setActions}
        />

        <div className="col-12 justify-content-center" style={{ padding: "20px 0px" }}>
          <LoadingButton
            variant="contained"
            color="primary"
            buttonState={state.buttonState}
            savedLabel={"Saved"}
            label={"Save"}
            clickedRegularButton={saveActions}
            clickedSavedButton={() => {
              setState({});
            }}
          />
        </div>

        <div
          className="row col-12"
          style={{ marginTop: "25px", paddingTop: "25px", borderTop: "1px #ddd solid" }}>
          <div
            style={{
              width: "70px",
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              marginTop: "5px",
            }}>
            <i
              className="material-icons"
              title="Exclude"
              style={{ fontSize: "18px", color: "#24305e" }}>
              filter_alt
            </i>
          </div>
          <div style={{ flex: 1 }}>
            <h3 style={{ fontSize: "16px", fontWeight: "bold", marginTop: "3px" }}>
              Exclusions
            </h3>
            <p className="greyDesc">
              Exclude gifts by type, funds, and campaigns to ensure this integration
              doesn't fire for unintended donations (recurring donations, materials,
              etc.). Gratavid will take no action if the gift matches any of these
              exclusions.
            </p>
          </div>
        </div>

        <div style={{ padding: "0px 20px" }} className="row col-12">
          <Exclude
            selectedExcludes={selectedExcludes}
            setSelectedExcludes={setSelectedExcludes}
            source="blackbaud"
            excludeKey="giftTypes"
            title="Blackbaud Gift Types"
            options={giftTypeOptions}
            optionsFetchState="fetched"
            accountInfo={props.accountInfo}
          />
          <Exclude
            selectedExcludes={selectedExcludes}
            setSelectedExcludes={setSelectedExcludes}
            source="blackbaud"
            excludeKey="campaigns"
            title="Blackbaud Campaigns"
            fetchEvent="getCampaignList"
            accountInfo={props.accountInfo}
          />
          <Exclude
            selectedExcludes={selectedExcludes}
            setSelectedExcludes={setSelectedExcludes}
            source="blackbaud"
            excludeKey="funds"
            title="Blackbaud Funds"
            fetchEvent="getFundsList"
            accountInfo={props.accountInfo}
          />
        </div>

        {state.errorMessage && (
          <div className="errorMessageBox">
            <b>{state.errorMessage}</b>
          </div>
        )}

        <div className="col-12 justify-content-center" style={{ padding: "20px 0px" }}>
          <LoadingButton
            variant="contained"
            color="primary"
            buttonState={state.buttonState}
            savedLabel={"Saved"}
            label={"Save"}
            clickedRegularButton={saveActions}
            clickedSavedButton={() => {
              setState({});
            }}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
