import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { MenuItem, LinearProgress } from "@material-ui/core";
import timeSinceDate from "../../../util/functions/timeSinceDate";
import { getVideo } from "services/api.service";

export default function VideoRequestItem(props) {
  let [state, setState] = useState({ contactLabel: "loading" });

  useEffect(() => {
    let getData = async () => {
      try {
        const video = await getVideo(props.current.videoId);
        setState({ contactLabel: video?.speakerName || "Someone" });
      } catch (err) {
        console.error(err.message);
        setState({ contactLabel: "Someone" });
      } finally {
        props.updateMenuPosition();
      }
    };

    getData();
  }, []);

  if (!state.contactLabel) return null;

  let timeSinceLastSent = timeSinceDate(new Date(props.current.createdDate));

  return (
    <MenuItem
      style={{ display: "flex" }}
      component={Link}
      to={`/a/${props.accountId}/videos/` + props.current.videoId}
      onClick={() => props.setAnchorEl(null)}>
      <div style={{ width: "30px" }}>
        <i
          className="material-icons"
          style={{ marginRight: "10px", color: "grey", fontSize: "20px" }}>
          backup
        </i>
      </div>
      <div style={{ flex: 1 }} className="row">
        <div className="col-12">
          {state.contactLabel === "loading" ? (
            <LinearProgress />
          ) : (
            state.contactLabel + " finished uploading a video"
          )}
        </div>
        <div className="col-12">
          <p className="greyDesc" style={{ fontSize: "14px" }}>
            {timeSinceLastSent}
          </p>
        </div>
      </div>
    </MenuItem>
  );
}

VideoRequestItem.propTypes = {
  current: PropTypes.object.isRequired,
  accountId: PropTypes.string.isRequired,
  setAnchorEl: PropTypes.func.isRequired,
  updateMenuPosition: PropTypes.func.isRequired,
};
