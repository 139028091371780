import React from "react";
import styles from "./drawerItem.module.css";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

export default function DrawerItem(props) {
  const linkProps = props.to?.startsWith("http")
    ? { href: props.to, target: "_blank", rel: "noopener noreferrer", component: "a" }
    : { to: props.to, component: Link };
  const isOutlinedIcon = props?.isOutlinedIcon ?? true;
  const isOpen = props?.isDesktopOpen ?? true;

  return (
    <Button
      className={`${styles.nav} ${props.isActive ? styles.active : ""} ${
        isOpen ? "" : styles.closed
      } ${props.howTo ? styles.howTo : ""}`}
      fullWidth
      onClick={props.onClick}
      disableFocusRipple
      {...linkProps}>
      <i
        className={`${isOutlinedIcon ? "material-icons-outlined" : "material-icons"}`}
        title={props.label}>
        {props.icon}
      </i>
      <span>{props.label}</span>
    </Button>
  );
}

DrawerItem.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isDesktopOpen: PropTypes.bool,
  isActive: PropTypes.bool,
  howTo: PropTypes.bool,
  isOutlinedIcon: PropTypes.bool,
};
