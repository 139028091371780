import React, { useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import LoadingSwitch from "../../../../../util/buttons/loadingSwitch";
import { updateAccountIntegrations } from "services/api.service";

export default function UnsubscribeAndStopText(props) {
  let { eventData = {} } = props;
  eventData = eventData || {};
  let [state, setState] = useState({});
  let initialActive = eventData.active ? eventData.active : false;
  let [active, setActive] = useState(initialActive);
  let [open, setOpen] = useState(false);

  const handleChange = (event, isExpanded) => {
    setOpen(isExpanded ? true : false);
  };

  const handleSwitch = async (e) => {
    e.stopPropagation();
    let newActiveValue = e.target.checked;

    try {
      setState({ buttonState: "loading" });
      let updates = [];

      updates.push({
        path: "webhooks/urls/unsubscribe/bbun_001",
        value: newActiveValue
          ? {
              dateCreated: Date.now(),
              event: "unsubscribe",
              source: "blackbaud",
              type: "function",
            }
          : null,
      });
      updates.push({
        path: "webhooks/urls/stopText/bbst_001",
        value: newActiveValue
          ? {
              dateCreated: Date.now(),
              event: "stopText",
              source: "blackbaud",
              type: "function",
            }
          : null,
      });
      updates.push({
        path: "endpoint/blackbaud/events/unsubscribeAndStopText/active",
        value: newActiveValue || null,
      });

      await updateAccountIntegrations(updates);
      setState({});
      setActive(newActiveValue);
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.error(errorMessage);
      setState({ errorMessage });
    }
  };

  return (
    <Accordion
      expanded={open}
      onChange={handleChange}
      style={{
        cursor: "pointer",
        borderRadius: "8px",
        marginTop: "25px",
        width: "100%",
      }}>
      <AccordionSummary
        expandIcon={<i className="material-icons">expand_less</i>}
        aria-controls="panel1bh-content"
        id="panel1bh-header">
        <div style={{ width: "80px", display: "flex", alignItems: "center" }}>
          <LoadingSwitch
            checked={active}
            handleSwitch={handleSwitch}
            buttonState={state && state.buttonState}
          />
        </div>
        <div style={{ flex: 1 }}>
          <h2>Add Unsubscribes To Blackbaud</h2>
          <span className="greyDesc">
            Update the Blackbaud constituent's record when they unsubscribe from Gratavid
            emails or texts.
          </span>
        </div>
      </AccordionSummary>
      <AccordionDetails
        className="row col-12"
        style={{ cursor: "auto", padding: "8px 20px 16px" }}>
        <p>
          Automatically mark the constituent's email as "do not email" and opt them out
          (consent) of emails in Blackbaud when they select "Opt out of all emails" after
          receiving a Gratavid. If contact texts STOP, we will automatically opt them out
          (consent) of SMS in Blackbaud. Make sure you have set up a consent email and SMS
          channel in Blackbaud for consent updates to register.
        </p>
        <p style={{ marginTop: "10px" }}>
          Note: The Blackbaud constituent's id must be saved under "Custom User Id" in
          Gratavid for this integration to work. Gratavid will automatically add this id
          if you use the "Import List From Blackbaud" or "Gift Added" integration.
        </p>
      </AccordionDetails>
    </Accordion>
  );
}
