import React, { useState } from "react";
import PropTypes from "prop-types";
import { LinearProgress, IconButton } from "@material-ui/core";
import { deleteAccountFolder } from "services/api.service";

export default function TagItemLabel(props) {
  let { current, indexFolders } = props;
  let [view, setView] = useState("regular");

  let deleteFolder = async (e) => {
    e.stopPropagation();
    try {
      setView("loading");
      if (current.id) {
        await deleteAccountFolder(current.id);
      }
      const tempIndexFolders = { ...indexFolders };
      delete tempIndexFolders[current.label];
      props.setIndexFolders(tempIndexFolders);

      setView("regular");
    } catch (err) {
      console.error(err.message);
    }
  };

  let updateView = (e, newView) => {
    e.stopPropagation();
    setView(newView);
  };

  if (view === "loading") {
    return (
      <div style={{ flex: 1 }}>
        <LinearProgress />
      </div>
    );
  } else if (view === "delete") {
    return (
      <>
        <div style={{ flex: 1 }}>
          <span>Delete this tag?</span>
        </div>
        <div style={{ width: "24px" }}>
          <IconButton
            aria-label="close"
            onClick={(e) => updateView(e, "regular")}
            size="small">
            <i
              className="material-icons"
              style={{ fontSize: "17px", color: "red", padding: "0px" }}>
              cancel
            </i>
          </IconButton>
        </div>
        <div style={{ width: "17px" }}>
          <IconButton aria-label="close" onClick={deleteFolder} size="small">
            <i
              className="material-icons"
              style={{ fontSize: "17px", color: "green", padding: "0px" }}>
              check_circle
            </i>
          </IconButton>
        </div>
      </>
    );
  }

  return (
    <>
      <div style={{ flex: 1 }}>{current.label}</div>
      <div style={{ width: "17px" }}>
        <IconButton
          aria-label="close"
          onClick={(e) => updateView(e, "delete")}
          size="small">
          <i
            className="material-icons"
            style={{ fontSize: "17px", color: "grey", padding: "0px" }}>
            delete
          </i>
        </IconButton>
      </div>
    </>
  );
}

TagItemLabel.propTypes = {
  accountId: PropTypes.string.isRequired,
  current: PropTypes.shape({
    label: PropTypes.string.isRequired,
    id: PropTypes.string,
  }).isRequired,
  indexFolders: PropTypes.object.isRequired,
  setIndexFolders: PropTypes.func.isRequired,
};
