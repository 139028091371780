import React from "react";
import PropTypes from "prop-types";
import styles from "./navbar.module.css";
import { IconButton } from "@material-ui/core";
import MenuHover from "./menuHover";
import Notifications from "./notifications/notifications";
import { Link } from "react-router-dom";
import AccountSwitcher from "components/account/account-switcher";

export default function NavBar(props) {
  return (
    <div className={styles.appBar}>
      <div className={styles.logoWrapper}>
        {!props.hideRightDrawer && (
          <span>
            <IconButton
              className={`${styles.desktopMenu} ${
                props.desktopOpen ? "" : styles.iconRotateClose
              }`}
              onClick={() => props.setDesktopOpen(!props.desktopOpen)}>
              <i className={`material-icons`}>menu_open</i>
            </IconButton>
            <IconButton
              className={styles.mobileMenu}
              aria-label="open drawer"
              onClick={props.handleDrawerToggle}>
              <i
                className="material-icons"
                style={{ color: "#24305e", fontSize: "22px" }}>
                menu
              </i>
            </IconButton>
          </span>
        )}

        {props.hideRightDrawer && <span></span>}
        <Link
          className={styles.logo}
          to={
            props.pendingAccountId
              ? `/pending/${props.pendingAccountId}`
              : `/a/${props.accountInfo.id}/`
          }>
          <img alt="logo" src="/static/img/gratavidHeart.svg" />
          <img alt="Gratavid" src="/static/img/gratavidText.svg" />
        </Link>
      </div>
      {!props.hideRightMenu && (
        <div className={styles.rightMenu}>
          <div className={styles.accountSwitcher}>
            <AccountSwitcher {...props} />
          </div>
          <Notifications {...props} />
          <MenuHover {...props} />
        </div>
      )}
    </div>
  );
}

NavBar.propTypes = {
  drawerWidth: PropTypes.number,
  setDesktopOpen: PropTypes.func,
  handleDrawerToggle: PropTypes.func,
  desktopOpen: PropTypes.bool,
  isInternalUser: PropTypes.bool,
  accountInfo: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  userInfo: PropTypes.shape({
    roles: PropTypes.array,
  }),
  userRole: PropTypes.object,
  passedSetStateFromApp: PropTypes.func,
  handleAccountChange: PropTypes.func,
  hideRightMenu: PropTypes.bool,
  hideRightDrawer: PropTypes.bool,
  pendingAccountId: PropTypes.string,
};
