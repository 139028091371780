import React from "react";
import PropTypes from "prop-types";
import Analytics from "./analytics";
import { useGetAccountData } from "./getData/useGetAccountData";

export default function AccountAnalytics(props) {
  let [
    lastXDays,
    setLastXDays,
    showUniqueData,
    setShowUniqueData,
    errorMessage,
    summaryBoxTotals,
    dataByShortDateArray,
    handleUniqueToggle,
    updateDays,
    dataByNoteId,
    replies,
  ] = useGetAccountData();

  return (
    <Analytics
      {...props}
      noteId={null}
      calledFromNote={false}
      calledFromAccount={true}
      lastXDays={lastXDays}
      setLastXDays={setLastXDays}
      showUniqueData={showUniqueData}
      dataByNoteId={dataByNoteId}
      replies={replies}
      setShowUniqueData={setShowUniqueData}
      errorMessage={errorMessage}
      summaryBoxTotals={summaryBoxTotals}
      dataByShortDateArray={dataByShortDateArray}
      handleUniqueToggle={handleUniqueToggle}
      updateDays={updateDays}
    />
  );
}
AccountAnalytics.propTypes = {
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};
