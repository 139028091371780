import React, { useState } from "react";
import SelectTypes from "./selectTypes";
import SingleUser from "./singleUser";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  withMobileDialog,
  Stepper,
  StepLabel,
  Step,
} from "@material-ui/core";

function AddUserDialog(props) {
  let { closeDialog, isUserAdmin } = props;
  let [activeStep, setActiveStep] = useState(0);
  let [steps, setSteps] = useState(["Select Options", "Add User"]);
  let [userType, setUserType] = useState(isUserAdmin ? "internal" : "external");

  let handleClose = () => {
    setActiveStep(0);
    closeDialog();
  };

  let handleNext = () => {
    let step = userType === "internal" ? "Add User" : "Add External User";
    setSteps(["Select Options", step]);
    setActiveStep(1);
  };

  return (
    <Dialog
      fullScreen={true}
      open={props.addDialogOpen}
      onClose={handleClose}
      aria-labelledby="add user dialog">
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
          <span style={{ fontSize: "14px", color: "grey", padding: "20px" }}>
            Add Users
          </span>
        </div>
        <div style={{ width: "48px" }}>
          <IconButton aria-label="close" onClick={props.closeDialog}>
            <i className="material-icons">close</i>
          </IconButton>
        </div>
      </div>

      <Stepper activeStep={activeStep}>
        {steps.map((current) => (
          <Step key={current}>
            <StepLabel>{current}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === 0 && (
        <>
          <DialogContent>
            <SelectTypes
              userType={userType}
              setUserType={setUserType}
              isUserAdmin={isUserAdmin}
            />
          </DialogContent>
          <DialogActions
            style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
            <Button aria-label="cancel" onClick={handleClose} variant="outlined">
              Cancel
            </Button>

            <Button
              aria-label="cancel"
              variant="contained"
              color="primary"
              onClick={handleNext}>
              Next
            </Button>
          </DialogActions>
        </>
      )}

      {activeStep === 1 && (
        <SingleUser userType={userType} {...props} closeDialog={handleClose} />
      )}
    </Dialog>
  );
}

export default withMobileDialog()(AddUserDialog);
