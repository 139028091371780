import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import LoadingButton from "../../../util/buttons/loadingbutton";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { deleteNote } from "services/api.service";

function DeleteNoteDialog(props) {
  const [buttonState, setButtonState] = useState("regular");
  const [errorMessage, setErrorMessage] = useState(null);
  const history = useHistory();

  function deleteNoteObject(e) {
    e.preventDefault();
    setButtonState("loading");

    deleteNote(props.currentNote.id)
      .then(() => {
        setButtonState("saved");
        setErrorMessage(null);
        history.push(`/a/${props.accountId}/notes`);
      })
      .catch((error) => {
        console.log(error);
        setButtonState("regular");
        setErrorMessage(error.message);
      });
  }

  return (
    <Dialog
      fullScreen={props.fullScreen}
      open={props.dialogOpen}
      onClose={() => props.setDialogOpen(false)}
      aria-labelledby="delete-note-title">
      <div style={{ minWidth: "250px", padding: "20px" }}>
        <div
          className="row col-12 pagePadding"
          style={{ padding: "20px", minWidth: "250px" }}>
          <div className="col-10 align-items-center">
            <h2>Confirm Delete</h2>
          </div>

          <div className="col-2 justify-content-end">
            <IconButton aria-label="Delete" onClick={() => props.setDialogOpen(false)}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>

          <div className="col-12">
            <p style={{ paddingTop: "5px" }}>
              Are you sure you want to <b> delete {props.currentNote.title}</b>? You can
              not undo this action. Contacts will not longer be able to view the links you
              shared with them.
            </p>
          </div>

          {errorMessage && (
            <div className="errorMessageBox">
              <b>{errorMessage}</b>
            </div>
          )}

          <div className="col-12 justify-content-center" style={{ padding: "20px" }}>
            <LoadingButton
              variant="contained"
              color="primary"
              buttonState={buttonState}
              savedLabel={"Note Deleted"}
              label={"Delete Now"}
              clickedRegularButton={deleteNoteObject}
              clickedSavedButton={() => props.setDialogOpen(false)}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
}

DeleteNoteDialog.propTypes = {
  accountId: PropTypes.string.isRequired,
  currentNote: PropTypes.shape({ id: PropTypes.string, title: PropTypes.string })
    .isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool,
};

export default withMobileDialog()(DeleteNoteDialog);
