import React from "react";
import PropTypes from "prop-types";
import CopyTextInput from "../../../../util/copyTextInput";

export default function SlatePublicUrl(props) {
  const url = `${process.env.REACT_APP_API_ENDPOINT}integration-export/slate?source=slate&event=extractFromGratavid&accountId=${props.accountId}&apiKey=${props.apiKey}`;

  return (
    <div style={{ flex: 1, marginTop: "15px", padding: 5 }}>
      <h2 style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "5px" }}>
        Gratavid Remote Server URL
      </h2>
      <p style={{ marginTop: "10px" }}>
        Use the Gratavid Remote Server to sync video engagement data back in Slate.
        Copy/paste this URL when setting up your ‘Source Format Import Automation’ in
        Slate as shown in step 2.5 under{" "}
        <a
          href="https://graduwayhelp.zendesk.com/hc/en-us/articles/6653072064924-Slate-Integration"
          target="_blank"
          rel="noopener noreferrer">
          Importing Data From Gratavid To Slate.
        </a>{" "}
      </p>
      <div style={{ marginTop: "10px" }}>
        <CopyTextInput label="" copyURL={url} />
      </div>
    </div>
  );
}

SlatePublicUrl.propTypes = {
  accountId: PropTypes.string.isRequired,
  apiKey: PropTypes.string.isRequired,
};
