import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import gcBCPLanguages from "../../../../util/data/gcBCPLanguages";
import gcISOLanguages from "../../../../util/data/gcISOLanguages";
import LoadingButton from "../../../../util/buttons/loadingbutton";
import LinearProgress from "@material-ui/core/LinearProgress";
import QuestionToLink from "../../../../util/explainers/questionToLink";
import ListCaptions from "./listCaptions";
import { editVideo, getVideo, transcribeVideo } from "services/api.service";
import { toPostgresDateString } from "util/helpers";

function Captions(props) {
  const [buttonState, setButtonState] = useState("regular");
  const [errorMessage, setErrorMessage] = useState(null);
  const [primaryBCPLanguageCode, setPrimaryBCPLanguageCode] = useState("");
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    return () => clearInterval(intervalId);
  });

  if (props.captions)
    return (
      <ListCaptions
        accountId={props.accountId}
        videoId={props.videoId}
        duration={props.duration}
        mediaURL={props.mediaURL}
        captions={props.captions}
        passedSetEditVideoState={props.passedSetEditVideoState}
      />
    );

  let createTranscription = async (e) => {
    try {
      if (!primaryBCPLanguageCode) {
        setErrorMessage("Please select the primary language used in your video");
        return;
      }
      setButtonState("loading");
      const primaryISOLanguageCode =
        gcBCPLanguages.itemsMap[primaryBCPLanguageCode]["ISO-639-1"];
      const payload = {
        primaryBcpLanguageCode: primaryBCPLanguageCode,
        primaryIsoLanguageCode: primaryISOLanguageCode,
      };

      await transcribeVideo(props.videoId, payload);
      // Begin Polling
      setIntervalId(
        setInterval(async () => {
          const updatedVideo = await getVideo(props.videoId);
          if (updatedVideo.captions) {
            props.passedSetEditVideoState("captions", updatedVideo.captions);
          }
        }, 2000)
      );
    } catch (err) {
      setErrorMessage(err?.response?.data?.message ?? err.message);
      setButtonState("regular");
    }
  };

  const manuallyCreateFirstCaption = async (e) => {
    try {
      if (!primaryBCPLanguageCode) {
        setErrorMessage("Please select the primary language used in your video");
        return;
      }
      setButtonState("loading");
      const primaryISOLanguageCode =
        gcBCPLanguages.itemsMap[primaryBCPLanguageCode]["ISO-639-1"];
      const transcriptionLabel =
        gcISOLanguages?.itemsMap?.[primaryISOLanguageCode]?.language ?? "";

      const updates = {
        createdDate: toPostgresDateString(new Date()),
        primaryISOLanguageCode,
        published: false,
        label: transcriptionLabel,
        sourceType: "manual",
        phrases: {
          "123_id": {
            phrase: "",
            st: "00:00:00.000",
            et: "00:00:03.000",
            startTime: { seconds: 0 },
            endTime: { seconds: 3 },
          },
        },
      };
      const tempCaptions = {
        transcribe: { status: "success" },
        languages: {
          list: {
            [primaryISOLanguageCode]: updates,
          },
        },
      };

      await editVideo(props.videoId, { captions: tempCaptions });
      props.passedSetEditVideoState("captions", tempCaptions);
    } catch (err) {
      setErrorMessage(err?.response?.data?.message ?? err.message);
      setButtonState("regular");
    }
  };

  const optionsHTML = gcBCPLanguages.list.map((current) => (
    <option key={current.language} value={current["BCP-47"]}>
      {current.language}
    </option>
  ));

  if (!errorMessage && buttonState === "loading") {
    return (
      <div className="col-12" style={{ padding: "20px" }}>
        <p className="greyDesc">
          Creating captions. Give us a few minutes. We will send you a notification when
          your captions are ready.
        </p>
        <LinearProgress />
      </div>
    );
  }
  if (errorMessage || !props.audioSrc) {
    const headline = errorMessage
      ? "There was an error with your automated captions; however, you can manually create captions."
      : "This video is not eligible for automatic captioning because the video was created before Gratavid added multi-language captions; however, you can manually create captions.";
    return (
      <div className="col-12" style={{ padding: "20px" }}>
        <div className="notificationMessageBox">
          <b>{headline}</b>
        </div>

        <div className="col-12" style={{ marginTop: "25px" }}>
          <FormControl style={{ width: "320px" }}>
            <InputLabel shrink htmlFor="language-native-select">
              Primary Language of Video
            </InputLabel>
            <Select
              value={primaryBCPLanguageCode}
              native
              onChange={(e) => setPrimaryBCPLanguageCode(e.target.value)}
              inputProps={{ id: "language-native-select" }}>
              <option value="" disabled>
                Select language
              </option>
              {optionsHTML}
            </Select>
          </FormControl>
        </div>

        {errorMessage && (
          <div className="errorMessageBox">
            <b>{errorMessage}</b>
          </div>
        )}

        <div className="col-12" style={{ marginTop: "20px" }}>
          <LoadingButton
            variant="contained"
            color="primary"
            buttonState={buttonState}
            savedLabel={"Created"}
            label="Start Creating"
            clickedRegularButton={manuallyCreateFirstCaption}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="col-12 row pagePadding">
      <div className="col-12" style={{ marginTop: "15px" }}>
        <h2 style={{ display: "flex" }}>
          Automatically create captions <QuestionToLink id="captions" desc="captions" />
        </h2>
        <p style={{ color: "grey" }}>
          For the most accurate results, select the primary language and the region of the
          speaker. Providing the correct speaker region will improve the accuracy of your
          captions.
        </p>
      </div>

      <div className="col-12" style={{ marginTop: "25px" }}>
        <FormControl style={{ width: "320px" }}>
          <InputLabel shrink htmlFor="language-native-select">
            Primary Language and Locale
          </InputLabel>
          <Select
            value={primaryBCPLanguageCode}
            native
            onChange={(e) => setPrimaryBCPLanguageCode(e.target.value)}
            inputProps={{ id: "language-native-select" }}>
            <option value="" disabled>
              Select language
            </option>
            {optionsHTML}
          </Select>
        </FormControl>
      </div>

      <div className="col-12" style={{ marginTop: "20px" }}>
        {errorMessage && (
          <div className="errorMessageBox">
            <b>{errorMessage}</b>
          </div>
        )}
        <LoadingButton
          variant="contained"
          color="primary"
          buttonState={buttonState}
          savedLabel={"Created"}
          label="Create Captions"
          clickedRegularButton={createTranscription}
          clickedSavedButton={() => {
            return null;
          }}
        />
      </div>
    </div>
  );
}

export default withMobileDialog()(Captions);

Captions.propTypes = {
  accountId: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
  audioSrc: PropTypes.string,
  status: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  mediaURL: PropTypes.string.isRequired,
  passedSetEditVideoState: PropTypes.func.isRequired,
  captions: PropTypes.object,
};
