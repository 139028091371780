import React, { useEffect } from "react";
import LoadingButton from "../buttons/loadingbutton";

export default function InfiniteScroll(props) {
  useEffect(() => {
    function handleScroll() {
      let { searchTerm, searchLoading, calledFromNotes } = props;
      if (calledFromNotes) return; //don't auto scroll if video is called from notes
      if (
        window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight
      )
        return;

      if (!searchLoading) {
        console.log("infi search");
        props.doSearch(searchTerm);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [props]);

  return (
    <LoadingButton
      variant="outlined"
      buttonState={props.searchLoading ? "loading" : "regular"}
      savedLabel={""}
      label={"Load More"}
      clickedRegularButton={() => props.doSearch(props.searchTerm)}
      clickedSavedButton={() => {
        return null;
      }}
    />
  );
}
