import React from "react";
import AssigneeChips from "./assigneeChips";

export default function ArrangeRow(props) {
  return (
    <div
      className="row col-12 align-items-center"
      style={{ minHeight: "60px", margin: "4px 0px" }}>
      <AssigneeChips {...props} />
    </div>
  );
}
