import { useEffect, useState } from "react";

export const useCheckListIntegrations = (accountInfo, userInfo, vendorId) => {
  let [showIntegration, setShowIntegration] = useState(null);

  useEffect(() => {
    if (!accountInfo?.integrations?.endpoint?.[vendorId]?.active) {
      return;
    }

    //admins can always import list
    //and admins can turn on the option to let non-admins import list
    //therefore if one of these is true show import option from Blackbaud
    let allowAllUsers =
      accountInfo?.integrations?.endpoint?.[vendorId]?.events?.importContacts
        ?.allowAllUsers;
    let admin = userInfo?.roles?.[0]?.isAdmin ?? false;
    if (allowAllUsers || admin) setShowIntegration(true);
  }, []);

  return [showIntegration];
};
