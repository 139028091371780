import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import LoadingButton from "../../../../util/buttons/loadingbutton";
import { useGetContactProperties } from "../../../../util/contacts/useGetContactProperties";
import MapColumns from "../bulk/map/mapColumns";
import ConfirmAndAdd from "./confirmAndAdd";
import GetList from "./getLists";
import { getBlackbaudListColumns, getSalesforceListColumns } from "services/api.service";

export default function AddBulkStepper(props) {
  let { platformKey = "" } = props;
  let [contactProperties] = useGetContactProperties(props.accountInfo);
  let [properties, setProperties] = useState("loading");
  let [csvData, setCsvData] = useState({});
  let [activeStep, setActiveStep] = useState(0);
  let [state, setState] = useState({});
  let [selectedList, setSelectedList] = useState(null);

  useEffect(() => {
    if (contactProperties === "loading") return;
    //JSON.parse(JSON.stringify ensures we do a deep copy of object. spread operator is shallow copy
    //problem before json was someone would change un-map property which deleted column header
    //then they would open it again and property would look mapped but it would miss column header so wouldn't map
    let tempProperties = JSON.parse(JSON.stringify(contactProperties));

    //pre-map blackbaud columns
    if (platformKey === "blackbaud") {
      tempProperties.customUserId = {
        columnHeader: "id",
        id: "customUserId",
        label: "Custom User Id",
        mapped: true,
      };
      tempProperties.email = {
        columnHeader: "email.address",
        id: "email",
        label: "Email",
        mapped: true,
      };
      tempProperties.dobDay = {
        columnHeader: "birthdate.d",
        id: "dobDay",
        label: "Date Of Birth - Day",
        mapped: true,
      };
      tempProperties.dobMonth = {
        columnHeader: "birthdate.m",
        id: "dobMonth",
        label: "Date Of Birth - Month",
        mapped: true,
      };
      tempProperties.firstName = {
        columnHeader: "first",
        id: "firstName",
        label: "First Name",
        mapped: true,
      };
      tempProperties.lastName = {
        columnHeader: "last",
        id: "lastName",
        label: "Last Name",
        mapped: true,
      };
    }

    setProperties(tempProperties);
  }, [contactProperties]);

  let getSampleListData = async () => {
    try {
      //loop over every list selected
      setState({ buttonState: "loading" });
      if (!selectedList?.id) throw new Error("No list selected");

      let result;
      if (platformKey === "blackbaud") {
        result = await getBlackbaudListColumns(selectedList.id);
      } else if (platformKey === "salesforce") {
        result = await getSalesforceListColumns(selectedList.id);
      }

      let { columnHeaders, columnSamples } = result;
      setCsvData({ headers: columnHeaders, rows: columnSamples });
      setState({});
      setActiveStep(1);
    } catch (err) {
      const errorMessage = err?.data?.message ?? err.message;
      console.error(errorMessage);
      setState({ errorMessage: errorMessage });
    }
  };

  let clearProperties = () => {
    //reset properties so some are not already mapped
    let tempProperties = { ...properties };
    let propertiesKeys =
      tempProperties && Object.keys(tempProperties) ? Object.keys(tempProperties) : [];
    propertiesKeys.forEach((id) => {
      delete tempProperties[id].mapped;
    });
    setProperties(tempProperties);
  };

  let goBackClicked = () => {
    clearProperties();
    setActiveStep(0);
  };

  let steps = ["Select List", "Map and Upload"];

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <div className="row col-12 pagePadding">
        {activeStep === 0 && (
          <>
            <GetList
              accountId={props.accountInfo.id}
              platformKey={props.platformKey}
              platformLabel={props.platformLabel}
              selectedList={selectedList}
              setSelectedList={setSelectedList}
            />
            <div className="bulkUploadActionBar" style={{ justifyContent: "flex-end" }}>
              <div style={{ flex: 1 }} className="justify-content-end">
                {state.errorMessage && (
                  <div className="bulkUploadActionBarErrorMessageBox">
                    <i
                      className="material-icons"
                      style={{ marginRight: "5px", color: "red" }}>
                      error
                    </i>
                    <b>{state.errorMessage}</b>
                  </div>
                )}
              </div>
              <div className="justify-content-end" style={{ width: "240px" }}>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  buttonState={state.buttonState}
                  savedLabel="Selected"
                  label="Next"
                  clickedRegularButton={getSampleListData}
                  clickedSavedButton={() => {
                    return null;
                  }}
                />
              </div>
            </div>
          </>
        )}

        {activeStep === 1 && (
          <>
            <MapColumns
              csvData={csvData}
              {...props}
              properties={properties}
              setProperties={setProperties}
              calledFromTask={true}
            />
            <ConfirmAndAdd
              {...props}
              properties={properties}
              csvData={csvData}
              setActiveStep={setActiveStep}
              goBackClicked={goBackClicked}
              setProperties={setProperties}
              selectedList={selectedList}
              clearProperties={clearProperties}
            />
          </>
        )}
      </div>
    </div>
  );
}

AddBulkStepper.propTypes = {
  platformKey: PropTypes.string.isRequired,
  platformLabel: PropTypes.string.isRequired,
  accountInfo: PropTypes.object.isRequired,
};
