import React from "react";
import PropTypes from "prop-types";
import TextInputToApi from "components/util/hooks/input/textInputToApi";

export default function Message(props) {
  return (
    <div className="row col-12 adSectionBox">
      <div className="col-12" style={{ marginBottom: "15px" }}>
        <h2>Message</h2>
      </div>
      <div className="col-12 " style={{ margin: "5px 0px 30px 0px" }}>
        <div style={{ flex: 1 }}>
          <TextInputToApi
            initialValue={props.senderName}
            apiCall={props.passedSetState}
            field="senderName"
            label="Sender Name"
            variant="standard"
          />
        </div>
      </div>
      <div className="col-12 ">
        <div style={{ flex: 1 }}>
          <TextInputToApi
            initialValue={props.messageHtml}
            multiline={true}
            apiCall={props.passedSetState}
            field="messageHtml"
            label="Note Message"
            customContactProperties={props.customContactProperties}
            variant="standard"
          />
        </div>
      </div>
    </div>
  );
}

Message.propTypes = {
  senderName: PropTypes.string,
  messageHtml: PropTypes.string,
  customContactProperties: PropTypes.array,
  passedSetState: PropTypes.func,
};
