import React, { Component } from "react";
import PropTypes from "prop-types";
import RequestVideo from "./request/request";
import LinearProgress from "@material-ui/core/LinearProgress";
import PageNotFound444 from "../../../util/pageNotFound444";
import TrimVideo from "./trim/trimVideo";
import { getVideo } from "services/api.service";
import ManageVideo from "./manageVideo";

export default class EditVideo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      uploadType: "loading",
      status: null,
      mediaURL: null,
      webmURL: null,
      thumbnail: null,
      addNewDialogOpen: false,
      deletedVideoState: null,
    };
  }

  async componentDidMount() {
    let video;
    try {
      video = await getVideo(this.props.videoId);
      const title = video?.title ?? "";
      const thumbnail =
        video?.thumbnailUrl ??
        "https://account.gratavid.com/static/img/playThumbnailNoPlayButton.png";
      const mediaURL = video?.videoUrl;
      const audioSrc = video?.audioUrl;
      const gif = video?.gifUrl;
      const duration = video?.duration ?? 0;
      const status = video?.status ?? "create";
      this.setState({
        ...video,
        thumbnail,
        mediaURL,
        audioSrc,
        gif,
        duration,
        status,
        title,
      });
    } catch (error) {
      this.setState({ errorMessage: error.message, uploadType: "error" });
    }
  }

  //used by upload
  videoUploadStatusChanged = (
    status,
    webmURL,
    mediaURL,
    errorMessage,
    thumbnail,
    gif,
    audioSrc,
    captions
  ) => {
    this.setState({
      status,
      webmURL,
      mediaURL,
      errorMessage,
      thumbnail,
      gif,
      audioSrc,
      captions,
    });
  };

  //used by upload
  updateStatus = (status, errorMessage) => {
    this.setState({ status, errorMessage });
  };

  passedSetEditVideoState = (state, value) => {
    this.setState({ [state]: value });
  };

  render() {
    let child;
    if (this.state.uploadType === "error") {
      return (child = (
        <div className="row col-12 pagePadding">
          <PageNotFound444 errorMessage={this.state.errorMessage} />
        </div>
      ));
    } else if (["trimming", "trimUploading"].includes(this.state.status)) {
      child = (
        <div className="row col-12">
          <TrimVideo
            accountId={this.props.accountId}
            videoId={this.state.id}
            mediaURL={this.state.mediaURL}
            status={this.state.status}
            videoUploadStatusChanged={this.videoUploadStatusChanged}
            updateStatus={this.updateStatus}
          />
        </div>
      );
    } else if (this.state.uploadType === "loading") {
      return (
        <div className="row col-12">
          <LinearProgress />
        </div>
      );
    } else if (this.state.uploadType === "request") {
      child = (
        <RequestVideo
          {...this.props}
          {...this.state}
          videoSelected={this.videoSelected}
          passedSetEditVideoState={this.passedSetEditVideoState}
          updateStatus={this.updateStatus}
          videoUploadStatusChanged={this.videoUploadStatusChanged}
        />
      );
    } else if (this.state.uploadType === "upload") {
      child = (
        <ManageVideo
          accountId={this.props.accountId}
          videoId={this.state.id}
          title={this.state.title}
          recorderName={this.props.recorderName}
          uploadType={this.state.uploadType}
          gif={this.state.gif}
          speakerName={this.state.speakerName}
          thumbnail={this.state.thumbnail}
          mediaURL={this.state.mediaURL}
          audioSrc={this.state.audioSrc}
          duration={this.state.duration}
          status={this.state.status}
          captions={this.state.captions}
          videoUploadStatusChanged={this.videoUploadStatusChanged}
          updateStatus={this.updateStatus}
          passedSetEditVideoState={this.passedSetEditVideoState}
          deletedVideoState={this.state.deletedVideoState}
        />
      );
    }

    return (
      <div className="row col-12">
        {this.state.errorMessage && (
          <div className="errorMessageBox">
            <b>{this.state.errorMessage}</b>
          </div>
        )}

        {child}
      </div>
    );
  }
}

EditVideo.propTypes = {
  accountId: PropTypes.string.isRequired,
  recorderName: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
};
