import React, { useState } from "react";
import UploadFile from "./uploadFile";
import UploadLists from "./uploadLists";
import RuleMaker from "./ruleMaker";
import { Stepper, Step, StepLabel, Button } from "@material-ui/core";
import RuleKeys from "./rule-keys";

const filterContacts = function (contacts, rules) {
  const folderEmails = {};
  const contactsToInsert = {};
  const ruleHits = Array(rules.length).fill(0);

  for (const contact of contacts) {
    for (let i = 0; i < rules.length; i++) {
      const conditions = rules[i];
      const match = conditions.every(({ key, values }) =>
        key === "groups"
          ? values.some((column) => contact[column] === "Y")
          : values.includes(contact[key])
      );
      if (match) {
        ruleHits[i]++;
        conditions[0].folders.forEach(({ id }) =>
          folderEmails[id]
            ? folderEmails[id].add(contact.Email)
            : (folderEmails[id] = new Set([contact.Email]))
        );
        contactsToInsert[contact.Email] = contact;
      }
    }
  }
  return { contacts: Object.values(contactsToInsert), folderEmails, ruleHits };
};

export default function GraduwayEngage(props) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [csvData, setCsvData] = useState({});
  const [rules, setRules] = useState([
    [{ key: RuleKeys.affiliations, index: 0, values: [], folders: [] }],
  ]);
  const [contacts, setContacts] = useState(null);
  const [folderEmails, setFolderEmails] = useState(null);
  const [ruleHits, setRuleHits] = useState(null);

  const onRuleContinue = function () {
    const hasCondWithFolders = rules.every((rule) =>
      rule.every((condition) => condition.folders.length)
    );
    const hasCondWithValues = rules.every((rule) =>
      rule.every((condition) => condition.values.length)
    );
    if (!hasCondWithFolders) {
      setErrorMessage(
        'Missing "Add To" List. Please check that you have included at least one Gratavid list to be updated.'
      );
    } else if (!hasCondWithValues) {
      setErrorMessage(
        "Missing condition values. Please select at least one condition value."
      );
    } else {
      const { contacts, folderEmails, ruleHits } = filterContacts(
        csvData.contacts,
        rules
      );
      setContacts(contacts);
      setFolderEmails(folderEmails);
      setRuleHits(ruleHits);
      setErrorMessage(null);
      setActiveStep(2);
    }
  };

  let steps = ["Upload File", "Configure Lists", "Confirm Upload"];

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div className="row col-12 pagePadding">
        {activeStep === 0 && (
          <>
            <UploadFile setCsvData={setCsvData} setActiveStep={setActiveStep} />
            <div className="bulkUploadActionBar" style={{ justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="primary"
                disabled={!csvData.file}
                onClick={() => setActiveStep(1)}>
                Next
                <i className="material-icons">arrow_forward</i>
              </Button>
            </div>
          </>
        )}

        {activeStep === 1 && (
          <RuleMaker
            accountInfo={props.accountInfo}
            rules={rules}
            setRules={setRules}
            keys={Object.values(RuleKeys)}
            conditions={{
              [RuleKeys.affiliations]: {
                label: "Affiliations",
                description: "The contact has any of these affiliations",
                values: csvData[RuleKeys.affiliations],
              },
              [RuleKeys.industries]: {
                label: "Industries",
                description: "The contact works in any of these industries",
                values: csvData[RuleKeys.industries],
              },
              [RuleKeys.functions]: {
                label: "Job Functions",
                description: "The contact has any of these job functions",
                values: csvData[RuleKeys.functions],
              },
              [RuleKeys.groups]: {
                label: "Groups",
                description: "The contact is a member of any of these groups",
                values: csvData[RuleKeys.groups],
              },
            }}
            onContinue={onRuleContinue}
          />
        )}

        {activeStep === 2 && (
          <UploadLists
            contacts={contacts}
            folderEmails={folderEmails}
            rules={rules}
            ruleHits={ruleHits}
            setActiveStep={setActiveStep}
            accountId={props.accountInfo.id}
            setAddNewDialogOpen={props.setAddNewDialogOpen}
          />
        )}

        {errorMessage && (
          <div className="errorMessageBox">
            <b>{errorMessage}</b>
          </div>
        )}
      </div>
    </div>
  );
}
