import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./userForm.module.css";
import { updateAccountUser, addAccountUser } from "services/adminApi.service.js";

import { TextField } from "@material-ui/core";
import Switch from "components/util/inputSwitch/inputSwitch.js";
import Button from "components/util/button/button.js";

export default function UserForm({ accountUID, user, onChange, onError, onCancel }) {
  const accountRole = user?.roles?.find((role) => role.accountId);
  const [form, setForm] = useState({
    id: user?.id ?? "",
    fullName: user?.fullName ?? "",
    email: user?.email ?? "",
    isAdmin: accountRole?.isAdmin || false,
    isBulkSender: accountRole?.isBulkSender || false,
    isNoteEditor: accountRole?.isNoteEditor || false,
  });

  function handleInput(e) {
    const { name, value } = e.target;
    setForm((form) => {
      return { ...form, [name]: value };
    });
  }

  function handleSwitch(e) {
    const { name, checked } = e.target;
    setForm((form) => {
      return { ...form, [name]: checked };
    });
  }

  async function save() {
    const payload = {
      user: {
        fullName: form.fullName,
        email: form.email,
      },
      role: {
        isAdmin: form.isAdmin,
        isBulkSender: form.isBulkSender,
        isNoteEditor: form.isNoteEditor,
        userType: "internal",
      },
    };

    try {
      if (user?.id) {
        const data = await updateAccountUser(accountUID, user.id, payload);
        onChange(data);
      } else {
        const data = await addAccountUser(accountUID, payload);
        onChange(data);
      }
    } catch {
      onError();
    }
  }

  return (
    <form>
      <div className={styles.form}>
        <div className={styles.textFields}>
          <TextField
            variant="outlined"
            size="small"
            label="Name"
            required
            name="fullName"
            value={form.fullName}
            onChange={handleInput}
            autoComplete="off"
          />
          <TextField
            variant="outlined"
            size="small"
            label="Email"
            required
            name="email"
            value={form.email}
            onChange={handleInput}
            autoComplete="off"
          />
        </div>
        <div className={styles.roles}>
          <div>
            <label>
              <span className={styles.rolesLabel}>Admin</span>
              <Switch
                name="isAdmin"
                checked={form.isAdmin}
                onChange={handleSwitch}></Switch>
            </label>
            <p>
              Admins can add/delete users (including you), manage sign-in methods, manage
              integrations and user permissions. Note: you can’t edit your own account.
            </p>
          </div>
          <div>
            <label>
              <span className={styles.rolesLabel}>Bulk Sender</span>
              <Switch
                name="isBulkSender"
                checked={form.isBulkSender}
                onChange={handleSwitch}></Switch>
            </label>
            <p>Allow users to send a Gratavid to 4 or more contacts at once.</p>
          </div>
          <div>
            <label>
              <span className={styles.rolesLabel}>Note Editor</span>
              <Switch
                name="isNoteEditor"
                checked={form.isNoteEditor}
                onChange={handleSwitch}></Switch>
            </label>
            <p>Allow users to create, edit and delete notes.</p>
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        {!user?.id && (
          <Button variant="text" color="primary" onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button variant="contained" color="primary" onClick={save}>
          {user?.id ? "Save Changes" : "Add"}
        </Button>
      </div>
    </form>
  );
}

UserForm.propTypes = {
  accountUID: PropTypes.string.isRequired,
  user: PropTypes.object,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onCancel: PropTypes.func,
};
