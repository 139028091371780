import React from "react";
import "./style/App.css";
import "./style/colors.css";
import "./style/boottrapp.css";
import "./style/adbuilder.css";
import muiTheme from "./muiTheme";
import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AuthOpenIdRedirect from "./components/util/logincreate/authOpenIdRedirect";
import ResetPassword from "./components/util/logincreate/resetpassword";
import LoginFromInvite from "./components/util/logincreate/invite/loginFromInvite";
import Terms from "./components/util/compliance/terms";
import UserContentTerms from "./components/util/compliance/userContentTerms";
import Privacy from "./components/util/compliance/privacy";
import Security from "./components/util/compliance/security";
import GDPR from "./components/util/compliance/gdpr";
import PageNotFound444 from "./components/util/pageNotFound444";
import { datadogLogs } from "@datadog/browser-logs";
import * as Sentry from "@sentry/react";
import Maintenance from "components/maintenance-window";
import AccountManager from "./components/account/account-manager.js";
import PendingAccountSignup from "./components/account/pending-account-signup.js";

if (process.env.REACT_APP_DD_CLIENT_TOKEN) {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
    site: "datadoghq.com",
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });
}

//if (process.env.NODE_ENV !== "development") {
Sentry.init({
  dsn: "https://6322d32caf564f04867cf8fb10cdfa58@o227651.ingest.sentry.io/4504249408684032",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        React.useLocation,
        React.useNavigationType,
        React.createRoutesFromChildren,
        React.matchRoutes
      ),
      tracePropagationTargets: [
        "localhost",
        "account.sandbox.gratavid.com",
        "account.gratavid.com",
      ],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
//}

export default function App() {
  if (process.env.REACT_APP_MAINTENANCE_END_DATE) {
    return (
      <Router>
        <Route
          path="*"
          exact={true}
          render={() => (
            <MuiThemeProvider theme={muiTheme}>
              <Maintenance></Maintenance>
            </MuiThemeProvider>
          )}
        />
      </Router>
    );
  }

  return (
    <Router>
      <StylesProvider injectFirst>
        {" "}
        <MuiThemeProvider theme={muiTheme}>
          <div style={{ minHeight: "calc(100vh - 40px)" }}>
            <Switch>
              <Route
                path={"/a/:accountId/"}
                render={(props) => <AccountManager {...props} />}
              />

              <Route
                path={"/pending/:pendingAccountId/"}
                render={(props) => <PendingAccountSignup {...props} />}
              />

              <Route
                exact
                path={"/loginfrominvite"}
                render={(props) => <LoginFromInvite />}
              />
              <Route
                exact
                path={"/resetpassword"}
                render={(props) => <ResetPassword />}
              />
              <Route exact path={"/terms"} render={(props) => <Terms />} />
              <Route
                exact
                path={"/userContentTerms"}
                render={(props) => <UserContentTerms />}
              />
              <Route exact path={"/privacy"} render={(props) => <Privacy />} />
              <Route exact path={"/security"} render={(props) => <Security />} />
              <Route exact path={"/gdpr"} render={(props) => <GDPR />} />
              <Route
                exact
                path={"/openid/redirect"}
                render={(props) => <AuthOpenIdRedirect {...props} />}
              />

              <Route
                path="*"
                exact={true}
                render={(props) => (
                  <PageNotFound444 errorMessage="This page does not exist" />
                )}
              />
            </Switch>
          </div>
        </MuiThemeProvider>
      </StylesProvider>
    </Router>
  );
}
