import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, Typography, Tooltip } from "@material-ui/core";

const TrappTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#475B74",
    width: 250,
    color: "white",
    fontSize: "12px",
    boxShadow:
      "0 1px 8px 0 rgba(0,0,0,.2), 0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12)",
    padding: "10px 20px",
  },
}))(Tooltip);

export default function InfoBox(props) {
  let { title, desc, href = null } = props;

  return (
    <div style={{ marginLeft: "-2px" }}>
      <TrappTip
        interactive
        placement="bottom"
        title={
          <React.Fragment>
            <Typography color="inherit" style={{ fontSize: "15px", fontWeight: "bold" }}>
              {title}
            </Typography>
            <Typography color="inherit" style={{ marginTop: "10px", fontSize: "14px" }}>
              {desc}
            </Typography>

            {href && (
              <>
                <div style={{ height: "25px" }} />
                <a
                  href={href}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="infoBoxLink">
                  Learn more
                </a>
              </>
            )}
          </React.Fragment>
        }>
        <IconButton style={{ padding: "10px" }}>
          <i style={{ fontSize: "16px", color: "#8F949C" }} className="material-icons">
            info
          </i>
        </IconButton>
      </TrappTip>
    </div>
  );
}
