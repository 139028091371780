import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { LinearProgress } from "@material-ui/core";
import EditContact from "./editContact";
import Messenger from "./messenger/messenger";
import ReplyBox from "./messenger/replyBox";
import { getContact, getContactActivity } from "services/api.service";

export default function ContactProfile(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [contactData, setContactData] = useState(null);
  const [contactActivity, setContactActivity] = useState([]);

  // ref is created for auto-scroll
  const chatContainerRef = useRef(null);

  const { accountInfo, contactId } = props;
  useEffect(() => {
    async function getContactData() {
      try {
        const contact = await getContact(contactId);
        setContactData(contact);
      } catch {
        setErrorMessage("Error loading contact");
      } finally {
        setIsLoading(false);
      }
    }
    getContactData();
  }, [accountInfo.id, contactId]);

  const pollId = useRef();

  useEffect(() => {
    let getData = async () => {
      try {
        const { noteHistory, userReplies, appReplies } = await getContactActivity(
          contactId
        );

        const activity = [
          ...appReplies.map((x) => ({ ...x, type: "appReply" })),
          ...noteHistory.map((x) => ({ ...x, type: "noteHistory" })),
          ...userReplies.map((x) => ({ ...x, type: "userReply" })),
        ];
        activity.sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate));

        setContactActivity(activity);
        //auto scroll to bottom of chat if first load aka less than 10 items on activity
        //don't want to auto-scroll when user hits load more at the top
        if (chatContainerRef?.current) {
          chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
      } catch (err) {
        console.error(err.message);
      }
    };
    clearInterval(pollId.current);
    pollId.current = setInterval(getData, 30000);
    getData();
    return () => clearInterval(pollId.current);
  }, [contactId, accountInfo, chatContainerRef, setContactActivity]);

  function onReply(reply) {
    setContactActivity([...contactActivity, reply]);
  }

  if (isLoading) {
    return (
      <div className="col-12" style={{ padding: "20px" }}>
        <LinearProgress />
      </div>
    );
  } else if (errorMessage) {
    return (
      <div className="errorMessageBox">
        <b>{errorMessage}</b>
      </div>
    );
  }

  // Find out what email opt out does
  let { bounce, email, emailOptOut = null } = contactData;
  let optOutLink = `${process.env.REACT_APP_FRONTEND}/emailoptout?actId=${props.accountInfo.id}&email=${email}&cId=${contactId}`;

  return (
    <div
      style={{
        backgroundColor: "white",
        width: "100%",
        height: "calc(100vh - 80px)",
        display: "flex",
        flexDirection: "column",
      }}>
      <div
        style={{
          height: "60px",
          padding: "10px 15px",
          boxShadow: "0 1px 2px 0 rgba(0, 0, 0, .10)",
        }}>
        <EditContact
          contactData={contactData}
          accountInfo={props.accountInfo}
          fullScreen={props.fullScreen}
        />
      </div>

      {bounce && (
        <div className="errorMessageBox">
          <b>
            Email &quot;{email}&quot; bounced. This contact will no longer receive any
            emails until you update their email address.
          </b>
        </div>
      )}

      {emailOptOut && (
        <div className="errorMessageBox">
          <b>
            Contact opted out of emails. <a href={optOutLink}>Edit Opt In</a>{" "}
          </b>
        </div>
      )}

      <div
        style={{ flex: 1, padding: "15px 40px 15px 20px", overflow: "auto" }}
        ref={chatContainerRef}>
        <Messenger
          contactActivity={contactActivity}
          {...props}
          contactData={contactData}
          accountCustomProperties={props.accountInfo.customContactProperties}
        />
      </div>

      <div style={{ height: "135px", padding: "15px" }}>
        <ReplyBox
          contactId={contactData.id}
          accountId={props.accountInfo.id}
          textOptIn={contactData.textOptIn && !!contactData.cellNumber}
          textEnabled={props.accountInfo.textEnabled}
          onReply={onReply}
        />
      </div>
    </div>
  );
}

ContactProfile.propTypes = {
  contactId: PropTypes.string.isRequired,
  accountInfo: PropTypes.object.isRequired,
  drawerWidth: PropTypes.number,
  fullScreen: PropTypes.bool,
};
