import React from "react";
import PropTypes from "prop-types";
import styles from "./radioSegment.module.css";
import Description from "./description.js";
import InputRadioGroup from "components/util/inputRadioGroup/inputRadioGroup.js";

import { Controller } from "react-hook-form";

const RadioSegment = ({
  options,
  title,
  description,
  name,
  control,
  customValue,
  setCustomValue,
}) => (
  <div className={styles.wrapper}>
    <Description title={title} description={description} />
    <div
      className={`${styles.radio_group} ${
        options.length < 3 ? styles.radio_boolean : ""
      }`}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <InputRadioGroup
            options={options}
            {...field}
            customValue={customValue}
            setCustomValue={setCustomValue}
          />
        )}
      />
    </div>
  </div>
);

RadioSegment.propTypes = {
  control: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  name: PropTypes.string,
  customValue: PropTypes.string,
  setCustomValue: PropTypes.func,
};

export default RadioSegment;
