import React, { useState } from "react";
import { Button, Dialog } from "@material-ui/core";

export default function Thumbnail(props) {
  let [dialogOpen, setDialogOpen] = useState(false);
  let { videoData } = props;

  if (videoData === "loading")
    return (
      <div className="align-items-center" style={{ width: "80px", paddingLeft: "5px" }}>
        <div className="video-thumbnail-item loading-pulse" />
      </div>
    );

  let { errorMessage, videoUrl, thumbnailUrl } = videoData;

  if (errorMessage) {
    return (
      <div className="align-items-center" style={{ paddingLeft: "5px" }}>
        <div className="video-thumbnail-item justify-content-center">
          <div style={{ paddingTop: "5px" }}>
            <i
              className="material-icons"
              style={{ color: "grey", justifyContent: "center", display: "flex" }}>
              error
            </i>
            <span style={{ fontSize: "11px", display: "absolute", bottom: "0px" }}>
              Vid Deleted
            </span>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="align-items-center" style={{ width: "80px", paddingLeft: "5px" }}>
          <Button
            variant="text"
            className="video-thumbnail-item"
            style={{ position: "relative" }}
            onClick={() => setDialogOpen(true)}>
            <img
              src={thumbnailUrl}
              style={{
                width: "75px",
                height: "50px",
                objectFit: "cover",
                borderRadius: "8px",
              }}
              alt="video thumbnail"
            />
            <div
              style={{
                position: "absolute",
                display: "flex",
                align: "center",
                justifyContent: "center",
              }}>
              <img
                src={"/static/img/playButton.svg"}
                style={{ maxWidth: "25px", maxHeight: "25px" }}
                alt="play thumbnail"
              />
            </div>
          </Button>
        </div>
        {dialogOpen && (
          <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <video
              autoPlay
              controls
              src={videoUrl + "?cacheBuster=123"}
              preload="none"
              width="100%"
              height="auto"
              type="video/mp4">
              Sorry, your browser doesn't support embedded videos.{" "}
              <a href={videoUrl} target="_blank" rel="noopener noreferrer">
                Click here to view
              </a>
            </video>
          </Dialog>
        )}
      </>
    );
  }
}
