import React, { useState } from "react";
import { Switch, LinearProgress, Typography } from "@material-ui/core";
import { updateContact } from "services/api.service";

export default function ContactItemSendStatus(props) {
  let { contact } = props;
  let {
    lastBirthdaySent = null,
    excludeBirthday: defaultExcludeBirthday = false,
    dobMonth: contactDobMonth = 0,
    dobDay: contactDobDay = 0,
  } = contact;
  let [excludeBirthday, setExcludeBirthday] = useState(defaultExcludeBirthday);

  let toggleExcludeBirthday = async () => {
    try {
      let newExcludeBirthday = !excludeBirthday;

      props.setState({ buttonState: "loading" });
      await updateContact(contact.id, { excludeBirthday: newExcludeBirthday });
      props.setState({ buttonState: "saved" });

      setExcludeBirthday(newExcludeBirthday);
    } catch (err) {
      console.error(err.message);
      props.setState({ errorMessage: err.message });
    }
  };

  //show toggle
  const now = new Date(new Date().toLocaleString("en-US", { timeZone: props.timezone }));
  const scheduledSendTime = new Date(now.getTime());
  const [birthdayJobHour, birthdayJobMinute] = props.localSendTime.split(":");
  scheduledSendTime.setHours(birthdayJobHour);
  scheduledSendTime.setMinutes(birthdayJobMinute);
  scheduledSendTime.setSeconds(0);

  const contactBirthday = new Date(scheduledSendTime.getTime());
  contactBirthday.setMonth(contactDobMonth - 1);
  contactBirthday.setDate(contactDobDay);

  const alreadySentToday =
    scheduledSendTime.getTime() === contactBirthday.getTime() &&
    now.getTime() > scheduledSendTime.getTime();

  const sentLabel = lastBirthdaySent
    ? "Last birthday sent on " + new Date(lastBirthdaySent).toLocaleDateString()
    : null;
  if (scheduledSendTime.getTime() <= contactBirthday.getTime()) {
    if (alreadySentToday) {
      return (
        <div style={{ width: "65px" }}>
          <props.TrappTip
            placement="top"
            interactive
            title={
              <React.Fragment>
                <Typography color="inherit">{sentLabel}</Typography>
                <a
                  href={`/a/${props.accountInfo.id}/contacts/` + contact.id}
                  style={{ fontSize: "12px" }}
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer">
                  View Analytics
                </a>
              </React.Fragment>
            }>
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              {lastBirthdaySent ? (
                <i className="material-icons" style={{ color: "green" }}>
                  check_circle
                </i>
              ) : (
                <span style={{ fontSize: "12px", color: "grey" }}>Skipped</span>
              )}
            </div>
          </props.TrappTip>
        </div>
      );
    }

    //send birthday shows true if excludeBirthday is null or false
    let sendBirthday = excludeBirthday === true ? false : true;
    return (
      <div style={{ width: "65px" }}>
        {props.state.buttonState === "loading" ? (
          <LinearProgress />
        ) : (
          <Switch
            size="small"
            checked={sendBirthday}
            onChange={() => toggleExcludeBirthday()}
            value="excludeBirthday"
          />
        )}
      </div>
    );
  } else {
    //2) it's past the scheduled time to send a birthday
    return (
      <div style={{ width: "65px" }}>
        <props.TrappTip
          placement="top"
          interactive
          title={
            <React.Fragment>
              <Typography color="inherit">{sentLabel}</Typography>
              <a
                href={`/a/${props.accountInfo.id}/contacts/` + contact.id}
                style={{ fontSize: "12px" }}
                className="link"
                target="_blank"
                rel="noopener noreferrer">
                View Analytics
              </a>
            </React.Fragment>
          }>
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            {lastBirthdaySent ? (
              <i className="material-icons" style={{ color: "green" }}>
                check_circle
              </i>
            ) : (
              <span style={{ fontSize: "12px", color: "grey" }}>Skipped</span>
            )}
          </div>
        </props.TrappTip>
      </div>
    );
  }
}
