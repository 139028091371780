export default function milestoneCountsOptions(label) {
  let giftCountsToShow = [
    2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100, 125, 150, 175,
    200, 300, 400, 500, 1000,
  ];
  let giftCountsOptions = giftCountsToShow.map((current) => {
    return { id: current, value: current.toString(), label: current + " " + label };
  });
  return giftCountsOptions;
}
