import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const app = initializeApp({
  apiKey: "AIzaSyAvAYrIbElTo5Me5W3ppGtcLmtw7Bc1uhM",
  authDomain: "gratavid.firebaseapp.com",
  databaseURL: "https://gratavid.firebaseio.com",
  projectId: "gratavid",
  storageBucket: "gratavid.appspot.com",
  messagingSenderId: "970100913970",
  appId: "1:970100913970:web:2d0ff43a9ce44b5529c92b",
  measurementId: "G-EDC43NVNF9",
});

export async function setupMessaging(workerRegistration, onMessageFunc) {
  const messaging = getMessaging(app);
  await Notification.requestPermission();
  const currentToken = await getToken(messaging, {
    serviceWorkerRegistration: workerRegistration,
    vapidKey:
      "BBmmmfLXaF5N0AKz_BhHpmgJQ_lGlBc2gm-uvxTpTg8yjN5-cndwz818UYhLiLfbWcLHjEo_YfHVhGmPUXN5LMk",
  });
  onMessage(messaging, onMessageFunc);
  return currentToken;
}
