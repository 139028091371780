import React, { useState } from "react";
import styles from "./inputColor.module.css";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { ChromePicker } from "react-color";

function InputColor(props) {
  let [modalOpen, setModalOpen] = useState(false);
  let [color, setColor] = useState(props.color);

  const onDone = () => {
    props.onChange(props.colorKey, color.hex);
    setModalOpen(false);
  };

  return (
    <div>
      <h5 className={styles.title}>{props.title}</h5>
      <div className={styles.input_wrapper}>
        <div
          className={styles.color_preview}
          style={{ backgroundColor: props.color }}
          onClick={() => setModalOpen(true)}></div>
        <Button
          className={styles.button}
          variant="outlined"
          size="small"
          onClick={() => setModalOpen(true)}>
          Hex {props.color}
        </Button>
      </div>

      <Dialog className={styles.dialog} open={modalOpen} onClose={onDone}>
        <DialogTitle>
          Select Color{" "}
          <IconButton className={styles.dialog_close} onClick={onDone}>
            <i className="material-icons">close</i>
          </IconButton>
        </DialogTitle>
        <ChromePicker disableAlpha={true} color={color} onChange={setColor} />
      </Dialog>
    </div>
  );
}

InputColor.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  colorKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default InputColor;
