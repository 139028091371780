import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getAccountContactsUnsubscribed } from "services/api.service";

import QuestionToLink from "../../../util/explainers/questionToLink";
import ListUnsubscribes from "./listUnsubscribes";

export default function Unsubscribes(props) {
  let [list, setList] = useState("loading");
  let [tabSelected, setTabSelected] = useState(0);

  const handleBulkImportDone = () => {
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const unsubscribeRecords = await getAccountContactsUnsubscribed();
      setList(unsubscribeRecords);
    } catch (err) {
      console.error(err.message);
    }
  };

  function a11yProps(index) {
    return {
      id: `task-tab-${index}`,
      "aria-controls": `task-tabpanel-${index}`,
    };
  }

  let tabTextStyle = {
    textTransform: "none",
    color: "rgb(71, 98, 130)",
    fontSize: "14px",
    fontWeight: 600,
  };
  const useStyles = makeStyles((theme) => ({
    wrapper: { alignItems: "start", paddingLeft: "8.5px" },
  }));
  const classes = useStyles();

  return (
    <div className="row col-12">
      <div className="row col-12 pagePadding" style={{ marginBottom: "10px" }}>
        <div className="col-12" style={{ display: "flex", alignItems: "center" }}>
          <h1>Unsubscribes (Opt-out)</h1>
          <QuestionToLink id="unsubscribes" desc="unsubscribes" />
        </div>
        <div className="col-12">
          <p className="greyDesc">
            A list of contacts that will not receive emails or texts from your Gratavid
            account. Contacts who select "opt-out of all emails" after receiving a
            Gratavid email or text STOP will show on this list.
          </p>
        </div>
      </div>

      <div className="col-12" style={{ borderBottom: "1px #ddd solid" }}>
        <Tabs
          value={tabSelected}
          onChange={(e, newValue) => setTabSelected(newValue)}
          aria-label="task tabs">
          <Tab
            label="Email"
            {...a11yProps(0)}
            value={0}
            style={tabTextStyle}
            classes={{ wrapper: classes.wrapper }}
          />
          <Tab
            label="Text"
            {...a11yProps(1)}
            value={1}
            style={tabTextStyle}
            classes={{ wrapper: classes.wrapper }}
          />
        </Tabs>
      </div>

      {tabSelected === 0 && (
        <ListUnsubscribes
          onBulkImportDone={handleBulkImportDone}
          unsubscribesList={list}
          type="emails"
          {...props}
        />
      )}
      {tabSelected === 1 && (
        <ListUnsubscribes
          onBulkImportDone={handleBulkImportDone}
          unsubscribesList={list}
          type="texts"
          {...props}
        />
      )}
    </div>
  );
}
