import React from "react";
import PropTypes from "prop-types";
import SelectUser from "../../../util/users/selectUser";
import SelectNote from "./selectNote";
import transactionAmountsOptions from "../types/util/data/transactionAmountsOptions";
import SelectItem from "../types/util/selectItem";
import { FormControl, Select, IconButton, InputLabel } from "@material-ui/core";

export default function Action(props) {
  let {
    currentAction = {},
    defaultAction,
    actions = {},
    arrayIndex,
    currencyLabel = "$",
  } = props;
  let {
    successFunc = "doNothing",
    assignedTo = "",
    noteId = null,
    noteTitle = null,
    conditions,
    order,
    actionId,
  } = currentAction;

  let editAction = (defaultAction, key, value, conditionId) => {
    let tempActions = { ...actions };

    if (defaultAction) {
      tempActions[key] = value;
    } else {
      //metric values are unders the conditions. success functions are note
      if (conditionId) tempActions.list[arrayIndex].conditions[conditionId][key] = value;
      tempActions.list[arrayIndex][key] = value;
    }

    props.setActions(tempActions);
  };

  let assignUserSelected = (defaultAction, userUID) => {
    let tempActions = { ...actions };
    if (defaultAction) {
      tempActions.assignedTo = userUID;
    } else {
      tempActions.list[arrayIndex].assignedTo = userUID;
    }

    props.setActions(tempActions);
  };

  let noteSelected = (defaultAction, note) => {
    let tempActions = { ...actions };
    if (defaultAction) {
      if (!note) delete tempActions.noteId;
      else {
        tempActions.noteId = note.id;
        //have to save title too
        tempActions.noteTitle = note.title;
      }
    } else {
      if (!note) delete tempActions.list[arrayIndex].noteId;
      else {
        tempActions.list[arrayIndex].noteId = note.id;
        //have to save title too
        tempActions.list[arrayIndex].noteTitle = note.title;
      }
    }

    props.setActions(tempActions);
  };

  let deleteAction = (arrayIndex) => {
    let tempActions = { ...actions };
    let list = tempActions.list;
    list.splice(arrayIndex, 1);
    props.setActions(tempActions);
  };

  if (defaultAction) {
    let { defaultFunc } = currentAction;
    let actionsKeys = actions && actions.list ? Object.keys(actions.list) : [];

    return (
      <>
        {actionsKeys.length > 0 && (
          <div className="col-12" style={{ padding: "20px 0px 0px 70px" }}>
            else if none of the above rules are true then
          </div>
        )}

        <div className="row col-12" style={{ margin: "10px 0px" }}>
          <div
            style={{
              width: "70px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <div
              style={{
                height: "12px",
                width: "12px",
                borderRadius: "50%",
                backgroundColor: "#24305e",
                marginRight: "8px",
              }}
            />
          </div>

          <div
            style={{
              flex: 1,
              marginTop: "6px",
              border: "2px #DDECF6 solid",
              borderRadius: "4px",
            }}>
            <div
              style={{
                cursor: "pointer",
                width: "100%",
                display: "flex",
                height: "35px",
                borderBottom: "1px #ddd solid",
                padding: "5px",
                backgroundColor: "#DDECF6",
              }}>
              <div
                style={{
                  margin: "2px 0px 0px 5px",
                  color: "#487CB4",
                  fontWeight: "bold",
                }}>
                Default Rule
              </div>
            </div>

            <div style={{ padding: "30px", display: "flex" }}>
              <FormControl
                style={{ width: "175px", paddingRight: "10px" }}
                variant="filled">
                <InputLabel id="assignedTo">Select Action</InputLabel>
                <Select
                  value={defaultFunc}
                  native
                  onChange={(e) => editAction(true, "defaultFunc", e.target.value)}
                  inputProps={{
                    "aria-label": "Filter task by assignee",
                    name: "assignedTo",
                    id: "assignedTo",
                  }}>
                  <option aria-label="None" value="doNothing">
                    Do nothing
                  </option>
                  <option aria-label="Create Task" value="createTask">
                    Create Task
                  </option>
                  <option aria-label="Send Note" value="sendNote">
                    Send Note
                  </option>
                </Select>
              </FormControl>

              {defaultFunc === "createTask" && (
                <SelectUser
                  label="Select Assignee"
                  assignedTo={assignedTo}
                  {...props}
                  userSelected={(userUID) => assignUserSelected(true, userUID)}
                  variant="filled"
                  emptyLabel="unassigned"
                />
              )}
              {defaultFunc === "sendNote" && (
                <SelectNote
                  accountInfo={props.accountInfo}
                  noteId={noteId}
                  noteTitle={noteTitle}
                  itemSelected={(note) => noteSelected(true, note)}
                  variant="outlined"
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  //for now we are just going to grab the first condition, but down the road we could have some ANDs or ORs
  let conditionsKeys = Object.keys(conditions);
  let conditionId = conditionsKeys[0];
  let condition = conditions[conditionId];
  let { metricValue = 0 } = condition;
  let giftAmountOptions = transactionAmountsOptions(currencyLabel);

  return (
    <>
      <div className="row col-12" style={{ margin: "10px 0px" }}>
        <div
          style={{
            width: "70px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <IconButton
            onClick={() => deleteAction(arrayIndex)}
            style={{ marginRight: "8px" }}>
            <i className="material-icons" style={{ fontSize: "18px" }}>
              delete
            </i>
          </IconButton>
        </div>

        <div
          style={{
            flex: 1,
            marginTop: "6px",
            border: "1px #ddd solid",
            borderRadius: "4px",
          }}>
          <div
            style={{
              cursor: "pointer",
              width: "100%",
              display: "flex",
              height: "35px",
              borderBottom: "1px #ddd solid",
              padding: "5px",
            }}>
            <i className="material-icons">drag_handle</i>
            <div style={{ margin: "2px 0px 0px 5px" }}>Condition: {props.order}</div>
          </div>

          <div style={{ padding: "20px" }}>
            <div> If the transaction amount is greater than or equal to </div>

            <SelectItem
              options={giftAmountOptions}
              selectedItem={metricValue}
              handleChangeonChange={(e) =>
                editAction(false, "metricValue", e.target.value, conditionId)
              }
              label="Amount"
              alt="Select gift amount"
              width="150px"
            />

            <div> then </div>

            <FormControl style={{ width: "175px", margin: "5px 0px" }} variant="filled">
              <Select
                value={successFunc}
                native
                onChange={(e) => editAction(false, "successFunc", e.target.value)}
                inputProps={{
                  "aria-label": "Filter task by assignee",
                  name: "assignedTo",
                  id: "assignedTo",
                }}>
                <option aria-label="None" value="doNothing">
                  Do nothing
                </option>
                <option aria-label="Create Task" value="createTask">
                  Create Task
                </option>
                <option aria-label="Send Note" value="sendNote">
                  Send Note
                </option>
              </Select>
            </FormControl>

            {successFunc === "createTask" && (
              <>
                <div style={{ margin: "5px 0px" }}> and assign it to </div>
                <SelectUser
                  assignedTo={assignedTo}
                  {...props}
                  userSelected={(userUID) => assignUserSelected(false, userUID)}
                  variant="filled"
                  emptyLabel="unassigned"
                />
              </>
            )}

            {successFunc === "sendNote" && (
              <>
                <div style={{ margin: "5px 0px" }}> and send this note </div>
                <SelectNote
                  accountInfo={props.accountInfo}
                  noteId={noteId}
                  noteTitle={noteTitle}
                  itemSelected={(note) => noteSelected(false, note)}
                  variant="outlined"
                />
              </>
            )}
          </div>
        </div>
      </div>

      <div style={{ marginLeft: "90px", marginTop: "10px", padding: "5px 0px" }}>
        else if transaction amount is not greater than or equal to ${metricValue}, then
      </div>
    </>
  );
}
Action.propTypes = {
  accountInfo: PropTypes.shape({}),
  currentAction: PropTypes.shape({}),
  defaultAction: PropTypes.bool,
  actions: PropTypes.shape({}),
  arrayIndex: PropTypes.string,
  currencyLabel: PropTypes.string,
  order: PropTypes.string,
  setActions: PropTypes.func,
};
