export function downloadFile(dataString, fileName = "data.csv") {
  const blob = new Blob([dataString]);
  if (window.navigator.msSaveOrOpenBlob) {
    // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
    window.navigator.msSaveBlob(blob, fileName);
  } else {
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    link.click();
    URL.revokeObjectURL(url);
  }
}
