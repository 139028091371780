import React from "react";
import PropTypes from "prop-types";
import DailyChart from "./dailyChart";
import TopNotesChart from "./topNotesChart";
import SummaryBox from "./summaryBox";
import { FormControl, Select, FormControlLabel, Switch } from "@material-ui/core";
import Replies from "./replies";
import DownloadContactsDialog from "./downloadContactsDialog";
import QuestionToLink from "../../util/explainers/questionToLink";

export default function Analytics(props) {
  let {
    noteId,
    calledFromNote = null,
    summaryBoxTotals,
    dataByShortDateArray,
    dataByNoteId,
    lastXDays,
    updateDays,
    showUniqueData,
    handleUniqueToggle,
    errorMessage,
  } = props;
  let loading = dataByShortDateArray === "loading" ? true : false;

  return (
    <>
      <div className="row pagePadding col-12">
        <div className="row col-12" style={{ padding: "10px" }}>
          <div style={{ flex: 1, display: "flex" }}>
            <div
              className="align-items-center"
              style={{
                backgroundColor: "#405468",
                padding: "5px 20px",
                borderRadius: "25px",
              }}>
              <h1 style={{ fontSize: "16px", color: "white" }}>
                {calledFromNote ? "Note Analytics" : "Account Analytics"}
              </h1>
            </div>
            <FormControl
              className="boxShadow"
              style={{
                marginLeft: "10px",
                padding: "5px 20px",
                borderRadius: "25px",
                width: "150px",
              }}>
              <Select
                value={lastXDays}
                native
                onChange={(e) => updateDays(e)}
                inputProps={{ name: "lastXDays", id: "lastXDays" }}>
                <option value={3}>Last 3 Days</option>
                <option value={7}>Last 7 Days</option>
                <option value={14}>Last 14 Days</option>
                <option value={28}>Last 28 Days</option>
                <option value={30}>Last 30 Days</option>
                <option value={90}>Last 90 Days</option>
                <option value={180}>Last 180 Days</option>
                <option value={360}>Last 360 Days</option>
              </Select>
            </FormControl>
          </div>

          <FormControlLabel
            control={
              <Switch
                checked={showUniqueData}
                onChange={handleUniqueToggle}
                name="showUniqueData"
              />
            }
            label="Show Unique Data"
          />

          <div style={{ height: "48px", display: "flex" }}>
            <QuestionToLink id="analytics" desc="analytics" size="medium" />
          </div>
          <div style={{ width: "55px" }}>
            <DownloadContactsDialog
              noteId={props.noteId}
              lastXDays={lastXDays}
              accountId={props.accountInfo.id}
            />
          </div>
        </div>

        {errorMessage && (
          <div className="errorMessageBox">
            <b>{errorMessage}</b>
          </div>
        )}

        <div className="col-12 col-md-4">
          <SummaryBox
            loading={loading}
            dataType="video"
            data={summaryBoxTotals.note}
            showUniqueData={showUniqueData}
            handleUniqueToggle={handleUniqueToggle}
          />
        </div>

        <div className="col-12 col-md-4">
          <SummaryBox
            loading={loading}
            dataType="video-rate"
            data={summaryBoxTotals.note}
            showUniqueData={showUniqueData}
            handleUniqueToggle={handleUniqueToggle}
          />
        </div>

        <div className="col-12 col-md-4">
          <SummaryBox
            loading={loading}
            dataType="note"
            data={summaryBoxTotals.note}
            showUniqueData={showUniqueData}
            handleUniqueToggle={handleUniqueToggle}
          />
        </div>

        <div className="col-12 col-md-4">
          <SummaryBox
            loading={loading}
            dataType="email-open"
            data={summaryBoxTotals.email}
            showUniqueData={showUniqueData}
            handleUniqueToggle={handleUniqueToggle}
          />
        </div>

        <div className="col-12 col-md-4">
          <SummaryBox
            loading={loading}
            dataType="email-ctr"
            data={summaryBoxTotals.email}
            showUniqueData={showUniqueData}
            handleUniqueToggle={handleUniqueToggle}
          />
        </div>

        <div className="col-12 col-md-4">
          <SummaryBox
            loading={loading}
            dataType="text"
            data={summaryBoxTotals.text}
            showUniqueData={showUniqueData}
            handleUniqueToggle={handleUniqueToggle}
          />
        </div>

        <div className="col-12">
          <Replies
            loading={loading}
            noteId={noteId}
            lastXDays={lastXDays}
            accountId={props.accountInfo.id}
          />
        </div>

        <div className="col-12">
          <DailyChart
            loading={loading}
            dataByShortDateArray={dataByShortDateArray}
            lastXDays={lastXDays}
          />
        </div>

        {!calledFromNote && (
          <div className="col-12">
            <TopNotesChart
              loading={loading}
              dataByNoteId={dataByNoteId}
              lastXDays={lastXDays}
              accountId={props.accountInfo.id}
            />
          </div>
        )}
      </div>
    </>
  );
}

Analytics.propTypes = {
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  noteId: PropTypes.string,
  calledFromNote: PropTypes.bool,
  summaryBoxTotals: PropTypes.object.isRequired,
  dataByShortDateArray: PropTypes.any,
  dataByNoteId: PropTypes.object.isRequired,
  lastXDays: PropTypes.number.isRequired,
  updateDays: PropTypes.func.isRequired,
  showUniqueData: PropTypes.bool.isRequired,
  handleUniqueToggle: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};
