import React, { useState } from "react";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import SelectColor from "../../../../util/selectColor";
import TextInputToApi from "components/util/hooks/input/textInputToApi";
import { validURL } from "util/urls";

export default function Buttons(props) {
  const [buttonLinkErrorMessage, setButtonLinkErrorMessage] = useState(null);

  async function updateButton(field, value) {
    if (field === "link") {
      if (!value.startsWith("http") && !value.startsWith("mailto:"))
        setButtonLinkErrorMessage(
          "Link must include http://, https://, or mailto: at the beginning. ie - https://www.gratavid.com/"
        );
      else if (!validURL(value)) {
        setButtonLinkErrorMessage("Invalid URL");
      } else {
        setButtonLinkErrorMessage(null);
        return props.onChange(field, value);
      }
    } else {
      return props.onChange(field, value);
    }
  }

  return (
    <div
      className="row col-12"
      style={{
        border: "1px #ddd solid",
        padding: "5px",
        display: "flex",
        borderRadius: 2,
      }}>
      <div
        className="align-items-center"
        style={{ cursor: "pointer", width: "45px", padding: "5px" }}>
        <IconButton size="small">
          <i className="material-icons" id="gvDragHandleIcon">
            drag_handle
          </i>
        </IconButton>
      </div>

      <div className="col-12 row" style={{ padding: "5px", flex: 1 }}>
        <div className="col-12" style={{ padding: "14px 0px 0px 0px" }}>
          <TextInputToApi
            initialValue={props.current.label}
            apiCall={updateButton}
            field="label"
            label="Button Label"
            variant="standard"
          />
        </div>
        <div className="col-12" style={{ padding: "14px 0px 0px 0px" }}>
          <TextInputToApi
            initialValue={props.current.link}
            apiCall={updateButton}
            field="link"
            label="Button Link"
            variant="standard"
            parentErrorMessage={buttonLinkErrorMessage}
          />
        </div>
        <div className="col-12" style={{ padding: "14px 0px 0px 0px" }}>
          <label className="greyDesc" style={{ fontSize: "12px" }}>
            Button Color
          </label>
          <SelectColor
            color={props.current.color}
            colorKey="color"
            passedSetState={updateButton}
          />
        </div>
      </div>

      <div
        className="align-items-center"
        style={{ cursor: "pointer", width: "45px", padding: "5px" }}>
        <IconButton size="small" onClick={props.onDelete}>
          <i className="material-icons">clear</i>
        </IconButton>
      </div>
    </div>
  );
}

Buttons.propTypes = {
  current: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
    color: PropTypes.string,
  }),
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
};
