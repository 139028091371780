import React from "react";
import PropTypes from "prop-types";
import ReSendDialog from "./request/reSendDialog";
import Button from "@material-ui/core/Button";
import TextInput from "components/util/textInput";
import { editVideo } from "services/api.service";

export default function VideoPreview(props) {
  let deleteMediaUpload = () => {
    //save current datat to delete state. if user cancels delete, we just reload this data
    let { status, webmURL, mediaURL, thumbnail, gif, audioSrc, captions } = props;
    let deletedVideoState = {
      status,
      webmURL,
      mediaURL,
      thumbnail,
      gif,
      audioSrc,
      captions,
    };

    props.videoUploadStatusChanged("create", null, null, null, null, null, null, null);
    props.passedSetEditVideoState("deletedVideoState", deletedVideoState);
  };

  return (
    <>
      <div className="col-12 row pagePadding justify-content-center">
        <div className="col-12 row" style={{ maxWidth: "600px" }}>
          <div className="col-12" style={{ marginTop: "25px" }}>
            <TextInput
              value={props?.speakerName ?? ""}
              onChange={(value) => editVideo(props.videoId, { speakerName: value })}
              debounce={300}
              placeholder="e.g. Sarah Smith, CEO"
              variant="outlined"
              label="Video Speaker Name"
              fullWidth
            />
          </div>

          <div className="col-12 justify-content-center" style={{ marginTop: "15px" }}>
            <video
              src={props.mediaURL}
              style={{
                width: "100%",
                objectFit: "contain",
                maxHeight: "400px",
                backgroundColor: "#ddd",
                borderRadius: "10px",
              }}
              controls
            />
          </div>

          <div
            className="col-12 row"
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "35px",
            }}>
            <div>
              <h3>Thumbnail</h3>
              <img
                src={props.thumbnail}
                style={{ maxWidth: "150px", borderRadius: "10px" }}
                alt="thumbnail"
              />
            </div>

            <div>
              <h3>GIF</h3>
              <img
                src={props.gif}
                style={{ maxWidth: "150px", borderRadius: "10px" }}
                alt="GIf"
              />
            </div>
          </div>

          <div
            className="col-12 justify-content-center"
            style={{ marginTop: "25px", padding: "20px" }}>
            {props.uploadType === "request" ? (
              <ReSendDialog {...props} />
            ) : (
              <Button variant="outlined" onClick={() => deleteMediaUpload()}>
                <i className="material-icons" style={{ marginRight: "5px" }}>
                  delete
                </i>
                Record/Upload New
              </Button>
            )}
          </div>

          <div className="col-12" style={{ height: "100px" }} />
        </div>
      </div>
    </>
  );
}

VideoPreview.propTypes = {
  accountId: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
  uploadType: PropTypes.string.isRequired,
  gif: PropTypes.string,
  speakerName: PropTypes.string,
  thumbnail: PropTypes.string,
  mediaURL: PropTypes.string,
  status: PropTypes.string.isRequired,
  videoUploadStatusChanged: PropTypes.func.isRequired,
  passedSetEditVideoState: PropTypes.func.isRequired,
};
