const { createTheme } = require("@material-ui/core");

//yellow f8e9a1
//light blue #a8d0e6
//light navy #374785
//dark navy #24305e
//orange #f76c6c

export default createTheme({
  palette: {
    secondary: { main: "#f76c6c", contrastText: "#ffffff" },
    primary: { light: "#a8d0e6", main: "#24305e", contrastText: "#fff" },
  },
  typography: { useNextVariants: true },
  overrides: {
    MuiAvatar: {
      root: {
        lineHeight: "1rem", // fix for 15px font-sze
      },
    },
    MuiBottomNavigation: {
      root: {
        height: "auto",
      },
    },
    MuiBottomNavigationAction: {
      root: {
        padding: "12px 0 16px",
        "&$selected": {
          color: "inherit",
          paddingTop: "12px",
        },
      },
      label: {
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: 500,
        paddingTop: "4px",
        color: "hsl(0, 0%, 30%)",
        "&$selected": {
          fontSize: "12px",
          lineHeight: "16px",
          fontWeight: 700,
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: "9999px",
      },
    },
    MuiButtonGroup: {
      root: {
        borderRadius: "9999px",
      },
    },
    MuiIconButton: {
      root: {
        color: "hsl(0, 0%, 30%)",
      },
    },
    MuiChip: {
      label: {
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: "400",
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: "16px",
        boxShadow: "0px 15px 15px rgba(0, 0, 0, 0.08)",
        marginTop: "12px",
      },
      list: {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        padding: "12px",
        minWidth: "190px",
      },
    },
    MuiMenuItem: {
      gutters: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: "auto",
        paddingRight: "8px",
      },
    },
  },
});
