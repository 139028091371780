export default {
  "": {
    title: "How To Send",
    href: "https://graduwayhelp.zendesk.com/hc/en-us/articles/6653342062876-How-To-Send-A-Gratavid",
  },
  task: {
    title: "How To Send",
    href: "https://graduwayhelp.zendesk.com/hc/en-us/articles/6653342062876-How-To-Send-A-Gratavid",
  },
  notes: {
    title: "How To Send",
    href: "https://graduwayhelp.zendesk.com/hc/en-us/articles/6653342062876-How-To-Send-A-Gratavid",
  },
  videos: {
    title: "Video Requests",
    href: "https://graduwayhelp.zendesk.com/hc/en-us/articles/6653463948828-How-To-Collect-Request-Videos",
  },
  "bulk-video": {
    title: "Bulk Requests",
    href: "https://graduwayhelp.zendesk.com/hc/en-us/articles/6653463948828-How-To-Collect-Request-Videos",
  },
  contacts: {
    title: "Manage Contacts",
    href: "https://graduwayhelp.zendesk.com/hc/en-us/articles/6653507685660-How-To-Add-Update-Contacts",
  },
  editnote: {
    title: "How To Send",
    href: "https://graduwayhelp.zendesk.com/hc/en-us/articles/6653342062876-How-To-Send-A-Gratavid",
  },
  editvideo: {
    title: "Manage Videos",
    href: "https://graduwayhelp.zendesk.com/hc/en-us/articles/6656338310172-Edit-Video-Overview-Download-Trim-Rotate-",
  },
  birthday: {
    title: "Setup Birthdays",
    href: "https://graduwayhelp.zendesk.com/hc/en-us/articles/6653584971036-How-To-Send-Birthday-Gratavids",
  },
  users: {
    title: "Manage Users",
    href: "https://graduwayhelp.zendesk.com/hc/en-us/articles/6656599048476-Managing-Users",
  },
  "integration-blackbaud": {
    title: "Blackbaud Setup",
    href: "https://graduwayhelp.zendesk.com/hc/en-us/articles/6616387050140-Blackbaud-Integration",
  },
  "integration-salesforce": {
    title: "Salesforce Setup",
    href: "https://graduwayhelp.zendesk.com/hc/en-us/articles/6616948402076-Salesforce-Integration",
  },
  "integration-bloomerang": {
    title: "Bloomerang Setup",
    href: "https://graduwayhelp.zendesk.com/hc/en-us/articles/6616544788636-Bloomerang-Integration",
  },
  "integration-planningCenter": {
    title: "Planning Center",
    href: "https://graduwayhelp.zendesk.com/hc/en-us/articles/6617041872412-Planning-Center-Integration",
  },
  "integration-donorPerfect": {
    title: "DonorPerfect",
    href: "https://graduwayhelp.zendesk.com/hc/en-us/articles/6652868543772-DonorPerfect-Integration",
  },
  "integration-neon": {
    title: "Neon One Setup",
    href: "https://graduwayhelp.zendesk.com/hc/en-us/articles/6652989750940-Neon-One-Integration",
  },
  "integration-virtuous": {
    title: "Virtuous Setup",
    href: "https://graduwayhelp.zendesk.com/hc/en-us/articles/6617338612380-Virtuous-Integration",
  },
};
