import React, { useState, useEffect } from "react";
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  withMobileDialog,
} from "@material-ui/core";
import LoadingButtonCheckProductLimits from "../../../../../../util/buttons/productLimits/loadingbutton";
import LoadingButton from "../../../../../../util/buttons/loadingbutton";
import ListContactInputs from "../../../../../../util/contacts/listContactInputs";
import { upsertContact } from "services/api.service";
import { consolidateCustomProperties, flattenCustomProperties } from "util/contacts";

function AddEditContactDialog(props) {
  let { accountInfo, customContactProperties } = props;
  let [contactData, setContactData] = useState(props.contactData);
  const [flattenedContactData, setFlattenedContactData] = useState(null);
  let [state, setState] = useState({});

  useEffect(() => {
    const contact = { ...contactData.contact };
    setFlattenedContactData({
      ...contactData,
      contact: flattenCustomProperties(props.accountInfo, contact),
    });
  }, [contactData, props.accountInfo]);
  let validateEmail = (email) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  function handleSetContactData(data) {
    const contact = consolidateCustomProperties(props.accountInfo, data?.contact);
    setContactData({ ...contactData, contact });
  }

  let saveContact = async () => {
    try {
      let {
        email,
        cellNumber,
        customProperties,
        customAccountId,
        customUserId,
        firstName,
        id,
        lastName,
        salutation,
        textOptIn,
      } = contactData.contact;
      email = email.replace(/ /g, "");
      if (!validateEmail(email)) {
        throw new Error("Invalid email");
      }

      setState({ buttonState: "loading" });

      const responseData = await upsertContact({
        email,
        cellNumber,
        customProperties,
        customAccountId,
        customUserId,
        firstName,
        id,
        lastName,
        salutation,
        textOptIn,
      });
      const updatedContactData = { ...contactData };
      updatedContactData.contact = { ...contactData.contact, ...responseData };
      //save data back into contact map so it shows as selected contact
      if (
        !props.taskContacts.some(
          (taskContact) => taskContact.contactId === updatedContactData.contact.id
        )
      ) {
        props.setTaskContacts([...props.taskContacts, updatedContactData]);
      } else {
        props.setTaskContacts(
          props.taskContacts.map((taskContact) => {
            if (taskContact.contactId === updatedContactData.contact.id) {
              return contactData;
            }
            return taskContact;
          })
        );
      }

      if (props.addContactToTask) {
        props.addContactToTask(updatedContactData.contact);
      }

      props.closeDialog();

      if (props.setShowSearchContacts) {
        props.setShowSearchContacts(null);
      }
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      setState({ errorMessage });
    }
  };

  return (
    <Dialog
      fullScreen={props.fullScreen}
      open={true}
      onClose={props.closeDialog}
      aria-labelledby="Edit contact">
      <DialogContent
        style={{
          height: props.fullScreen ? "auto" : "550px",
          width: props.fullScreen ? "auto" : "600px",
        }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <span style={{ fontSize: "22px" }} role="heading">
              {contactData?.id ? "Edit contact" : "Add contact"}
            </span>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton aria-label="close" onClick={props.closeDialog}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        <ListContactInputs
          contactData={flattenedContactData}
          setContactData={handleSetContactData}
          accountInfo={accountInfo}
          customContactProperties={customContactProperties}
          disableAutoSuggest={true}
        />
      </DialogContent>
      <DialogActions
        style={{
          padding: "12px",
          backgroundColor: "hsl(220, 12%, 95%)",
          display: "flex",
        }}>
        <div style={{ flex: 1 }}>
          {state.errorMessage && (
            <div className="col-12 errorMessageBox">
              <b>{state.errorMessage}</b>
            </div>
          )}
        </div>

        <Button variant="outlined" color="default" onClick={props.closeDialog}>
          Cancel
        </Button>

        {contactData?.id ? (
          <LoadingButton
            variant="contained"
            color="primary"
            buttonState={state.buttonState}
            savedLabel="Saved"
            label="Save"
            clickedRegularButton={saveContact}
            clickedSavedButton={props.closeDialog}
          />
        ) : (
          <LoadingButtonCheckProductLimits
            productKey="contacts"
            productLabel="contact"
            countToAdd={1}
            variant="contained"
            color="primary"
            buttonState={state.buttonState}
            savedLabel="Close"
            label="Save"
            clickedRegularButton={saveContact}
            clickedSavedButton={props.closeDialog}
            userIsAdmin={props.userIsAdmin}
            accountInfo={props.accountInfo}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}

export default withMobileDialog()(AddEditContactDialog);
