import React from "react";
import PropTypes from "prop-types";
import styles from "./rule.module.css";
import {
  FormControl,
  Select,
  IconButton,
  InputLabel,
  Button,
  TextField,
  Chip,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SelectTags from "components/util/tags/selectTags";
import { makeStyles } from "@material-ui/core/styles";
import RuleKeys from "./rule-keys";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFilledInput-root": {
      background: "white",
      border: "rgb(0, 164, 189) 2px solid",
    },
  },
}));

export default function Rule(props) {
  const classes = useStyles();

  const onSelectKey = (conditionIndex, key) => {
    const condition = props.rule[conditionIndex];
    condition.key = key;
    condition.values = [];
    props.onChange([...props.rule]);
  };

  const onSelectValue = (conditionIndex, values) => {
    const condition = props.rule[conditionIndex];
    condition.values = values.map(({ label }) => label);
    props.onChange([...props.rule]);
  };

  const onDeleteCondition = (conditionIndex) => {
    props.rule.splice(conditionIndex, 1);
    props.onChange([...props.rule]);
  };

  const onAddCondition = () => {
    props.rule.push({
      key: RuleKeys.affiliations,
      index: 0,
      values: [],
      folders: props.rule[0].folders,
    });
    props.onChange([...props.rule]);
  };

  const onFolderChange = (newFolders) => {
    for (const condition of props.rule) {
      condition.folders = newFolders;
    }
    props.onChange([...props.rule]);
  };

  return (
    <div className={`row col-12 ${styles.wrapper}`}>
      <div className={`col-12 ${styles.row}`}>
        <div className={`col-12 ${styles.titleWrapper}`}>
          <div className={styles.title}>
            <h4>Create Gratavid List</h4>
          </div>
          <div>
            <IconButton onClick={props.onDelete} className={styles.buttonWrapper}>
              <i className="material-icons">delete</i>
            </IconButton>
          </div>
        </div>
        <div className={styles.rulesWrapper}>
          {props.rule.map((condition, conditionIndex) => {
            const isFirst = conditionIndex === 0;
            return (
              <div className={styles.ruleWrapper} key={`condition-${conditionIndex}`}>
                <div className={styles.label}>
                  <h3>{isFirst ? "IF" : "AND IF"}</h3>
                </div>

                <div className={styles.formWrapper}>
                  <FormControl
                    variant="filled"
                    className={`${classes.root} ${styles.dropdown}`}>
                    <InputLabel id="conditionType">Select Condition</InputLabel>
                    <Select
                      value={condition.key}
                      native
                      onChange={(e) => onSelectKey(conditionIndex, e.target.value)}
                      inputProps={{
                        "aria-label": "Select condition type",
                        name: "conditionType",
                        id: "conditionType",
                      }}>
                      {Object.keys(props.conditions).map((key) => (
                        <option value={key} key={key}>
                          {props.conditions[key].description}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className={styles.rightDropdown}>
                    <Autocomplete
                      multiple
                      selectOnFocus
                      style={{ backgroundColor: "white" }}
                      value={condition.values.map((label) => ({ id: label, label }))}
                      options={props.conditions[condition.key].values.map((label) => ({
                        id: label,
                        label,
                      }))}
                      onChange={(event, inputValues) =>
                        onSelectValue(conditionIndex, inputValues)
                      }
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.label}
                      getOptionSelected={(option, value) => value.id === option.id}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox checked={selected} />
                          {option.label}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          label={props.conditions[condition.key].label}
                          className={classes.root}
                        />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option.label}
                            key={option.label}
                            size="small"
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                    />
                  </FormControl>
                </div>
                <div className={styles.closeWrapper}>
                  {!isFirst && (
                    <IconButton
                      onClick={() => onDeleteCondition(conditionIndex)}
                      style={{ marginRight: "8px" }}>
                      <i className="material-icons">close</i>
                    </IconButton>
                  )}
                </div>
              </div>
            );
          })}
          <Button
            size="small"
            onClick={() => onAddCondition()}
            variant="text"
            className={styles.addButton}>
            <i className="material-icons">add</i>
            <span className={styles.buttonText}>Add Condition</span>
          </Button>
          <div className={`col-12 ${styles.decoWrapper}`}>
            <i className="material-icons">arrow_downward</i>
            <i className="material-icons">arrow_downward</i>
            <i className="material-icons">arrow_downward</i>
          </div>

          <div className={styles.tagWrapper}>
            <div className={styles.tagTitle}>
              <h3>Add To</h3>
            </div>

            <SelectTags
              accountId={props.accountInfo.id}
              selectedFoldersArray={props.rule[0].folders}
              setSelectedFoldersArray={onFolderChange}
              indexName="contacts"
              inputVariant="outlined"
              label="List"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const conditionType = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Rule.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  rule: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      index: PropTypes.number.isRequired,
      values: PropTypes.arrayOf(PropTypes.string).isRequired,
      folders: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired
  ),
  onChange: PropTypes.func.isRequired,
  conditions: PropTypes.shape({
    [RuleKeys.affiliations]: PropTypes.shape(conditionType),
    [RuleKeys.industries]: PropTypes.shape(conditionType),
    [RuleKeys.functions]: PropTypes.shape(conditionType),
    [RuleKeys.groups]: PropTypes.shape(conditionType),
  }).isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
