import React, { useState } from "react";
import AddSingleContact from "./addSingleContact";
import AddBulkStepper from "./bulk/addBulkStepper";
import IntegrationsAddBulkStepper from "./integrations/addBulkStepper";
import GraduwayEngageStepper from "./graduwayEngage/stepper";
import IconButton from "@material-ui/core/IconButton";
import Radio from "@material-ui/core/Radio";
import { Accordion, AccordionSummary, AccordionDetails, Button } from "@material-ui/core";
import QuestionToLink from "../../../util/explainers/questionToLink";
import { useCheckListIntegrations } from "./useCheckListIntegrations";
import { useGetAccountCustomProperties } from "../../../util/contacts/useGetAccountCustomProperties";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiCollapse-root": {
      width: "100%",
    },
  },
}));

export function BulkUploadsDisabled(accountId) {
  return (
    <div className="col-12 row">
      <p style={{ fontSize: "15px" }}>
        Your current Gratavid plan does not include uploading contacts in bulk. You must
        add contacts one at a time or upgrade your plan.
      </p>

      <div className="col-12" style={{ margin: "15px 0px 30px 0px" }}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={`/a/${accountId}/subscription`}>
          Upgrade Plan
        </Button>
      </div>
    </div>
  );
}

export default function AddContactDialog(props) {
  const [expanded, setExpanded] = useState("");
  let { accountInfo, userInfo, bulkUploadContactsDisabled } = props;
  const [showBlackbaudImport] = useCheckListIntegrations(
    accountInfo,
    userInfo,
    "blackbaud"
  );
  const [showSaleforceImport] = useCheckListIntegrations(
    accountInfo,
    userInfo,
    "salesforce"
  );
  const [showGraduwayEngageImport] = useCheckListIntegrations(
    accountInfo,
    userInfo,
    "graduwayEngage"
  );
  let [accountCustomProperties, customContactProperties] = useGetAccountCustomProperties(
    props.accountInfo
  );
  const classes = useStyles();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="row col-12" style={{ minWidth: "250px" }}>
      <div className="row col-12" style={{ marginBottom: "10px" }}>
        <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
          <h1>Add Contacts</h1>
          <QuestionToLink id="contacts" desc="contacts" />
        </div>

        <div style={{ width: "55px" }}>
          <IconButton
            aria-label="Delete"
            onClick={() => {
              props.setAddNewDialogOpen(false);
            }}>
            <i className="material-icons">close</i>
          </IconButton>
        </div>
      </div>

      <div className="col-12 row">
        <Accordion
          className="col-12"
          expanded={expanded === "addSingle"}
          onChange={handleChange("addSingle")}>
          <AccordionSummary
            style={{ padding: "10px 15px 10px 10px" }}
            expandIcon={<i className="material-icons">expand_more</i>}
            aria-controls="panel2bh-content"
            id="panel2bh-header">
            <div style={{ display: "flex" }}>
              <div style={{ width: "40px" }} className="align-items-center">
                <Radio
                  style={{ width: "40px" }}
                  checked={expanded === "addSingle"}
                  value="pro"
                  color="primary"
                  aria-label="contact form"
                />
              </div>
              <div style={{ flex: 1 }}>
                <h2>Add Single Contact</h2>
                <p className="greyDesc">Manually add contacts one at a time.</p>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <AddSingleContact
              {...props}
              customContactProperties={customContactProperties}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion
          className="col-12"
          expanded={expanded === "addBulk"}
          onChange={handleChange("addBulk")}>
          <AccordionSummary
            style={{ padding: "10px 15px 10px 10px" }}
            expandIcon={<i className="material-icons">expand_more</i>}
            aria-controls="panel3bh-content"
            id="panel3bh-header">
            <div style={{ display: "flex" }}>
              <div style={{ width: "40px" }} className="align-items-center">
                <Radio
                  style={{ width: "40px" }}
                  checked={expanded === "addBulk"}
                  value="pro"
                  color="primary"
                  aria-label="bulk import"
                />
              </div>
              <div style={{ flex: 1 }}>
                <h2>Bulk Upload CSV</h2>
                <p className="greyDesc">Bulk upload contacts from a csv file.</p>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {bulkUploadContactsDisabled ? (
              <BulkUploadsDisabled accountUID={props.accountInfo.id} />
            ) : (
              <AddBulkStepper {...props} />
            )}
          </AccordionDetails>
        </Accordion>

        {showGraduwayEngageImport && (
          <>
            <Accordion
              className={classes.root}
              expanded={expanded === "addGraduwayEngage"}
              onChange={handleChange("addGraduwayEngage")}>
              <AccordionSummary
                className="col-12"
                style={{ padding: "10px 15px 10px 10px" }}
                expandIcon={<i className="material-icons">expand_more</i>}
                aria-controls="panel3bh-content"
                id="panel3bh-header">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "40px" }} className="align-items-center">
                    <Radio
                      style={{ width: "40px" }}
                      checked={expanded === "addGraduwayEngage"}
                      value="pro"
                      color="primary"
                      aria-label="import from Graduway Engage"
                    />
                  </div>
                  <div
                    style={{ width: 70 }}
                    className="align-items-center justify-content-center">
                    <img
                      src="/static/img/logos/graduwayIcon.png"
                      width={65}
                      alt="Graduway logo"
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <h2>Import From Graduway</h2>
                    <p className="greyDesc">
                      Create Gratavid lists from Graduway data files
                    </p>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.root}>
                {bulkUploadContactsDisabled ? (
                  <BulkUploadsDisabled accountUID={props.accountInfo.id} />
                ) : (
                  expanded === "addGraduwayEngage" && <GraduwayEngageStepper {...props} />
                )}
              </AccordionDetails>
            </Accordion>
          </>
        )}

        {showBlackbaudImport && (
          <>
            <Accordion
              className="col-12"
              expanded={expanded === "addBlackbaud"}
              onChange={handleChange("addBlackbaud")}>
              <AccordionSummary
                style={{ padding: "10px 15px 10px 10px" }}
                expandIcon={<i className="material-icons">expand_more</i>}
                aria-controls="panel3bh-content"
                id="panel3bh-header">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "40px" }} className="align-items-center">
                    <Radio
                      style={{ width: "40px" }}
                      checked={expanded === "addBlackbaud"}
                      value="pro"
                      color="primary"
                      aria-label="import from blackbaud"
                    />
                  </div>
                  <div
                    style={{ width: 70 }}
                    className="align-items-center justify-content-center">
                    <img
                      src="/static/img/logos/blackbaudLogo.png"
                      width={50}
                      alt="Blackbaud logo"
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <h2>Import From Blackbaud</h2>
                    <p className="greyDesc">Import contacts from a Blackbaud list.</p>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {bulkUploadContactsDisabled ? (
                  <BulkUploadsDisabled accountUID={props.accountInfo.id} />
                ) : (
                  expanded === "addBlackbaud" && (
                    <IntegrationsAddBulkStepper
                      platformLabel="Blackbaud"
                      platformKey="blackbaud"
                      {...props}
                    />
                  )
                )}
              </AccordionDetails>
            </Accordion>
          </>
        )}

        {showSaleforceImport && (
          <>
            <Accordion
              className="col-12"
              expanded={expanded === "addSalesforce"}
              onChange={handleChange("addSalesforce")}>
              <AccordionSummary
                style={{ padding: "10px 15px 10px 10px" }}
                expandIcon={<i className="material-icons">expand_more</i>}
                aria-controls="panel3bh-content"
                id="panel3bh-header">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "40px" }} className="align-items-center">
                    <Radio
                      style={{ width: "40px" }}
                      checked={expanded === "addSalesforce"}
                      value="pro"
                      color="primary"
                      aria-label="import from addSalesforce"
                    />
                  </div>
                  <div
                    style={{ width: 70 }}
                    className="align-items-center justify-content-center">
                    <img
                      src="/static/img/logos/salesforce.svg"
                      width={50}
                      alt="Salesforce logo"
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <h2>Import From Salesforce</h2>
                    <p className="greyDesc">
                      Import contacts from a Salesforce list view.
                    </p>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {bulkUploadContactsDisabled ? (
                  <BulkUploadsDisabled accountUID={props.accountInfo.id} />
                ) : (
                  expanded === "addSalesforce" && (
                    <IntegrationsAddBulkStepper
                      platformLabel="Salesforce"
                      platformKey="salesforce"
                      {...props}
                    />
                  )
                )}
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </div>
      <div className="col-12" style={{ height: "100px" }} />
    </div>
  );
}
