import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import NoteItem from "../noteItem";
import BulkActionForSearchTableHeader from "../../../util/search/bulkActionForSearchTableHeader";
import Checkbox from "@material-ui/core/Checkbox";

export default function ListNotes(props) {
  let { searchList: notes, searchState } = props;

  let [selectedItems, setSelectedItems] = useState([]);
  let [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    if (allSelected && notes?.length) {
      setSelectedItems(notes.map((note) => ({ id: note.id })));
    }
  }, [notes, allSelected]);

  if (notes === "loading") return null;

  const selectedItemsCount = allSelected
    ? searchState.nbHits
    : selectedItems.filter((x) => x).length;

  function selectAll() {
    if (allSelected) {
      setSelectedItems([]);
      setAllSelected(false);
    } else {
      setAllSelected(true);
    }
  }

  function itemSelected(noteId, checked) {
    if (allSelected) {
      setAllSelected(false);
      setSelectedItems([{ id: noteId }]);
    } else {
      if (!checked) {
        setSelectedItems(selectedItems.filter((item) => item.id !== noteId));
      } else {
        setSelectedItems([...selectedItems, { id: noteId }]);
      }
    }
  }

  const listNotes = notes.map((note) => {
    return (
      <NoteItem
        current={note}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        itemSelected={itemSelected}
        allSelected={allSelected}
        {...props}
        key={note.id}
      />
    );
  });

  //turn table background red if something selected
  let tableHeadBackgroundColor = selectedItemsCount ? "#fbe0ea" : null;

  //user cant edit notes
  if (!props.userRole.isNoteEditor) {
    return (
      <>
        <div className="row col-12" style={{ padding: "5px 0px" }}>
          <p style={{ color: "grey" }}>
            Showing {notes.length} out of {props.searchState.nbHits} results
          </p>
        </div>

        <div className="row col-12">{listNotes}</div>
      </>
    );
  }

  return (
    <>
      <div className="row col-12" style={{ padding: "5px 0px" }}>
        <p style={{ color: "grey" }}>
          Showing {notes.length} out of {props.searchState.nbHits} results
        </p>
      </div>

      <div
        className="row col-12"
        style={{
          display: "flex",
          padding: "4px",
          border: "2px #999 solid",
          borderRadius: "2px",
          backgroundColor: tableHeadBackgroundColor,
        }}>
        <div style={{ width: "40px" }} className="align-items-center">
          <Checkbox
            checked={allSelected}
            onClick={() => selectAll()}
            size="small"
            style={{ padding: "5px" }}
          />
        </div>

        <div style={{ flex: 1 }} className="align-items-center">
          <b style={{ color: "#999" }}>Title</b>
        </div>

        <div style={{ width: "150px" }} className="row">
          <BulkActionForSearchTableHeader
            {...props}
            accountId={props.accountInfo.id}
            indexName="notes"
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            allSelected={allSelected}
          />
        </div>
      </div>

      <div className="row col-12">{listNotes}</div>
    </>
  );
}

ListNotes.propTypes = {
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  searchList: PropTypes.array.isRequired,
  searchState: PropTypes.shape({
    nbHits: PropTypes.number.isRequired,
  }),
  userRole: PropTypes.shape({
    isNoteEditor: PropTypes.bool.isRequired,
  }),
};
