import React from "react";
import PropTypes from "prop-types";
import styles from "./ssoSegment.module.css";
import Description from "./description.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Controller } from "react-hook-form";

function entityForms(forms, control) {
  return forms.map((form, index) => {
    return (
      <div key={form.id} className={styles.integration}>
        <FormControlLabel
          className={styles.ssoLabel}
          label={form.name}
          control={
            <Controller
              control={control}
              name={`ssos.${index}.isActive`}
              render={({ field }) => (
                <Checkbox {...field} checked={field.value} onChange={field.onChange} />
              )}
            />
          }
        />
      </div>
    );
  });
}

const SsoSegment = ({ forms, control, errors, touched, isSubmitted }) => (
  <div className={styles.ssoSegment}>
    <Description
      title="Single Sign-On Method"
      description="Alternative sign in methods."
    />
    <div>{entityForms(forms, control, errors, touched, isSubmitted)}</div>
  </div>
);

SsoSegment.propTypes = {
  isSubmitted: PropTypes.bool.isRequired,
  forms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  control: PropTypes.object,
  errors: PropTypes.array,
  touched: PropTypes.array,
};

export default SsoSegment;
