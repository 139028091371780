import React, { useState, useCallback } from "react";
import { ClickAwayListener } from "@material-ui/core";
import { debounce } from "util/helpers";

// Component accept text, placeholder values and also pass what type of Input - input, textarea so that we can use it for styling accordingly
export default function InlineEditor(props) {
  let [isEditing, setIsEditing] = useState(null);
  const [state, setState] = useState(props.value || "");

  const debouncedSetState = useCallback(
    debounce((value) => props.passedSetState(value)),
    [props.passedSetState]
  );

  function handleChange(e) {
    debouncedSetState(e.target.value);
    setState(e.target.value);
  }

  return (
    <div>
      {isEditing ? (
        <ClickAwayListener onClickAway={() => setIsEditing(false)}>
          <textarea
            type="text"
            style={{ minWidth: "75%" }}
            value={state}
            onChange={handleChange}
          />
        </ClickAwayListener>
      ) : (
        <div onClick={() => setIsEditing(true)}>
          <span
            style={{
              fontSize: props.fontSize || 15,
              whiteSpace: "pre-line",
              overflowWrap: "anywhere",
            }}>
            {state}
          </span>
        </div>
      )}
    </div>
  );
}
