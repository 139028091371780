import React, { useState } from "react";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Chip, FormControl } from "@material-ui/core";
import { manageIntegrations } from "services/api.service";

export default function GetListSelectList(props) {
  let {
    label = "",
    selectedItem = { id: "", label: "" },
    requestData = {},
    options: initialOptions = "loading",
    optionsFetchNeeded = false,
    variant = "filled",
    width = "50%",
  } = props; //selectedExcludes is all excludes for event ie - opportunityWon

  let [state, setState] = useState({}); //when select is open we change this to loading
  let [options, setOptions] = useState(initialOptions);
  let [defaultValue, setDefaultValue] = useState(selectedItem);

  let selectOpened = async () => {
    if (!optionsFetchNeeded) return;

    //get data
    try {
      const results = await manageIntegrations(
        { ...requestData, accountId: props.accountId },
        requestData
      );

      setOptions(results?.results || []);
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.error(errorMessage);
      setState({ errorMessage });
    }
  };

  let renderOptions = options === "loading" ? [] : options;

  return (
    <FormControl style={{ margin: "5px 0px", width }}>
      <Autocomplete
        id={"select-" + label}
        selectOnFocus
        defaultValue={defaultValue}
        onOpen={selectOpened}
        loading={Boolean(options === "loading")}
        options={renderOptions}
        onChange={(event, inputValue) => props.handleChange(inputValue)}
        getOptionSelected={(option, value) => option.id === selectedItem.id}
        getOptionLabel={(option) => option.label}
        renderOption={({ id, label }) => <React.Fragment>{label}</React.Fragment>}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={variant}
            label={label}
            error={Boolean(state.errorMessage)}
            helperText={state.errorMessage}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={index}
              variant={variant === "filled" ? "outlined" : "filled"}
              label={option.label}
              size="small"
              {...getTagProps({ index })}
            />
          ))
        }
      />
    </FormControl>
  );
}

GetListSelectList.propTypes = {
  label: PropTypes.string,
  selectedItem: PropTypes.object,
  requestData: PropTypes.object,
  options: PropTypes.oneOf([PropTypes.array, PropTypes.string]),
  optionsFetchNeeded: PropTypes.bool,
  variant: PropTypes.string,
  width: PropTypes.string,
  handleChange: PropTypes.func,
  accountId: PropTypes.string.isRequired,
};
