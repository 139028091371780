import React, { useState } from "react";
import PropTypes from "prop-types";
import LoadingButton from "../../../util/buttons/loadingbutton";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import { deleteContacts, deleteContactsBulk } from "services/api.service";

export default function DeleteContactsDialog(props) {
  let [state, setState] = useState({ buttonState: "regular", errorMessage: null });

  const onDelete = async () => {
    try {
      setState({ buttonState: "loading", errorMessage: null });

      if (props.allSelected) {
        await deleteContactsBulk(
          props.selectedCount,
          props.foldersFilter,
          props.searchTerm
        );
        props.setSearchList([]);
        props.setAllSelected(false);
      } else {
        const contactsToDelete = Object.keys(props.selectedContacts);
        await deleteContacts(contactsToDelete);
        const updatedSearchList = props.searchList.filter(
          (contact) => !contactsToDelete.includes(contact.id)
        );
        props.setSearchList(updatedSearchList);
      }

      //reset if dialog gets re-opened, passing state will close dialog
      setState({ buttonState: "regular", errorMessage: null });

      props.setSelectedContacts({});
      props.setDeleteDialogOpen(false);
    } catch (err) {
      let errorMessage = err.response?.data?.message || err.data?.message;
      console.log(errorMessage);
      setState({ buttonState: "regular", errorMessage });
    }
  };

  let { errorMessage, buttonState } = state;

  return (
    <Dialog
      open={props.deleteDialogOpen}
      onClose={() => props.setDeleteDialogOpen(false)}
      aria-label="delete-contacts">
      <div className="row col-12 pagePadding" style={{ padding: "20px" }}>
        <div className="col-10 align-items-center">
          <h2>Confirm Delete</h2>
        </div>

        <div className="col-2 justify-content-end">
          <IconButton
            aria-label="Delete"
            onClick={() => props.setDeleteDialogOpen(false)}>
            <i className="material-icons">close</i>
          </IconButton>
        </div>

        <div className="col-12">
          <p style={{ paddingTop: "5px" }}>
            Are you sure you want to <b> delete </b> {props.selectedCount} contacts? You
            can not undo this action.
          </p>
        </div>

        {errorMessage && (
          <div className="errorMessageBox">
            <b>{errorMessage}</b>
          </div>
        )}

        <div className="col-12 justify-content-center" style={{ padding: "20px" }}>
          <LoadingButton
            variant="contained"
            color="primary"
            buttonState={buttonState}
            savedLabel={"Contacts Deleted"}
            label={"Delete Now"}
            clickedRegularButton={() => onDelete()}
            clickedSavedButton={() => props.setDeleteDialogOpen(false)}
          />
        </div>
      </div>
    </Dialog>
  );
}

DeleteContactsDialog.propTypes = {
  accountId: PropTypes.string.isRequired,
  selectedContacts: PropTypes.object.isRequired,
  searchList: PropTypes.array.isRequired,
  setSearchList: PropTypes.func.isRequired,
  setSelectedContacts: PropTypes.func.isRequired,
  setDeleteDialogOpen: PropTypes.func.isRequired,
  deleteDialogOpen: PropTypes.bool.isRequired,
  selectedCount: PropTypes.number.isRequired,
  allSelected: PropTypes.bool,
  setAllSelected: PropTypes.func,
  foldersFilter: PropTypes.array,
  searchTerm: PropTypes.string,
};
