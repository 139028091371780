import React from "react";
import PropTypes from "prop-types";
import NoteItem from "./noteItem";
import ResendNoteItem from "./resendNoteItem";
import ReplyItem from "./replyItem";
import VideoReplyItem from "./videoReplyItem";

export default function Messenger(props) {
  let { contactId, accountInfo, history, contactActivity } = props;

  let showItems = contactActivity.map((current) => {
    if (
      (current.type === "userReply" || current.type === "appReply") &&
      current.replyVideoSrc
    ) {
      current.replyId = current.id;
      return (
        <VideoReplyItem
          current={current}
          createdDate={current.createdDate}
          replyText={current.replyText || ""}
          replyingAs={current.replyingAs}
          contactId={contactId}
          key={current.id}
          contactData={props.contactData}
          accountInfo={accountInfo}
          history={history}
        />
      );
    } else if (current.type === "userReply" || current.type === "appReply") {
      return (
        <ReplyItem
          current={current}
          contactId={contactId}
          key={current.id}
          contactData={props.contactData}
          createdDate={current.createdDate}
          replyText={current.replyText || ""}
          createdByUserId={current.createdByUserId}
        />
      );
    } else if (current.resendActive && current.type === "noteHistory") {
      current.gvSendId = current.key;
      return (
        <ResendNoteItem
          current={current}
          key={current.id}
          contactData={props.contactData}
          contactId={contactId}
          accountCustomProperties={props.accountCustomProperties}
        />
      );
    } else if (current.type === "noteHistory") {
      return (
        <NoteItem
          current={current}
          key={current.id}
          accountId={accountInfo.id}
          contactData={props.contactData}
          contactId={contactId}
          videoId={current.noteHistoryVideos?.[0]?.videoId}
          accountInfo={accountInfo}
          accountCustomProperties={props.accountCustomProperties}
        />
      );
    } else {
      return null;
    }
  });

  if (props.contactActivity.length === 0)
    showItems = <div style={{ color: "grey" }}>No history</div>;

  return <div className="row col-12">{showItems}</div>;
}

Messenger.propTypes = {
  contactId: PropTypes.string.isRequired,
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  contactActivity: PropTypes.array.isRequired,
  contactData: PropTypes.object,
  accountCustomProperties: PropTypes.object,
  history: PropTypes.object,
};
