import React from "react";
import Navbar from "../../navbar/navbar";
import "../../../style/compliance.css";

export default function Terms() {
  return (
    <>
      <Navbar />
      <div className="compliancePage pagePadding row justify-content-center">
        <div className="col-12 col-md-11 col-lg-10">
          <div className="col-12 align-items-center justify-content-center">
            <h1 style={{ margin: "40px 0px", fontSize: "30px" }}>Terms Of Use</h1>
          </div>

          <div className="col-12">
            <p>
              Thank you for visiting Gratavid. This Online Agreement (the “Agreement”) is
              entered into by and between Gratavid, LLC (the “Company”) and the customer
              (the “Partner”) identified in the Partner Online Agreement Order Form (the
              “Order Form”). This Agreement governs the Partner&apos;s relationship with,
              and use of, the Company’s website, mobile application or content, and
              products and/or services made available by the Company (the “Gratavid
              Platform”).
            </p>

            <p>
              This Agreement is effective as of the date the Partner clicks the “I Accept”
              button on the Order Form, or signs a paper version of the Order Form
              (“Effective Date”) . If you are accepting on behalf of a Partner, you
              represent and warrant that: (i) you have full legal authority to bind your
              employer, or the applicable entity, to these terms and conditions; (ii) you
              have read and understand this Agreement; and (iii) you agree, on behalf of
              the party that you represent, to this Agreement. If you do not have the
              legal authority to bind a Partner, please do not sign or click the &quot;I
              Accept&quot; button on the Order Form.
            </p>

            <h2>1. Services and Support</h2>
            <ol type="a">
              <li>
                <b>Services. </b>
                Subject to the terms of this Agreement, Company will use commercially
                reasonable efforts to provide Partner with the Services listed in the
                Order Form and reasonable customer support services. Some features of the
                Site or Services may require registration for an account with Gratavid
                (“Account”). Partner warrants that any Account information provided by
                Partner shall be valid, accurate, complete, and current.
              </li>

              <li>
                <b>Technical Support. </b>
                Subject to the terms hereof, Company will provide Partner with reasonable
                technical support services during regular business hours. Regular business
                hours are Monday through Friday, 9 AM CT to 7 PM CT.
              </li>
              <li>
                <b>Site Maintenance. </b>
                Services for the Gratavid Platform may be temporarily unavailable for
                scheduled maintenance or for unscheduled emergency maintenance, either by
                the Company or by third-party providers, or because of other causes beyond
                our reasonable control. We will use reasonable efforts to provide advance
                notice in writing or by e-mail of any scheduled service disruption.
                Company guarantees that it will maintain an uptime of at least 99%.
              </li>
              <li>
                <b>Acceptable Use and Suspension. </b>
                Company retains the right to monitor large bulk messaging by Partner, as
                determined by the number of message recipients. Company retains the right
                to analyze Partner’s email database in Gratavid, apportion and stagger
                large bulk messaging, or suspend its Services if Partner’s: (i) bounce
                rate exceeds industry accepted percentage average of 10%, (ii) spam rate
                exceeds industry accepted percentage average of .1%; or (iii) if Partner’s
                use is not in compliance with Section 5(a)(i) or Section 5(a)(ii).
              </li>
              <li>
                <b>Gratavid is Continually Evolving. </b>
                The Services may change from time to time, at our discretion for the
                purpose of testing platform improvements. We may test various different
                versions of the platform Services both in terms of the User and Admin
                experience on the Site, the Gratavid email, and the Gratavid landing page.
                We may create and add new features for Gratavid and experiment with new
                features at any time. We may stop (permanently or temporarily) providing
                the Services or any features within the Services to you or to users
                generally. Gratavid will inform the partner organization administrators of
                any major change made to how their organization&apos;s Gratavid appears to
                their recipients. Partners will be given the ability to opt out of the
                testing before their Gratavids are sent.
              </li>
              <li>
                <b>Additional Purchases. </b>
                Over the course of this Agreement, Partner may purchase additional
                features or services for the Gratavid application. Purchases may be
                confirmed through signed order form, email confirmation, or purchase
                within the Gratavid application.
              </li>
            </ol>

            <h2>2. Fees and Payment</h2>
            <ol type="a">
              <li>
                <b>Fees; Payment. </b>
                The fees owed by Partner to Company in connection with this Agreement are
                set forth in the applicable Order Form. Unless otherwise set forth in the
                applicable Order Form, all fees and charges are due net thirty (30) days
                from the invoice date, and all payments made under this Agreement shall be
                in United States dollars. All amounts paid or payable hereunder are
                non-refundable.
              </li>
              <li>
                <b>Termination for Nonpayment. </b>
                If Partner’s account is overdue (except with respect to fees or charges
                then under reasonable and good faith dispute), in addition to any of its
                other rights or remedies available to Company hereunder or at law or
                equity, Company reserves the right to, in its sole discretion, terminate
                this Agreement, provided that Company has provided Partner fifteen (15)
                days prior written notice of its intention to terminate the Agreement and
                Partner has failed to bring its account current during such period.
                Company shall have no liability to Partner as a result of any such
                termination of this Agreement.
              </li>
              <li>
                <b>Taxes. </b>
                Company's fees do not include any local, state, federal or foreign taxes,
                levies or duties of any nature ("Taxes"). Partner is responsible for
                paying all Taxes, excluding only taxes based on Company's income. If
                Company has the legal obligation to pay or collect Taxes for which Partner
                is responsible, the appropriate amount shall be invoiced to and paid by
                Partner to Company unless Partner provides Company with a valid tax
                exemption certificate authorized by the appropriate taxing authority.
              </li>
              <li>
                <b>Expenses. </b>
                Except as otherwise set forth herein or mutually agreed by the parties in
                writing, each party will bear its own costs and expenses of its
                performance under this Agreement.
              </li>
            </ol>

            <h2>3. Intellectual Property/Licenses</h2>
            <ol type="a">
              <li>
                <b>Intellectual Property Ownership. </b>
                Except as specifically set forth herein, each party shall retain all
                intellectual property rights in its intellectual property and for any
                works or materials which it creates in connection with this Agreement.
                Except as licensed herein, this Agreement does not transfer any
                intellectual property rights between the Parties. For clarification
                purposes (i) Partner retains all right, title and interest in any video
                messages sent by Partner (“Messages”) and replies to video message sent by
                Partner (“Replies”) using the Gratavid Platform (collectively, Messages
                and Replies shall be referred to as “User Content”) and all derivative
                works thereof; and (ii) Company retains all right, title and interest in
                and to the Gratavid Platform and all improvements and modifications
                thereto and all derivative works thereof. Other than as expressly set
                forth in this Agreement, no license or other rights in or to a party’s
                products, services or intellectual property is granted, and all such
                licenses and rights are hereby expressly reserved.
              </li>
              <li>
                <b>License. </b>
                Subject to the terms of this Agreement, Partner grants Company a
                perpetual, worldwide, transferable, non-exclusive license, together with
                the right to sublicense, to use, distribute (directly and indirectly),
                modify, extend, and make derivative works from any User Content, solely in
                connection with providing recipients of Partner’s Messages with access to
                the Gratavid Platform and the ability to create Replies.
              </li>
              <li>
                <b>Usage Rights. </b>
                Each party grants the other party a limited, nonexclusive, personal and
                non-transferable license to use its name, logos, graphics, trade names,
                service names, trademarks, slogans and other marks (collectively, “Marks”)
                for the purpose of promoting either the Gratavid Platform or the Agreement
                contemplated herein. Company may not use Partner’s name and a sample video
                message for promotional in sale purposes to individual parties without
                notification or Partner’s prior approval. For all other purposes, before
                using Partner’s Marks in a promotional context, Company will notify
                Partner and request prior approval. Each party shall retain all right,
                title, goodwill and interest in and to its Marks and shall cease use of
                the other party’s Marks within five (5) days after written request from
                the other party.
              </li>
            </ol>

            <h2>4. Confidentiality; Proprietary Rights</h2>
            <ol type="a">
              <li>
                <b>Proprietary Information. </b>
                Each party (the “Receiving Party”) understands that the other party (the
                “Disclosing Party”) has disclosed or may disclose business, technical or
                financial information relating to the Disclosing Party’s business or
                organization (hereinafter referred to as “Proprietary Information” of the
                Disclosing Party). Proprietary Information of Company includes non-public
                information regarding features, functionality and performance of the
                Service. Proprietary Information of Partner includes non-public data
                provided by Partner to Company to enable the provision of the Gratavid
                Platform (“Partner Data”). The Receiving Party agrees: (i) to take
                reasonable precautions to protect such Proprietary Information, and (ii)
                not to use (except in performance of the Gratavid Platform or as otherwise
                permitted herein) or divulge to any third person any such Proprietary
                Information. The Disclosing Party agrees that the foregoing shall not
                apply with respect to any information after three (3) years following the
                disclosure thereof or any information that the Receiving Party can
                document (a) is or becomes generally available to the public, (b) was in
                its possession or known by it prior to receipt from the Disclosing Party,
                (c) was rightfully disclosed to it without restriction by a third party,
                (d) was independently developed without use of any Proprietary Information
                of the Disclosing Party, or (e) is required to be disclosed by law.
              </li>
              <li>
                <b>Partner Data. </b>
                Unless otherwise provided in this Agreement, Partner shall own all right,
                title and interest in and to the Partner Data. Company shall have the
                right to collect and analyze data and other information relating to the
                provision, use and performance of various aspects of the Gratavid Platform
                and related systems and technologies (including, without limitation,
                information concerning Partner Data and data derived therefrom), and
                Company will be free (during and after the term hereof) to (i) use such
                information and data to improve and enhance the Gratavid Platform and for
                other development, diagnostic and corrective purposes in connection with
                the Gratavid Platform and other Company offerings, and (ii) disclose such
                data solely in aggregate or other de-identified form in connection with
                its business.
              </li>
              <li>
                <b>Remedies. </b>
                If the Receiving Party discloses or uses (or threatens to disclose or use)
                any Confidential Information of the Disclosing Party in breach of this
                Agreement, the Disclosing Party shall have the right, in addition to any
                other remedies available to it, to seek injunctive relief to enjoin such
                acts, it being specifically acknowledged by the parties that any other
                available remedies are inadequate.
              </li>
            </ol>

            <h2>5. Representations and Warranties; Disclaimers</h2>
            <ol type="a">
              <li>
                <b>Partner Representations and Warranties. </b>
                Partner represents and warrants as of the Agreement Date and for as long
                as this Agreement is in effect that:
                <ol type="i">
                  <li>
                    Partner’s use of the Gratavid Platform shall comply with all
                    applicable laws and regulations, including without limitation the
                    unlawful gathering or collecting, or the assisting in the gathering or
                    collecting of information in violation of any privacy laws and
                    regulations in all appropriate jurisdictions, including but not
                    limited to the European Union General Data Protection Regulation
                    (“GDPR”) and applicable provisions of this Agreement and Company’s
                    Privacy Policy then in effect (the “Terms and Policies”).
                  </li>
                  <li>
                    Partner may not use the Gratavid Platform to send: (A) spam messages;
                    (B) messages offering to sell, purchase, or distribute illegal goods
                    or services; (C) pornographic or sexually explicit material; (D)
                    malware or other material that you know, or have reasonable grounds to
                    believe, is or may be tortious, libelous, offensive, infringing,
                    harassing, harmful, disruptive or abusive.
                  </li>
                  <li>
                    In accordance with this Agreement and Company’s Privacy Policy,
                    Partner may from time to time engage in non-commercial or commercial
                    messaging through Gratavid to users. For any such messages (including
                    any email or SMS text messages), Partner shall be responsible for (i)
                    ensuring that such messaging includes only users who either have
                    consented to receiving such messages or have not opted out to receive
                    such messages and (ii) ensuring the content of such messages,
                    including, if applicable, any email headers, comply with the CAN-SPAM
                    Act and any other applicable law related to anti-spamming, consumer
                    protection, data privacy, electronic commerce, false advertising, or
                    unlawful materials.
                  </li>
                  <li>
                    This provision, (a)(iv), is for educational institution use only: If
                    Partner is an educational institution, Partner certifies that it is
                    FERPA-compliant with regards to education, personnel, student, and
                    other records. Partner shall also define “school officials” and
                    “legitimate educational interest” broadly enough, as permitted by
                    FERPA, to permit Company to provide the Services to Partner. Company
                    will maintain the confidentiality of personnel, Student, and other
                    records in accordance with the requirements of applicable law.
                  </li>

                  <li>
                    None of Partner’s Messages will infringe or violate any third party’s
                    rights, including intellectual property rights, rights of publicity,
                    or misappropriation of trade secrets. None of Partner’s Messages will
                    contain any Protected Health Information (“PHI”) as defined by the
                    Health Insurance Portability and Accountability Act (“HIPAA”). The
                    unauthorized submission of PHI, copyrighted or other proprietary User
                    Content a violation of the law and could subject Partner to personal
                    liability for damages in a civil suit as well as criminal prosecution.
                    Partner assumes all liability for any damage resulting from any
                    infringement of copyrights or proprietary rights, or from any other
                    harm arising from an unauthorized submission or submission of User
                    Content. Company assumes no liability for any damage resulting from
                    any disclosure of PHI, any infringement of copyrights or proprietary
                    rights, or from any other harm arising from any User Content.
                  </li>
                  <li>
                    Partner will not, directly or indirectly: reverse engineer, decompile,
                    disassemble or otherwise attempt to discover the source code, object
                    code or underlying structure, ideas, know-how or algorithms relevant
                    to the Gratavid Platform or any software, documentation or data
                    related made; modify, translate, or create derivative works based on
                    the Gratavid Platform (except to the extent expressly permitted by
                    Company or authorized within the Gratavid Platform); use the Gratavid
                    Platform for timesharing or service bureau purposes or otherwise for
                    the benefit of a third party; or remove any proprietary notices or
                    labels.
                  </li>
                  <li>
                    Partner will obtain and maintain any equipment and ancillary services
                    needed to connect to, access, or otherwise use the Gratavid Platform,
                    including, without limitation, video cameras, hardware, servers,
                    software, operating systems, networking, web servers and the like
                    (collectively, “Equipment”).
                  </li>
                  <li>
                    Partner will use best efforts to secure its Equipment, Partner
                    account, passwords (including but not limited to administrative and
                    user passwords) and files, and for all uses of Partner account or the
                    Equipment with or without Partner’s knowledge or consent. Partner
                    agrees to immediately notify Company of any unauthorized use of
                    Partner’s username and password or any other security breach. Gratavid
                    will not be liable for damage or loss from Partner’s failure to comply
                    with the terms of this Agreement. Username and password combination
                    are not transferable to other users. Company reserves the right to
                    restrict, suspend, or terminate access to the Site for any username
                    and password combination (each, an "Account") because of inactivity or
                    fraudulent activity under that Account, or any other reason Company
                    deems appropriate.
                  </li>
                </ol>
              </li>
              <li>
                <b>DISCLAIMER. </b>
                EXCEPT AS EXPRESSLY SET FORTH IN THIS SECTION 5, EACH PARTY HEREBY
                SPECIFICALLY DISCLAIMS ANY REPRESENTATIONS OR WARRANTIES, EXPRESS OR
                IMPLIED, REGARDING ANY OF ITS PRODUCTS OR SERVICES, INCLUDING ANY IMPLIED
                WARRANTY OF MERCHANTABILITY, NONIFRINGEMENT, FITNESS FOR A PARTICULAR
                PURPOSE, NON-INTERFERENCE, SYSTEM INTEGRATION, ACCURACY OF DATA AND ANY
                IMPLIED WARRANTIES ARISING FROM COURSE OF DEALING OR COURSE OF
                PERFORMANCE. APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION OF CERTAIN IMPLIED
                WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO ALL PARTNERS. COMPANY
                AND COMPANY AFFILIATES DO NOT WARRANT THAT USE OF THE SITE OR SERVICE WILL
                BE UNINTERRUPTED, ERROR-FREE, OR VIRUS FREE. COMPANY IS NOT THE PROVIDER
                OF AND MAKE NO WARRANTIES WITH RESPECT TO ANY THIRD-PARTY OFFERINGS.
                COMPANY DOES NOT GUARANTEE THE SECURITY OF ANY INFORMATION TRANSMITTED TO
                OR FROM THE SITE OR SERVICES; AND PARTNERS AGREE TO ASSUME THE SECURITY
                RISK FOR ANY INFORMATION PARTNERS PROVIDE USING THE SITE OR SERVICES. NO
                REPRESENTATION OR WARRANTY IS MADE THAT THE SITE OR SERVICES PROVIDE
                COMPREHENSIVE OR ACCURATE INFORMATION. COMPANY RESERVES THE RIGHT TO
                FILTER, ALTER, OR REMOVE CONTENT, MEDIA, INFORMATION, OR ANY OTHER
                MATERIAL FROM THE SITE OR SERVICES AND FROM THE OUTPUT OF THE SITE OR
                SERVICES.
              </li>
            </ol>

            <h2>6. Indemnification; Insurance</h2>
            <ol type="a">
              <li>
                Each Party shall defend, indemnify, and hold harmless the other Party,
                including its officers, directors, employees, stockholders,
                representatives, agents, successors and assigns, from and against any and
                all losses, damages, expenses, costs, claims, suits, proceedings,
                investigations, or other liabilities (including reasonable attorneys’ fees
                and costs of litigation and amounts awarded by a court of competent
                jurisdiction or agreed upon in settlement) arising out of (i) the actions
                or inactions of the Indemnifying party, including without limitation the
                negligence and willful misconduct of the Indemnifying party or its
                employees or agents, (ii) an allegation that either party has infringed on
                a copyright, or misappropriates a trade secret, or otherwise infringes,
                violates or misappropriates a third party’s intellectual property rights
                or rights of publicity.
              </li>
              <li>
                Neither party shall take action to settle any such claim, suit, or
                proceeding that would in any manner impose obligations (monetary or
                otherwise) on the other party without that party’s prior written consent.
              </li>
            </ol>

            <h2>7. Limitation of Liability</h2>
            <ol type="a">
              <li>
                <b>Limitation of Liability. </b>
                Use of the Site or Services provided under this Agreement by Company is at
                Partner’s own risk. IN NO EVENT SHALL COMPANY’S AGGREGATE LIABILITY
                ARISING OUT OF OR RELATED TO THIS AGREEMENT, WHETHER IN CONTRACT, TORT OR
                UNDER ANY OTHER THEORY OF LIABILITY, EXCEED $500.
              </li>
              <li>
                <b>Exclusion of Consequential and Related Damages. </b>
                IN NO EVENT SHALL COMPANY HAVE ANY LIABILITY TO PARTNER FOR ANY LOST
                PROFITS, LOSS OF USE, COSTS OF PROCUREMENT OF SUBSTITUTE GOODS OR
                SERVICES, OR FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, OR
                CONSEQUENTIAL DAMAGES HOWEVER CAUSED, AND, WHETHER IN CONTRACT, TORT OR
                UNDER ANY OTHER THEORY OF LIABILITY, WHETHER OR NOT THE PARTY HAS BEEN
                ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. SOME STATES AND JURISDICTIONS
                DO NOT ALLOW LIMITATIONS ON DURATION OR THE EXCLUSION OF AN IMPLIED
                WARRANTY, SO THE ABOVE LIMITATION MAY NOT APPLY.
              </li>
              <li>
                <b>Limitation of Action. </b>
                Except for actions for breach of Sections 2 or 3 or failure to comply with
                indemnification obligations under Section 6, no action (regardless of
                form) arising out of this Agreement may be commenced by either party more
                than one (1) year after the cause of action has accrued.
              </li>
              <li>
                <b>Exclusions. </b>
                The limitations or exclusions of warranties and remedies contained in this
                Agreement shall apply to Partner only to the extent such limitations or
                exclusions and remedies are permitted under the laws of the jurisdiction
                where Partner is located. Certain states and/or jurisdictions do not allow
                the exclusion of implied warranties or limitation of liability for
                incidental or consequential damages, so the exclusions set forth above may
                not apply to every Partner.
              </li>
            </ol>

            <h2>8. Third Party Services</h2>
            <p>
              The Company may rely on third-party services (collectively, “Third Party
              Services” and individually a “Third Party Service”) to provide the Services,
              including but not limited to Stripe to handle payment processing, Google
              Cloud Platform and Vercel to host the Gratavid Platform, Twilio to send SMS
              messages, Sendgrid to send emails, Facebook, Twitter, and LinkedIn for
              social sharing, and AWS for video hosting. These third party services are
              not subject to any of the warranties, service commitments, or other
              obligations under this Agreement. The Company does not control any Third
              Party Services and will not be liable to the Partner for any damages arising
              out of or related to any Third Party Services. Company has no obligation to
              monitor or maintain Third Party Services, and may disable or restrict access
              to any Third Party Services at any time. By using or enabling any Third
              Party Service, Partner expressly permits the Company to disclose Proprietary
              Information to the extent necessary to utilize the Third Party Services.
              PARTNER’S USE OF THIRD PARTY SERVICE IS AT PARTNER’S OWN RISK AND IS SUBJECT
              TO ANY ADDITIONAL TERMS, CONDITIONS AND POLICIES APPLICABLE TO SUCH THIRD
              PARTY SERVICE (SUCH AS TERMS OF SERVICE OR PRIVACY POLICIES OF THE PROVIDERS
              OF SUCH THIRD PARTY SERVICE). More information on security, and Gratavid's
              third-party services can be found at{" "}
              <a href="https://gratavid.com/security">
                https://account.gratavid.com/security
              </a>
              .
            </p>

            <h2>9. Term And Termination</h2>
            <ol type="a">
              <li>
                Subject to earlier termination as provided below, this Agreement shall be
                effective as of the Effective Date and shall remain in full force and
                effect until the first anniversary of the Effective Date (the “Initial
                Service Term”). The Company shall contact Partner with a minimum of 90
                days prior to the expiration of the Agreement to begin the renewal of the
                Agreement.
              </li>
              <li>
                In addition to any other remedies it may have, either party may terminate
                this Agreement upon thirty (30) days notice or with fifteen (15) days
                notice in the case of nonpayment.
              </li>
              <li>
                All sections of this Agreement, which by their nature should survive
                termination, will survive termination, including, without limitation,
                confidentiality obligations, warranty disclaimers, and limitations of
                liability.
              </li>
              <li>
                Upon any termination or expiration of this Agreement, with or without
                cause: (i) each party shall immediately discontinue any use of the Marks
                of the other party and/or its products and services; (ii) the licenses set
                forth herein shall terminate; and (iii) both parties shall immediately
                discontinue all representations or statements from which it might be
                inferred that any relationship exists between the two parties under the
                terms of this Agreement.
              </li>
              <li>
                Upon termination of this Agreement, you acknowledge and agree that we may
                permanently delete your Account and all the data associated with it,
                including your User Contacts. If requested by Partner, Gratavid will
                permanently delete all your User Contact Personally identifiable
                information (PII) within 90 days of request. In the event of Account
                cancellation or termination, we have no obligation to migrate or provide
                assistance in migration of data from the Site.
              </li>
            </ol>

            <h2>10. COPYRIGHT POLICY</h2>
            <p>
              Copyright © 2020 Gratavid, LLC. All rights reserved. All Content and
              Trademarks displayed on the Site or Services are our property or the
              property of other third parties. Subject to any limited license provided in
              these Terms, you are not permitted to use these Trademarks or Content
              without our prior written consent or the consent of such third party that
              may own the Trademarks or Content.
            </p>

            <h2>11. Modifications To Services</h2>
            <p>
              Company reserves the right to modify the Site and/or Services at any time
              without notice. If Partner object to any changes to the Site or Services,
              Partner’s sole recourse will be to cease using the Site and Service,
              terminate the Agreement between Company and Partner and request termination
              of the Partner Account for a pro-rata refund of any pre-paid fees. Continued
              use of the Site or Services following posting of any such changes will
              indicate Partner’s acknowledgement of such changes and satisfaction with the
              Services as so modified. Company also reserves the right to discontinue the
              Site and/or Services at any time without notice. If Company suspends or
              terminates this Agreement, the Partner Account, the Site, or the Service
              other than for Partner’s breach of this Agreement, Company only liability to
              Partner shall be to refund Partner a pro-rata share of any amounts pre-paid
              for under this Agreement for an Account to the Service (if any). Partner
              understands that any termination of a Partner Account involves deletion of
              Partner associated User Content from Company’s live databases.{" "}
            </p>

            <h2>12. Privacy Policy</h2>
            <p>
              The Company Privacy Policy (“Privacy Policy”) as currently posted on the
              website may be amended by the Company and incorporated into this Agreement
              and provides additional terms and conditions related to the Company’s use,
              collection, and disclosure of personal information. Any other posted
              guidelines or rules applicable to any individual use of the website will
              also form part of these Terms. Use of the Company’s website acknowledges
              that Partner agrees to abide by all such other guidelines and rules.
            </p>

            <h2>13. General Terms</h2>
            <ol type="a">
              <li>
                Relationship of Parties. The parties are independent contractors and will
                have no power or authority to assume or create any obligation or
                responsibility on behalf of each other. This Agreement will not be
                construed to create or imply any partnership, agency or joint venture.
              </li>
              <li>
                Non-exclusive. Nothing contained in this Agreement shall be construed as
                creating an exclusive relationship between Partner and Company.
              </li>
              <li>
                Taxes. Each party shall be liable for all taxes, duties, levies or tariffs
                or charges of any kind imposed by any federal, state or local governmental
                entity with respect to the net income recognized by such party in
                connection with this Agreement.
              </li>
              <li>
                Governing Law. The Agreement shall be governed by and construed in
                accordance with the laws of the State of Arkansas, without regard to its
                conflict of law provisions. Each party consents to, and agrees that each
                party is subject to, the exclusive jurisdiction of the state and federal
                courts of the State of Arkansas with respect to any actions arising in
                connection with this Agreement including, without limitation, actions for
                interpretation, enforcement, or breach hereof.
              </li>
              <li>
                Assignment. Neither party may assign any of its rights or obligations
                hereunder, whether by operation of law or otherwise, without the prior
                express written consent of the other party. Notwithstanding the foregoing,
                either party may assign this Agreement together with all rights and
                obligations hereunder, without consent of the other party, in connection
                with a merger, acquisition, corporate reorganization, or sale of all or
                substantially all of its assets related to this Agreement. This Agreement
                shall bind and inure to the benefit of the parties, their respective
                successors, and assigns.
              </li>

              <li>
                Waiver; Cumulative Remedies. No failure or delay by either party in
                exercising any right under this Agreement shall constitute a waiver of
                that right. Other than as expressly stated herein, the remedies provided
                herein are in addition to, and not exclusive of, any other remedies of a
                party at law or in equity.
              </li>
              <li>
                Force Majeure. Neither Party hereto shall be responsible for any failure
                to perform its obligations under this Agreement if such failure is caused
                by acts of God, war, strikes, revolutions, pandemic, electrical, internet,
                or telecommunication outage that is not caused by such Party; government
                restrictions (including the denial or cancelation of any export, import or
                other license); or other event outside the reasonable control of such
                Party. Obligations hereunder, however, shall in no event be excused but
                shall be suspended only until the cessation of any cause of such failure.
              </li>
              <li>
                Entire Agreement. This Agreement, together with the Company’s Privacy
                Policy, constitutes the entire agreement between the Parties with respect
                to the subject matter hereof and supersedes any prior or contemporaneous
                oral or written agreements. Each party acknowledges and agrees that the
                other has not made any representations, warranties or agreements of any
                kind, except as expressly set forth herein. No modification, amendment, or
                waiver of any provision of this Agreement shall be effective unless in
                writing and signed by the party against whom the modification, amendment,
                or waiver is to be asserted. In the event of any conflict between the
                provisions of this Agreement and any separately-entered into Partner or
                Services Agreement, the terms of the Partner or Services Agreement shall
                prevail.
              </li>
              <li>
                Severability. If any provision of this Agreement shall be held illegal or
                unenforceable, the provision shall be modified by the court and
                interpreted so as best to accomplish the objectives of the original
                provision to the fullest extent permitted by law, and the remaining
                provisions of this Agreement shall remain in effect.
              </li>
              <li>
                Notices. All notices under this Agreement will be in writing and will be
                deemed to have been duly given when received, if personally delivered;
                when receipt is electronically confirmed, if transmitted by e-mail; the
                day after it is sent, if sent for next day delivery by recognized
                overnight delivery service; and upon receipt, if sent by certified or
                registered mail, return receipt requested.
              </li>
            </ol>

            <h2>End of terms</h2>
            <p>
              Terms last modified on August 23rd, 2020. Contact Information:
              support@gratavid.com
            </p>
            <div style={{ height: "40px" }} />
          </div>
        </div>
      </div>
    </>
  );
}
