import React, { useState, useEffect } from "react";
import styles from "./pending-account-signup.module.css";
import AccountNavbar from "./accountNavbar/navbar";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useParams } from "react-router-dom";
import AccountForm from "./admin/components/accountForm";
import { getPendingAccount } from "services/api.service";
import { Card, LinearProgress } from "@material-ui/core";
import MaintenanceSnackbar from "components/maintenanceSnackbar";

export default function PendingAccountSignup() {
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbar, setSnackbar] = useState({
    severity: "success",
    message: "",
    open: false,
  });
  const [isLoading, setIsLoading] = useState(true);

  const params = useParams();
  const pendingAccountId = params.pendingAccountId;

  useEffect(() => {
    const loadPendingAccount = async () => {
      if (!pendingAccountId) {
        window.location.href = "/notfound";
      } else {
        try {
          const pendingAccount = await getPendingAccount(pendingAccountId);

          if (pendingAccount?.accountId) {
            handleSnackbar("info", "Account already exists, redirecting in 5 seconds");
            setTimeout(() => {
              window.location.href = `/a/${pendingAccount.accountId}/`;
            }, 5000);
          } else {
            handleSnackbar(
              "success",
              "Welcome to Gratavid! Fill out your account information below to get started"
            );
          }
        } catch (error) {
          setErrorMessage(error.data?.message || "An error occurred");
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadPendingAccount();
  }, [pendingAccountId]);

  const handleSnackbar = (severity, message) => {
    setSnackbar({ severity, message, open: true });
  };

  const handleClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const onComplete = async () => {
    const pendingAccount = await getPendingAccount(pendingAccountId);

    if (pendingAccount?.accountId) {
      window.location.href = `/a/${pendingAccount.accountId}/`;
    } else {
      setErrorMessage("Cannot find account, please try again later");
    }
  };

  return (
    <div className={styles.root}>
      <CssBaseline />
      <MaintenanceSnackbar />
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity={snackbar.severity} onClose={handleClose}>
          {`${snackbar.message}`}
        </Alert>
      </Snackbar>
      <AccountNavbar
        hideRightDrawer={true}
        hideRightMenu={true}
        pendingAccountId={pendingAccountId}
      />

      <main className={styles.contentWrapper}>
        {isLoading && <LinearProgress />}
        {!isLoading && errorMessage && (
          <div className={styles.errorCardContainer}>
            <Card className={styles.errorCard}>{errorMessage}</Card>
          </div>
        )}
        {!isLoading && !errorMessage && (
          <div>
            <h1 className={styles.title}>Create New Account</h1>

            <AccountForm
              pendingAccountId={pendingAccountId}
              isEditMode={false}
              onComplete={onComplete}
              onSnackbar={handleSnackbar}
            />
          </div>
        )}
      </main>

      <footer className={styles.footer}>
        <div className={styles.footerTextContainer}>
          <p>Having issues? Contact us at&nbsp;</p>
          <p>
            <a href="mailto:support@gratavid.com">support@gratavid.com</a>
          </p>
        </div>
      </footer>
    </div>
  );
}

PendingAccountSignup.propTypes = {};
