import React, { useState } from "react";
import LoadingButton from "../../../../util/buttons/loadingbutton";
import {
  DialogActions,
  DialogContent,
  Dialog,
  IconButton,
  Button,
} from "@material-ui/core";
import SelectInputToApi from "../../../../util/hooks/input/selectInputToApi";
import { sendNote, updateSelf } from "services/api.service";

export default function ResendNowDialog(props) {
  let { fullScreen, dialogOpen, ssoProvider, userInfo, userEmailProvider } = props;

  let [state, setState] = useState({});
  const userEmail = userInfo?.email ?? "";
  const domain = props.accountInfo.domain || "gratavid.com";

  let resendNow = async (e) => {
    try {
      e.preventDefault();

      setState({ buttonState: "loading" });

      if (props.selectedItems.length === 0) {
        throw new Error("No items selected");
      }

      if (props.selectedItems.length > 50) {
        throw new Error("Can only send up to 50 items at once");
      }

      //create copy of tasks. and add latest resent to task analytics
      let twoMinuteMiliseconds = 60000 * 2;
      let scheduledDateTimestamp = new Date().getTime() + twoMinuteMiliseconds;
      props.selectedItems.forEach(async (selectedTask) => {
        scheduledDateTimestamp += 5000; //add 5 seconds

        const currentTask = props.tasks.find((task) => task.id === selectedTask.id);
        const sendObject = {
          noteId: currentTask.noteId,
          taskId: currentTask.id,
          gvSendId: "sid_" + crypto.randomUUID(),
          status: "processing",
          calledFrom: "editNote",
          contactIds: currentTask.taskContacts.map(
            (taskContact) => taskContact.contactId
          ),
          type: "sendNote",
          emailProvider: userEmailProvider,
          scheduledDateTimestamp: new Date(scheduledDateTimestamp),
          resending: true,
        };

        if (currentTask.shareViaText !== null) {
          sendObject.shareViaText = currentTask.shareViaText;
        }
        if (currentTask.shareViaEmail !== null) {
          sendObject.shareViaEmail = currentTask.shareViaEmail;
        }
        await sendNote(sendObject);
      });

      setState({ buttonState: "saved" });
      //props.setTasks(tempTasks)
      props.setSelectedItems([]);
    } catch (err) {
      console.error(err.message);
      setState({ buttonState: "regular", errorMessage: err.message });
    }
  };

  let { buttonState, errorMessage } = state;

  let closeDialog = () => {
    setState({});
    props.setDialogOpen(null);
  };

  async function onUpdateUser(field, value) {
    //change it at top level listTask
    props.setUserEmailProvider(value);
    updateSelf({ [field]: value });
  }

  let emailProviderOptions = [{ value: "default", label: "Default - @" + domain }];
  if (ssoProvider === "microsoft")
    emailProviderOptions.push({ value: "outlook", label: "Outlook - " + userEmail });
  if (ssoProvider === "google")
    emailProviderOptions.push({ value: "gmail", label: "Gmail - " + userEmail });

  return (
    <Dialog
      fullScreen={fullScreen}
      open={dialogOpen}
      onClose={closeDialog}
      aria-labelledby="edit task dialog">
      <DialogContent
        style={{
          height: props.fullScreen ? "auto" : "550px",
          width: props.fullScreen ? "auto" : "600px",
        }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: 22 }}>Resend Tasks</span>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton aria-label="close" onClick={closeDialog}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        <div className="notificationMessageBox">
          This will be resent to all selected contacts even if they previously viewed your
          Gratavid.
        </div>

        <div className="col-12" style={{ marginTop: "15px" }}>
          {buttonState === "saved" ? (
            <p>Task(s) resent! Please allow a few minutes to process.</p>
          ) : (
            <p>
              Resend all selected tasks ({props.selectedItems.length})? We recommend
              making sure you have not selected any tasks that have already been played.
            </p>
          )}
        </div>

        {errorMessage && (
          <div className="col-12 errorMessageBox">
            <b>{errorMessage}</b>
          </div>
        )}

        {ssoProvider && (
          <div className="col-12" style={{ marginTop: "25px" }}>
            <SelectInputToApi
              label="Send Via"
              field="emailProvider"
              initialValue={userEmailProvider}
              options={emailProviderOptions}
              apiCall={onUpdateUser}
              variant="outlined"
            />
          </div>
        )}
      </DialogContent>
      <DialogActions style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
        <Button variant="outlined" color="primary" onClick={closeDialog}>
          Close
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          buttonState={buttonState}
          savedLabel={"Sent!"}
          label={"Resend now"}
          clickedRegularButton={resendNow}
          clickedSavedButton={closeDialog}
        />
      </DialogActions>
    </Dialog>
  );
}
