import defaultContactProperties from "components/util/data/defaultContactProperties.json";

export function getContactProperties(accountInfo) {
  const customProperties = { ...defaultContactProperties };

  const { customContactProperties } = accountInfo;
  Object.entries(customContactProperties).forEach(([key, value]) => {
    const label = value?.label ?? key;
    customProperties[key] = { id: key, columnHeader: key, label, mapped: false };
  });

  return customProperties;
}

export function isCustomProperty(accountInfo, property) {
  const defaultProperties = Object.keys(defaultContactProperties).map(
    (key) => defaultContactProperties[key].id
  );
  const customProperties = Object.keys(accountInfo.customContactProperties);
  return customProperties.includes(property) && !defaultProperties.includes(property);
}

export function flattenCustomProperties(accountInfo, contact) {
  if (contact.customProperties) {
    const accountCustomProperties = Object.keys(accountInfo.customContactProperties);

    for (const [key, value] of Object.entries(contact.customProperties)) {
      if (accountCustomProperties.includes(key) && !contact[key]) {
        contact[key] = value;
      }
    }
  }

  return contact;
}

export function consolidateCustomProperties(accountInfo, contact) {
  const customProperties = { ...(contact.customProperties || {}) };
  const defaultProperties = Object.keys(defaultContactProperties).map(
    (key) => defaultContactProperties[key].id
  );
  const accountCustomProperties = Object.keys(accountInfo.customContactProperties);

  for (const [key, value] of Object.entries(contact)) {
    if (!defaultProperties.includes(key) && accountCustomProperties.includes(key)) {
      customProperties[key] = value;
      delete contact[key];
    }
  }

  contact.customProperties = customProperties;

  return contact;
}
