export const validURL = (str) => {
  try {
    const url = new URL(str);
    return (
      url.protocol === "http:" || url.protocol === "https:" || url.protocol === "mailto:"
    );
  } catch (error) {
    return false;
  }
};

export function getHashValue(key) {
  const matches = window.location.hash.match(new RegExp(key + "=([^&]*)"));
  return matches ? matches[1] : null;
}
