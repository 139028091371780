import React, { useState } from "react";
import SearchIndex from "../../util/search/searchIndex";
import AddContactDialog from "./addContact/addContactDialog";
import ListContacts from "./listContacts/listContacts";
import Button from "../../util/button/button";
import QuestionToLink from "../../util/explainers/questionToLink";

export default function Contacts(props) {
  let [searchList, setSearchList] = useState("loading");
  let [addNewDialogOpen, setAddNewDialogOpen] = useState(false);
  let [resetSearch, setResetSearch] = useState(null);
  let [selectedContacts, setSelectedContacts] = useState({});
  let [allSelected, setAllSelected] = useState(false);

  let selectContact = (contactId, currentContact) => {
    let tempSelectedContacts = { ...selectedContacts };
    if (tempSelectedContacts[contactId]) delete tempSelectedContacts[contactId];
    else tempSelectedContacts[contactId] = { ...currentContact };
    setSelectedContacts(tempSelectedContacts);
    setAllSelected(false);
  };

  let bulkContactsAdded = () => {
    //this will make searchIndex useEffect load again and re-search so users will see the new contacts they added
    setAddNewDialogOpen(false);
    setResetSearch(Date.now());
  };

  return (
    <div className="col-12 row">
      {addNewDialogOpen ? (
        <AddContactDialog
          {...props}
          searchList={searchList}
          setSearchList={setSearchList}
          addNewDialogOpen={addNewDialogOpen}
          setAddNewDialogOpen={setAddNewDialogOpen}
          bulkContactsAdded={bulkContactsAdded}
        />
      ) : (
        <>
          <div className="row col-12" style={{ marginBottom: "10px" }}>
            <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
              <h1>Contacts</h1>
              <QuestionToLink id="contacts" desc="contacts" />
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setAddNewDialogOpen(true)}
              startIcon={<i className="material-icons">add</i>}>
              New Contact
            </Button>
          </div>

          <div className="row col-12">
            <SearchIndex
              {...props}
              setSearchList={setSearchList}
              searchList={searchList}
              indexName="contacts"
              allSelected={allSelected}
              setAllSelected={setAllSelected}
              selectedContacts={selectedContacts}
              setSelectedContacts={setSelectedContacts}
              selectContact={selectContact}
              listComponent={ListContacts}
              resetSearch={resetSearch}
            />
          </div>
        </>
      )}
    </div>
  );
}
