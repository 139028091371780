import React from "react";
import { Button } from "@material-ui/core";
import CopyEmbedCode from "./copyEmbedCode";

export default function ContactItem(props) {
  let { noteId, videoData = {}, accountInfo, type, contentId, taskId } = props;
  let label = null;
  let dotColor = "#33AD52";
  if (!props.taskContact.contact) {
    label = "Anonymous Contact";
    dotColor = "#FEBB38";
  } else {
    const { email, firstName, lastName } = props.taskContact.contact;
    label = firstName || lastName ? (firstName || "") + " " + (lastName || "") : email;
  }

  if (type === "embed") {
    return (
      <div
        className="col-12"
        key={props.taskContact.contactId}
        style={{
          padding: "10px 0px",
          margin: "2px 0px",
          display: "flex",
          borderRadius: "3px",
        }}>
        <div
          style={{
            width: "36px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <div
            style={{
              backgroundColor: dotColor,
              height: 8,
              width: 8,
              borderRadius: "50%",
            }}
          />
        </div>
        {label === "Anonymous Contact" ? (
          <div style={{ flex: 1 }}>
            <span style={{ color: "black", fontWeight: 600 }}>{label}</span>
            <br />
            <p
              style={{
                color: "grey",
                fontSize: "12px",
                fontWeight: "normal",
                paddingRight: "10px",
              }}>
              Merge fields and reply notifications will not register for anonymous
              contacts
            </p>
          </div>
        ) : (
          <div style={{ flex: 1 }} className="align-items-center">
            <span style={{ color: "black", fontWeight: 600 }}>{label}</span>
            <br />
          </div>
        )}
        <div style={{ width: "135px" }}>
          <CopyEmbedCode
            videoData={videoData}
            noteId={noteId}
            contactId={props.taskContact.contactId}
            taskId={taskId}
            accountInfo={accountInfo}
          />
        </div>
      </div>
    );
  }

  const url = new URL(`${process.env.REACT_APP_FRONTEND}/note`);
  url.searchParams.set("noteId", noteId);
  url.searchParams.set("preview", true);
  url.searchParams.set("showPreviewNotification", 1);

  if (props.taskContact.contactId) {
    url.searchParams.set("cId", props.taskContact.contactId);
  }

  if (taskId) {
    url.searchParams.set("tId", taskId);
  }

  if (contentId) {
    url.searchParams.set("contentId", contentId);
  }

  const href = url.href;
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <div
        className="col-12 dialogSearchItem"
        key={props.taskContact.contactId}
        style={{
          padding: "10px 0px",
          margin: "2px 0px",
          display: "flex",
          borderRadius: "3px",
        }}>
        <div
          style={{
            width: "36px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <div
            style={{
              backgroundColor: dotColor,
              height: 8,
              width: 8,
              borderRadius: "50%",
            }}
          />
        </div>
        {label === "Anonymous Contact" ? (
          <div style={{ flex: 1 }}>
            <span style={{ color: "black" }}>{label}</span>
            <br />
            <p
              style={{
                color: "grey",
                fontSize: "12px",
                fontWeight: "normal",
                paddingRight: "10px",
              }}>
              Merge fields and reply notifications will not register for anonymous
              contacts
            </p>
          </div>
        ) : (
          <div style={{ flex: 1 }} className="align-items-center">
            <span style={{ color: "black" }}>{label}</span>
            <br />
          </div>
        )}
        <div style={{ width: "100px" }}>
          <Button color="primary">
            Preview{" "}
            <i className="material-icons" style={{ fontSize: "12px" }}>
              open_in_new
            </i>
          </Button>
        </div>
      </div>
    </a>
  );
}
