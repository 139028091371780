import React, { useState } from "react";
import General from "./general/general";
import Notifications from "./notifications/notifications";
import { Tabs, Tab } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { getSearchParams } from "util/helpers";

const AntTabs = withStyles({
  indicator: {
    backgroundColor: "#f76c6c",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    marginRight: "10px",
  },
  selected: {},
}))((props) => <Tab {...props} />);

export default function ManageSettings(props) {
  let tempTab = getSearchParams().get("tab") || "general";
  let [tabSelected, setTabSelected] = useState(tempTab);

  function a11yProps(index) {
    return {
      id: `task-tab-${index}`,
      "aria-controls": `task-tabpanel-${index}`,
    };
  }

  let tabTextStyle = {
    textTransform: "none",
    color: "rgb(71, 98, 130)",
    fontSize: "14px",
    fontWeight: 600,
  };

  return (
    <div className="row col-12 pagePadding">
      <div className="row col-12 " style={{ marginBottom: "10px" }}>
        <h1>My Settings</h1>
      </div>

      <div className="col-12" style={{ borderBottom: "1px #ddd solid" }}>
        <AntTabs
          value={tabSelected}
          onChange={(e, newValue) => setTabSelected(newValue)}
          aria-label="edit video tabs">
          <AntTab
            label="Profile"
            {...a11yProps(0)}
            value="general"
            style={tabTextStyle}
          />
          <AntTab
            label="Notifications"
            {...a11yProps(1)}
            value="notifications"
            style={tabTextStyle}
          />
        </AntTabs>
      </div>

      {tabSelected === "general" && <General {...props} />}
      {tabSelected === "notifications" && <Notifications {...props} />}
    </div>
  );
}
