import React from "react";
import Navbar from "../../navbar/navbar";

export default function Security() {
  return (
    <>
      <Navbar />
      <div className="pagePadding termsText">
        <h1>GDPR Compliance with Gratavid</h1>

        <h2>What is the GDPR</h2>
        <p>
          The GDPR, or General Data Protection Regulation, is a European privacy law that
          went into effect in May 2018. It regulates how personal data of individuals in
          the EU can be collected, used, and processed. The law impacts European companies
          and any business that targets European individuals or collects, uses, or
          processes the personal data of European individuals regardless of where the
          business is located. Essentially, this means the GDPR will apply to most
          organizations that process personal data of EU individuals—regardless of where
          they are established and regardless of where their processing activities take
          place.
        </p>

        <h2>What is personal data</h2>
        <p>
          ‘Personal data’ means any information relating to an identified or identifiable
          natural person (‘data subject’); an identifiable natural person is one who can
          be identified, directly or indirectly, in particular by reference to an
          identifier such as a name, an identification number, location data, an online
          identifier or to one or more factors specific to the physical, physiological,
          genetic, mental, economic, cultural or social identity of that natural personal.
        </p>
        <br />
        <p>
          Personal Data must be “Processed lawfully, fairly and in a transparent manner.”
        </p>

        <h2>Consent and Transparency</h2>
        <p>
          Please understand that both you and Gratavid have obligations and requirements
          for GDPR compliance. Our <a href="/terms">Terms of Service</a> require you to
          lawfully obtain and process all personal data appropriately such as opt-in
          consent. Consent should be clear and unique to a specific organization.
          Explicit, purpose-based collection, that is freely given is the highest standard
          for data collection and use policies. This means that there is no ambiguity as
          to the activities consented to or the organization carrying out those
          activities.
        </p>

        <h2>How Gratavid helps you comply with the rights of EU residents</h2>
        <p>
          The rights of an EU resident under the GDPR with respect to Gratavid include
          right of access, right to be forgotten, right to object, right to rectification,
          right to rectification, and the right of portability. Gratavid provides the
          necessary mechanism to comply with requests from you and will support you in
          fulfilling GDPR requests from your customers:
        </p>

        <ul>
          <li>
            <b>Right of access</b> - You can delete contacts from your Gratavid account at
            any time.
          </li>
          <li>
            <b>Right to be forgotten</b> - You can export data about individual contacts
            from your Gratavid account, which can help you fulfill access requests.
          </li>
          <li>
            <b>Right to object</b> - If a contact objects to you processing their personal
            data you can remove them from your Gratavid account at any time.
          </li>
          <li>
            <b>Right to rectification</b> - You can access and update your contacts’
            profiles within your Gratavid account to correct or complete their contact
            information at any time.
          </li>
          <li>
            <b>Right of portability</b> - You can export data about individual contacts
            from your Gratavid account at any time. The export folder will include CSV
            files that display all contact data stored in your account for the individual.
          </li>
        </ul>

        <h2>More information</h2>
        <div style={{ padding: "0px 20px" }}>
          <li>
            <a href="/privacy">Privacy Policy</a>
          </li>
          <li>
            <a href="https://gratavid.com/security">Security Page</a>
          </li>
          <li>
            <a href="mailto:support@gratavid.com?subject=Data%20Processing%20Addendum&body=Hello%2C%20will%20you%20please%20send%20me%20a%20copy%20of%20Gratavid's%20Data%20Processing%20Addendum%3F">
              Data Processing Addendum
            </a>
          </li>
          <li>
            <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32016R0679&from=EN">
              Official text of the GDPR
            </a>
          </li>
          <li>
            <a href="https://ec.europa.eu/info/law/law-topic/data-protection_en">
              The full text of the GDPR
            </a>
          </li>
        </div>

        <h2>Last Reviewed, Approved, And Edited</h2>
        <p>June 21st, 2021</p>

        <br />
        <br />
      </div>
    </>
  );
}
