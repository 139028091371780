import React from "react";
import { ButtonBase } from "@material-ui/core";

export default function ItemAnalytics(props) {
  const { sendItemData, data } = props;
  const { uniqueEmails = 0, uniqueTexts = 0 } = sendItemData;

  let handleContactAnalyticsClicked = (eventKey) => {
    props.setRecipientsEventSelected(eventKey);
    props.setTabSelected("recipients");
  };

  //loads so fast loading icon was flashy
  if (data === "loading") return null;

  let eventWrapperClass = "col-6 col-lg-3 row";
  let eventHeaderStyle = { fontSize: "14px", marginBottom: "4px", textAlign: "center" };
  let eventCountStyle = { fontSize: "28px", fontWeight: 600, color: "#24305e" };

  //regular send and resend both use same gvSendId
  //have to parse our the events. regular totals include resend amounts
  let {
    delivered: emailsDelivered = 0,
    click: emailsClicked = 0,
    bounce: emailsBounced = 0,
    blocked: emailsBlocked = 0, // TODO: no record in email_analytics
    dropped: emailsDropped = 0,
  } = data?.email || {};

  let emailsFinalized = emailsDelivered + emailsBounced + emailsDropped + emailsBlocked;
  let stillProcessing = uniqueEmails - emailsFinalized;
  stillProcessing = stillProcessing < 0 ? 0 : stillProcessing;
  let totalFails = emailsBounced + emailsDropped;

  //texts data
  //text with either be sent or failed
  let {
    sent: textsSent = 0,
    failed: textsFailed = 0,
    click: textsClicked = 0,
    undelivered: textUndelivered = 0,
  } = data?.text || {};

  let textsFinalized = textsSent + textsFailed;
  let stillProcessingTexts = uniqueTexts - textsFinalized;
  stillProcessingTexts = stillProcessingTexts < 0 ? 0 : stillProcessingTexts;

  //text can be sent but then show undelivered
  let textsDelivered = textsSent - textUndelivered;
  let allTextsFailed = textsFailed + textUndelivered;

  let showEmail = uniqueEmails && uniqueEmails > 0 ? true : false;
  let showText = uniqueTexts && uniqueTexts > 0 ? true : false;

  //when item is sending still show placeholder email data
  let showPlaceholder = showEmail === false && showText === false ? true : false;

  return (
    <div className="row col-12" style={{ padding: "0px 20px 10px 20px" }}>
      {(showEmail || showPlaceholder) && (
        <>
          <div className={eventWrapperClass} style={{ padding: "25px 5px" }}>
            <span
              className="col-12 align-items-center justify-content-center"
              style={eventHeaderStyle}>
              Emails delivered
            </span>

            <ButtonBase
              className="col-12 align-items-center justify-content-center"
              style={eventCountStyle}
              onClick={() => handleContactAnalyticsClicked("ed")}>
              {emailsDelivered}
            </ButtonBase>
          </div>

          <div className={eventWrapperClass} style={{ padding: "25px 5px" }}>
            <span
              className="col-12 align-items-center justify-content-center"
              style={eventHeaderStyle}>
              Bounces
            </span>

            <ButtonBase
              className="col-12 align-items-center justify-content-center"
              style={eventCountStyle}
              onClick={() => handleContactAnalyticsClicked("ebo")}>
              {totalFails}
            </ButtonBase>
          </div>

          <div className={eventWrapperClass} style={{ padding: "25px 5px" }}>
            <span
              className="col-12 align-items-center justify-content-center"
              style={eventHeaderStyle}>
              Processing
            </span>

            <ButtonBase
              className="col-12 align-items-center justify-content-center"
              style={eventCountStyle}
              onClick={() => handleContactAnalyticsClicked("ed")}>
              {stillProcessing}
            </ButtonBase>
          </div>

          <div className={eventWrapperClass} style={{ padding: "25px 5px" }}>
            <span
              className="col-12 align-items-center justify-content-center"
              style={eventHeaderStyle}>
              Clicks
            </span>

            <ButtonBase
              className="col-12 align-items-center justify-content-center"
              style={eventCountStyle}
              onClick={() => handleContactAnalyticsClicked("ec")}>
              {emailsClicked}
            </ButtonBase>
          </div>
        </>
      )}

      {showText && (
        <div className="row col-12">
          <div className={eventWrapperClass} style={{ padding: "25px 5px" }}>
            <span
              className="col-12 align-items-center justify-content-center"
              style={eventHeaderStyle}>
              Texts delivered
            </span>

            <ButtonBase
              className="col-12 align-items-center justify-content-center"
              style={eventCountStyle}
              onClick={() => handleContactAnalyticsClicked("tsnt")}>
              {textsDelivered}
            </ButtonBase>
          </div>

          <div className={eventWrapperClass} style={{ padding: "25px 5px" }}>
            <span
              className="col-12 align-items-center justify-content-center"
              style={eventHeaderStyle}>
              Texts failed
            </span>

            <ButtonBase
              className="col-12 align-items-center justify-content-center"
              style={eventCountStyle}
              onClick={() => handleContactAnalyticsClicked("tf")}>
              {allTextsFailed}
            </ButtonBase>
          </div>

          <div className={eventWrapperClass} style={{ padding: "25px 5px" }}>
            <span
              className="col-12 align-items-center justify-content-center"
              style={eventHeaderStyle}>
              Texts processing
            </span>

            <ButtonBase
              className="col-12 align-items-center justify-content-center"
              style={eventCountStyle}
              onClick={() => handleContactAnalyticsClicked("tsnt")}>
              {stillProcessingTexts}
            </ButtonBase>
          </div>

          <div className={eventWrapperClass} style={{ padding: "25px 5px" }}>
            <span
              className="col-12 align-items-center justify-content-center"
              style={eventHeaderStyle}>
              Texts clicked
            </span>

            <ButtonBase
              className="col-12 align-items-center justify-content-center"
              style={eventCountStyle}
              onClick={() => handleContactAnalyticsClicked("tc")}>
              {textsClicked}
            </ButtonBase>
          </div>
        </div>
      )}
    </div>
  );
}
