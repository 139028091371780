import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Typography,
  Tooltip,
  FormControlLabel,
  Switch,
} from "@material-ui/core";

const TrappTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "whitesmoke",
    maxWidth: 220,
    color: "black",
    fontSize: "12px",
    boxShadow:
      "0 1px 8px 0 rgba(0,0,0,.2), 0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12)",
    border: "2px solid darkgrey",
    padding: "10px",
  },
}))(Tooltip);

export default function QuestionToLink(props) {
  let { dataType, showUniqueData } = props;
  let desc,
    formula,
    formulaName = "";
  let reg = "",
    regForm = "",
    uniq = "",
    uniqForm = "";

  if (dataType === "video") {
    reg =
      "The sum of all videos played. If the note contains 3 videos and a contact watches all 3, that will count as 3 video plays.";
    uniq = "The number of contacts who played a video.";
    desc = showUniqueData ? uniq : reg;
  } else if (dataType === "video-rate") {
    reg = "The percentage of videos that were watched all the way through.";
    regForm = "full video plays / videos played";
    uniq = "The percentage of contacts who watched a video all the way through.";
    uniqForm =
      "the number of contacts who watched the full video / the number of contacts who started a video";
    desc = showUniqueData ? uniq : reg;
    formulaName = showUniqueData ? "Unique View-Through Rate = " : "View-Through Rate = ";
    formula = showUniqueData ? uniqForm : regForm;
  } else if (dataType === "note") {
    reg = "The sum of all note page views, replies, or shares.";
    uniq =
      "The number of contacts who viewed your note (page view), replied, or shared the Gratavid.";
    desc = showUniqueData ? uniq : reg;
  } else if (dataType === "email-open") {
    reg =
      "Email open-rate is the percentage of emails that were opened. Open rate gives you a sense of your overall engagement, including repeat email openers. If one contact opens an email 5 times, that will count as 5 opens.";
    regForm = "total email opens / total emails delivered";
    uniq =
      "Unique email open-rate is the percentage of contacts that have opened at least one email. If one contact opens an email 5 times, that will count as 1 unique open.";
    uniqForm =
      "the number of contacts who opened your email / the number of contacts who received your email";
    desc = showUniqueData ? uniq : reg;
    formulaName = showUniqueData ? "Unique Open Rate = " : "Open Rate = ";
    formula = showUniqueData ? uniqForm : regForm;
  } else if (dataType === "email-ctr") {
    reg =
      "Email CTR is the percentage of emails that were clicked. Click rate gives you a sense of your engagement, including repeat email clickers.";
    regForm = "total emails clicked / total emails delivered";
    uniq =
      "Unique email CTR is the percentage of contacts that have clicked at least one email. If one contact clicks an email 5 times, that will count as 1 unique click.";
    uniqForm =
      "the number of contacts who clicked your email / the number of contacts who received your email";
    desc = showUniqueData ? uniq : reg;
    formulaName = showUniqueData
      ? "Unique Click-Through Rate = "
      : "Click-Through Rate = ";
    formula = showUniqueData ? uniqForm : regForm;
  } else if (dataType === "text") {
    reg =
      "Text CTR is percentage of texts that were clicked. Click rate gives you a sense of your engagement, including repeat text clickers.";
    regForm = "total texts clicked / total texts delivered";
    uniq =
      "Unique text CTR is the percentage of contacts that have clicked at least one email. If one contact clicks a text 5 times, that will count as 1 unique click.";
    uniqForm =
      "the number of contacts who clicked your text / the number of contacts who received your text";
    desc = showUniqueData ? uniq : reg;
    formulaName = showUniqueData
      ? "Unique Click-Through Rate = "
      : "Click-Through Rate = ";
    formula = showUniqueData ? uniqForm : regForm;
  }

  return (
    <div style={{ marginLeft: "-2px" }}>
      <TrappTip
        interactive
        placement="bottom"
        title={
          <React.Fragment>
            <Typography color="inherit">{desc}</Typography>
            {formula && (
              <>
                <br />
                <Typography color="inherit">
                  <b>{formulaName}</b> {formula}
                </Typography>
                <br />
                <FormControlLabel
                  control={
                    <Switch
                      checked={showUniqueData}
                      onChange={props.handleUniqueToggle}
                      name="showUniqueData"
                    />
                  }
                  label="Show Unique Data"
                />
                <br />
              </>
            )}
            <br />

            <a
              href="https://graduwayhelp.zendesk.com/hc/en-us/articles/6653626865308-Understanding-Analytics"
              rel="noopener noreferrer"
              target="_blank"
              className="align-items-center">
              Learn more
            </a>
          </React.Fragment>
        }>
        <IconButton style={{ padding: "10px" }}>
          <i style={{ fontSize: "20px", color: "#8E949D" }} className="material-icons">
            info
          </i>
        </IconButton>
      </TrappTip>
    </div>
  );
}
