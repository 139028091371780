import React, { useState } from "react";
import LoadingButton from "../../util/buttons/loadingbutton";
import {
  Dialog,
  withMobileDialog,
  IconButton,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { emailNoteAnalytics } from "services/api.service";

function DownloadContactsDialog(props) {
  let { noteId } = props;
  let [state, setState] = useState({});
  let [lastXDays, setLastXDays] = useState(7);
  let [downloadDialogOpen, setDownloadDialogOpen] = useState(false);

  let downloadContacts = async () => {
    try {
      setState({ buttonState: "loading" });
      await emailNoteAnalytics(noteId, lastXDays);

      setState({ buttonState: "saved" });
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      setState({ errorMessage: errorMessage });
      console.error(errorMessage);
    }
  };

  let closeDialog = () => {
    setState({});
    setDownloadDialogOpen(false);
  };

  let fullScreen = props.fullScreen;
  let title = props.noteId
    ? "Download Data For Note Over Past " + lastXDays + " Days"
    : "Download Data For Account Over Past " + lastXDays + " Days";

  return (
    <>
      <IconButton
        aria-label="download contact metrics"
        onClick={() => setDownloadDialogOpen(true)}>
        <i className="material-icons" style={{ color: "black" }}>
          get_app
        </i>
      </IconButton>

      <Dialog
        fullScreen={fullScreen}
        open={downloadDialogOpen}
        onClose={() => setDownloadDialogOpen(false)}
        aria-labelledby="add note dialog">
        <div style={{ minWidth: "250px", padding: "20px" }}>
          <div
            className="row col-12 pagePadding"
            style={{ padding: "20px", minWidth: "250px" }}>
            <div className="col-10">
              <h2>{title}</h2>
            </div>

            <div className="col-2 justify-content-end">
              <IconButton aria-label="close" onClick={() => setDownloadDialogOpen(false)}>
                <i className="material-icons">close</i>
              </IconButton>
            </div>

            <FormControl variant="filled" fullWidth>
              <InputLabel id="lastXDays">Select date range</InputLabel>
              <Select
                labelId="lastXDays"
                id="lastXDays"
                value={lastXDays}
                onChange={(e) => setLastXDays(e.target.value)}>
                <MenuItem value={3}>Last 3 Days</MenuItem>
                <MenuItem value={7}>Last 7 Days</MenuItem>
                <MenuItem value={14}>Last 14 Days</MenuItem>
                <MenuItem value={28}>Last 28 Days</MenuItem>
                <MenuItem value={30}>Last 30 Days</MenuItem>
                <MenuItem value={90}>Last 90 Days</MenuItem>
                <MenuItem value={180}>Last 180 Days</MenuItem>
                <MenuItem value={360}>Last 360 Days</MenuItem>
              </Select>
            </FormControl>

            <div className="col-12" style={{ marginTop: "20px" }}>
              <p style={{ fontSize: "16px" }}>
                We will send an email to you with the data attached.
              </p>
            </div>

            {state.errorMessage && (
              <div className="errorMessageBox">
                <b>{state.errorMessage}</b>
              </div>
            )}

            <div
              className="col-12 justify-content-center"
              style={{ padding: "20px 0px" }}>
              <LoadingButton
                variant="contained"
                color="primary"
                buttonState={state.buttonState}
                savedLabel={"Sent. Check Inbox"}
                label={"Send Now"}
                clickedRegularButton={downloadContacts}
                clickedSavedButton={closeDialog}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default withMobileDialog()(DownloadContactsDialog);
