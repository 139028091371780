import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import LoadingButton from "../buttons/loadingbutton";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import NavBar from "../../navbar/navbar";
import { getUserByToken, createPassword, logout, login } from "services/api.service";
import { getSearchParams } from "util/helpers";

export default function ResetPassword(props) {
  let [email, setEmail] = useState("loading");
  let [newPassword, setNewPassword] = useState("");
  let [newPasswordTwo, setNewPasswordTwo] = useState("");
  let [state, setState] = useState({});

  useEffect(() => {
    let getData = async () => {
      try {
        let errorMessage =
          "Link expired or invalid link. Insert email and click resend password link";
        const token = getSearchParams().get("token");
        const user = await getUserByToken(token);
        if (!user?.email) throw new Error(errorMessage);
        setState({});
        setEmail(user.email);
      } catch (err) {
        console.error(err.message);
        setState({
          errorMessage: (
            <span>
              {err.message} Visit the <Link to={"/"}>login page</Link> to generate a new
              reset link
            </span>
          ),
        });
        setEmail("");
      }
    };

    getData();
  }, []);

  let resetPassword = async (e) => {
    try {
      e.preventDefault();
      setState({ buttonState: "loading", errorMessage: null });

      const token = getSearchParams().get("token");

      if (!token) {
        throw new Error(
          "Incorrect link. Try resetting password again from the login page"
        );
      } else if (newPassword === "") throw new Error("Please insert new password");
      else if (newPasswordTwo === "") throw new Error("Please re-insert new password");
      else if (newPassword !== newPasswordTwo) throw new Error("Passwords must match");
      else {
        await createPassword(newPassword, token);
        await logout();
        await login(email, newPassword);
        setState({ buttonState: "saved" });
      }
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  let { errorMessage, buttonState } = state;

  if (email === "loading") {
    return (
      <div style={{ padding: "20px" }}>
        <LinearProgress />
      </div>
    );
  } else if (buttonState === "saved") {
    return (
      <>
        <NavBar />
        <div
          className="d-flex justify-content-center row no-gutters"
          style={{ padding: "10px" }}>
          <div style={{ maxWidth: "348px", width: "100%" }}>
            <div className="boxOutline" style={{ padding: "20px", width: "100%" }}>
              <div className="col-12 d-flex justify-content-center">
                <h1 style={{ fontSize: "26px" }}>Password reset</h1>
              </div>

              <div className="col-12 d-flex justify-content-center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => (window.location = "/")}>
                  View Account
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <NavBar />
        <div className="d-flex justify-content-center row" style={{ padding: "10px" }}>
          <div style={{ maxWidth: "348px", width: "100%" }}>
            <form
              onSubmit={resetPassword}
              className="boxOutline"
              style={{ padding: "20px", width: "100%" }}>
              <div className="col-12">
                <h1 style={{ fontSize: "26px" }}>Reset password</h1>
              </div>

              <div className="col-12" style={{ marginTop: "20px" }}>
                <label htmlFor="email" className="textField-label">
                  Email Address
                </label>
                <TextField
                  fullWidth={true}
                  disabled
                  id="email"
                  variant="outlined"
                  value={email}
                />
              </div>

              <div className="col-12" style={{ marginTop: "20px" }}>
                <label htmlFor="newPassword" className="textField-label">
                  New Password
                </label>
                <TextField
                  fullWidth={true}
                  type="password"
                  id="newPassword"
                  placeholder="******"
                  variant="outlined"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>

              <div className="col-12" style={{ marginTop: "20px" }}>
                <label htmlFor="newPasswordTwo" className="textField-label">
                  Re-enter New Password
                </label>
                <TextField
                  fullWidth={true}
                  type="password"
                  id="newPasswordTwo"
                  placeholder="******"
                  variant="outlined"
                  value={newPasswordTwo}
                  onChange={(e) => setNewPasswordTwo(e.target.value)}
                />
              </div>

              {errorMessage && (
                <div className="errorMessageBox">
                  <b>{errorMessage}</b>
                </div>
              )}

              <div className="col-12" style={{ marginTop: "20px" }}>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  fullWidth={true}
                  type="submit"
                  buttonState={buttonState}
                  savedLabel={"Success! View Account"}
                  label={"Update Password"}
                  clickedRegularButton={resetPassword}
                  clickedSavedButton={() => (window.location = "/")}
                />
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}
