import papa from "papaparse";

export default function arrayToCsv(rowsArray, fileName) {
  try {
    fileName = fileName ? fileName : "gratavid-export.csv";

    const csvContent = papa.unparse(rowsArray);

    var blob = new Blob([csvContent]);
    if (window.navigator.msSaveOrOpenBlob) {
      // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      var a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(blob, { type: "text/plain" });
      a.download = fileName;
      document.body.appendChild(a);
      a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
      document.body.removeChild(a);
    }

    return { status: "SUCCESS", errorMessage: null };
  } catch (err) {
    return { status: "ERROR", errorMessage: err.message };
  }
}
