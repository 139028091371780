import React, { useState } from "react";
import {
  Button,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  LinearProgress,
} from "@material-ui/core";
import arrayToCsv from "../../../../../util/contacts/arrayToCsv";
import { getNoteAnalyticsEvent } from "services/api.service";

export default function ListActions(props) {
  let { eventSelected, eventTitle, eventNoun, eventPluralNoun, noteTitle = "" } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [state, setState] = useState({});

  let downloadList = async (listType) => {
    try {
      if (state.buttonState === "loading") return;
      setState({ buttonState: "loading" });

      const analytics = await getNoteAnalyticsEvent(props.noteId, eventSelected);

      const out = analytics.map((current) => {
        let contact = current.contact;
        if (!contact) {
          contact = { email: "No email", firstName: "Deleted", lastName: "Contact" };
        }
        const lastEvent = new Date(current.timestamp).toLocaleString();

        if (listType === "task") {
          return {
            email: contact.email,
            firstName: contact.firstName,
            lastName: contact.lastName,
            comments:
              "Contact was sent the Gratavid '" +
              noteTitle +
              "' and engaged with " +
              current.count +
              " " +
              eventPluralNoun,
            assigneeEmail: "",
            ["Total " + eventPluralNoun]: current.count,
            ["Last " + eventNoun + " (Your time zone)"]: lastEvent,
          };
        }
        return {
          email: contact.email,
          firstName: contact.firstName,
          lastName: contact.lastName,
          customUserId: contact.customUserId,
          ["Last " + eventNoun + " (Your time zone)"]: lastEvent,
          ["Total " + eventPluralNoun]: current.count,
        };
      });

      const fileName =
        listType === "task" ? "taskTemplate.csv" : noteTitle + "_" + eventTitle + ".csv";
      const { errorMessage } = arrayToCsv(out, fileName);

      if (errorMessage) setState({ errorMessage });
      else setState({});
    } catch (err) {
      console.error(err.message);
      if (err.status === 0) {
        setState({ error: "Unable to retrieve analytics data" });
      } else {
        setState({ error: "Something went wrong" });
      }
    }
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setState({});
  };

  return (
    <>
      <Button onClick={(e) => setAnchorEl(e.currentTarget)} size="small">
        <span style={{ fontSize: "14px", textTransform: "none", color: "#24305e" }}>
          List actions
        </span>
        <i style={{ color: "#24305e" }} className="material-icons">
          arrow_drop_down
        </i>
      </Button>

      <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-end">
        <Paper>
          <ClickAwayListener onClickAway={closeMenu}>
            {state.buttonState === "loading" ? (
              <div style={{ padding: "20px 5px", width: "80px" }}>
                <LinearProgress />
              </div>
            ) : state.error ? (
              <div className="errorMessageBox">{state.error}</div>
            ) : (
              <MenuList id="split-button-menu">
                <MenuItem onClick={() => downloadList("list")}>Download list</MenuItem>
                <MenuItem onClick={() => downloadList("task")}>
                  Download 'taskTemplate.csv'
                </MenuItem>
              </MenuList>
            )}
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
}
