import React, { useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import DeleteContactsDialog from "./deleteContactsDialog";
import EditItemFolders from "../../../util/folders/search/editItemFolders";
import { getAccountContacts, getAccountFolders } from "services/api.service";
import { objectToCsv } from "util/csv";
import { downloadFile } from "util/download";

export default function ContactsBulkAction(props) {
  let [anchorEl, setAnchorEl] = useState(null);
  let [editItemFoldersDialogOpen, setEditItemFoldersDialogOpen] = useState(false);
  let [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [buttonState, setButtonState] = useState("regular");

  // Pull a list of all account contacts from a filter and download them
  let downloadList = async () => {
    try {
      setButtonState("loading");
      setAnchorEl(null);

      const selectedContacts = Object.values(props.selectedContacts);

      const contacts = selectedContacts.length
        ? selectedContacts
        : (await getAccountContacts(0, null, "", props?.foldersFilter ?? [])).rows;

      const folders = await getAccountFolders("contacts");

      if (!contacts.length) {
        setButtonState("regular");
        setErrorMessage("No contacts for download.");
        return;
      }
      for (const contact of contacts) {
        contact.contactFolders = contact.contactFolders.map(
          (cf) => folders.find(({ id }) => id === cf.folderId)?.name ?? ""
        );
      }

      downloadFile(objectToCsv(contacts), "contacts.csv");
      setButtonState("saved");
    } catch (err) {
      let errorMessage = err?.data?.message ?? err.message;
      setErrorMessage(errorMessage);
      setButtonState("regular");
    }
  };

  let deleteMenuClicked = () => {
    setAnchorEl(null);
    setDeleteDialogOpen(true);
  };

  let editItemFolders = () => {
    setEditItemFoldersDialogOpen(true);
    setAnchorEl(null);
  };

  let selectedContactsKeys = Object.keys(props.selectedContacts)
    ? Object.keys(props.selectedContacts)
    : [];

  if (selectedContactsKeys.length === 0 && !props.allSelected) return null;

  let selectedCount = props.allSelected
    ? props.searchState.nbHits
    : selectedContactsKeys.length;

  return (
    <>
      <div className="col-12 align-items-center justify-content-end">
        {selectedCount} selected
        <div style={{ marginRight: "10px" }}>
          {buttonState === "loading" ? (
            <div
              className="align-items-center justify-content-center"
              style={{ width: "48px" }}>
              <CircularProgress style={{ height: "20px", width: "20px" }} />
            </div>
          ) : (
            <IconButton
              aria-label="More"
              variant="contained"
              onClick={(e) => setAnchorEl(e.currentTarget)}
              style={{ padding: "0px" }}>
              <i className="material-icons" style={{ color: "#000" }}>
                more_vert
              </i>
            </IconButton>
          )}
        </div>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={anchorEl ? true : false}
          onClose={() => setAnchorEl(null)}
          PaperProps={{ style: { maxHeight: 200, minWidth: 200 } }}>
          <MenuItem key={0} button onClick={downloadList}>
            <span>
              <span style={{ fontWeight: "bold" }}>Download</span> selected
            </span>
          </MenuItem>
          {!props.allSelected && (
            <MenuItem key={1} button onClick={editItemFolders}>
              <span>
                <span style={{ fontWeight: "bold" }}>Edit list for </span> selected
              </span>
            </MenuItem>
          )}
          <MenuItem key={3} button onClick={() => deleteMenuClicked()}>
            <span>
              <span style={{ fontWeight: "bold" }}>Delete</span> selected
            </span>
          </MenuItem>
        </Menu>
      </div>

      {errorMessage && (
        <div className="errorMessageBox">
          <b>{errorMessage}</b>
        </div>
      )}

      {deleteDialogOpen && (
        <DeleteContactsDialog
          accountId={props.accountInfo.id}
          selectedContacts={props.selectedContacts}
          searchList={props.searchList}
          setSearchList={props.setSearchList}
          setSelectedContacts={props.setSelectedContacts}
          selectedCount={selectedCount}
          deleteDialogOpen={deleteDialogOpen}
          setDeleteDialogOpen={setDeleteDialogOpen}
          allSelected={props.allSelected}
          setAllSelected={props.setAllSelected}
          foldersFilter={props.foldersFilter}
          searchTerm={props.searchTerm}
        />
      )}
      {editItemFoldersDialogOpen && (
        <EditItemFolders
          accountId={props.accountInfo.id}
          dialogTitle="Contacts"
          setDialogOpen={setEditItemFoldersDialogOpen}
          selectedItems={Object.values(props.selectedContacts)}
          setSelectedItems={props.setSelectedContacts}
          searchList={props.searchList}
          setSearchList={props.setSearchList}
          dialogOpen={editItemFoldersDialogOpen}
          indexName="contacts"
        />
      )}
    </>
  );
}
