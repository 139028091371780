import React, { useState } from "react";
import Authenticate from "./authenticate";
import DeAuthenticateDialog from "./deAuthenticateDialog";
import IntegrationHeader from "../integration-header";
import TaskSent from "./triggers/taskSent";
import NoteWatched from "./triggers/noteWatched";
import SaveReply from "./triggers/saveReply";
import TransactionCreated from "./actions/transactionCreated";

export default function Kindful(props) {
  let { integrationDetails, data = {} } = props;
  let { events = {} } = data; //events are giftAdded and contactAddedUpdated
  let [connectionInfo, setConnectionInfo] = useState(props.data?.connectionInfo || null);

  if (!connectionInfo || connectionInfo === "loading")
    return (
      <Authenticate
        connectionInfo={connectionInfo}
        setConnectionInfo={setConnectionInfo}
        {...props}
      />
    );

  return (
    <div className="col-12 row">
      <IntegrationHeader
        integrationDetails={integrationDetails}
        connectionInfo={connectionInfo}>
        <DeAuthenticateDialog {...props} />
      </IntegrationHeader>

      <TransactionCreated {...props} eventData={events?.transactionCreated} />
      <TaskSent {...props} eventData={events?.taskSent} />
      <SaveReply {...props} eventData={events?.reply} />
      <NoteWatched {...props} eventData={events?.noteWatched} />

      <div className="col-12" style={{ height: "100px" }} />
    </div>
  );
}
