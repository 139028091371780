import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Progress from "./progress";
import PlayButton from "./playButton";
import TapSections from "./tapSections";
import LoadingCircleSVG from "../../../../../util/svg/loadingCircleSVG";
import VideoDoneOverlay from "./videoDoneOverlay";

export default function Videos(props) {
  const { videos } = props;

  const videoRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentVideo, setCurrentVideo] = useState({});
  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [storyFinished, setStoryFinished] = useState(false);
  const [percentagePlayed, setPercentagePlayed] = useState(0);
  const [muted, setMuted] = useState(false);

  useEffect(() => {
    if (videos.length) {
      changeVideo(0);
    }
  }, [videos]);

  async function playVideo() {
    await videoRef.current.play();
  }

  async function pauseVideo() {
    await videoRef.current.pause();
  }

  async function playNextVideo(skip) {
    if (!isPlaying && !skip) {
      playVideo();
      return;
    }

    const nextIndex = currentIndex + 1;

    if (nextIndex >= videos.length) {
      await pauseVideo();
      setStoryFinished(true);
    } else {
      await changeVideo(nextIndex);
      await playVideo();
    }
  }

  async function playPreviousVideo() {
    if (!isPlaying) {
      playVideo();
      return;
    }

    const previousIndex = currentIndex - 1;

    if (previousIndex < 0) {
      videoRef.current.currentTime = 0;
    } else {
      await changeVideo(previousIndex);
      await playVideo();
    }
  }

  async function changeVideo(index) {
    const video = videoRef.current;
    const videoData = videos[index].video;

    setCurrentIndex(index);
    setCurrentVideo(videoData);
    setPercentagePlayed(0);

    await pauseVideo();
    video.currentTime = 0;
    video.src = videoData.videoUrl;
    setIsReady(false);
  }

  function videoEnded() {
    if (videos.length - 1 === currentIndex) {
      setStoryFinished(true);
    } else {
      playNextVideo(true);
    }
  }

  async function restartStory() {
    setStoryFinished(false);
    await changeVideo(0);
    playVideo();
  }

  function videoPlayTimeUpdated(e) {
    const time = e.target.currentTime || 0;
    const duration = e.target.duration || 0.1;
    const percentagePlayed = (time / duration) * 100;
    setPercentagePlayed(percentagePlayed);
  }

  if (videos.length === 0) {
    return (
      <div className="col-12">
        <div
          className="col-12 justify-content-center"
          style={{
            height: "100%",
            position: "relative",
            minHeight: "550px",
            backgroundColor: "grey",
          }}>
          <div
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 0,
              top: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "default",
              zIndex: 900,
            }}>
            <div className="story">
              <img src={props.logo} alt=" loading logo" />
              <LoadingCircleSVG />
            </div>
          </div>
        </div>
      </div>
    );
  }

  let { videoUrl, thumbnailUrl, speakerName } = currentVideo;

  return (
    <div
      className="col-12 justify-content-center"
      style={{
        height: "100%",
        position: "relative",
        minHeight: "550px",
        backgroundColor: "grey",
        backgroundImage:
          "linear-gradient(180deg,rgba(0,0,0,.4),transparent 15%,rgba(0,0,0,0))",
      }}>
      <div
        className="thumbnail-bg-image"
        style={{ backgroundImage: "url(" + thumbnailUrl + ")" }}
      />

      {storyFinished && <VideoDoneOverlay {...props} restartStory={restartStory} />}

      <TapSections onLeft={playPreviousVideo} onRight={playNextVideo} />

      <Progress items={videos} index={currentIndex} percentage={percentagePlayed} />

      <video
        ref={videoRef}
        id="vidplayer"
        muted={muted}
        playsInline
        preload="auto"
        style={{
          width: "100%",
          height: "100%",
          maxHeight: "700px",
          display: "block",
          position: "relative",
          visibility: "initial",
          objectFit: "contain",
          overflow: "hidden",
        }}
        onTimeUpdate={(e) => videoPlayTimeUpdated(e)}
        onCanPlayThrough={() => setIsReady(true)}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onEnded={videoEnded}>
        <source src={videoUrl} type="video/mp4" />
      </video>

      <PlayButton
        videoUrl={videoUrl}
        speakerName={speakerName}
        logo={props.logo}
        muted={muted}
        isReady={isReady}
        isPlaying={isPlaying}
        showDownload={props.showDownload}
        showShare={props.showShare}
        playVideo={playVideo}
        pauseVideo={pauseVideo}
        setMuted={setMuted}
      />
    </div>
  );
}

Videos.propTypes = {
  videos: PropTypes.array,
  logo: PropTypes.string,
  showDownload: PropTypes.bool,
  showShare: PropTypes.bool,
};
