import React from "react";
import CheckBoxToApi from "components/util/hooks/input/checkBoxToApi";
import TextInputToApi from "components/util/hooks/input/textInputToApi";

export default function MessageOptions(props) {
  let { customContactProperties } = props;
  let message = props.noteContent.messageHtml;

  return (
    <>
      <div className="col-12" style={{ marginTop: "15px" }}>
        <CheckBoxToApi
          initialValue={props.noteContent.appendNoteVids}
          field="appendNoteVids"
          apiCall={props.onUpdateTask}
          label="Include note videos after the task video"
          bold={true}
        />
      </div>

      <div className="col-12" style={{ marginTop: "15px" }}>
        <TextInputToApi
          initialValue={props.noteContent.senderName}
          field="senderName"
          apiCall={props.onUpdateTask}
          label="Sender Name"
          variant="outlined"
        />
      </div>

      <div className="col-12" style={{ marginTop: "20px" }}>
        <TextInputToApi
          initialValue={message}
          field="message"
          apiCall={props.onUpdateTask}
          multiline={true}
          customContactProperties={customContactProperties}
          label="Note Message"
          variant="outlined"
        />
      </div>
    </>
  );
}
