import React, { useState } from "react";
import LoadingButton from "../../../../../util/buttons/loadingbutton";
import SelectItem from "../../util/selectItem";
import { updateAccountIntegrations } from "services/api.service";

export default function SaveConfig(props) {
  let { eventData, event = "" } = props;
  let { noteType: initNoteType = null } = eventData;

  console.log(event);

  if (!initNoteType && event === "taskSent") initNoteType = "Received Email";
  else if (!initNoteType && event === "reply") initNoteType = "Sent Email";
  else if (!initNoteType) initNoteType = "General";

  let [state, setState] = useState({});
  let [noteType, setNoteType] = useState(initNoteType);

  let saveData = async () => {
    try {
      setState({ buttonState: "loading" });

      await updateAccountIntegrations([
        {
          path: `endpoint/kindful/events/${event}/noteType`,
          value: noteType,
        },
      ]);

      setState({ buttonState: "saved" });
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  let noteOptions = [
    { id: "General", value: "General", label: "General" },
    { id: "Received Email", value: "Received Email", label: "Contact Received Email" },
    { id: "Sent Email", value: "Sent Email", label: "Contact Sent Email" },
  ];

  return (
    <>
      <div style={{ marginRight: "15px" }}>
        <SelectItem
          options={noteOptions}
          selectedItem={noteType}
          handleChange={(value) => setNoteType(value)}
          label="Kindful Note Type"
          alt="Select note type"
          width="250px"
        />
      </div>

      <div className="col-12 justify-content-center" style={{ padding: "20px 0px" }}>
        <LoadingButton
          variant="contained"
          color="primary"
          buttonState={state.buttonState}
          savedLabel={"Saved"}
          label={"Save"}
          clickedRegularButton={saveData}
          clickedSavedButton={() => {
            setState({});
          }}
        />
      </div>
    </>
  );
}
