import React from "react";
import { FormControl, TextField } from "@material-ui/core";

export default function InputNumber(props) {
  let { label = "", value = "", id = "id", width = "50%" } = props; //selectedExcludes is all excludes for event ie - opportunityWon

  const handleChange = async (inputValue) => {
    props.handleChange(inputValue);
  };

  return (
    <FormControl style={{ margin: "5px 0px", width }} variant="filled">
      <TextField
        id={id}
        label={label}
        variant="filled"
        value={value}
        type="number"
        onChange={(e) => handleChange(e.target.value)}
      />
    </FormControl>
  );
}
