import React from "react";
import ItemAnalytics from "../../../../../util/analytics/itemAnalytics";
import { toFullName } from "util/helpers";

export default function Item({ analytics, taskContact, accountInfo }) {
  return (
    <div style={{ margin: "12px 0px" }}>
      <div style={{ fontSize: 14 }}>
        <a
          href={`/a/${accountInfo.id}/contacts/` + taskContact.contactId}
          target="_blank"
          rel="noopener noreferrer">
          {toFullName(
            taskContact.contact.firstName,
            taskContact.contact.lastName,
            taskContact.contact.email
          )}
        </a>
      </div>
      <div style={{ fontSize: 12, color: "grey", paddingTop: 2 }}>
        {taskContact.contact.email}
      </div>

      <div style={{ flexDirection: "row", height: 24 }}>
        <ItemAnalytics analytics={analytics} />
      </div>
    </div>
  );
}
