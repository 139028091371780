import React, { useState } from "react";
import PropTypes from "prop-types";
import LoadingButton from "../../../../../util/buttons/loadingbutton";
import GetListSelectItem from "../../util/getListSelectItem";
import { updateAccountIntegrations } from "services/api.service";

export default function SaveConfig(props) {
  let { accountInfo, eventData, event = "" } = props;
  let { type: initType = "General" } = eventData;

  let [state, setState] = useState({});
  let [type, setType] = useState(initType);

  let saveData = async () => {
    try {
      setState({ buttonState: "loading" });

      const update = [
        {
          path: "endpoint/virtuous/events/" + event + "/type",
          value: type,
        },
      ];

      await updateAccountIntegrations(update);

      setState({ buttonState: "saved" });
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  return (
    <>
      <div style={{ marginRight: "15px" }}>
        <GetListSelectItem
          initialOptions="loading"
          optionsFetchNeeded={true}
          requestData={{ source: "virtuous", event: "getContactNoteTypes" }}
          accountId={props.accountInfo.id}
          selectedItem={{ id: type, label: type }}
          handleChange={(value) => setType(value && value.id ? value.id : "")}
          label="Type"
          width="250px"
        />
      </div>

      <div className="col-12 justify-content-center" style={{ padding: "20px 0px" }}>
        <LoadingButton
          variant="contained"
          color="primary"
          buttonState={state.buttonState}
          savedLabel={"Saved"}
          label={"Save"}
          clickedRegularButton={saveData}
          clickedSavedButton={() => {
            setState({});
          }}
        />
      </div>
    </>
  );
}
SaveConfig.propTypes = {
  accountInfo: PropTypes.shape({
    accountUID: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  eventData: PropTypes.shape({
    active: PropTypes.bool,
    type: PropTypes.string,
  }),
  event: PropTypes.string,
};
