import React, { useState } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { isValidEmail } from "util/helpers";
const filter = createFilterOptions();

export default function FreeSoloCreateOption(props) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [replyToEmails, setReplyToEmails] = useState(props.replyToEmails || []);

  async function onChange(event, inputValue, reason) {
    setErrorMessage(null);
    console.log(reason, inputValue);

    if (reason === "create-option" || reason === "select-option") {
      const newItem = inputValue[inputValue.length - 1];
      const email = typeof newItem === "string" ? newItem : newItem.email;
      if (!isValidEmail(email)) {
        setErrorMessage("Insert valid email");
        return;
      }
      inputValue[inputValue.length - 1] = email;
    }
    setReplyToEmails(inputValue);
    props.saveNoteState("replyToEmails", inputValue);
  }

  return (
    <Autocomplete
      value={replyToEmails}
      multiple
      onChange={onChange}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== "") {
          filtered.push({
            email: params.inputValue,
            suggestion: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      id="reply-to-emails"
      options={[]}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.suggestion
      }
      renderOption={(option) => (option === "string" ? option : option.suggestion)}
      style={{ width: "100%" }}
      freeSolo
      renderInput={(params) => (
        <TextField
          fullWidth
          {...params}
          label="Also Send Replies To"
          variant="standard"
          error={Boolean(errorMessage)}
          autoComplete="off"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password",
          }}
          helperText={
            errorMessage ? (
              errorMessage
            ) : (
              <span style={{ fontSize: "11.25px" }}>
                These emails will be included in the reply notifications for this note.{" "}
                <a
                  style={{ fontSize: "11.25px" }}
                  href={`/a/${props.accountInfo.id}/settings`}
                  target="_blank">
                  Manage your notifications
                </a>
              </span>
            )
          }
        />
      )}
    />
  );
}

FreeSoloCreateOption.propTypes = {
  replyToEmails: PropTypes.array,
  saveNoteState: PropTypes.func,
};
