import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { ChromePicker } from "react-color";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from "prop-types";

function SelectColor(props) {
  let [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="row col-12">
      <div
        className="selectColorButton boxShadow"
        onClick={() => setModalOpen(true)}
        style={{ backgroundColor: props.color, border: "1px #ddd solid" }}
      />

      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        PaperProps={{ style: { minWidth: "0px" } }}>
        <div className="row" style={{ padding: "10px 15px 0px 15px" }}>
          <div className="col-10 d-flex align-items-center">
            <h5 style={{ margin: "0px" }}>Select Color</h5>
          </div>
          <div className="col-2 justify-content-end">
            <IconButton className="" onClick={() => setModalOpen(false)}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        <ChromePicker
          color={props.color}
          id={props.colorKey}
          onChangeComplete={(newColor) =>
            props.passedSetState(props.colorKey, newColor.hex)
          }
        />
      </Dialog>
    </div>
  );
}

SelectColor.propTypes = {
  color: PropTypes.string.isRequired,
  colorKey: PropTypes.string.isRequired,
  passedSetState: PropTypes.func.isRequired,
};

export default SelectColor;
