import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Menu, MenuItem, IconButton } from "@material-ui/core";
import { logout } from "services/api.service";

export default function MenuHover(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  function signOut() {
    logout().then(() => {
      setAnchorEl(null);
    });
  }

  let rows = [
    { href: `/a/${props.accountInfo.id}/`, label: props.email, icon: "home" },
    {
      href: `/a/${props.accountInfo.id}/settings/`,
      label: "My Settings",
      icon: "settings",
    },
    { seperator: true },
    {
      href: `/a/${props.accountInfo.id}/accountsettings/`,
      label: "Account Settings",
      icon: "admin_panel_settings",
    },
    { href: `/a/${props.accountInfo.id}/users/`, label: "Manage Users", icon: "group" },
    {
      href: `/a/${props.accountInfo.id}/subscription/`,
      label: "Subscription",
      icon: "credit_card",
    },
    {
      href: `/a/${props.accountInfo.id}/analytics/`,
      label: "Analytics",
      icon: "insert_chart",
    },
    {
      href: `/a/${props.accountInfo.id}/integrations/`,
      label: "Integrations",
      icon: "sync",
    },
    {
      href: `/a/${props.accountInfo.id}/unsubscribes/`,
      label: "Unsubscribes",
      icon: "unsubscribe",
    },
    { seperator: true },
    { aLink: true, label: "Help Center" },
    { signOut: true },
  ];

  let showRows = rows.map((current, index) => {
    if (current.seperator) {
      return (
        <div
          key={index}
          style={{ height: "1px", width: "100%", backgroundColor: "#ddd" }}
        />
      );
    } else if (current.aLink) {
      return (
        <MenuItem key={index} style={{ width: "100%" }}>
          <a
            className="align-items-center"
            style={{
              color: "#000",
              width: "100%",
              fontSize: "15px",
              fontWeight: "normal",
            }}
            href="https://graduwayhelp.zendesk.com/hc/en-us/sections/6614353265948-How-To-Guides-"
            target="_blank"
            rel="noreferrer noopener">
            <i
              className="material-icons"
              style={{ marginRight: "10px", color: "grey", fontSize: "20px" }}>
              help
            </i>
            Help Center
          </a>
        </MenuItem>
      );
    } else if (current.signOut) {
      return (
        <MenuItem key={index} onClick={signOut}>
          <i
            className="material-icons"
            style={{ marginRight: "10px", color: "grey", fontSize: "20px" }}>
            exit_to_app
          </i>
          Sign out
        </MenuItem>
      );
    } else {
      return (
        <MenuItem
          key={index}
          component={Link}
          to={current.href}
          onClick={() => setAnchorEl(null)}>
          <i
            className="material-icons"
            style={{ marginRight: "10px", color: "grey", fontSize: "20px" }}>
            {current.icon}
          </i>
          {current.label}
        </MenuItem>
      );
    }
  });

  return (
    <>
      <IconButton
        style={{ padding: "8px" }}
        onClick={(e) => setAnchorEl(e.currentTarget)}>
        <i className="material-icons-outlined" style={{ color: "#4d4d4d" }}>
          account_circle
        </i>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}>
        {showRows}
      </Menu>
    </>
  );
}
