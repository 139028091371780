import React from "react";
import PropTypes from "prop-types";

export default function TapSections(props) {
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        display: "flex",
        zIndex: 899,
      }}>
      <div
        style={{ flex: 4, height: "100%", cursor: "pointer" }}
        onClick={() => props.onLeft()}
      />

      <div
        style={{ flex: 6, height: "100%", cursor: "pointer" }}
        onClick={() => props.onRight()}
      />
    </div>
  );
}

TapSections.propTypes = {
  onLeft: PropTypes.func.isRequired,
  onRight: PropTypes.func.isRequired,
};
