import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import QuestionToLink from "../../util/explainers/questionToLink";
import style from "./listIntegrations.module.css";

export default function ListIntegrations({ accountInfo }) {
  let list = [
    {
      integrationId: "blackbaud",
      header: "Blackbaud Raiser's Edge NXT",
      desc: "Connect Blackbaud and Gratavid",
      logoSrc: "/static/img/logos/blackbaudLogo.png",
    },
    {
      integrationId: "bloomerang",
      header: "Bloomerang",
      desc: "Connect Bloomerang and Gratavid",
      logoSrc: "/static/img/logos/bloomerangIcon.png",
    },
    {
      integrationId: "donorPerfect",
      header: "DonorPerfect",
      desc: "Connect DonorPerfect and Gratavid",
      logoSrc: "/static/img/logos/donorPerfectIcon.png",
    },
    {
      integrationId: "neon",
      header: "Neon One",
      desc: "Connect Neon One and Gratavid",
      logoSrc: "/static/img/logos/neonOneIcon.png",
    },
    {
      integrationId: "planningCenter",
      header: "Planning Center",
      desc: "Connect Planning Center and Gratavid",
      logoSrc: "/static/img/logos/planningCenter.png",
    },
    {
      integrationId: "salesforce",
      header: "Salesforce",
      desc: "Connect Salesforce and Gratavid",
      logoSrc: "/static/img/logos/salesforce.svg",
    },
    {
      integrationId: "slate",
      header: "Slate",
      desc: "Connect Slate and Gratavid",
      logoSrc: "/static/img/logos/slateIcon.png",
    },
    {
      integrationId: "kindful",
      header: "Kindful",
      desc: "Connect Kindful and Gratavid",
      logoSrc: "/static/img/logos/kindfulIcon.png",
    },
    {
      integrationId: "advance",
      header: "Advance",
      desc: "Connect Advance and Gratavid",
      logoSrc: "/static/img/logos/advanceIcon.png",
    },
    {
      integrationId: "graduwayEngage",
      header: "Graduway",
      desc: "Connect Graduway and Gratavid",
      logoSrc: "/static/img/logos/graduwayIcon.png",
    },
    {
      integrationId: "virtuous",
      header: "Virtuous",
      desc: "Connect Virtuous and Gratavid",
      logoSrc: "/static/img/logos/virtuousIcon.png",
    },
    {
      integrationId: "zapier",
      header: "Zapier",
      desc: "Integrate with over 2,000+ apps powered by Zapier",
      logoSrc: "/static/img/logos/zapierLogo.png",
      brandHex: "#F1F1F1",
    },
    {
      integrationId: "donorConnect",
      header: "Donor Connect by Wilson-Bennett",
      desc: "Connect Donor Connect and Gratavid",
      logoSrc: "/static/img/logos/donorConnectIcon.png",
    },
  ];

  let showList = list.map((current) => {
    let { integrationId, header, desc, logoSrc, brandHex } = current;
    return (
      <Button
        key={integrationId}
        className={style.integrationButton}
        variant="outlined"
        component={Link}
        to={`/a/${accountInfo.id}/integrations/${integrationId}`}>
        <div style={{ width: "70px", display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "60px",
              height: "60px",
              backgroundColor: brandHex,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img
              src={logoSrc}
              width={brandHex ? "45px" : "60px"}
              alt={`${header} logo`}
            />
          </div>
        </div>
        <div style={{ flex: 1, textAlign: "start" }}>
          <h2>{header}</h2>
          <p className="greyDesc">{desc}</p>
        </div>
      </Button>
    );
  });

  return (
    <div className="col-12 row">
      <div className="row col-12 align-items-center">
        <h1>Integrations</h1>
        <QuestionToLink id="integrations" desc="integrations" />
      </div>

      {showList}
    </div>
  );
}
