const times = [
  ["second", 1],
  ["minute", 60],
  ["hour", 3600],
  ["day", 86400],
  ["week", 604800],
  ["month", 2592000],
  ["year", 31536000],
  ["", Number.MAX_SAFE_INTEGER],
];

export default function timeSinceDate(timestamp, startText = "", fallbackMessage = "") {
  if (!timestamp) {
    return fallbackMessage;
  }

  if (typeof timestamp === "string") {
    timestamp = new Date(timestamp).getTime();
    if (isNaN(timestamp)) {
      return fallbackMessage;
    }
  }

  let diff = Math.round((Date.now() - timestamp) / 1000);
  for (var t = 0; t < times.length; t++) {
    if (diff < times[t][1]) {
      if (t === 0) {
        return startText + "just now";
      } else {
        diff = Math.round(diff / times[t - 1][1]);
        let formattedTime =
          diff + " " + times[t - 1][0] + (diff === 1 ? " ago" : "s ago");
        return startText + " " + formattedTime;
      }
    }
  }
}
