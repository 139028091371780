import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";
import DeleteVideoDialog from "../../account/videos/deleteVideo/deleteVideoDialog";
import DeleteNoteDialog from "../../account/notes/deleteNote/deleteNoteDialog";
import { patchNote, deleteNote, deleteVideos, editVideo } from "services/api.service";

export default function BigBreadcrumb(props) {
  let { editDisabled, indexName } = props;
  let [state, setState] = useState({ viewState: "regular" });
  let [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  let [editTitle, setEditTitle] = useState(props.title || "");

  useEffect(() => {
    setEditTitle(props.title);
  }, [props.title]);

  let deleteAction = async () => {
    try {
      setState({ viewState: "loading" });

      if (indexName === "notes") {
        await deleteNote(props.id);
      }
      if (indexName === "videos") {
        await deleteVideos([props.id]);
      }
      props.history.push((window.location = `/a/${props.accountId}/` + indexName));
    } catch (err) {
      console.log(err.message);
    }
  };

  let saveTitle = async () => {
    try {
      setState({ viewState: "loading" });

      if (indexName === "notes") {
        await patchNote(props.id, { title: editTitle });
      }
      if (indexName === "videos") {
        await editVideo(props.id, { title: editTitle });
      }
      props.setTitle(editTitle);
      setState({ viewState: "regular" });
    } catch (err) {
      console.log(err.message);
    }
  };

  if (state.viewState === "loading" || props.title === "loading") {
    return (
      <div style={{ padding: "20px", width: "80px" }}>
        <LinearProgress />
      </div>
    );
  } else if (editDisabled) {
    return (
      <div className="col-12" style={{ padding: "15px 0px 0px 20px", display: "flex" }}>
        <div className="align-items-center" style={{ flex: 1 }}>
          <h1 style={{ fontSize: "22px" }}>{props.title}</h1>
        </div>
      </div>
    );
  } else if (state.viewState === "edit") {
    return (
      <div
        style={{
          display: "flex",
          padding: "15px 0px 0px 20px",
          alignItems: "center",
          height: "40px",
        }}>
        <div style={{ flex: 1, cursor: "pointer" }}>
          <input value={editTitle} onChange={(e) => setEditTitle(e.target.value)} />
        </div>
        <div className="" style={{ width: "36px" }}>
          <i
            className="material-icons folderButtons"
            style={{ fontSize: "17px", color: "red", padding: "0px" }}
            onClick={() => setState({ viewState: "regular" })}>
            cancel
          </i>
          <i
            className="material-icons folderButtons"
            style={{ fontSize: "17px", color: "green", padding: "0px" }}
            onClick={() => saveTitle()}>
            check_circle
          </i>
        </div>
      </div>
    );
  } else if (state.viewState === "delete") {
    return (
      <div
        style={{
          display: "flex",
          padding: "15px 0px 0px 20px",
          alignItems: "center",
          height: "40px",
        }}>
        <div style={{ flex: 1, cursor: "pointer" }} onClick={() => deleteAction()}>
          <span>Delete this note. You can&apos;t undo this action.</span>
        </div>
        <div className="" style={{ width: "36px" }}>
          <i
            className="material-icons folderButtons"
            style={{ fontSize: "17px", color: "red", padding: "0px" }}
            onClick={() => setState({ viewState: "regular" })}>
            cancel
          </i>
          <i
            className="material-icons folderButtons"
            style={{ fontSize: "17px", color: "green", padding: "0px" }}
            onClick={() => deleteAction()}>
            check_circle
          </i>
        </div>
      </div>
    );
  }

  return (
    <div className="col-12" style={{ padding: "15px 0px 0px 20px", display: "flex" }}>
      <div className="align-items-center" style={{ flex: 1 }}>
        <h1 style={{ fontSize: "22px" }}>{props.title}</h1>
      </div>
      <div style={{ width: "60px" }}>
        <i
          className="material-icons folderButtons"
          style={{ marginLeft: "5px", fontSize: "17px", color: "grey", padding: "0px" }}
          onClick={() => setState({ viewState: "edit" })}>
          edit
        </i>
        <i
          className="material-icons folderButtons"
          style={{ fontSize: "17px", color: "grey", padding: "0px" }}
          onClick={() => setDeleteDialogOpen(true)}>
          delete
        </i>
      </div>

      {props.indexName === "videos" && (
        <DeleteVideoDialog
          id={props.id}
          title={props.title}
          accountId={props.accountId}
          {...props}
          deleteDialogOpen={deleteDialogOpen}
          setDeleteDialogOpen={setDeleteDialogOpen}
        />
      )}

      {props.indexName === "notes" && (
        <DeleteNoteDialog
          currentNote={{ id: props.id, title: props.title }}
          {...props}
          dialogOpen={deleteDialogOpen}
          setDialogOpen={setDeleteDialogOpen}
        />
      )}
    </div>
  );
}

BigBreadcrumb.propTypes = {
  accountId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
  setTitle: PropTypes.func.isRequired,
  title: PropTypes.string,
  editDisabled: PropTypes.bool,
};
