import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import LoadingButtonCheckProductLimits from "../../../util/buttons/productLimits/loadingbutton";
import ListContactInputs from "../../../util/contacts/listContactInputs";
import { insertTasks } from "services/api.service";
import { consolidateCustomProperties } from "util/contacts";

export default function AddSingleTask(props) {
  let [contactData, setContactData] = useState({});
  let [comments, setComments] = useState("");
  let [state, setState] = useState({});
  let [emailAutoSuggestInputKey, setEmailAutoSuggestInputKey] = useState(1);

  let validateEmail = (email) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  let addTask = async (e) => {
    if (e) e.preventDefault();

    try {
      //remove spaces from email
      const email = contactData.contact.email.replace(/ /g, "");

      if (email === "" || !validateEmail(email)) {
        throw new Error("Provide valid email address");
      }

      if (!contactData.contact.firstName || !contactData.contact.lastName) {
        throw new Error("First name and last name are required");
      }

      setState({ buttonState: "loading" });

      const consolidatedContactData = consolidateCustomProperties(
        props.accountInfo,
        contactData.contact
      );

      await insertTasks([
        {
          contactData: [
            {
              email: consolidatedContactData.email,
              firstName: consolidatedContactData.firstName,
              lastName: consolidatedContactData.lastName,
              cellNumber: consolidatedContactData.cellNumber ?? "",
              textOptIn: consolidatedContactData.textOptIn ?? false,
              salutation: consolidatedContactData.salutation ?? "",
              customUserId: consolidatedContactData.customUserId ?? "",
              customAccountId: consolidatedContactData.customAccountId ?? "",
              customProperties: consolidatedContactData.customProperties,
            },
          ],
          comments,
        },
      ]);

      setState({ buttonState: "saved" });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: "gv.addTask" });
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.error(errorMessage);
      setState({ buttonState: "regular", errorMessage });
    }
  };

  let addAnotherContact = (e) => {
    if (e) e.preventDefault();

    let tempContactData = {};
    let contactDataKeys = contactData?.contact ? Object.keys(contactData.contact) : [];
    contactDataKeys.forEach((id) => {
      tempContactData[id] = "";
    });

    setContactData(tempContactData);

    //reset email suggestion
    setEmailAutoSuggestInputKey(Date.now());

    setState({ buttonState: "regular" });
  };

  let { errorMessage, buttonState } = state;

  return (
    <div className="row col-12">
      <div
        className="col-12"
        style={{ height: 425, overflowY: "scroll", padding: "0px 20px 20px 20px" }}>
        <TextField
          name="comments"
          placeholder="e.g. Made donation"
          label="Comments or reason for task"
          fullWidth
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          inputProps={{ autoComplete: "new-password" }}
        />

        <ListContactInputs
          contactData={contactData}
          setContactData={setContactData}
          accountInfo={props.accountInfo}
          customContactProperties={props.customContactProperties}
          contactId={null}
          variant="standard"
          emailAutoSuggestInputKey={emailAutoSuggestInputKey}
        />
      </div>

      {errorMessage && (
        <div className="errorMessageBox">
          <b>{errorMessage}</b>
        </div>
      )}

      <div
        className="col-12 justify-content-center"
        style={{
          padding: "20px 12px",
          display: "flex",
          borderTop: "1px hsl(220, 12%, 95%) solid",
        }}>
        {buttonState === "saved" && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              props.setAddNewDialogOpen(false);
            }}
            style={{ marginRight: 15 }}>
            I'm Done
          </Button>
        )}

        <LoadingButtonCheckProductLimits
          productKey="contacts"
          productLabel="contact"
          countToAdd={1}
          variant="contained"
          color="primary"
          buttonState={state.buttonState}
          savedLabel="Add another"
          label="Add Task"
          clickedRegularButton={addTask}
          clickedSavedButton={addAnotherContact}
          userIsAdmin={props.userIsAdmin}
          accountInfo={props.accountInfo}
        />
      </div>
    </div>
  );
}
