import React from "react";
import { FormControl, Select, IconButton, InputLabel } from "@material-ui/core";
import SelectItem from "../../util/selectItem";
import SelectList from "../../util/selectList";
import GetListSelectList from "../../util/getListSelectList";
import SelectSinceMonthAndTier from "../../util/selectSinceMonthAndTier";
import transactionAmountsOptions from "../../util/data/transactionAmountsOptions";
import milestoneTransactionTierOptions from "../../util/data/milestoneTransactionTierOptions";
import milestoneYearsOptions from "../../util/data/milestoneYearsOptions";
import milestoneCountsOptions from "../../util/data/milestoneCountsOptions";

export default function Condition(props) {
  let { current, actionIndex, conditionId, actions } = props;
  let { metric, metricValue = "" } = current;

  let handleMetricValueChange = (metric) => {
    if (metric === "transactionAmount")
      handleItemChange("transactionAmount", "", "greaterEqual");
    if (metric === "isFirstTransaction")
      handleItemChange("isFirstTransaction", true, "equal");
    if (metric === "didTransactionAmountIncrease")
      handleItemChange("didTransactionAmountIncrease", true, "equal");
    if (metric === "transactionTypes")
      handleListChange("transactionTypes", [], "includes");
    if (metric === "campaigns") handleListChange("campaigns", [], "includes");
    if (metric === "solicitations") handleListChange("solicitations", [], "includes");
    if (metric === "subSolicitations")
      handleListChange("subSolicitations", [], "includes");
    if (metric === "recordTypes") handleListChange("recordTypes", [], "includes");
    if (metric === "giftTypes") handleListChange("giftTypes", [], "includes");
    if (metric === "glCodes") handleListChange("glCodes", [], "includes");
    if (metric === "milestoneSinceMonthAmountReached")
      handleItemChange(
        "milestoneSinceMonthAmountReached",
        "",
        "milestoneSinceMonthAmountReached"
      );
    if (metric === "milestoneLifetimeAmountReached")
      handleItemChange(
        "milestoneLifetimeAmountReached",
        "",
        "milestoneLifetimeAmountReached"
      );
    if (metric === "milestoneConsecYearsGivingReached")
      handleItemChange(
        "milestoneConsecYearsGivingReached",
        "",
        "milestoneConsecYearsGivingReached"
      );
    if (metric === "milestoneGiftCountReached")
      handleItemChange("milestoneGiftCountReached", "", "milestoneGiftCountReached");
  };

  let handleListChange = (metric, newSelectedItems, operator) => {
    let metricValue = {};
    newSelectedItems.forEach(({ id, label }) => {
      metricValue[id] = { id, label };
    });

    let item = { metric, metricValue, operator };
    let tempActions = { ...actions };
    tempActions.list[actionIndex].conditions[conditionId] = item;
    props.setActions(tempActions);
  };

  let handleItemChange = (metric, value, operator) => {
    let item = { metric, metricValue: value, operator };
    let tempActions = { ...actions };
    tempActions.list[actionIndex].conditions[conditionId] = item;
    props.setActions(tempActions);
  };

  let deleteCondition = () => {
    let tempActions = { ...actions };
    delete tempActions.list[actionIndex].conditions[conditionId];
    props.setActions(tempActions);
  };

  let currentCondition = actions.list[actionIndex].conditions[conditionId];
  let selectedItems =
    currentCondition && currentCondition.metricValue ? currentCondition.metricValue : [];

  let giftAmountOptions = transactionAmountsOptions();
  let tierAmountOptions = milestoneTransactionTierOptions();
  let yearsGivenOptions = milestoneYearsOptions();
  let giftCountsOptions = milestoneCountsOptions("donations");

  return (
    <div style={{ display: "flex", paddingTop: "5px" }}>
      <div
        style={{
          width: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
          {props.order === 0 ? "IF" : "AND IF"}
        </h3>
      </div>

      <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
        <FormControl style={{ width: "350px", paddingRight: "15px" }} variant="filled">
          <InputLabel id="conditionType">Select Condition</InputLabel>
          <Select
            value={metric}
            native
            onChange={(e) => handleMetricValueChange(e.target.value)}
            inputProps={{
              "aria-label": "Select condition type",
              name: "conditionType",
              id: "conditionType",
            }}>
            <optgroup label="Recommended">
              <option value="transactionAmount">
                The gift amount is greater than or equal to
              </option>
              <option value="isFirstTransaction">It's a constituents first gift</option>
            </optgroup>
            <optgroup label="Milestone reached (i.e. will only trigger once when milestone reached)">
              <option value="milestoneGiftCountReached">
                Constituents number of donations reached any of these milestones
              </option>
              <option value="milestoneLifetimeAmountReached">
                Constituents total lifetime giving reached any of these milestones
              </option>
              <option value="milestoneSinceMonthAmountReached">
                Since MONTH, constituents cumulative giving reached any of these
                milestones
              </option>
              <option value="milestoneConsecYearsGivingReached">
                Constituent has consecutively given for any of these yearly milestones
              </option>
            </optgroup>
            <optgroup label="Additional filtering">
              <option value="didTransactionAmountIncrease">
                The donation amount is greater than the constituent's previous gift amount
              </option>
              <option value="campaigns">The gift is from any of these campaigns</option>
              <option value="recordTypes">The gift record type is any of these</option>
              <option value="giftTypes">The gift type is any of these</option>
              <option value="solicitations">
                The gift is from any of these solicitations
              </option>
              <option value="subSolicitations">
                The gift is from any of these sub solicitations
              </option>
              <option value="glCodes">The GL code is any of these</option>
            </optgroup>
          </Select>
        </FormControl>

        {metric === "transactionAmount" && (
          <SelectItem
            options={giftAmountOptions}
            selectedItem={metricValue}
            handleChange={(value) =>
              handleItemChange("transactionAmount", value, "greaterEqual")
            }
            label="Amount"
            alt="Select gift amount"
            width="150px"
          />
        )}
        {metric === "campaigns" && (
          <GetListSelectList
            initialOptions="loading"
            optionsFetchNeeded={true}
            requestData={{ source: "donorPerfect", event: "getCampaignList" }}
            accountId={props.accountId}
            selectedItems={selectedItems}
            handleChange={(value) => handleListChange("campaigns", value, "includes")}
            label="Campaigns"
            width="50%"
          />
        )}
        {metric === "solicitations" && (
          <GetListSelectList
            initialOptions="loading"
            optionsFetchNeeded={true}
            requestData={{ source: "donorPerfect", event: "getSolicitationList" }}
            accountId={props.accountId}
            selectedItems={selectedItems}
            handleChange={(value) => handleListChange("solicitations", value, "includes")}
            label="Solicitations"
            width="50%"
          />
        )}
        {metric === "subSolicitations" && (
          <GetListSelectList
            initialOptions="loading"
            optionsFetchNeeded={true}
            requestData={{ source: "donorPerfect", event: "getSubSolicitationList" }}
            accountId={props.accountId}
            selectedItems={selectedItems}
            handleChange={(value) =>
              handleListChange("subSolicitations", value, "includes")
            }
            label="Sub Solicitations"
            width="50%"
          />
        )}
        {metric === "recordTypes" && (
          <GetListSelectList
            initialOptions="loading"
            optionsFetchNeeded={true}
            requestData={{ source: "donorPerfect", event: "getRecordTypeList" }}
            accountId={props.accountId}
            selectedItems={selectedItems}
            handleChange={(value) => handleListChange("recordTypes", value, "includes")}
            label="Record Types"
            width="50%"
          />
        )}
        {metric === "giftTypes" && (
          <GetListSelectList
            initialOptions="loading"
            optionsFetchNeeded={true}
            requestData={{ source: "donorPerfect", event: "getGiftTypeList" }}
            accountId={props.accountId}
            selectedItems={selectedItems}
            handleChange={(value) => handleListChange("giftTypes", value, "includes")}
            label="Gift Types"
            width="50%"
          />
        )}
        {metric === "glCodes" && (
          <GetListSelectList
            initialOptions="loading"
            optionsFetchNeeded={true}
            requestData={{ source: "donorPerfect", event: "getGlCodesList" }}
            accountId={props.accountId}
            selectedItems={selectedItems}
            handleChange={(value) => handleListChange("glCodes", value, "includes")}
            label="GL Codes"
            width="50%"
          />
        )}
        {metric === "milestoneSinceMonthAmountReached" && (
          <SelectSinceMonthAndTier
            selectedItems={selectedItems}
            actions={actions}
            setActions={props.setActions}
            currentCondition={current}
            actionIndex={actionIndex}
            conditionId={conditionId}
          />
        )}
        {metric === "milestoneConsecYearsGivingReached" && (
          <SelectList
            options={yearsGivenOptions}
            selectedItems={selectedItems}
            handleChange={(value) =>
              handleListChange(
                "milestoneConsecYearsGivingReached",
                value,
                "milestoneConsecYearsGivingReached"
              )
            }
            label="Milestones"
            width="50%"
          />
        )}
        {metric === "milestoneGiftCountReached" && (
          <SelectList
            options={giftCountsOptions}
            selectedItems={selectedItems}
            handleChange={(value) =>
              handleListChange(
                "milestoneGiftCountReached",
                value,
                "milestoneGiftCountReached"
              )
            }
            label="Milestones"
            width="50%"
          />
        )}
        {metric === "milestoneLifetimeAmountReached" && (
          <SelectList
            options={tierAmountOptions}
            selectedItems={selectedItems}
            handleChange={(value) =>
              handleListChange(
                "milestoneLifetimeAmountReached",
                value,
                "milestoneLifetimeAmountReached"
              )
            }
            label="Milestones"
            width="50%"
          />
        )}
      </div>
      <div
        style={{
          width: "70px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        {props.order > 0 && (
          <IconButton onClick={() => deleteCondition()} style={{ marginRight: "8px" }}>
            <i className="material-icons" style={{ fontSize: "18px" }}>
              close
            </i>
          </IconButton>
        )}
      </div>
    </div>
  );
}
