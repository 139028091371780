import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import LoadingButton from "../../../../util/buttons/loadingbutton";
import { authenticateNeon, getAccountIntegrations } from "services/api.service";

export default function Authenticate(props) {
  let [organizationId, setOrganizationId] = useState("");
  let [apiKey, setApiKey] = useState("");
  let [state, setState] = useState({});
  let { integrationDetails, setConnectionInfo } = props;
  let { header, logoSrc, brandHex } = integrationDetails;

  useEffect(() => {
    getAccountIntegrations()
      .then((integrations) =>
        setConnectionInfo(integrations?.endpoint?.neon?.connectionInfo)
      )
      .catch((err) => setState({ errorMessage: err.message }));
  }, [setConnectionInfo]);

  let saveApiToken = async () => {
    try {
      if (!apiKey) throw new Error("Please add api key");
      setState({ buttonState: "loading" });
      await authenticateNeon({ organizationId, apiKey });
      props.setConnectionInfo({ dateConnected: Date.now() });
    } catch (err) {
      let errorMessage = err.response?.data?.message || err.message;
      console.log(errorMessage);
      setState({ errorMessage });
    }
  };

  return (
    <div className="row col-12">
      <div className="col-12 row">
        <div
          style={{
            width: "70px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <div
            style={{
              marginRight: "8px",
              width: "45px",
              height: "45px",
              backgroundColor: brandHex,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img src={logoSrc} width="50px" alt="logo" />
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <h1>{header}</h1>
          <span style={{ display: "flex", alignItems: "center" }}>
            <i
              className="material-icons"
              title="check icon"
              style={{ color: "red", fontSize: "25px", marginRight: "4px" }}>
              cancel
            </i>
            Your account is not connected.
          </span>
        </div>
      </div>

      <div className="col-12" style={{ marginTop: "25px" }}>
        Need your Neon One API Key? Follow the steps under{" "}
        <a
          href="https://graduwayhelp.zendesk.com/hc/en-us/articles/6652989750940-Neon-One-Integration"
          target="_blank"
          rel="noopener noreferrer">
          How To Connect
        </a>
      </div>

      <div className="col-12" style={{ marginTop: "20px" }}>
        <TextField
          fullWidth={true}
          id="organizationId"
          label="Neon One Organization Id"
          variant="outlined"
          value={organizationId}
          name="organizationId"
          onChange={(e) => setOrganizationId(e.target.value)}
        />
      </div>

      <div className="col-12" style={{ marginTop: "20px" }}>
        <TextField
          fullWidth={true}
          id="apiKey"
          label="Neon One API Key"
          variant="outlined"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
        />
      </div>

      {state.errorMessage && (
        <div className="col-12 errorMessageBox">
          <b>{state.errorMessage}</b>
        </div>
      )}

      <div className="col-12" style={{ marginTop: "20px" }}>
        <LoadingButton
          variant="contained"
          color="primary"
          buttonState={state.buttonState}
          savedLabel="Connected!"
          label="Connect"
          clickedRegularButton={saveApiToken}
          clickedSavedButton={() => {
            setState({});
          }}
        />
      </div>
    </div>
  );
}

Authenticate.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  integrationDetails: PropTypes.object.isRequired,
  connectionInfo: PropTypes.object,
  setConnectionInfo: PropTypes.func.isRequired,
};
