import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AnalyticsEventLookup from "../../util/data/analyticsEventLookup.json";

const CustomTooltip = ({ active, payload, label: date }) => {
  if (active && payload && payload.length) {
    let eventLabel = payload[0].name;
    let regularSends = payload[0].value;
    let resends = payload[1] && payload[1].value ? payload[1].value : 0;
    return (
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "10px",
          border: "1px solid #ddd",
        }}>
        <p style={{ fontSize: "15px" }}>{date}</p>
        <p style={{ fontSize: "14px" }}>
          Total{" "}
          <span style={{ textTransform: "lowercase", fontSize: "14px" }}>
            {eventLabel}
          </span>
          : {regularSends + resends}
        </p>
        {resends > 0 && (
          <p style={{ fontSize: "14px" }}>
            {eventLabel} from resend: {resends}
          </p>
        )}
      </div>
    );
  }

  return null;
};

export default function DailyChartWithResends(props) {
  let { analyticsDaily } = props;
  let [eventKey, setEventKey] = useState("pageView");

  let data = [];
  let resendTotalAccrossAllDays = 0;

  analyticsDaily.forEach((current) => {
    let resendValue = current["rs_" + eventKey] ?? 0;
    let regularSends = current[eventKey] ?? 0;
    let totalValue = regularSends + resendValue;
    resendTotalAccrossAllDays += resendValue;

    if (totalValue === 0 && resendValue === 0) return;

    let item = {
      date: current.date,
      value: totalValue,
      regularValue: regularSends,
      resendValue: resendValue,
    };
    data.push(item);
  });

  let { label } = AnalyticsEventLookup[eventKey] ? AnalyticsEventLookup[eventKey] : {};

  return (
    <div className="row col-12" style={{ padding: "10px" }}>
      <div
        className="row col-12 boxShadow"
        style={{ backgroundColor: "white", padding: "15px", borderRadius: "3px" }}>
        <div className="col-6">
          <h2>Daily</h2>
        </div>

        <div className="col-6 justify-content-end">
          <FormControl style={{ width: "150px" }}>
            <InputLabel htmlFor="dateType">Select Event</InputLabel>
            <Select
              value={eventKey}
              native
              onChange={(e) => setEventKey(e.target.value)}
              inputProps={{ name: "eventType", id: "eventType" }}>
              <option value="pageView">Page views</option>
              <option value="playVideo">Video plays</option>
              <option value="playedFullVideo">Full video watches</option>
              <option value="reply">Replies</option>
              <option value="ctaClick">CTA clicks</option>
              <option value="email_open">Emails opened</option>
              <option value="email_click">Emails clicked</option>
            </Select>
          </FormControl>
        </div>

        <div className="col-12" style={{ marginTop: "25px" }}>
          <ResponsiveContainer height={200}>
            <BarChart data={data} margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar dataKey="regularValue" stackId="a" name={label} fill="#24305e" />
              {resendTotalAccrossAllDays > 0 && (
                <Bar
                  dataKey="resendValue"
                  stackId="a"
                  name={label + " from resend"}
                  fill="#66bb6a"
                />
              )}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}
