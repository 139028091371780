import React from "react";
import PropTypes from "prop-types";
import ColumnItem from "./columnItem";

export default function MapColumns(props) {
  let { csvData, properties, setProperties } = props;
  let { rows, headers } = csvData;
  const columnSamples = rows.slice(0, 4);

  const items = headers.map((columnHeader) => (
    <ColumnItem
      key={columnHeader}
      csvColumnHeader={columnHeader}
      columnSamples={columnSamples}
      properties={properties}
      setProperties={setProperties}
      calledFromTask={props.calledFromTask}
      calledFromBulkRequest={props.calledFromBulkRequest}
      calledFromContacts={props.calledFromContacts}
    />
  ));

  return (
    <>
      <div className="col-12 row" style={{ padding: "20px 0px 10px 0px" }}>
        <div className="col-12">
          <h2>3) Preview and Map Your Data</h2>
          <p style={{ color: "grey", fontSize: "13px", marginTop: "5px" }}>
            Map your data to upload it.
          </p>
        </div>
        <div className="col-12 row">{items}</div>
      </div>
      {props.calledFromContacts && <div className="col-12" style={{ height: "70px" }} />}
    </>
  );
}

MapColumns.propTypes = {
  csvData: PropTypes.shape({
    rows: PropTypes.array.isRequired,
    headers: PropTypes.array.isRequired,
  }).isRequired,
  setProperties: PropTypes.func.isRequired,
  calledFromTask: PropTypes.bool,
  calledFromBulkRequest: PropTypes.bool,
  calledFromContacts: PropTypes.bool,
  properties: PropTypes.object,
};
