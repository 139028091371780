import React, { useState } from "react";
import { Button } from "@material-ui/core";
import ResendNowDialog from "./resendNowDialog";
import withMobileDialog from "@material-ui/core/withMobileDialog";

function ActionRow(props) {
  let [dialogOpen, setDialogOpen] = useState("");
  let { selectedItems = {} } = props;
  let selectedItemsKeys = Object.keys(selectedItems);

  return (
    <>
      <Button
        size="small"
        onClick={() => setDialogOpen("resend")}
        disabled={selectedItemsKeys.length > 0 ? false : true}>
        <i className="material-icons" style={{ fontSize: "18px" }}>
          send
        </i>
        <span
          style={{ textTransform: "capitalize", fontSize: "14px", marginLeft: "3px" }}>
          Resend Now
        </span>
      </Button>

      <ResendNowDialog
        {...props}
        dialogOpen={Boolean(dialogOpen === "resend")}
        setDialogOpen={setDialogOpen}
      />
    </>
  );
}

export default withMobileDialog()(ActionRow);
