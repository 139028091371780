import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  ListItem,
  LinearProgress,
  ListItemIcon,
  Radio,
  ListItemText,
} from "@material-ui/core";
import {
  getBlackbaudContactLists,
  getSalesforceContactLists,
} from "services/api.service";

export default function GetList(props) {
  let { platformLabel, platformKey = "" } = props;
  let [state, setState] = useState({});
  let [list, setList] = useState("loading");

  useEffect(() => {
    let getList = async () => {
      try {
        let result;
        if (platformKey === "salesforce") {
          result = await getSalesforceContactLists();
        }
        if (props.platformKey === "blackbaud") {
          result = await getBlackbaudContactLists();
        }
        setList(result);
      } catch (err) {
        const errorMessage = err?.response?.message ?? err.message;
        setState({ errorMessage: errorMessage });
      }
    };

    getList();
  }, []);

  if (list === "loading") {
    return (
      <div className="row col-12">
        <p className="greyDesc" style={{ fontSize: "14px", marginBottom: "4px" }}>
          One second. Retrieving your list from {platformLabel}...
        </p>
        <div className="col-12">
          {state.errorMessage ? (
            <div className="errorMessageBox">
              <b>{state.errorMessage}</b>
            </div>
          ) : (
            <LinearProgress />
          )}
        </div>
      </div>
    );
  }

  let showList = list.map(({ label = "List", id, count = 1 }) => {
    if (label === "All Contacts") return null; //return salesforce all contacts list
    let listLabel = count ? label + " (" + count + " contacts)" : label;
    return (
      <ListItem
        key={id}
        role={undefined}
        dense
        button
        onClick={() => props.setSelectedList({ id, label, count })}>
        <ListItemIcon>
          <Radio
            edge="start"
            checked={id === props.selectedList?.id}
            tabIndex={-1}
            disableRipple
            inputProps={{ "aria-labelledby": "label" + id }}
          />
        </ListItemIcon>
        <ListItemText id={id} primary={listLabel} />
      </ListItem>
    );
  });

  return (
    <div className="row col-12">
      <div className="col-12">
        <h3 style={{ marginBottom: "20px" }}>Select your list from {platformLabel}</h3>
      </div>

      <div className="col-12">{showList}</div>
    </div>
  );
}

GetList.propTypes = {
  platformLabel: PropTypes.string.isRequired,
  platformKey: PropTypes.string.isRequired,
  setSelectedList: PropTypes.func.isRequired,
  selectedList: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};
