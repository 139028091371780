import React, { useState, useEffect } from "react";
import VideoDialog from "./videoDialog";
import { getVideo } from "services/api.service";

export default function Thumbnail(props) {
  let [thumbnailUrl, setThumbnailUrl] = useState(props.video?.thumbnailUrl);

  useEffect(() => {
    let thumbnailInterval = null;

    async function listenForThumbnail() {
      const video = await getVideo(props.video.id);

      if (video?.thumbnailUrl) {
        setThumbnailUrl(video.thumbnailUrl);
        clearInterval(thumbnailInterval);
        thumbnailInterval = null;
      }
    }

    if (props.video?.status === "webMUploaded" && !props.video.thumbnailUrl) {
      thumbnailInterval = setInterval(listenForThumbnail, 10000);
    }

    return () => {
      if (thumbnailInterval) {
        clearInterval(thumbnailInterval);
      }
    };
  }, []);

  let status = props.video?.status || "create";

  if (thumbnailUrl) {
    return (
      <div style={{ width: "75px", height: "50px" }}>
        <VideoDialog video={props.video} thumbnailUrl={thumbnailUrl} />
      </div>
    );
  } else if (status === "webMUploaded") {
    return (
      <div
        style={{
          width: "75px",
          height: "50px",
          backgroundColor: "#ddd",
          borderRadius: "8px",
        }}
        className="align-items-center justify-content-center">
        <div>
          <i
            className="material-icons"
            style={{ color: "grey", justifyContent: "center", display: "flex" }}>
            autorenew
          </i>
          <span style={{ fontSize: "12px", display: "absolute", bottom: "0px" }}>
            Processing
          </span>
        </div>
      </div>
    );
  } else if (status === "requested") {
    return (
      <div
        style={{
          width: "75px",
          height: "50px",
          backgroundColor: "#ddd",
          borderRadius: "8px",
        }}
        className="align-items-center justify-content-center">
        <div>
          <i
            className="material-icons"
            style={{ color: "grey", justifyContent: "center", display: "flex" }}>
            contact_mail
          </i>
          <span style={{ fontSize: "12px", display: "absolute", bottom: "0px" }}>
            Requested
          </span>
        </div>
      </div>
    );
  } else if (status === "error") {
    return (
      <div
        style={{
          width: "75px",
          height: "50px",
          backgroundColor: "#ddd",
          borderRadius: "8px",
        }}
        className="align-items-center justify-content-center">
        <div>
          <i
            className="material-icons"
            style={{ color: "grey", justifyContent: "center", display: "flex" }}>
            warning
          </i>
          <span style={{ fontSize: "12px", display: "absolute", bottom: "0px" }}>
            No video
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          width: "75px",
          height: "50px",
          backgroundColor: "#ddd",
          borderRadius: "8px",
        }}
        className="align-items-center justify-content-center"
      />
    );
  }
}
