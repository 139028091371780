import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { getNotes } from "services/api.service";
import { debounce } from "util/helpers";

export default function SelectNote(props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  async function getNoteList(searchQuery) {
    setIsLoading(true);

    const notes = await getNotes(0, 0, searchQuery).catch((e) => {
      setErrorMessage(e.message);
      setSearchList([]);
      setIsLoading(false);
    });

    setSearchList(notes.rows);
    setIsLoading(false);
  }

  const debouncedGetNotes = useCallback(debounce(getNoteList), [props]);

  useEffect(() => {
    debouncedGetNotes(searchTerm);
  }, [searchTerm, debouncedGetNotes]);

  let handleInputChange = (value, reason) => {
    if (reason === "reset") return;
    //reason is input, this mean user typed in new search
    setSearchTerm(value);
  };

  if (props.noteId)
    return (
      <TextField
        variant="filled"
        fullWidth
        value={props.noteTitle}
        id="textInput-selected"
        onClick={() => props.itemSelected(null)}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <InputAdornment position={"end"}>
              <IconButton
                aria-label="help button, view more info"
                onClick={() => props.itemSelected(null, null, null)}
                id="closeButton">
                <i className="material-icons">close</i>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );

  return (
    <>
      <Autocomplete
        id="country-select-demo"
        options={searchList}
        loading={isLoading}
        onChange={(e, item) => props.itemSelected(item)}
        onInputChange={(e, value, reason) => handleInputChange(value, reason)}
        noOptionsText="No notes exist. Create a note first."
        getOptionLabel={(option) => option.title}
        renderOption={(option) => <React.Fragment>{option.title}</React.Fragment>}
        renderInput={(params) => (
          <TextField
            {...params}
            style={{ minWidth: 300 }}
            label="Select Note"
            variant="filled"
            fullWidth
            placeholder="search for existing notes"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />

      {errorMessage && (
        <div className="errorMessageBox">
          <b>{errorMessage}</b>
        </div>
      )}
    </>
  );
}
SelectNote.propTypes = {
  accountInfo: PropTypes.shape({
    id: PropTypes.string,
    searchSecuredApiKey: PropTypes.string,
  }),
  noteId: PropTypes.string,
  noteTitle: PropTypes.string,
  itemSelected: PropTypes.func,
};
