import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Divider, Drawer, Hidden } from "@material-ui/core";
import tutorialConstants from "./tutorial-constants";
import DrawerItem from "./drawerItem";

export default function DesktopDrawer(props) {
  const { classes, desktopOpen } = props;
  let { title = null, href = null } = tutorialConstants?.[props.pageId] ?? {};

  return (
    <Hidden xsDown implementation="css">
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: desktopOpen,
          [classes.drawerClose]: !desktopOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: desktopOpen,
            [classes.drawerClose]: !desktopOpen,
          }),
        }}>
        {props.listDrawerItems}
        <Divider variant="middle" />
        <DrawerItem
          to="https://graduwayhelp.zendesk.com/hc/en-us/sections/6614353265948-How-To-Guides-"
          icon="help_outline"
          label="Help Center"
          isDesktopOpen={desktopOpen}
        />
        <DrawerItem
          to="https://graduwayhelp.zendesk.com/hc/en-us/requests/new"
          icon="support_agent"
          label="Contact Support"
          isDesktopOpen={desktopOpen}
        />
        {title && (
          <>
            <Divider variant="middle" />
            <DrawerItem
              to={href}
              icon="play_circle"
              label={title}
              isDesktopOpen={props.desktopOpen}
              isOutlinedIcon={false}
              howTo
            />
          </>
        )}
      </Drawer>
    </Hidden>
  );
}

DesktopDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  listDrawerItems: PropTypes.array.isRequired,
  pageId: PropTypes.string,
  desktopOpen: PropTypes.bool,
};
