import React from "react";
import PropTypes from "prop-types";
import { Checkbox, Tooltip } from "@material-ui/core";
import timeSinceDate from "../../../../util/functions/timeSinceDate";
import style from "./taskItem.module.css";

export default function TaskItem(props) {
  let { current = {} } = props;
  let { comments, taskVideos = [], noteId } = current;
  let taskCreatedDate = timeSinceDate(new Date(current.createdDate).valueOf(), "", "");
  comments = current.comments || "Say thanks";

  let checkboxSelected = (e, task) => {
    e.stopPropagation();

    if (props.selectedItems?.some((selectedTask) => task.id === selectedTask.id)) {
      props.setSelectedItems(
        props.selectedItems.filter((selectedTask) => task.id !== selectedTask.id)
      );
    } else {
      props.setSelectedItems([...(props.selectedItems || []), task]);
    }

    props.setEditTaskSelected(null);
    props.setAllSelected(false);
  };

  let listItemSelected = (task) => {
    props.setEditTaskSelected(task);
    props.setSelectedItems([task]);
    props.setAllSelected(false);
  };

  const selected = current.id === props.editTaskSelected?.id;
  const checked =
    props.allSelected || props.selectedItems?.some((item) => item.id === current.id);

  const assignedUser = props.users.find((user) => user.id === current.assignedUserId);

  const statusOn = `material-icons ${style.statusOn}`;
  const statusOff = `material-icons-outlined ${style.statusOff}`;

  const videoIcon = taskVideos.length
    ? { class: statusOn, tooltip: "Video selected" }
    : { class: statusOff, tooltip: "No video selected" };
  const noteIcon = noteId
    ? { class: statusOn, tooltip: "Note selected" }
    : { class: statusOff, tooltip: "No note selected" };
  const assignedUserIcon = assignedUser
    ? { class: statusOn, label: "Assigned to " + assignedUser.label }
    : { class: statusOff };

  return (
    <div
      className={`${style.item} ${selected && style.selected}`}
      selected={selected}
      onClick={() => listItemSelected(current)}>
      {current.sentByUserId ? (
        <i className={`material-icons ${style.greenCheck}`}>check_circle</i>
      ) : (
        <Checkbox
          checked={checked}
          onClick={(e) => checkboxSelected(e, current)}
          size="small"
          color="primary"
        />
      )}
      <div className={style.details}>
        <div className={style.title}>{comments}</div>
        <div className={style.date}>{taskCreatedDate}</div>

        <div className={style.statuses}>
          <Tooltip title={videoIcon.tooltip}>
            <i className={videoIcon.class}>videocam</i>
          </Tooltip>
          <Tooltip title={noteIcon.tooltip}>
            <i className={noteIcon.class}>note_alt</i>
          </Tooltip>
          <div className={style.assignedUser}>
            <i className={assignedUserIcon.class}>assignment_ind</i>
            {assignedUserIcon.label}
          </div>
        </div>
      </div>
    </div>
  );
}

TaskItem.propTypes = {
  current: PropTypes.object,
  users: PropTypes.array.isRequired,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func.isRequired,
  allSelected: PropTypes.bool,
  setAllSelected: PropTypes.func.isRequired,
  editTaskSelected: PropTypes.object,
  setEditTaskSelected: PropTypes.func.isRequired,
};
