import http from "./http.service";

/**
 * Gets list of all accounts.
 * @returns {Promise<Object>}
 */
export function getAccounts(offset, limit, sortBy, sortDirection, searchTerm) {
  const params = new URLSearchParams({
    offset,
    limit,
    sortBy,
    sortDirection,
    searchTerm,
  });
  return http.get("/admin/accounts", { params });
}

/**
 * Gets account info by id for superadmin.
 * @returns {Promise<Object>}
 */
export function getAdminAccount(accountId) {
  return http.get(`/admin/accounts/${accountId}`);
}

/**
 * Adds new organization.
 * @param {Object} payload - Organization settings object.
 * @returns {Promise<Object>}
 */
export function createAccount(payload) {
  return http.post("/admin/accounts", payload);
}

/**
 * Updates organization data, not including integrations.
 * @param {string} accountId - Organization's unique identifier.
 * @param {Object} payload - Organization settings object.
 * @returns {Promise<Object>}
 */
export function setAccount(accountId, payload) {
  return http.patch(`/admin/accounts/${accountId}`, payload);
}

/**
 * Deletes all organization data.
 * @param {string} accountId - Organization's unique identifier.
 * @returns {Promise<Object>}
 */
export function deleteAccount(accountId) {
  return http.delete(`/admin/accounts/${accountId}`);
}

/**
 * Verifies branded domain information with sendgrid
 * @param {string} accountId - Account unique identifier
 * @return {Promise<Object>}
 */
export function verifyBrandedDomainEmail(accountId) {
  return http.post(`/admin/accounts/${accountId}/branded-domain/verify-email`);
}

/**
 * Verifies branded domain information with vercel
 * @param {string} accountId - Account unique identifier
 * @return {Promise<Object>}
 */
export function verifyBrandedDomainVercel(accountId) {
  return http.post(`/admin/accounts/${accountId}/branded-domain/verify-vercel`);
}

/**
 * Upserts integrations object to update active integrations and keys
 * @param {Object} payload - Integration form settings
 * @param {bool} payload.integration.integrationName - boolean indicating if the integration is active for each integration
 * @param {bool} payload.hasTextingFeature - If text feature is on
 * @param {string} payload.customDomain - Domain for Gratavid site to be hosted
 * @param {string} payload.domain - Domain for emails to originate
 * @param {string} payload.domainLocalPart - Prefix of the automated emails
 * @return {Promise<Object>}
 */
export function upsertIntegrations(accountId, payload) {
  return http.post(`/admin/accounts/${accountId}/integrations`, payload);
}

/**
 * @typedef {object} User
 * @property {string} user.uid uid
 * @property {string} user.email email address
 * @property {string} user.fullName full name
 * @property {boolean} role.admin user can manage other users
 * @property {boolean} role.disableBulkSends user can't bulk send
 * @property {boolean} role.disableNoteWrite use can't edit notes
 */

/**
 * Get an account's users
 * @param {string} accountId account uid
 * @return {Promise<User[]>} array of users
 */
export function getAccountUsers(accountId) {
  return http.get(`/admin/accounts/${accountId}/users`);
}

/**
 * Add a user to an account
 * @param {string} accountId account uid
 * @param {User} user new user object
 * @return {Promise<User>} newly added user
 */
export function addAccountUser(accountId, user) {
  return http.post(`/admin/accounts/${accountId}/users`, user);
}

/**
 * Update a user and their role for an account
 * @param {string} accountId account uid
 * @param {string} userId user id
 * @param {object} payload { user, role }
 * @return {Promise<User>} updated user
 */
export function updateAccountUser(accountId, userId, payload) {
  return http.patch(`/admin/accounts/${accountId}/users/${userId}`, payload);
}

/**
 * Delete user and account user roles
 * @param {string} accountId account uid
 * @param {string} userId user uid
 * @return {Promise<Object>} uid of deleted user
 */
export function deleteAccountUser(accountId, userId) {
  return http.delete(`/admin/accounts/${accountId}/users/${userId}`);
}

/**
 * Send email to account admin to verify branded domain
 * @param {string} accountId account uid
 * @param {string} emailRecipient - Recipient for email. If not provided, main account user will be emailed
 * @return {Promise<Object>} message sent to account admin
 */
export function emailBrandedDomain(accountId, emailRecipient) {
  return http.post(`/admin/accounts/${accountId}/branded-domain/email`, {
    to: emailRecipient,
  });
}

/**
 * Get all super admin Users
 * @returns {Promise<Users[]>} super-admins
 */
export function getSuperAdmins() {
  return http.get(`/admin/super-admins`);
}

/**
 * Create a super-admin by email
 * @param {string} email - Email of user to become super admin
 * @returns {Promise<User>} Newly created super-admin
 */
export function addSuperAdmin(email) {
  return http.patch(`/admin/super-admins`, { email });
}

/**
 * Remove a users super-admin role by email
 * @param {string} userId - User unique identifier
 * @returns {Promise<User>}
 */
export function deleteSuperAdmin(userId) {
  return http.delete(`/admin/super-admins/${userId}`);
}
