import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import AnalyticsInfoBox from "./analyticsInfoBox";

export default function SummaryBox(props) {
  let {
    dataType,
    analyticsTotals,
    color,
    icon,
    label,
    kpiKey,
    kpiTwoKey,
    kpiThreeKey,
    kpiLabel,
    kpiTwoLabel,
    kpiThreeLabel,
    showUniqueData,
    indicatorWidth,
    gvSendId,
  } = props;

  if (analyticsTotals === "loading") {
    return (
      <div className="row col-12" style={{ padding: "10px", minHeight: "200px" }}>
        <div
          className="boxShadow col-12"
          style={{ backgroundColor: "white", padding: "20px", borderRadius: "3px" }}>
          <LinearProgress />
        </div>
      </div>
    );
  }

  if (kpiKey === "npv") indicatorWidth = "100%";
  let kpi, kpiTwo, kpiThree;
  if (showUniqueData) {
    kpi = analyticsTotals["unique_" + kpiKey] ? analyticsTotals["unique_" + kpiKey] : 0;
    kpiTwo = analyticsTotals["unique_" + kpiTwoKey]
      ? analyticsTotals["unique_" + kpiTwoKey]
      : 0;
    kpiThree = analyticsTotals["unique_" + kpiThreeKey]
      ? analyticsTotals["unique_" + kpiThreeKey]
      : 0;
  } else {
    kpi = analyticsTotals[kpiKey] ? analyticsTotals[kpiKey] : 0;
    kpiTwo = analyticsTotals[kpiTwoKey] ? analyticsTotals[kpiTwoKey] : 0;
    kpiThree = analyticsTotals[kpiThreeKey] ? analyticsTotals[kpiThreeKey] : 0;
  }

  return (
    <div className="row col-12" style={{ padding: "10px", height: "100%" }}>
      <div
        className="row col-12 boxShadow"
        style={{ backgroundColor: "white", padding: "20px 15px", borderRadius: "3px" }}>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: 5,
            overflow: "hidden",
            display: "flex",
            borderRadius: "3px 3px 0px 0px",
          }}>
          <div style={{ width: indicatorWidth, backgroundColor: color }} />
          <div style={{ flex: 1, backgroundColor: "#bfcad8" }} />
        </div>

        <div className="col-12 row" style={{ marginBottom: "15px" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <i
              className="material-icons"
              style={{ marginRight: "5px", color: "#8E949D" }}>
              {icon}
            </i>
            <span style={{ fontSize: "12px", color: "grey", fontWeight: "bold" }}>
              {label}
            </span>
          </div>
          <div style={{ width: "28px" }}>
            <AnalyticsInfoBox
              size="medium"
              dataType={dataType}
              showUniqueData={showUniqueData}
              handleUniqueToggle={props.handleUniqueToggle}
              gvSendId={gvSendId}
            />
          </div>
        </div>

        <div className="col-12">
          <span style={{ fontSize: "28px", fontWeight: "bold" }}>{kpi}</span>
          <br />
          <span style={{ fontSize: "13px", color, fontWeight: "bold" }}>
            {showUniqueData ? "UNIQUE " + kpiLabel : kpiLabel}
          </span>
        </div>

        <div
          style={{
            margin: "10px 0px",
            backgroundColor: "#ddd",
            width: "20px",
            height: "2px",
          }}
        />

        <div className="col-12">
          <span style={{ fontSize: "12px", color: "#71859b" }}>
            <span style={{ fontSize: "12px", color: "#506070", fontWeight: "bold" }}>
              {kpiTwo}
            </span>
            {showUniqueData ? " unique " + kpiTwoLabel : kpiTwoLabel}
          </span>
          <br />
          <span style={{ fontSize: "12px", color: "#71859b" }}>
            <span style={{ fontSize: "12px", color: "#506070", fontWeight: "bold" }}>
              {kpiThree}
            </span>
            {showUniqueData ? " unique " + kpiThreeLabel : kpiThreeLabel}
          </span>
        </div>
      </div>
    </div>
  );
}
