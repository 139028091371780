import React, { useState } from "react";
import LoadingButton from "../../../../../util/buttons/loadingbutton";
import SelectItem from "../../util/selectItem";
import GetListSelectItem from "../../util/getListSelectItem";
import { updateAccountIntegrations } from "services/api.service";

export default function SaveConfig(props) {
  let { eventData, event = "" } = props;
  let {
    saveAs: initSaveAs = null,
    noteType: initNoteType = "Personal",
    actionCategory: initActionCategory = "Task/Other",
    actionType: initActionType = "Thank You",
    actionStatus: initActionStatus = "Completed",
  } = eventData;

  if (!initSaveAs && event === "taskSent") initSaveAs = "action";
  else if (!initSaveAs) initSaveAs = "note";

  let [state, setState] = useState({});
  let [saveAs, setSaveAs] = useState(initSaveAs);
  let [noteType, setNoteType] = useState(initNoteType);
  let [actionCategory, setActionCategory] = useState(initActionCategory);
  let [actionType, setActionType] = useState(initActionType);
  let [actionStatus, setActionStatus] = useState(initActionStatus);

  let saveData = async () => {
    try {
      let updates = [];
      setState({ buttonState: "loading" });
      updates.push({
        path: `endpoint/blackbaud/events/${event}/saveAs`,
        value: saveAs,
      });
      updates.push({
        path: `endpoint/blackbaud/events/${event}/noteType`,
        value: noteType,
      });
      updates.push({
        path: `endpoint/blackbaud/events/${event}/actionCategory`,
        value: actionCategory,
      });
      updates.push({
        path: `endpoint/blackbaud/events/${event}/actionType`,
        value: actionType,
      });
      updates.push({
        path: `endpoint/blackbaud/events/${event}/actionStatus`,
        value: actionStatus,
      });

      await updateAccountIntegrations(updates);

      setState({ buttonState: "saved" });
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  let saveAsOptions = [
    { id: "note", value: "note", label: "Note" },
    { id: "action", value: "action", label: "Action" },
  ];
  let actionCategoryOptions = [
    { id: "Mailing", value: "Mailing", label: "Mailing" },
    { id: "Email", value: "Email", label: "Email" },
    { id: "Task/Other", value: "Task/Other", label: "Task/Other" },
  ];

  return (
    <>
      <div style={{ marginRight: "15px" }}>
        <SelectItem
          options={saveAsOptions}
          selectedItem={saveAs}
          handleChange={(value) => setSaveAs(value)}
          label="Save As"
          alt="Select save as"
          width="250px"
        />
      </div>

      {saveAs === "note" ? (
        <GetListSelectItem
          initialOptions="loading"
          optionsFetchNeeded={true}
          requestData={{ source: "blackbaud", event: "getNoteTypes" }}
          accountId={props.accountInfo.id}
          selectedItem={{ id: noteType, label: noteType }}
          handleChange={(value) => setNoteType(value && value.id ? value.id : "")}
          label="Note Type"
          width="250px"
        />
      ) : (
        <>
          <div style={{ marginRight: "15px" }}>
            <SelectItem
              options={actionCategoryOptions}
              selectedItem={actionCategory}
              handleChange={(value) => setActionCategory(value)}
              label="Action Category"
              alt="Select action category"
              width="250px"
            />
          </div>
          <GetListSelectItem
            initialOptions="loading"
            optionsFetchNeeded={true}
            requestData={{ source: "blackbaud", event: "getActionTypes" }}
            accountId={props.accountInfo.id}
            selectedItem={{ id: actionType, label: actionType }}
            handleChange={(value) => setActionType(value && value.id ? value.id : "")}
            label="Action Type"
            width="250px"
          />

          <div style={{ marginLeft: "15px" }}>
            <GetListSelectItem
              initialOptions="loading"
              optionsFetchNeeded={true}
              requestData={{ source: "blackbaud", event: "getActionStatusTypes" }}
              accountId={props.accountInfo.id}
              selectedItem={{ id: actionStatus, label: actionStatus }}
              handleChange={(value) => setActionStatus(value && value.id ? value.id : "")}
              label="Action Status"
              width="250px"
            />
          </div>
        </>
      )}

      <div className="col-12 justify-content-center" style={{ padding: "20px 0px" }}>
        <LoadingButton
          variant="contained"
          color="primary"
          buttonState={state.buttonState}
          savedLabel={"Saved"}
          label={"Save"}
          clickedRegularButton={saveData}
          clickedSavedButton={() => {
            setState({});
          }}
        />
      </div>
    </>
  );
}
