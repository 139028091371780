import React from "react";
import styles from "./maintenance-window.module.css";

export default function Maintenance() {
  return (
    <div className={styles.maintenancePage}>
      <div className={styles.maintenancePageQuadrilateral}></div>
      <div className={styles.maintenancePageContent}>
        <div className={styles.maintenancePageLogoWrapper}>
          <img
            className={styles.maintenancePageLogo}
            src="/static/img/logo.png"
            alt="logo"
          />
          <div className={styles.maintenance}>UNDER CONSTRUCTION</div>
        </div>
        <div className={styles.maintenancePageLoginWrapper}>
          <h1>MAINTENANCE HAS BEGUN</h1>
          <p>
            Please be patient while we work on some routine maintenance.
            <br />
            We should be back up by <b>{process.env.REACT_APP_MAINTENANCE_END_DATE}</b>
          </p>
        </div>
      </div>
    </div>
  );
}
