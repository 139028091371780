import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  MenuItem,
  MenuList,
  Popper,
} from "@material-ui/core";

export default function AddVideoButton(props) {
  let variant = props.videoIds.length > 0 ? "outlined" : "contained";
  let color = props.videoIds.length > 0 ? "default" : "primary";
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleMenuItemClick = (id) => {
    setOpen(false);
    if (id === "loading") return;
    props.setAddState(id);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  let menuOptions = [];
  menuOptions = [
    { id: "record", label: "Record/Upload Now" },
    { id: "search", label: "Search Existing Vids" },
    { id: "request", label: "Request New Video" },
  ];

  return (
    <div
      className={
        props.calledFromTask ? "row col-12" : "row col-12 justify-content-center"
      }>
      <ButtonGroup
        variant={variant}
        color={color}
        ref={anchorRef}
        aria-label="split button">
        <Button onClick={() => props.setAddState("record")}>
          <i className="material-icons">add</i>Add Video
        </Button>
        <Button
          color={color}
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}>
          <i className="material-icons">arrow_drop_down</i>
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={{ zIndex: "1301" }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}>
            <Paper style={{ zIndex: "1302" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {menuOptions.map(({ label, id }) => (
                    <MenuItem key={id} onClick={() => handleMenuItemClick(id)}>
                      {label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

AddVideoButton.propTypes = {
  setAddState: PropTypes.func.isRequired,
  videoIds: PropTypes.array,
  calledFromTask: PropTypes.bool,
};
