import React from "react";
import PropTypes from "prop-types";
import { Tooltip, IconButton } from "@material-ui/core";

export default function ProductsUsageMeter(props) {
  return (
    <div className="row col-12">
      {Object.keys(props.accountInfo.productCounts).map((productId) => {
        const product = props.accountInfo.productCounts[productId];
        const productLimit =
          props.subscription.plan === "enterprise" &&
          (productId === "videos" || productId === "users")
            ? "Unlimited"
            : product.limit;
        const usagePerc =
          productLimit === "Unlimited" ? 0 : (product.count / product.limit) * 100;

        return (
          <div style={{ marginRight: 20, marginBottom: 20, width: 250 }} key={productId}>
            <h3 style={{ textTransform: "capitalize" }}>
              {productId} ({product.count}/{productLimit})
              <Tooltip
                title={
                  <React.Fragment>
                    <span>
                      You have used {product.count} out of {productLimit} {productId}
                    </span>
                  </React.Fragment>
                }>
                <IconButton size="small">
                  <i
                    style={{ fontSize: "16px", color: "#8F949C" }}
                    className="material-icons">
                    info
                  </i>
                </IconButton>
              </Tooltip>
            </h3>
            <div
              style={{
                width: 250,
                height: 20,
                overflow: "hidden",
                display: "flex",
                borderRadius: 3,
              }}>
              <div style={{ width: usagePerc + "%", backgroundColor: "#24305e" }} />
              <div style={{ flex: 1, backgroundColor: "#bfcad8" }} />
            </div>
          </div>
        );
      })}
    </div>
  );
}

ProductsUsageMeter.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  subscription: PropTypes.object,
};
