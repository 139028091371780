import React from "react";
import { FormControl, Select, IconButton, InputLabel } from "@material-ui/core";
import SelectItem from "../../util/selectItem";
import GetListSelectList from "../../util/getListSelectList";

export default function Condition(props) {
  let { current, actionIndex, conditionId, actions } = props;
  let { metric, metricValue = "" } = current;

  let handleMetricValueChange = (metric) => {
    if (metric === "transactionAmount")
      handleItemChange("transactionAmount", "", "greaterEqual");
    if (metric === "isFirstTransaction")
      handleItemChange("isFirstTransaction", true, "equal");
    if (metric === "funds") handleListChange("funds", [], "includes");
    if (metric === "campuses") handleListChange("campuses", [], "includes");
  };

  let handleListChange = (metric, newSelectedItems, operator) => {
    let metricValue = {};
    newSelectedItems.forEach(({ id, label }) => {
      metricValue[id] = { id, label };
    });

    let item = { metric, metricValue, operator };
    let tempActions = { ...actions };
    tempActions.list[actionIndex].conditions[conditionId] = item;
    props.setActions(tempActions);
  };

  let handleItemChange = (metric, value, operator) => {
    let item = { metric, metricValue: value, operator };
    let tempActions = { ...actions };
    tempActions.list[actionIndex].conditions[conditionId] = item;
    props.setActions(tempActions);
  };

  let deleteCondition = () => {
    let tempActions = { ...actions };
    delete tempActions.list[actionIndex].conditions[conditionId];
    props.setActions(tempActions);
  };

  let currentCondition = actions.list[actionIndex].conditions[conditionId];
  let selectedItems =
    currentCondition && currentCondition.metricValue ? currentCondition.metricValue : [];

  let giftAmountOptions = [
    { id: 0, value: "0", label: "$0" },
    { id: 10, value: "10", label: "$10" },
    { id: 25, value: "25", label: "$25" },
    { id: 50, value: "50", label: "$50" },
    { id: 100, value: "100", label: "$100" },
    { id: 150, value: "150", label: "$150" },
    { id: 200, value: "200", label: "$200" },
    { id: 300, value: "300", label: "$300" },
    { id: 400, value: "400", label: "$400" },
    { id: 500, value: "500", label: "$500" },
    { id: 750, value: "750", label: "$750" },
    { id: 1000, value: "1000", label: "$1,000" },
    { id: 1500, value: "1500", label: "$1,500" },
    { id: 2000, value: "2000", label: "$2,000" },
    { id: 3000, value: "3000", label: "$3,000" },
    { id: 4000, value: "4000", label: "$4,000" },
    { id: 5000, value: "5000", label: "$5,000" },
    { id: 10000, value: "10000", label: "$10,000" },
    { id: 20000, value: "20000", label: "$20,000" },
    { id: 30000, value: "30000", label: "$30,000" },
    { id: 40000, value: "40000", label: "$40,000" },
    { id: 50000, value: "50000", label: "$50,000" },
    { id: 75000, value: "75000", label: "$75,000" },
    { id: 100000, value: "100000", label: "$100,000" },
    { id: 250000, value: "250000", label: "$250,000" },
    { id: 500000, value: "500000", label: "$500,000" },
    { id: 750000, value: "750000", label: "$750,000" },
    { id: 1000000, value: "1000000", label: "$1,000,000" },
    { id: 2000000, value: "2000000", label: "$2,000,000" },
    { id: 5000000, value: "5000000", label: "$5,000,000" },
  ];

  return (
    <div style={{ display: "flex", paddingTop: "5px" }}>
      <div
        style={{
          width: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
          {props.order === 0 ? "IF" : "AND IF"}
        </h3>
      </div>

      <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
        <FormControl style={{ width: "350px", paddingRight: "15px" }} variant="filled">
          <InputLabel id="conditionType">Select Condition</InputLabel>
          <Select
            value={metric}
            native
            onChange={(e) => handleMetricValueChange(e.target.value)}
            inputProps={{
              "aria-label": "Select condition type",
              name: "conditionType",
              id: "conditionType",
            }}>
            <option value="transactionAmount">
              The donation amount is greater than or equal to
            </option>
            <option value="isFirstTransaction">It's a persons first donation</option>
            <option value="funds">The donation is from any of these funds</option>
            <option value="campuses">The donors primary campus is any of these</option>
          </Select>
        </FormControl>

        {metric === "transactionAmount" && (
          <SelectItem
            options={giftAmountOptions}
            selectedItem={metricValue}
            handleChange={(value) =>
              handleItemChange("transactionAmount", value, "greaterEqual")
            }
            label="Amount"
            alt="Select gift amount"
            width="150px"
          />
        )}
        {metric === "funds" && (
          <GetListSelectList
            initialOptions="loading"
            optionsFetchNeeded={true}
            requestData={{ source: "planningCenter", event: "getFunds" }}
            accountId={props.accountId}
            selectedItems={selectedItems}
            handleChange={(value) => handleListChange("funds", value, "includes")}
            label="Funds"
            width="50%"
          />
        )}
        {metric === "campuses" && (
          <GetListSelectList
            initialOptions="loading"
            optionsFetchNeeded={true}
            requestData={{ source: "planningCenter", event: "getCampuses" }}
            accountId={props.accountId}
            selectedItems={selectedItems}
            handleChange={(value) => handleListChange("campuses", value, "includes")}
            label="Campuses"
            width="50%"
          />
        )}
      </div>
      <div
        style={{
          width: "70px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        {props.order > 0 && (
          <IconButton onClick={() => deleteCondition()} style={{ marginRight: "8px" }}>
            <i className="material-icons" style={{ fontSize: "18px" }}>
              close
            </i>
          </IconButton>
        )}
      </div>
    </div>
  );
}
