import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Checkbox, TextField, Chip, FormControl } from "@material-ui/core";
import { debounce } from "util/helpers";
import { manageIntegrations } from "services/api.service";

export default function GetListSelectList(props) {
  const {
    label = "",
    selectedItems = {},
    requestData = {},
    options: initialOptions = "loading",
    optionsFetchNeeded = false,
    variant = "filled",
    width = "50%",
  } = props; //selectedExcludes is all excludes for event ie - opportunityWon
  let [state, setState] = useState({}); //when select is open we change this to loading
  let [options, setOptions] = useState(initialOptions);

  let selectedItemsKeys = Object.keys(selectedItems);
  let tempDefaultValues = selectedItemsKeys.map((id) => {
    let current = selectedItems[id];
    return { id, label: current.label };
  });
  let [defaultValue] = useState(tempDefaultValues);

  let selectOpened = async (searchTerm) => {
    if (!optionsFetchNeeded) return;

    try {
      if (typeof searchTerm === "string") {
        requestData.searchTerm = searchTerm;
      }

      const results = await manageIntegrations(
        { ...requestData, accountId: props.accountId },
        requestData
      );

      setOptions(results?.results || []);
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.error(err);
      setState({ errorMessage });
    }
  };

  const debouncedSearch = useCallback(debounce(selectOpened, 500), [props]);

  function handleInputChange(evt, value) {
    if (props.searchOnInput) {
      setOptions("loading");
      debouncedSearch(value);
    }
  }

  let renderOptions = options === "loading" ? [] : options;

  return (
    <FormControl style={{ margin: "5px 0px", width }}>
      <Autocomplete
        multiple
        id={"select-" + label}
        selectOnFocus
        defaultValue={defaultValue}
        onOpen={selectOpened}
        loading={Boolean(options === "loading")}
        options={renderOptions}
        onInputChange={handleInputChange}
        onChange={(event, inputValue) => props.handleChange(inputValue)}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => value.id === option.id}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox checked={selected} />
            {option.label}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={variant}
            label={label}
            error={Boolean(state.errorMessage)}
            helperText={state.errorMessage}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={index}
              variant={variant === "filled" ? "outlined" : "filled"}
              label={option.label}
              size="small"
              {...getTagProps({ index })}
            />
          ))
        }
      />
    </FormControl>
  );
}

GetListSelectList.propTypes = {
  label: PropTypes.string,
  selectedItems: PropTypes.object,
  requestData: PropTypes.object,
  options: PropTypes.oneOf([PropTypes.array, PropTypes.string]),
  optionsFetchNeeded: PropTypes.bool,
  variant: PropTypes.string,
  width: PropTypes.string,
  searchOnInput: PropTypes.bool,
  handleChange: PropTypes.func,
  accountId: PropTypes.string.isRequired,
};
