import React, { useState } from "react";
import LoadingButton from "../../../../../util/buttons/loadingbutton";
import SelectItem from "../../util/selectItem";
import { updateAccountIntegrations } from "services/api.service";

export default function SaveConfig(props) {
  let { eventData, event = "" } = props;
  let { channel: initChannel = "Other", purpose: initPurpose = "Other" } = eventData;

  let [state, setState] = useState({});
  let [channel, setChannel] = useState(initChannel);
  let [purpose, setPurpose] = useState(initPurpose);

  let saveData = async () => {
    try {
      setState({ buttonState: "loading" });
      await updateAccountIntegrations([
        {
          path: `endpoint/bloomerang/events/${event}/channel`,
          value: channel,
        },
        {
          path: `endpoint/bloomerang/events/${event}/purpose`,
          value: purpose,
        },
      ]);
      setState({ buttonState: "saved" });
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  let channelOptions = [
    { id: "Email", value: "Email", label: "Email" },
    { id: "In Person", value: "In Person", label: "In Person" },
    { id: "Mail", value: "Mail", label: "Mail" },
    { id: "Mass Email", value: "Mass Email", label: "Mass Email" },
    { id: "Phone", value: "Phone", label: "Phone" },
    { id: "Social Media", value: "Social Media", label: "Social Media" },
    { id: "Text Message", value: "Text Message", label: "Text Message" },
    { id: "Website", value: "Website", label: "Website" },
    { id: "Other", value: "Other", label: "Other" },
  ];
  let purposeOptions = [
    { id: "Acknowledgement", value: "Acknowledgement", label: "Acknowledgement" },
    {
      id: "Impact/Cultivation",
      value: "Impact/Cultivation",
      label: "Impact/Cultivation",
    },
    { id: "Newsletter", value: "Newsletter", label: "Newsletter" },
    { id: "Pledge Reminder", value: "Pledge Reminder", label: "Pledge Reminder" },
    { id: "Receipt", value: "Receipt", label: "Receipt" },
    { id: "Solicitation", value: "Solicitation", label: "Solicitation" },
    { id: "Special Event", value: "Special Event", label: "Special Event" },
    {
      id: "Volunteer Activity",
      value: "Volunteer Activity",
      label: "Volunteer Activity",
    },
    { id: "Welcome", value: "Welcome", label: "Welcome" },
    { id: "Other", value: "Other", label: "Other" },
  ];

  return (
    <>
      <div style={{ marginRight: "15px" }}>
        <SelectItem
          options={channelOptions}
          selectedItem={channel}
          handleChange={(value) => setChannel(value)}
          label="Channel Type"
          alt="Select channel type"
          width="250px"
        />
      </div>

      <div style={{ marginRight: "15px" }}>
        <SelectItem
          options={purposeOptions}
          selectedItem={purpose}
          handleChange={(value) => setPurpose(value)}
          label="Purpose"
          alt="Select purpose"
          width="250px"
        />
      </div>

      <div className="col-12 justify-content-center" style={{ padding: "20px 0px" }}>
        <LoadingButton
          variant="contained"
          color="primary"
          buttonState={state.buttonState}
          savedLabel={"Saved"}
          label={"Save"}
          clickedRegularButton={saveData}
          clickedSavedButton={() => {
            setState({});
          }}
        />
      </div>
    </>
  );
}
