import React from "react";
import PropTypes from "prop-types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SendTestDialog from "../notes/editNote/sendNote/sendTestDialog";

export default function BirthdaySettings(props) {
  let { active, localSendTime, timezone } = props;

  return (
    <div className="row col-12 adSectionBox" style={{ marginBottom: "10px" }}>
      <div className="col-12">
        <h2>Activate Birthday Notes</h2>
        <p className="greyDesc">
          Automatically send birthday notes to your contacts on their birthday.
        </p>
      </div>

      <div className="col-12">
        <FormControl style={{ marginTop: "12px", width: "135px" }}>
          <InputLabel htmlFor="timezone">Time Zone</InputLabel>
          <Select
            value={timezone}
            native
            displayEmpty
            onChange={(e) =>
              props.updateBirthdaySettings(active, localSendTime, e.target.value)
            }
            inputProps={{ name: "timezone", id: "timezone" }}>
            <option value="" disabled></option>
            <option value={"America/Los_Angeles"}>Pacific Time</option>
            <option value={"America/Denver"}>Mountain Time</option>
            <option value={"America/Chicago"}>Central Time</option>
            <option value={"America/New_York"}>Eastern Time</option>
          </Select>
        </FormControl>
      </div>

      <div className="col-12">
        <FormControl style={{ marginTop: "12px", width: "135px" }}>
          <InputLabel htmlFor="localSendTime">Send Time</InputLabel>
          <Select
            value={localSendTime}
            native
            displayEmpty
            onChange={(e) =>
              props.updateBirthdaySettings(active, e.target.value, timezone)
            }
            inputProps={{ name: "localSendTime", id: "localSendTime" }}>
            <option value="" disabled></option>
            <option value={"8:30"}>8:30 am</option>
            <option value={"9:0"}>9:00 am</option>
            <option value={"9:30"}>9:30 am</option>
            <option value={"10:0"}>10:00 am</option>
            <option value={"10:30"}>10:30 am</option>
            <option value={"11:0"}>11:00 am</option>
            <option value={"11:30"}>11:30 am</option>
            <option value={"12:0"}>12:00 pm</option>
            <option value={"12:30"}>12:30 pm</option>
            <option value={"13:0"}>1:00 pm</option>
            <option value={"13:30"}>1:30 pm</option>
            <option value={"14:0"}>2:00 pm</option>
            <option value={"14:30"}>2:30 pm</option>
            <option value={"15:0"}>3:00 pm</option>
            <option value={"15:30"}>3:30 pm</option>
            <option value={"16:0"}>4:00 pm</option>
            <option value={"16:30"}>4:30 pm</option>
            <option value={"17:0"}>5:00 pm</option>
            <option value={"17:30"}>5:30 pm</option>
          </Select>
        </FormControl>
      </div>

      <div className="col-12" style={{ marginTop: "20px" }}>
        <FormControlLabel
          control={
            <Switch
              checked={active}
              onChange={(e) =>
                props.updateBirthdaySettings(e.target.checked, localSendTime, timezone)
              }
              value="active"
            />
          }
          label="Send Birthday Notes"
        />
      </div>

      <div className="col-12" style={{ marginTop: "20px" }}>
        <SendTestDialog {...props} videoIds={[true]} />
      </div>
    </div>
  );
}

BirthdaySettings.propTypes = {
  active: PropTypes.bool.isRequired,
  localSendTime: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  updateBirthdaySettings: PropTypes.func.isRequired,
};
