import React from "react";
import Navbar from "../../navbar/navbar";

export default function Security() {
  return (
    <>
      <Navbar />
      <div className="pagePadding termsText">
        <h1>Gratavid Security</h1>
        <p>
          This page has moved to{" "}
          <a href="https://gratavid.com/security">https://gratavid.com/security</a>
        </p>
      </div>
    </>
  );
}
