import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { IconButton, LinearProgress, Button } from "@material-ui/core";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Papa from "papaparse";
import { updateAccountContactsUnsubscribed } from "services/api.service";
import { isValidEmail } from "../../../../../src/util/helpers";

const { phone } = require("phone");

function BulkImportDialog(props) {
  let [state, setState] = useState({});

  const uploadFileNow = async (e) => {
    try {
      const file = e.target.files[0];
      const blobSize = file.size;
      const maxFileSize = 100 * 1024 * 1024;

      if (blobSize > maxFileSize) {
        throw new Error("File is too large. Must be smaller than 100mb.");
      }

      const results = await parseCsvFile(file);
      setState({ buttonState: "loading", errorMessage: null });
      processUnsubscribes(results.data);
    } catch (err) {
      const errorMessage = err.data?.message || err.message;
      console.error(errorMessage);
      setState({ buttonState: "regular", errorMessage });
    }
  };

  const parseCsvFile = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        transform: (val) => val.trim(),
        complete: (results) => resolve(results),
        error: (error) => reject(error),
      });
    });
  };

  const processUnsubscribes = async (data) => {
    try {
      const subscriptionActive = props.accountInfo.active;
      const emailsArray = [];
      const phoneArray = [];
      let uploadErrors = 0;
      const rowsWithErrors = [];

      if (!subscriptionActive || subscriptionActive === false) {
        throw new Error("Account subscription is not active");
      }

      let index = 1;
      for (const currentRow of data) {
        index++;
        if (props.type === "emails") {
          const email = currentRow.email;

          if (!email || !isValidEmail(email)) {
            rowsWithErrors.push(index);
            uploadErrors++;
            continue;
          }

          emailsArray.push(email.toLowerCase());
        } else if (props.type === "texts") {
          const cellNumber = phone(currentRow.cellNumber);

          if (cellNumber) {
            phoneArray.push(cellNumber.phoneNumber);
          } else {
            uploadErrors++;
            rowsWithErrors.push(index);
          }
        }
      }

      if (uploadErrors === 0) {
        await updateContactsUnsubscribed(props.type, emailsArray, phoneArray);
        setState({ buttonState: "saved" });
        props.setAddNewDialogOpen(false);
      } else {
        setState({
          errorMessage: `There were ${uploadErrors} errors. Make sure the following rows have correctly formatted emails or cell numbers: ${rowsWithErrors.join(
            ", "
          )}`,
          buttonState: "saved",
        });
      }

      return { data, uploadErrors };
    } catch (err) {
      const errorMessage = err?.data?.message || err?.message;
      setState({ buttonState: "regular", errorMessage });
    }
  };

  const updateContactsUnsubscribed = async (type, emails, phoneNumbers) => {
    if (type === "emails") {
      await updateAccountContactsUnsubscribed("email", emails);
    } else {
      await updateAccountContactsUnsubscribed("cellNumber", phoneNumbers);
    }

    props.onBulkImportDone();
  };

  let downloadTemplate = () => {
    let csvContent = props.type === "emails" ? "email\r\n" : "cellNumber\r\n";

    var blob = new Blob([csvContent]);
    if (window.navigator.msSaveOrOpenBlob) {
      // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
      window.navigator.msSaveBlob(blob, "contacts.csv");
    } else {
      var a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(blob, { type: "text/plain" });
      a.download = "unsubscribe_import.csv";
      document.body.appendChild(a);
      a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
      document.body.removeChild(a);
    }
  };

  if (!props.addNewDialogOpen) return null;

  const inputStyle = {
    uploadInput: {
      cursor: "pointer",
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      width: "100%",
      opacity: 0,
    },
  };

  return (
    <Dialog
      fullScreen={true}
      open={props.addNewDialogOpen}
      onClose={() => props.setAddNewDialogOpen(false)}
      aria-labelledby="add note dialog">
      <div style={{ minWidth: "250px", padding: "20px" }}>
        <div
          className="row col-12 pagePadding"
          style={{ padding: "20px", minWidth: "250px" }}>
          <div className="col-10">
            <h2>Import List</h2>
            <p className="greyDesc" style={{ fontSize: "14px" }}>
              Import a list of {props.type === "emails" ? "emails" : "cell numbers"} to
              add them to your unsubscribe/opt-out list.
            </p>
          </div>

          <div className="col-2 justify-content-end">
            <IconButton
              aria-label="close"
              onClick={() => props.setAddNewDialogOpen(false)}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>

          <div className="col-12" style={{ marginTop: "30px" }}>
            <h2>Step 1: Download template</h2>
            <button className="link-button" onClick={() => downloadTemplate()}>
              Download file template
            </button>
          </div>

          <div className="col-12" style={{ marginTop: "30px" }}>
            <h2>Step 2: Fill out template and upload</h2>
          </div>

          {state.errorMessage && (
            <div className="errorMessageBox">
              <b>{state.errorMessage}</b>
            </div>
          )}

          <div className="col-12 justify-content-center" style={{ padding: "20px 0px" }}>
            {state.buttonState === "loading" ? (
              <div className="col-12" style={{ padding: "20px" }}>
                <LinearProgress />
              </div>
            ) : (
              <Button variant="contained" color="primary">
                <i className="material-icons" style={{ marginRight: "10px" }}>
                  file_upload
                </i>
                <span>Upload File</span>
                <input
                  type="file"
                  accept=".csv"
                  id="uploadFile"
                  style={inputStyle.uploadInput}
                  onClick={(e) => (e.target.value = null)}
                  onChange={uploadFileNow}
                />
              </Button>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default withMobileDialog()(BulkImportDialog);
