import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import InfiniteScroll from "./infiniteScroll";
import FilterSearchFolders from "../../util/folders/search/filterSearchFolders";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { getAccountContacts, getAccountVideos, getNotes } from "services/api.service";
export default function SearchIndex(props) {
  let [searchTerm, setSearchTerm] = useState("");
  let [hideVidsCreatedByTask, setHideVidsCreatedByTask] = useState(true);
  let [searchTimer, setSearchTimer] = useState(null);
  let [state, setState] = useState({});
  let [foldersFilter, setFoldersFilter] = useState([]);
  let [searchLoading, setSearchLoading] = useState(null);
  let [searchState, setSearchState] = useState({ nbHits: 0, nbPages: 0, nextPage: 0 });

  useEffect(() => {
    //wait until folders are loaded to get search data

    //passing tempNextPage as 0 will force new search results
    let tempNextPage = 0;
    doSearch("", tempNextPage);

    return () => {
      if (searchTimer) clearTimeout(searchTimer);
    };
  }, [foldersFilter, props.resetSearch, hideVidsCreatedByTask]);

  let handleSearchTextField = (e) => {
    let tempSearchTerm = e && e.target && e.target.value ? e.target.value : "";
    setSearchTerm(tempSearchTerm);

    if (searchTimer) clearTimeout(searchTimer);

    let tempSearchTimer = setTimeout(() => {
      //passing tempNextPage as 0 will force new search results
      let tempNextPage = 0;
      doSearch(tempSearchTerm, tempNextPage);
    }, 500);

    setSearchTimer(tempSearchTimer);
    setSearchLoading(true);
  };

  let doSearch = async (searchTerm, tempNextPage) => {
    try {
      //have to pass in
      let { searchList, indexName } = props;
      tempNextPage = tempNextPage >= 0 ? tempNextPage : searchState.nextPage;
      setSearchLoading(true);

      const itemsPerPage = props.calledFromSearchExistingVid ? 5 : 20;
      const offset = itemsPerPage * tempNextPage;

      let data;

      if (indexName === "contacts") {
        data = await getAccountContacts(offset, itemsPerPage, searchTerm, foldersFilter);
      }
      if (indexName === "videos") {
        data = await getAccountVideos(
          searchTerm,
          hideVidsCreatedByTask,
          false,
          offset,
          itemsPerPage,
          foldersFilter
        );
      } else if (indexName === "notes") {
        data = await getNotes(offset, itemsPerPage, searchTerm, foldersFilter);
      }
      const nbHits = data.count ?? searchState.nbHits;
      const hits = data.rows || [];
      const nbPages = Math.ceil(nbHits / itemsPerPage);
      let page = tempNextPage;

      const list = page > 0 ? [...searchList, ...hits] : hits;
      props.setSearchList(list);

      page++;
      setSearchState({ nbPages: nbPages, nextPage: page, nbHits });
      setSearchLoading(false);
    } catch (err) {
      console.error(err.message);
      setSearchLoading(false);
      setState({ errorMessage: err.message });
    }
  };

  return (
    <div className="col-12">
      <div className="col-12 row">
        <div style={{ flex: 1, display: "flex" }}>
          <div style={{ flex: 1, display: "flex" }}>
            <div style={{ width: "30px" }} className="align-items-center">
              {searchLoading ? (
                <CircularProgress style={{ height: "20px", width: "20px" }} />
              ) : (
                <i className="material-icons" style={{ color: "grey" }}>
                  search
                </i>
              )}
            </div>
            <InputBase
              value={searchTerm}
              style={{ flex: 1, borderBottom: "1px #ddd solid" }}
              onChange={handleSearchTextField}
              placeholder={"Search " + props.indexName}
            />
          </div>

          <div style={{ width: "50px" }}>
            <FilterSearchFolders
              indexName={props.indexName}
              accountInfo={props.accountInfo}
              foldersFilter={foldersFilter}
              setFoldersFilter={setFoldersFilter}
            />
          </div>
        </div>
      </div>

      {state.errorMessage && (
        <div className="errorMessageBox">
          <b>{state.errorMessage}</b>
        </div>
      )}

      {props.indexName === "videos" && props.showHideTaskToggle && (
        <div style={{ padding: "10px 5px 5px 5px" }}>
          <FormControlLabel
            control={
              <Switch
                checked={hideVidsCreatedByTask}
                value={hideVidsCreatedByTask}
                onChange={(e) => setHideVidsCreatedByTask(e.target.checked)}
                name="hide videos created by a task"
              />
            }
            label={
              <span style={{ color: "grey", paddingLeft: "5px" }}>
                Hide task videos from search results
              </span>
            }
          />
        </div>
      )}

      <div className="col-12">
        <props.listComponent
          {...props}
          searchState={searchState}
          foldersFilter={foldersFilter}
          searchTerm={searchTerm}
          hideTaskVids={hideVidsCreatedByTask}
        />
      </div>

      {searchState.nextPage < searchState.nbPages && (
        <div className="col-12 justify-content-center" style={{ margin: "10px 0px" }}>
          <InfiniteScroll
            doSearch={doSearch}
            searchLoading={searchLoading}
            searchTerm={searchTerm}
            searchState={searchState}
            calledFromNotes={props.calledFromNotes}
          />
        </div>
      )}
    </div>
  );
}

SearchIndex.propTypes = {
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    searchSecuredApiKey: PropTypes.string,
  }).isRequired,
  indexName: PropTypes.string.isRequired,
  setSearchList: PropTypes.func.isRequired,
  searchList: PropTypes.any.isRequired,
  calledFromSearchExistingVid: PropTypes.bool,
  showHideTaskToggle: PropTypes.bool,
  showOnlyRequestedStatus: PropTypes.bool,
  resetSearch: PropTypes.number,
  calledFromNotes: PropTypes.any,
};
