import React from "react";
import { ListItemAvatar, ListItem, ListItemText, Avatar } from "@material-ui/core";

export default function Email(props) {
  let { current, type } = props;
  let {
    email,
    cellNumber,
    firstName,
    lastName,
    emailUnsubscribedDate,
    textUnsubscribedDate,
  } = current;
  let text = type === "emails" ? email : cellNumber + " " + firstName + " " + lastName;
  let icon = type === "emails" ? "unsubscribe" : "phonelink_erase";
  let date =
    type === "emails"
      ? emailUnsubscribedDate && new Date(emailUnsubscribedDate)
      : textUnsubscribedDate && new Date(textUnsubscribedDate);
  let showDate = date?.toLocaleString() || "";

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar>
          <i className="material-icons">{icon}</i>
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={text} secondary={"Opt-out date: " + showDate} />
    </ListItem>
  );
}
