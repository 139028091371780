import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteContactsDialog from "./deleteContactsDialog";
import EditItemFolders from "../../../util/folders/search/editItemFolders";
import timeSinceDate from "../../../util/functions/timeSinceDate";

const TrappTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "whitesmoke",
    maxWidth: 220,
    color: "black",
    fontSize: "12px",
    boxShadow:
      "0 1px 8px 0 rgba(0,0,0,.2), 0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12)",
    border: "2px solid darkgrey",
    padding: "10px",
  },
}))(Tooltip);

export default function ContactItem(props) {
  let [anchorEl, setAnchorEl] = useState(null);
  let [dialogOpen, setDialogOpen] = useState(false);
  let [editItemFoldersDialogOpen, setEditItemFoldersDialogOpen] = useState(false);

  let contactSelected = (contactId, currentContact) => {
    let tempSelectedContacts = { ...props.selectedContacts };
    if (tempSelectedContacts[contactId]) delete tempSelectedContacts[contactId];
    else tempSelectedContacts[contactId] = { ...currentContact };
    props.setSelectedContacts(tempSelectedContacts);
    props.setAllSelected(false);
  };

  let { lastEmailDate, lastTextDate, email, firstName, lastName, cellNumber } =
    props.currentContact;

  let latestSendDate;
  if (!lastEmailDate || typeof lastEmailDate === undefined) latestSendDate = lastTextDate;
  else if (!lastTextDate || typeof lastTextDate === undefined)
    latestSendDate = lastEmailDate;
  else latestSendDate = lastEmailDate > lastTextDate ? lastEmailDate : lastTextDate;

  let timeSinceLastNoteSent = timeSinceDate(
    latestSendDate,
    "Sent note ",
    "No note history"
  );

  firstName = firstName ? firstName : "";
  lastName = lastName ? lastName : "";

  let name = firstName + " " + lastName;
  let label = name === " " ? email : name;

  let { currentContact, selectedContacts, allSelected } = props;
  let { id, textOptIn } = currentContact;
  const contactId = id;
  let checked = selectedContacts[contactId] || allSelected ? true : false;

  let contactProfileHref = `/a/${props.accountId}/contacts/` + contactId;

  let editContact = () => props.history.push(contactProfileHref);

  let deleteContact = () => {
    setDialogOpen(true);
  };

  let editItemFolders = () => {
    setEditItemFoldersDialogOpen(true);
    setAnchorEl(null);
  };

  let menuOptions = [
    { label: "Edit Contact", action: editContact },
    { label: "Edit List", action: editItemFolders },
    { label: "Delete Contact", action: deleteContact },
  ];

  let menuList = menuOptions.map((current, index) => {
    return (
      <MenuItem
        key={index}
        button
        onClick={() => {
          current.action();
          setAnchorEl(null);
        }}>
        {current.label}
      </MenuItem>
    );
  });

  return (
    <div
      className="col-12 listItem-item"
      style={{
        display: "flex",
        height: "55px",
        padding: "4px",
        borderBottom: "1px #ddd solid",
        borderLeft: "2px transparent solid",
        borderRight: "2px transparent solid",
      }}>
      <div className="align-items-center" style={{ width: "40px" }}>
        <Checkbox
          checked={checked}
          size="small"
          color="default"
          style={{ padding: "5px" }}
          onClick={() => contactSelected(contactId, currentContact)}
        />
      </div>

      <div
        style={{ flex: 1, overflow: "hidden" }}
        className="row"
        onClick={() => contactSelected(contactId, currentContact)}>
        <div className="col-12">
          <TrappTip
            placement="top"
            title={
              <React.Fragment>
                {label}
                <br />
                {email}
                <br />
                {cellNumber}
              </React.Fragment>
            }>
            <Link
              className="mainlistItemTitle link"
              to={contactProfileHref}
              style={{ marginRight: "2px" }}>
              {label}
            </Link>
          </TrappTip>

          {textOptIn && (
            <TrappTip placement="top" title="Opted in to text">
              <IconButton
                size="small"
                aria-label="view contact analytics"
                style={{
                  height: "22px",
                  width: "22px",
                  padding: "0px",
                  marginRight: "4px",
                }}>
                <i
                  className="material-icons"
                  style={{ color: "green", fontSize: "16px" }}>
                  mobile_friendly
                </i>
              </IconButton>
            </TrappTip>
          )}
        </div>
        <div className="col-12">
          <span style={{ color: "grey", fontSize: "12px" }}>{timeSinceLastNoteSent}</span>
        </div>
      </div>

      <div
        className="align-items-center justify-content-end"
        style={{ width: "48px", marginRight: "10px" }}>
        <IconButton
          aria-label="More"
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}
          style={{ padding: "0px" }}>
          <i className="material-icons">more_vert</i>
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={anchorEl ? true : false}
          onClose={() => setAnchorEl(null)}
          PaperProps={{ style: { maxHeight: 200, width: 200 } }}>
          {menuList}
        </Menu>
      </div>

      {dialogOpen && (
        <DeleteContactsDialog
          selectedContacts={{ [contactId]: { email } }}
          setSelectedContacts={props.setSelectedContacts}
          allSelected={false}
          selectedCount={1}
          deleteDialogOpen={dialogOpen}
          setDeleteDialogOpen={setDialogOpen}
          searchList={props.searchList}
          setSearchList={props.setSearchList}
          accountId={props.accountId}
        />
      )}
      {editItemFoldersDialogOpen && (
        <EditItemFolders
          accountId={props.accountId}
          dialogTitle="Contact"
          setDialogOpen={setEditItemFoldersDialogOpen}
          currentItem={currentContact}
          indexName="contacts"
          dialogOpen={editItemFoldersDialogOpen}
        />
      )}
    </div>
  );
}

withRouter(ContactItem);

ContactItem.propTypes = {
  accountId: PropTypes.string.isRequired,
  currentContact: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    textOptIn: PropTypes.bool,
    lastEmailDate: PropTypes.string,
    lastTextDate: PropTypes.string,
    lastName: PropTypes.string,
    cellNumber: PropTypes.string,
  }).isRequired,
  searchList: PropTypes.array.isRequired,
  setSearchList: PropTypes.func.isRequired,
  selectedContacts: PropTypes.object.isRequired,
  setSelectedContacts: PropTypes.func.isRequired,
  setAllSelected: PropTypes.func.isRequired,
  allSelected: PropTypes.bool,
};
