import React from "react";
import "../../../../../style/videoPlayer.css";
import LoadingCircleSVG from "../../../../util/svg/loadingCircleSVG";
import Videos from "./videos/videos";
import BackgroundStyle from "./backgroundStyle";

export default function DisplayContent(props) {
  let { noteVideos, background, messageHtml, videoDataLastUpdated, showEmbedForm, logo } =
    props;

  let backgroundStyle = BackgroundStyle(background);
  backgroundStyle.borderRadius = "4px";

  const showButtons = props.buttons?.map((current, i) => {
    return (
      <div key={i} style={{ padding: "10px 0px", display: "flex", flex: 1 }}>
        <a
          href={current.link}
          target="_blank"
          rel="noopener noreferrer"
          className="noteButton justify-content-center"
          style={{ backgroundColor: current.color }}>
          <span style={{ color: "#fff" }}>{current.label}</span>
        </a>
      </div>
    );
  });

  return (
    <>
      <div
        className="row col-12 "
        style={backgroundStyle}
        key={background && background.id}
      />

      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div className="row justify-content-center" style={{ marginTop: "24px" }}>
          <div
            className="col-9 col-xl-8 box-shadow row"
            style={{
              position: "relative",
              backgroundColor: "#fff",
              borderRadius: "4px",
              paddingBottom: "25px",
              overflow: "hidden",
            }}>
            {noteVideos === "loading" ? (
              <div className="col-12">
                <div
                  className="col-12 justify-content-center"
                  style={{
                    height: "100%",
                    position: "relative",
                    minHeight: "550px",
                    backgroundColor: "grey",
                  }}>
                  <div
                    style={{
                      position: "absolute",
                      left: 0,
                      right: 0,
                      bottom: 0,
                      top: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "default",
                      zIndex: 900,
                    }}>
                    <div className="story">
                      <img src={props.logo} alt=" loading logo" />
                      <LoadingCircleSVG />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-12">
                <Videos
                  videos={noteVideos}
                  videoIds={props.videoIds}
                  noteId={props.noteId}
                  logo={logo}
                  showShare={props.showShare}
                  showDownload={props.showDownload}
                  videoDataLastUpdated={videoDataLastUpdated}
                />
              </div>
            )}

            <div
              className="col-12"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                padding: "5px",
                backgroundColor: "white",
                zIndex: 100,
              }}>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  overflow: "scroll",
                  padding: "10px",
                  flexDirection: "column",
                }}>
                <div className="col-12">
                  <div className="col-12" style={{ display: "flex" }}>
                    <div style={{ width: "60px" }}>
                      <img
                        src={logo}
                        width="50px"
                        alt="logo"
                        style={{ borderRadius: "50%", overflow: "hidden" }}
                      />
                    </div>
                    <div style={{ flex: 1 }} className="row align-items-start">
                      <div className="col-12">
                        <span style={{ fontSize: "18px", fontWeight: 500 }}>
                          {props.senderName}
                        </span>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          borderLeft: "2px #3F99F8 solid",
                          paddingLeft: "10px",
                          marginTop: "5px",
                        }}>
                        <span style={{ fontSize: "16px", whiteSpace: "pre-line" }}>
                          {messageHtml}
                        </span>
                      </div>
                      <div className="col-12">
                        {showEmbedForm ? (
                          <div style={{ marginTop: "20px" }}>
                            <p>
                              <span style={{ color: "grey" }}>
                                Embed form will show here
                              </span>
                            </p>
                            <p>
                              <span style={{ backgroundColor: "#ddd", color: "#ddd" }}>
                                Lorem ipsum dolor sit amet, consec tetur.
                              </span>
                            </p>
                            <p>
                              <span style={{ backgroundColor: "#ddd", color: "#ddd" }}>
                                Lorem ipsum dolor sit amet, consec tetur adipiscing elit.
                              </span>
                            </p>
                            <p>
                              <span style={{ backgroundColor: "#ddd", color: "#ddd" }}>
                                Lorem ipsum dolor sit amet.
                              </span>
                            </p>
                            <p>
                              <span style={{ backgroundColor: "#ddd", color: "#ddd" }}>
                                Lorem ipsum dolor sit amet, consec tetur.
                              </span>
                            </p>
                            <p>
                              <span style={{ backgroundColor: "#ddd", color: "#ddd" }}>
                                Lorem ipsum dolor sit amet, consec tetur adipiscing elit.
                              </span>
                            </p>
                          </div>
                        ) : (
                          showButtons
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
