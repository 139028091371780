import React, { useState } from "react";
import { Button } from "@material-ui/core";
import ShareByContactDialog from "./shareByContactDialog";

export default function PreviewOrEmebedByContact(props) {
  let { type } = props;
  let [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Button variant="text" onClick={() => setDialogOpen(true)}>
        {type === "embed" ? (
          <>
            <i
              className="material-icons"
              style={{ fontSize: "16px", marginRight: "3px" }}>
              file_copy
            </i>{" "}
            Embed Video
          </>
        ) : (
          <>
            Preview{" "}
            <i className="material-icons" style={{ fontSize: "15px" }}>
              launch
            </i>
          </>
        )}
      </Button>

      {dialogOpen && (
        <ShareByContactDialog
          {...props}
          accountId={props.accountId || props.accountInfo.id}
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
        />
      )}
    </>
  );
}
