import React, { useState } from "react";
import PropTypes from "prop-types";
import { IconButton, Switch } from "@material-ui/core/";
import { updateSelfRole } from "services/api.service";

export default function SectionItem(props) {
  let { settingNotifications, current } = props;
  let { title, desc, settingKey, settingIcon } = current;
  let [open, setOpen] = useState(false);
  let [state, setState] = useState({});

  let expansionPanelClass = open ? "expansionPanel" : "expansionPanel collapsible";

  let whenToReceive = [
    { channelKey: "all", title: current.allNotifDesc, icon: "group" },
    { channelKey: "onlyMine", title: current.onlyMeNotifDesc, icon: "person" },
  ];

  let channels = [
    {
      channelKey: "push",
      title: "Push (Chrome browser notifications)",
      icon: "notifications",
    },
    { channelKey: "email", title: "Email", icon: "email" },
  ];

  let settingToggled = async (settingKey, channelKey, checked) => {
    try {
      let tempNotifications = { ...props.notifications };
      let settingsNotifs = tempNotifications?.[settingKey] ?? {};

      tempNotifications[settingKey] = settingsNotifs;
      tempNotifications[settingKey][channelKey] = checked;
      props.setNotifications(tempNotifications);

      await updateSelfRole({ notifications: tempNotifications });
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  return (
    <div className="col-12 row adSectionBox" style={{ marginTop: "10px" }}>
      <div
        className="col-12 row"
        onClick={() => setOpen(!open)}
        style={{ cursor: "pointer" }}>
        <div style={{ width: "50px", display: "flex", alignItems: "center" }}>
          <i className="material-icons">{settingIcon}</i>
        </div>
        <div style={{ flex: 1 }}>
          <h2 style={{ fontSize: "16px" }}>{title}</h2>
          <span className="greyDesc" style={{ fontSize: "13px" }}>
            {desc}
          </span>
        </div>
        <div style={{ width: "50px" }}>
          <IconButton onClick={() => setOpen(!open)}>
            <i className="material-icons">{open ? "expand_less" : "expand_more"}</i>
          </IconButton>
        </div>
      </div>

      <div className={"col-12 row " + expansionPanelClass}>
        <div className="row col-12" style={{ marginTop: "15px" }}>
          <span style={{ fontWeight: 600, fontSize: "14px" }}>Get notified when:</span>
        </div>

        {whenToReceive.map((receive) => {
          let { channelKey, title, icon } = receive;
          let channelNotifs = settingNotifications[channelKey]
            ? settingNotifications[channelKey]
            : {};
          let checked = channelNotifs === true ? true : false;

          return (
            <div
              className="row col-12"
              style={{ borderBottom: "1px #ddd solid", padding: "10px 5px" }}
              key={channelKey}>
              <div style={{ width: "50px", display: "flex", alignItems: "center" }}>
                <i className="material-icons">{icon}</i>
              </div>
              <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                <h2 style={{ fontSize: "14px", fontWeight: "normal" }}>{title}</h2>
              </div>
              <div style={{ width: "50px" }}>
                <Switch
                  checked={checked}
                  value={checked}
                  onChange={(e) =>
                    settingToggled(settingKey, channelKey, e.target.checked)
                  }
                  inputProps={{ "aria-label": settingKey + "_" + channelKey }}
                />
              </div>
            </div>
          );
        })}

        <div className="row col-12" style={{ marginTop: "30px" }}>
          <span style={{ fontWeight: 600, fontSize: "14px" }}>
            Where you receive these notifications
          </span>
        </div>

        {channels.map((currentChannel) => {
          let { channelKey, title, icon } = currentChannel;
          let channelNotifs = settingNotifications[channelKey]
            ? settingNotifications[channelKey]
            : {};
          let checked = channelNotifs === true ? true : false;

          return (
            <div
              className="row col-12"
              style={{ borderBottom: "1px #ddd solid", padding: "10px 5px" }}
              key={channelKey}>
              <div style={{ width: "50px", display: "flex", alignItems: "center" }}>
                <i className="material-icons">{icon}</i>
              </div>
              <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                <h2 style={{ fontSize: "14px", fontWeight: "normal" }}>{title}</h2>
              </div>
              <div style={{ width: "50px" }}>
                <Switch
                  checked={checked}
                  value={checked}
                  onChange={(e) =>
                    settingToggled(settingKey, channelKey, e.target.checked)
                  }
                  inputProps={{ "aria-label": settingKey + "_" + channelKey }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

SectionItem.propTypes = {
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  current: PropTypes.shape({
    title: PropTypes.string.isRequired,
    allNotifDesc: PropTypes.string.isRequired,
    onlyMeNotifDesc: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    settingKey: PropTypes.string.isRequired,
    settingIcon: PropTypes.string.isRequired,
  }),
  notifications: PropTypes.object.isRequired,
  setNotifications: PropTypes.func.isRequired,
  settingNotifications: PropTypes.object.isRequired,
};
