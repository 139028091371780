import React, { useState } from "react";
import LoadingButton from "../../../../../util/buttons/loadingbutton";
import GetListSelectItem from "../../util/getListSelectItem";
import { updateAccountIntegrations } from "services/api.service";

export default function SaveConfig(props) {
  let { eventData, event = "" } = props;
  let { noteCategory: initNoteCategory = { label: "General", id: null } } = eventData;

  let [state, setState] = useState({});
  let [noteCategory, setNoteCategory] = useState(initNoteCategory);

  let saveData = async () => {
    try {
      setState({ buttonState: "loading" });
      let { id, label } = noteCategory;
      await updateAccountIntegrations([
        {
          path: `endpoint/planningCenter/events/${event}/noteCategory/id`,
          value: id,
        },
        {
          path: `endpoint/planningCenter/events/${event}/noteCategory/label`,
          value: label,
        },
      ]);
      setState({ buttonState: "saved" });
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  return (
    <>
      <div className="col-12">
        <GetListSelectItem
          initialOptions="loading"
          optionsFetchNeeded={true}
          requestData={{ source: "planningCenter", event: "getNoteCategories" }}
          accountId={props.accountInfo.id}
          selectedItem={noteCategory}
          handleChange={(value) => setNoteCategory(value)}
          label="Note Category"
          width="250px"
        />
      </div>

      <div className="col-12 justify-content-center" style={{ padding: "20px 0px" }}>
        <LoadingButton
          variant="contained"
          color="primary"
          buttonState={state.buttonState}
          savedLabel={"Saved"}
          label={"Save"}
          clickedRegularButton={saveData}
          clickedSavedButton={() => {
            setState({});
          }}
        />
      </div>
    </>
  );
}
