const tempImages = (primaryColor, secondaryColor) => ({
  logos: {
    defaultLogo_v1: {
      id: "defaultLogo_v1",
      name: "Gratavid Icon",
      imgSrc: "https://gratavid.com/static/img/icon_padded.jpg",
    },
  },
  backgrounds: {
    w1: {
      id: "w1",
      name: "White Background",
      type: "code",
      template: "white1",
      colorOne: "#fff",
    },
    c1: {
      id: "c1",
      name: "Primary Color",
      type: "code",
      template: "color1",
      colorOne: primaryColor,
    },
    c2: {
      id: "c2",
      name: "Secondary Color",
      type: "code",
      template: "color2",
      colorOne: secondaryColor,
    },
    c3: {
      id: "c3",
      name: "Brand Colors",
      type: "code",
      template: "color3",
      colorOne: primaryColor,
      colorTwo: secondaryColor,
    },
    c4: {
      id: "c4",
      name: "Brand Colors Gradient",
      type: "code",
      template: "color4",
      colorOne: primaryColor,
      colorTwo: secondaryColor,
    },
    i1: {
      id: "i1",
      name: "Graduation Confetti",
      type: "img",
      template: "image1",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/gratavid.appspot.com/o/util%2FbackgroundImages%2FgraduationConfetti.jpg?alt=media&token=07f17bc3-e17c-46cf-a908-277b4a2c091c",
    },
    i2: {
      id: "i2",
      name: "Fall Leaves",
      type: "img",
      template: "image1",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/gratavid.appspot.com/o/util%2FbackgroundImages%2Ffallleaves.jpg?alt=media&token=b0cc4676-70c5-4ce6-a957-d7b6dbdc5ffc",
    },
    i3: {
      id: "i3",
      name: "Stadium",
      type: "img",
      template: "image1",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/gratavid.appspot.com/o/util%2FbackgroundImages%2Fstadium.jpg?alt=media&token=ae8eb66b-63ce-4311-a0ea-b81acd1979c5",
    },
    i4: {
      id: "i4",
      name: "Books",
      type: "img",
      template: "image1",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/gratavid.appspot.com/o/util%2FbackgroundImages%2Fbooks.jpg?alt=media&token=4c7eb30c-5f1f-43bb-a767-39d07d11eaf8",
    },
  },
});

export default tempImages;