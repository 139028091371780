import React from "react";
import SelectUser from "../../../util/users/selectUser";

export default function CrmAssigneeBackupUser(props) {
  let {
    currentAction = {},
    topLevelLabel = "assignee",
    backupAssignUserSelected,
  } = props;
  let { backupAssignedTo = "" } = currentAction;

  return (
    <div className="row" style={{ display: "flex", marginTop: 10, flex: 1 }}>
      <div className="col-12">
        <p style={{ fontSize: 15 }}>
          If there is not {topLevelLabel}, then assign the task to
        </p>
      </div>

      <div className="col-12" style={{ marginTop: 2 }}>
        <SelectUser
          assignedTo={backupAssignedTo}
          {...props}
          userSelected={(userUID) => backupAssignUserSelected(false, userUID)}
          variant="filled"
          emptyLabel="unassigned"
          label="Select Backup Assignee"
        />
      </div>
    </div>
  );
}
