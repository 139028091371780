import React from "react";
import PropTypes from "prop-types";
import VideoReplyExpireDialog from "./videoReplyExpireDialog";

export default function VideoReplyItem(props) {
  let { replyText = "", createdDate, replyingAs = null } = props;
  let showDate = new Date(createdDate).toLocaleString() || "";

  return (
    <>
      <div className="row col-12" style={{ marginBottom: "25px" }}>
        <div className="col-12 justify-content-center" style={{ margin: "12px 0px" }}>
          <span
            style={{ fontSize: "12px", color: "rgba(0, 0, 0, .40)", fontWeight: 500 }}>
            {showDate}
          </span>
        </div>

        <div className="col-12 justify-content-start">
          <div className="align-items-center" style={{ marginRight: "5px" }}>
            <img
              src="/static/img/profile.svg"
              style={{ width: "28px" }}
              alt="generic contact profile pic"
            />
          </div>

          {replyText && (
            <div
              style={{
                backgroundColor: "#E5E5EA",
                borderRadius: "1.3em",
                padding: "6px 12px",
                maxWidth: "70%",
                borderBottomLeftRadius: "5px",
              }}>
              <span
                style={{
                  fontSize: "15px",
                  color: "#000",
                  wordWrap: "break",
                  whiteSpace: "pre-line",
                }}>
                {replyText}
              </span>
              {replyingAs ? (
                <span style={{ fontSize: "12px", color: "grey" }}>
                  <br />
                  replying as{" "}
                  <span style={{ fontWeight: "bold", fontSize: "12px" }}>
                    {replyingAs}
                  </span>
                </span>
              ) : null}
            </div>
          )}
        </div>

        <VideoReplyExpireDialog
          dateCreated={props.createdDate}
          replyVideoSrc={props.current.replyVideoSrc}
          noteId={props.current.noteId}
          replyId={props.current.id}
          convertedToVideoId={props.current.convertedToVideoId}
          contactData={props.contactData}
          accountId={props.accountInfo.id}
          history={props.history}
        />
      </div>
    </>
  );
}

VideoReplyItem.propTypes = {
  current: PropTypes.shape({
    replyVideoSrc: PropTypes.string,
    noteId: PropTypes.string,
    id: PropTypes.string,
    convertedToVideoId: PropTypes.string,
  }),
  contactData: PropTypes.object,
  accountInfo: PropTypes.object,
  history: PropTypes.object,
  replyText: PropTypes.string,
  replyingAs: PropTypes.string,
  createdDate: PropTypes.string,
};
