import React, { useState } from "react";
import BackgroundStyle from "../../account/notes/editNote/preview/backgroundStyle";
import { Button } from "@material-ui/core";
import { deleteAccountUpload } from "../../../services/api.service.js";

export default function ImageItem(props) {
  let [deleteBtnClickedOnce, setDeleteBtnClickedOnce] = useState(false);
  let { current, logo, type, images } = props;
  let { id, name, imgSrc } = current;
  let backgroundClass = logo === imgSrc ? "selectedNoteLogo" : "";

  let onDeleteUpload = async () => {
    try {
      props.setState({ buttonState: "loading" });
      await deleteAccountUpload(id);
      let tempImages = { ...images };
      delete tempImages[id];
      props.setImages(tempImages);
      props.setState({ buttonState: "regular" });
    } catch (err) {
      console.error(err.message);
      props.setState({ errorMessage: err.message });
    }
  };

  if (type === "logo") {
    return (
      <div style={{ padding: "5px" }}>
        <div className={backgroundClass}>
          <Button
            onClick={() => props.passedSetState("logoUrl", imgSrc)}
            style={{ width: "116px", height: "112px" }}
            aria-label={name}
            alt={name}>
            <img src={imgSrc} alt={name} width="100%" style={{ borderRadius: "50%" }} />
          </Button>
        </div>

        {id === "defaultLogo_v1" ? null : (
          <div className="justify-content-center">
            {deleteBtnClickedOnce ? (
              <Button
                variant="contained"
                color="secondary"
                style={{ textTransform: "none" }}
                onClick={onDeleteUpload}>
                <span style={{ fontSize: "12px" }}>Click to delete</span>
              </Button>
            ) : (
              <Button
                style={{ textTransform: "none" }}
                onClick={() => setDeleteBtnClickedOnce(true)}>
                <span style={{ fontSize: "12px" }}>Delete</span>
              </Button>
            )}
          </div>
        )}
      </div>
    );
  } else {
    const { current, background } = props;
    const backgroundStyle = BackgroundStyle(current);
    backgroundStyle.position = "relative";
    backgroundStyle.height = "100px";
    backgroundStyle.minHeight = "100px";
    backgroundStyle.width = "100px";
    backgroundStyle.borderRadius = "2px";
    const backgroundClass =
      background.id === current.id ? "selectedBackgroundImage" : "selectBackgroundImage";

    return (
      <div style={{ padding: "5px" }}>
        <div>
          <Button
            onClick={() => props.passedSetState("background", current)}
            aria-label={background.name}
            alt={background.name}>
            <div style={backgroundStyle} className={backgroundClass} />
          </Button>
        </div>

        {!current.name && (
          <div className="justify-content-center">
            {deleteBtnClickedOnce ? (
              <Button
                variant="contained"
                color="secondary"
                style={{ textTransform: "none" }}
                onClick={onDeleteUpload}>
                <span style={{ fontSize: "12px" }}>Click to delete</span>
              </Button>
            ) : (
              <Button
                style={{ textTransform: "none" }}
                onClick={() => setDeleteBtnClickedOnce(true)}>
                <span style={{ fontSize: "12px" }}>Delete</span>
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }
}
