export default function BackgroundStyle(background) {
  const style = {
    minHeight: "105vh",
    WebkitBackgroundSize: "cover",
    MozBackgroundSize: "cover",
    OBackgroundSize: "cover",
    backgroundSize: "cover",
    overflow: "inherit",
    position: "absolute",
    top: "0px",
  };

  if (background) {
    const { colorOne = "#fff", colorTwo = "#fff", template } = background;

    if (template === "color1") {
      style.backgroundColor = colorOne;
    } else if (template === "color2") {
      style.backgroundColor = colorOne;
    } else if (template === "color3") {
      style.background =
        "linear-gradient(20deg, " + colorOne + " 45%, " + colorTwo + " 45%)";
    } else if (template === "color4") {
      style.background =
        "linear-gradient(0deg, " + colorOne + " 5%, " + colorTwo + " 95%)";
    } else if (template === "white1") {
      style.backgroundColor = "#fff";
    } else if (background.imgSrc) {
      style.background = "url(" + background.imgSrc + ") left top / cover no-repeat";
    }
  }
  return style;
}
