import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import { getAccountIntegrations } from "services/api.service";

export default function Kindful(props) {
  let [appEnvironment, setAppEnvironment] = useState("app");
  let { integrationDetails, connectionInfo } = props;

  const connectionInterval = useRef(null);

  useEffect(() => {
    connectionInterval.current = setInterval(async () => {
      const integrations = await getAccountIntegrations();
      if (integrations?.endpoint?.kindful?.connectionInfo) {
        props.setConnectionInfo(integrations?.endpoint?.kindful?.connectionInfo);
        clearInterval(connectionInterval.current);
        connectionInterval.current = null;
      }
    }, 5000);

    return () => {
      if (connectionInterval?.current) {
        clearInterval(connectionInterval.current);
      }
    };
  }, []);

  let { header, logoSrc, brandHex } = integrationDetails;

  let openPopupAuth = async () => {
    try {
      let redirect_uri =
        `${process.env.REACT_APP_API_ENDPOINT}vendors/integrations-endpoint?source=kindful%26event=authenticate%26appEnvironment=` +
        appEnvironment;
      let scope = "basic%20data_add%20data_query";
      let authorizeUrl =
        "https://" + appEnvironment + ".kindful.com/admin/oauth2/authorize";
      let client_id =
        appEnvironment === "app-playground"
          ? "fa0bf8a80345027a1d12041bd89bf190a721ff1f96858f1fcfdec2b6797a9a5e"
          : "d1b63ac88294957aca19cf0987eb61a268b102c68429235eac7113c2d3d95997";
      let bbFullAuthURL =
        authorizeUrl +
        "?client_id=" +
        client_id +
        "&response_type=code&redirect_uri=" +
        redirect_uri +
        "&state=" +
        props.userInfo.id +
        "_" +
        props.accountInfo.id +
        "&scope=" +
        scope;
      let popup = window.open(bbFullAuthURL, "login", "height=650,width=600,");
      if (window.focus) popup.focus();
    } catch (err) {
      console.error(err.message);
    }
  };

  if (connectionInfo === "loading")
    return (
      <div className="col-12" style={{ padding: "20px" }}>
        {" "}
        <LinearProgress />
      </div>
    );

  return (
    <div className="col-12 row">
      <div
        style={{
          width: "70px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <div
          style={{
            marginRight: "8px",
            width: "45px",
            height: "45px",
            backgroundColor: brandHex,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <img src={logoSrc} width="50px" alt="logo" />
        </div>
      </div>
      <div style={{ flex: 1 }} className="row">
        <div className="col-12">
          <h1>{header}</h1>
          <span style={{ display: "flex", alignItems: "center" }}>
            <i
              className="material-icons"
              title="check icon"
              style={{ color: "red", fontSize: "25px", marginRight: "4px" }}>
              cancel
            </i>
            Your account is not connected.
          </span>
        </div>

        <div className="col-12" style={{ marginTop: 25 }}>
          <FormControl style={{ width: "350px" }} variant="filled">
            <InputLabel id="conditionType">Select App Environment</InputLabel>
            <Select
              value={appEnvironment}
              native
              onChange={(e) => setAppEnvironment(e.target.value)}
              inputProps={{
                "aria-label": "Select App Environment",
                name: "appEnvironment",
                id: "appEnvironment",
              }}>
              <option value="app">Production</option>
              <option value="app-playground">Playground</option>
            </Select>
          </FormControl>
        </div>

        <div className="col-12" style={{ marginTop: 5 }}>
          <Button
            onClick={() => openPopupAuth()}
            variant="contained"
            color="primary"
            style={{ marginTop: "10px" }}>
            Connect To Kindful
          </Button>
        </div>
      </div>
    </div>
  );
}
