import React, { useState, useEffect } from "react";
import { LinearProgress } from "@material-ui/core";
import SentItem from "./item";
import LoadingButton from "../../../../util/buttons/loadingbutton";
import { getNoteHistory, getTasks } from "services/api.service";

export default function ScheduledHistory(props) {
  let [scheduledItems, setScheduledItems] = useState([]);
  let [state, setState] = useState({});
  let [loadMore, setLoadMore] = useState(null);
  let [isLoading, setIsLoading] = useState(null);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const getScheduledItems = async (isInit) => {
    try {
      const limit = 10;
      let startIndex = offset;
      setIsLoading(true);
      if (isInit) {
        startIndex = 0;
        setScheduledItems("loading");
      }

      if (props.calledFromTask) {
        const data = await getTasks("", "SCHEDULED", {
          sortBy: "scheduledDate",
          sortDirection: "asc",
          limit,
          offset: startIndex,
        });

        if (isInit) {
          setTotalItems(data.count);
          setScheduledItems(data.rows);
        } else {
          setScheduledItems([...scheduledItems, ...data.rows]);
        }
      } else {
        const data = await getNoteHistory(props.noteId, limit, offset, true, "scheduled");
        if (isInit) {
          setTotalItems(data.count);
          setScheduledItems(data.rows);
        } else {
          setScheduledItems([...scheduledItems, ...data.rows]);
        }
      }

      setIsLoading(false);
      setOffset(startIndex + limit);
    } catch (err) {
      console.error(err.message);
      setScheduledItems([]);
      setState({ errorMessage: err.message });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getScheduledItems(true);
  }, []);

  useEffect(() => {
    //this useEffect is called when the show more button is clicked
    if (!loadMore) {
      return;
    }

    getScheduledItems();
  }, [loadMore]);

  if (scheduledItems === "loading") {
    return (
      <div className="col-12" style={{ padding: "20px" }}>
        <LinearProgress />
      </div>
    );
  }

  let showSendItems = scheduledItems.map((scheduledItem, index) => {
    return (
      <SentItem
        key={scheduledItem.id}
        current={scheduledItem}
        index={index}
        {...props}
        scheduledItems={scheduledItems}
        setScheduledItems={setScheduledItems}
        totalItems={totalItems}
        setTotalItems={setTotalItems}
      />
    );
  });

  if (scheduledItems.length === 0) {
    return (
      <div style={{ padding: "20px" }}>
        {state.errorMessage && (
          <div className="col-12 errorMessageBox">
            <b>{state.errorMessage}</b>
          </div>
        )}
        No items scheduled
      </div>
    );
  }

  return (
    <div
      className="row col-12 pagePadding align-items-start"
      style={{ backgroundColor: "rgb(243, 244, 244)", minHeight: "100vh" }}>
      {state.errorMessage && (
        <div className="col-12 errorMessageBox">
          <b>{state.errorMessage}</b>
        </div>
      )}

      <div className="row col-12 align-items-start">{showSendItems}</div>

      {scheduledItems.length < totalItems && (
        <div
          className="col-12 justify-content-center"
          style={{ margin: "10px 0px 20px 0px" }}>
          <LoadingButton
            type="button"
            variant="outlined"
            size="small"
            color="default"
            buttonState={isLoading ? "loading" : null}
            savedLabel={"Loaded"}
            label="Show more"
            clickedRegularButton={() => setLoadMore(Date.now())}
            clickedSavedButton={() => {
              return null;
            }}
          />
        </div>
      )}

      <div style={{ height: "100px" }} className="col-12" />
    </div>
  );
}
