import React, { useState } from "react";
import PropTypes from "prop-types";
import Authenticate from "./authenticate";
import DeAuthenticateDialog from "./deAuthenticateDialog";
import IntegrationHeader from "../integration-header";
import TaskSent from "./triggers/taskSent";
import NoteWatched from "./triggers/noteWatched";
import SaveReply from "./triggers/saveReply";
import GiftAdded from "./actions/giftAdded";

export default function Virtuous(props) {
  let { integrationDetails, data = {} } = props;
  let { events = {} } = data;
  let initialConnectionInfo = data?.connectionInfo ?? "loading";
  let [connectionInfo, setConnectionInfo] = useState(initialConnectionInfo);

  if (!connectionInfo || connectionInfo === "loading")
    return (
      <Authenticate
        connectionInfo={connectionInfo}
        setConnectionInfo={setConnectionInfo}
        integrationId="virtuous"
        {...props}
      />
    );

  return (
    <div className="col-12 row">
      <IntegrationHeader
        integrationDetails={integrationDetails}
        connectionInfo={connectionInfo}>
        <DeAuthenticateDialog {...props} />
      </IntegrationHeader>

      <GiftAdded {...props} eventData={events?.giftAdded} />
      <TaskSent {...props} eventData={events?.taskSent} />
      <SaveReply {...props} eventData={events?.reply} />
      <NoteWatched {...props} eventData={events?.noteWatched} />

      <div className="col-12" style={{ height: "100px" }} />
    </div>
  );
}
Virtuous.propTypes = {
  integrationDetails: PropTypes.shape({
    header: PropTypes.string,
    logoSrc: PropTypes.string,
    brandHex: PropTypes.string,
  }),
  data: PropTypes.shape({}),
};
