import React from "react";
import { Tooltip, FormControlLabel, Checkbox } from "@material-ui/core";
import CheckBoxToApi from "components/util/hooks/input/checkBoxToApi";
import TextInputToApi from "components/util/hooks/input/textInputToApi";

export default function TextMessageOptions(props) {
  let { shareViaText, noteContent, atleastOneContactOptedInToText } = props;
  let { textMessageBody } = noteContent;

  let setShareViaText = async (field, value) => {
    //update state at editNote
    props.setShareViaText(value);
    props.onUpdateTask(field, value);
  };

  if (atleastOneContactOptedInToText) {
    return (
      <>
        <div className="col-12" style={{ marginTop: "5px" }}>
          <CheckBoxToApi
            initialValue={props.noteContent.shareViaText}
            field="shareViaText"
            apiCall={setShareViaText}
            label="Send Text"
            bold={true}
          />
        </div>

        {shareViaText && (
          <>
            <div className="col-12" style={{ marginTop: "15px" }}>
              <TextInputToApi
                initialValue={textMessageBody}
                maxLength={70}
                customContactProperties={props.customContactProperties}
                field="textMessageBody"
                apiCall={props.onUpdateTask}
                label="Text Message"
                variant="outlined"
              />
            </div>
            <span className="col-12" style={{ height: "10px" }} />
          </>
        )}
      </>
    );
  } else {
    return (
      <div className="col-12" style={{ marginTop: "5px" }}>
        <Tooltip title="No contacts opted in to text" placement="bottom">
          <FormControlLabel
            disabled
            control={<Checkbox checked={false} value={false} />}
            label="Send text message"
          />
        </Tooltip>
      </div>
    );
  }
}
