import React from "react";
import PropTypes from "prop-types";
import VideoItem from "../videoItem/videoItem";
import BulkActionForSearchTableHeader from "../../../util/search/bulkActionForSearchTableHeader";
import Checkbox from "@material-ui/core/Checkbox";

export default function ListVideos(props) {
  if (props.searchList === "loading") return null;

  const selectAll = () => {
    props.setSelectedItems([]);
    props.setAllSelected(!props.allSelected);
  };

  const listVids = props.searchList.map((video) => (
    <VideoItem
      key={video.id}
      accountId={props.accountInfo.id}
      video={video}
      selectedItems={props.selectedItems}
      setSelectedItems={props.setSelectedItems}
      allSelected={props.allSelected}
      setAllSelected={props.setAllSelected}
      calledFromSearchExistingVid={props.calledFromSearchExistingVid}
      videoSelectedForNote={props.videoSelectedForNote}
      searchList={props.searchList}
      setSearchList={props.setSearchList}
      history={props.history}
    />
  ));

  //turn table background red if something selected
  let tableHeadBackgroundColor =
    props.allSelected || props.selectedItems.length > 0 ? "#fbe0ea" : null;

  return (
    <>
      <div className="row col-12" style={{ padding: "5px 0px" }}>
        <p style={{ color: "grey" }}>
          Showing {props.searchList.length} out of {props.searchState?.nbHits ?? 0}&nbsp;
          results
        </p>
      </div>

      {!props.calledFromSearchExistingVid && (
        <div
          className="row col-12"
          style={{
            display: "flex",
            padding: "4px",
            border: "2px #999 solid",
            borderRadius: "2px",
            backgroundColor: tableHeadBackgroundColor,
          }}>
          <div style={{ width: "40px" }} className="align-items-center">
            <Checkbox
              checked={props.allSelected}
              onClick={() => selectAll()}
              size="small"
              style={{ padding: "5px" }}
            />
          </div>

          <div style={{ flex: 1 }} className="align-items-center">
            <b style={{ color: "#999" }}>Title</b>
          </div>

          <div style={{ width: "150px" }} className="row">
            <BulkActionForSearchTableHeader
              accountId={props.accountInfo.id}
              indexName="videos"
              selectedItems={props.selectedItems}
              setSelectedItems={props.setSelectedItems}
              searchState={props.searchState}
              searchList={props.searchList}
              setSearchList={props.setSearchList}
              hideTaskVids={props.hideTaskVids}
              allSelected={props.allSelected}
              fullScreen={props.fullScreen}
            />
          </div>
        </div>
      )}

      <div className="row col-12">{listVids}</div>
    </>
  );
}

const videoType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  status: PropTypes.string,
  title: PropTypes.string.isRequired,
  createdDate: PropTypes.string,
});

ListVideos.propTypes = {
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  searchList: PropTypes.oneOfType([PropTypes.arrayOf(videoType), PropTypes.string])
    .isRequired,
  setSearchList: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(videoType).isRequired,
  setSelectedItems: PropTypes.func,
  setAllSelected: PropTypes.func,
  searchState: PropTypes.shape({
    nbHits: PropTypes.number,
  }),
  hideTaskVids: PropTypes.bool,
  allSelected: PropTypes.bool,
  calledFromSearchExistingVid: PropTypes.bool,
  fullScreen: PropTypes.bool,
  videoSelectedForNote: PropTypes.func,
};
