// TODO Remove references to this in leu of src/utils/contacts.js
import { useEffect, useState } from "react";
import defaultContactProperties from "../data/defaultContactProperties.json";
import { useGetAccountCustomProperties } from "./useGetAccountCustomProperties";

export const useGetContactProperties = (accountInfo) => {
  // State and setter for search term
  let [contactProperties, setContactProperties] = useState("loading");
  let [accountCustomProperties] = useGetAccountCustomProperties(accountInfo);

  useEffect(() => {
    if (accountCustomProperties === "loading") return;

    let tempDefaultContactProperties = { ...defaultContactProperties };
    let accountCustomPropertiesKeys = Object.keys(accountCustomProperties);
    accountCustomPropertiesKeys.forEach((id) => {
      let current = accountCustomProperties[id];
      let label = current && current.label ? current.label : id;
      let item = { id: id, columnHeader: id, label: label, mapped: false };
      tempDefaultContactProperties[id] = item;
    });

    setContactProperties(tempDefaultContactProperties);
  }, [accountCustomProperties]);

  return [contactProperties];
};
