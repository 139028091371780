import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Checkbox } from "@material-ui/core";

export default function CheckBoxToApi(props) {
  const [inputValue, setInputValue] = useState(props.initialValue);
  const [errorMessage, setErrorMessage] = useState("");

  const onChange = (event) => {
    const value = event.target.checked;
    setInputValue(value);
    setErrorMessage(null);

    props.apiCall(props.field, value).catch((err) => {
      setErrorMessage(err.message);
    });
  };

  return (
    <>
      <FormControlLabel
        control={
          errorMessage ? (
            <i className="material-icons" style={{ marginRight: "5px", color: "red" }}>
              error
            </i>
          ) : (
            <Checkbox checked={inputValue} value={inputValue} onChange={onChange} />
          )
        }
        label={
          <div
            className="align-items-center"
            style={{ fontWeight: props.bold ? "bold" : "normal" }}>
            {props.label}
          </div>
        }
      />

      {errorMessage && (
        <div className="errorMessageBox">
          <b>{errorMessage}</b>
        </div>
      )}
    </>
  );
}

CheckBoxToApi.propTypes = {
  field: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  apiCall: PropTypes.func.isRequired,
  initialValue: PropTypes.bool,
  bold: PropTypes.bool,
};
