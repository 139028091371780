import React, { useState } from "react";
import PropTypes from "prop-types";
import SearchIndex from "../../util/search/searchIndex";
import ListNotes from "./listNotes/listNotes";
import AddNoteDialog from "./addNote/addNoteDialog";
import Button from "../../util/button/button";
import { Tooltip } from "@material-ui/core";
import QuestionToLink from "../../util/explainers/questionToLink";
export default function Notes(props) {
  let [searchList, setSearchList] = useState([]);
  let [addNewDialogOpen, setAddNewDialogOpen] = useState(false);

  return (
    <div className="col-12 row">
      <AddNoteDialog
        {...props}
        accountId={props.accountInfo.id}
        addNewDialogOpen={addNewDialogOpen}
        setAddNewDialogOpen={setAddNewDialogOpen}
      />

      <div className="row col-12" style={{ marginBottom: "10px" }}>
        <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
          <h1>Note Templates</h1>
          <QuestionToLink id="note" desc="notes" />
        </div>
        {!props.userRole.isNoteEditor ? (
          <Tooltip title="You do not have permission to create a new note. Please contact your account admin if you have questions.">
            <span>
              <Button
                disabled
                variant="contained"
                color="primary"
                type="icon"
                startIcon={<i className="material-icons">add</i>}>
                New Note
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAddNewDialogOpen(true)}
            startIcon={<i className="material-icons">add</i>}>
            New Note
          </Button>
        )}
      </div>

      <div className="row col-12">
        <SearchIndex
          {...props}
          setSearchList={setSearchList}
          searchList={searchList}
          indexName="notes"
          listComponent={ListNotes}
        />
      </div>
    </div>
  );
}

Notes.propTypes = {
  userRole: PropTypes.shape({
    isNoteEditor: PropTypes.bool.isRequired,
  }),
};
