import React, { useState } from "react";
import PropTypes from "prop-types";
import SearchIndex from "../../util/search/searchIndex";
import ListVideos from "./listVideos/listVideos";
import AddVideoDialog from "./addVideo/addVideoDialog";
import QuestionToLink from "../../util/explainers/questionToLink";
import Button from "../../util/button/button";

export default function Videos(props) {
  let [searchList, setSearchList] = useState("loading");
  let [addNewDialogOpen, setAddNewDialogOpen] = useState(false);
  let [selectedItems, setSelectedItems] = useState([]);
  let [allSelected, setAllSelected] = useState(false);

  return (
    <div className="col-12 row">
      <AddVideoDialog
        accountInfo={props.accountInfo}
        setAddNewDialogOpen={setAddNewDialogOpen}
        addNewDialogOpen={addNewDialogOpen}
        userIsAdmin={props.userIsAdmin}
        fullScreen={props.fullScreen}
      />

      <div className="row col-12" style={{ marginBottom: "10px" }}>
        <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
          <h1>Videos</h1>
          <QuestionToLink id="video" desc="videos" />
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setAddNewDialogOpen(true)}
          startIcon={<i className="material-icons">add</i>}>
          New Video
        </Button>
      </div>

      <div className="row col-12">
        <SearchIndex
          {...props}
          showHideTaskToggle={true}
          setSearchList={setSearchList}
          searchList={searchList}
          indexName="videos"
          allSelected={allSelected}
          setAllSelected={setAllSelected}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          listComponent={ListVideos}
        />
      </div>
    </div>
  );
}

Videos.propTypes = {
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  userIsAdmin: PropTypes.bool,
  fullScreen: PropTypes.bool,
};
