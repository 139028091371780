import React, { useState } from "react";
import PropTypes from "prop-types";
import { replyEmail, replyText } from "services/api.service";
import {
  TextField,
  InputAdornment,
  FormControlLabel,
  Tooltip,
  Checkbox,
} from "@material-ui/core";
import LoadingButton from "../../../../util/buttons/loadingbutton";

export default function ReplyBox(props) {
  const [message, setMessage] = useState("");
  const [isText, setIsText] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [buttonState, setButtonState] = useState(null);

  let sendReply = async () => {
    try {
      if (message === "") throw new Error("Please add your reply");

      setButtonState("loading");

      let reply = {};
      if (isText) {
        reply = await replyText(props.contactId, message);
      } else {
        reply = await replyEmail(props.contactId, message);
      }

      setMessage("");
      setErrorMessage(null);
      setButtonState("saved");

      //now add reply back to chat
      props.onReply && props.onReply({ ...reply, type: "appReply" });
    } catch (err) {
      const errorMessage = err?.data?.message || err?.message;
      console.error(errorMessage);
      setErrorMessage(errorMessage);
      setButtonState(null);
    }
  };

  const { textEnabled, textOptIn } = props;
  const textAllowed = textEnabled && textOptIn;
  const textDisabledLabel = !textEnabled
    ? "Subscription plan does not include texting"
    : "Contact not opted in to text";
  const tooltip = "Send contact a text message";

  return (
    <div>
      <div style={{ display: "flex", paddingBottom: "5px" }}>
        <Tooltip placement="top" title={textAllowed ? tooltip : textDisabledLabel}>
          <FormControlLabel
            disabled={!textAllowed}
            control={
              <Checkbox
                onChange={(e) => setIsText(e.target.checked)}
                checked={isText}
                style={{ height: "15px" }}
              />
            }
            label="Reply via text"
          />
        </Tooltip>
      </div>

      <div>
        <TextField
          fullWidth
          autoComplete="off"
          multiline
          disabled={!textAllowed}
          placeholder={"Write your reply..."}
          id="reply input"
          minRows={2}
          variant="outlined"
          error={!!errorMessage}
          helperText={errorMessage}
          FormHelperTextProps={{ style: { margin: "2px 0px 0px 0px" } }}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position={"end"}>
                {textAllowed ? (
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    size="small"
                    color="primary"
                    buttonState={buttonState}
                    savedLabel={"Sent"}
                    label="Send"
                    clickedRegularButton={() => sendReply()}
                    clickedSavedButton={() => {
                      setButtonState(null);
                    }}
                  />
                ) : (
                  <Tooltip placement="top" title={textDisabledLabel}>
                    <span>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        size="small"
                        color="primary"
                        buttonState={buttonState}
                        savedLabel={"Sent"}
                        label="Send"
                        disabled={true}
                      />
                    </span>
                  </Tooltip>
                )}
              </InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  );
}

ReplyBox.propTypes = {
  accountId: PropTypes.string.isRequired,
  contactId: PropTypes.string.isRequired,
  onReply: PropTypes.func,
  textEnabled: PropTypes.bool,
  textOptIn: PropTypes.bool,
};
