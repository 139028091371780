import React, { useState } from "react";
import LoadingButton from "../../../../util/buttons/loadingbutton";
import {
  Dialog,
  withMobileDialog,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import gcISOLanguages from "../../../../util/data/gcISOLanguages";
import { addCaptionsLanguage } from "services/api.service";

function AddLanguageDialog(props) {
  let { captions = {} } = props;
  let [dialogOpen, setDialogOpen] = useState(false);
  let [state, setState] = useState(false);
  let [sourceISOLanguageCode, setSourceISOLanguageCode] = useState("");
  let [targetISOLanguageCode, setTargetISOLanguageCode] = useState("");

  let createTranslation = async (e) => {
    try {
      setState({ buttonState: "loading" });
      if (sourceISOLanguageCode === "" || targetISOLanguageCode === "")
        throw new Error("Please select source and target language.");
      let result = await addCaptionsLanguage(
        props.videoId,
        sourceISOLanguageCode,
        targetISOLanguageCode
      );
      let { newLanguageData, newISOLanguageCode } = result;

      //add language back under caption
      let tempCaptions = { ...captions };
      tempCaptions.languages.list[newISOLanguageCode] = newLanguageData;
      props.passedSetEditVideoState(tempCaptions);

      setState({ buttonState: "saved" });
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.error(errorMessage);
      setState({ buttonState: "regular", errorMessage });
    }
  };

  let languagesList = captions.languages ? captions.languages.list : {};
  let keys = languagesList ? Object.keys(languagesList) : [];

  let sourceOptionsHTML = keys.map((currentCode) => {
    const isoLanguageCode =
      props.captions.languages.list[currentCode].primaryISOLanguageCode;
    const language = gcISOLanguages.itemsMap[isoLanguageCode]?.language || "NA";
    return (
      <option key={currentCode} value={currentCode}>
        {language}
      </option>
    );
  });

  let optionsHTML = gcISOLanguages.list.map((current) => {
    let id = current["ISO-639-1"];
    let label = current.language;
    return (
      <option key={id} value={id}>
        {label}
      </option>
    );
  });

  let closeDialog = () => {
    setState({});
    setDialogOpen(false);
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setDialogOpen(true)}>
        Add A New Language
      </Button>

      {dialogOpen && (
        <Dialog
          fullScreen={props.fullScreen}
          open={dialogOpen}
          onClose={closeDialog}
          aria-labelledby="add language dialog">
          <DialogContent
            style={{
              height: props.fullScreen ? "auto" : "550px",
              width: props.fullScreen ? "auto" : "600px",
            }}>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                <span style={{ fontSize: "18px" }}>Add A New Language</span>
              </div>
              <div style={{ width: "48px" }}>
                <IconButton aria-label="close" onClick={closeDialog}>
                  <i className="material-icons">close</i>
                </IconButton>
              </div>
            </div>

            <div className="col-12" style={{ marginTop: "10px" }}>
              <FormControl style={{ width: "235px" }}>
                <InputLabel shrink htmlFor="language-native-select">
                  Select Source Captions
                </InputLabel>
                <Select
                  value={sourceISOLanguageCode}
                  native
                  onChange={(e) => setSourceISOLanguageCode(e.target.value)}
                  inputProps={{ id: "language-native-select" }}>
                  <option value="" disabled>
                    Select language
                  </option>
                  {sourceOptionsHTML}
                </Select>
              </FormControl>
            </div>

            <div className="col-12" style={{ marginTop: "25px" }}>
              <FormControl style={{ width: "235px" }}>
                <InputLabel shrink htmlFor="language-native-select">
                  Select New Language
                </InputLabel>
                <Select
                  labelWidth={235}
                  value={targetISOLanguageCode}
                  native
                  onChange={(e) => setTargetISOLanguageCode(e.target.value)}
                  inputProps={{ id: "language-native-select" }}>
                  <option value="" disabled>
                    Select language
                  </option>
                  {optionsHTML}
                </Select>
              </FormControl>
            </div>

            {state.errorMessage && (
              <div className="col-12 errorMessageBox">
                <b>{state.errorMessage}</b>
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
            <Button variant="outlined" onClick={closeDialog}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              buttonState={state.buttonState}
              savedLabel={"Created"}
              label={"Create Now"}
              clickedRegularButton={createTranslation}
              clickedSavedButton={closeDialog}
            />
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default withMobileDialog()(AddLanguageDialog);
