import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  InputBase,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import LoadingButton from "../../../../../util/buttons/loadingbutton";
import UserItem from "../../assignees/userItem";
import QuestionToLink from "../../../../../util/explainers/questionToLink";
import { patchTask, remindUsers } from "services/api.service";

export default function AssignTo(props) {
  let { dialogOpen, selectedCount = 0 } = props;
  let [state, setState] = useState({ buttonState: "regular" });
  let [searchTerm, setSearchTerm] = useState("");
  let [selectedAssignee, setSelectedAssignee] = useState({ userUID: "", label: "" });
  let [searchUsers, setSearchUsers] = useState("loading");
  let [notifyAssignee, setNotifyAssignee] = useState(true);

  useEffect(() => {
    if (searchTerm === "") {
      setSearchUsers(props.users);
      return;
    }

    const lowerCaseTerm = searchTerm.toLowerCase();
    setSearchUsers(
      props.users.filter(
        (user) =>
          user.fullName.toLowerCase().includes(lowerCaseTerm) ||
          user.email.toLowerCase().includes(lowerCaseTerm)
      )
    );
  }, [searchTerm, props.users]);

  let saveAssignee = async (e) => {
    try {
      e.preventDefault();
      setState({ buttonState: "loading" });

      const selectedTaskIds = props.selectedItems.map((selectedItem) => selectedItem.id);
      if (selectedTaskIds.length) {
        await Promise.all(
          selectedTaskIds.map((selectedTaskId) =>
            patchTask(selectedTaskId, {
              assignedUserId: selectedAssignee.userUID || null,
            })
          )
        );

        if (selectedAssignee.userUID && notifyAssignee) {
          const user = props.users.find(
            (user) => user.userUID === selectedAssignee.userUID
          );
          user.taskCount = selectedTaskIds.length;
          user.type = user.externalUser ? "external" : "internal";
          await remindUsers([user]);
        }
      }

      props.setEditTaskSelected(null);
      props.selectedItems.forEach(
        (task) => (task.assignedUserId = selectedAssignee.userUID || null)
      );
      props.setTasks([...props.tasks]);
      setState({ buttonState: "saved" });
      props.setSelectedItems([]);
      props.setAllSelected(false);
    } catch (err) {
      console.error(err.message);
      setState({ buttonState: "regular", errorMessage: err.message });
    }
  };

  let taskTitleLabel = selectedCount > 1 ? selectedCount + " Tasks" : "Task";
  let title = "Assign " + taskTitleLabel + " To A User";
  let buttonLabel = notifyAssignee ? "Assign & Notify" : "Assign";
  let buttonSavedLabel = notifyAssignee ? "Assigned & Notified" : "Assigned";
  let notifyCheckboxDisabled = state.buttonState === "regular" ? false : true;

  return (
    <Dialog
      fullScreen={props.fullScreen}
      open={dialogOpen}
      onClose={props.closeDialog}
      aria-labelledby="assign task to a user dialog">
      <DialogContent
        style={{ minHeight: "500px", width: props.fullScreen ? "100%" : "600px" }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "18px" }}>{title}</span>
            <QuestionToLink id="taskAssignee" desc="task assignees" />
          </div>
          <div style={{ width: "48px" }}>
            <IconButton aria-label="close" onClick={props.closeDialog}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        {state.errorMessage && (
          <div className="col-12 errorMessageBox">
            <b>{state.errorMessage}</b>
          </div>
        )}

        <div
          style={{
            marginTop: "5px",
            flex: 1,
            display: "flex",
            backgroundColor: "hsl(220, 12%, 95%)",
            padding: "12px 6px",
            borderRadius: "3px",
          }}>
          <div style={{ width: "30px" }} className="align-items-center">
            <i className="material-icons" style={{ color: "grey" }}>
              search
            </i>
          </div>
          <InputBase
            value={searchTerm}
            style={{ flex: 1 }}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search users"
          />
        </div>

        <div className="col-12 row" style={{ padding: "5px 0px 20px 0px" }}>
          {searchUsers === "loading" ? (
            <>
              <div
                style={{ width: "60%", height: "20px", marginTop: "12px" }}
                className="col-7 loading-pulse"
              />
              <div
                style={{ width: "60%", height: "20px", marginTop: "12px" }}
                className="col-7 loading-pulse"
              />
              <div
                style={{ width: "60%", height: "20px", marginTop: "12px" }}
                className="col-7 loading-pulse"
              />
            </>
          ) : (
            <>
              {searchTerm === "" && (
                <UserItem
                  key="unassigned"
                  currentUser={{ label: "Unassigned", userUID: "" }}
                  selectedAssignee={selectedAssignee}
                  setSelectedAssignee={setSelectedAssignee}
                />
              )}

              {searchUsers.map((user) => {
                return (
                  <UserItem
                    key={user.userUID}
                    currentUser={user}
                    selectedAssignee={selectedAssignee}
                    setSelectedAssignee={setSelectedAssignee}
                  />
                );
              })}
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
        {selectedAssignee && selectedAssignee.userUID && (
          <FormControlLabel
            control={
              <Checkbox
                checked={notifyAssignee}
                onChange={(e) => setNotifyAssignee(e.target.checked)}
                disabled={notifyCheckboxDisabled}
              />
            }
            label={"Notify " + selectedAssignee.label}
          />
        )}

        <LoadingButton
          type="button"
          variant="contained"
          color="primary"
          buttonState={state.buttonState}
          savedLabel={buttonSavedLabel}
          label={buttonLabel}
          clickedRegularButton={saveAssignee}
          clickedSavedButton={props.closeDialog}
        />
      </DialogActions>
    </Dialog>
  );
}
