import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import { toFullName } from "util/helpers";

const TrappTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "white",
    maxWidth: 500,
    color: "black",
    fontSize: "12px",
    boxShadow:
      "0 1px 8px 0 rgba(0,0,0,.2), 0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12)",
    padding: "20px",
  },
}))(Tooltip);

export default function ContactItem(props) {
  const { contact, mergeFields } = props;

  let icon = "";
  let iconColor = "";
  let toolTipTitle;

  //if contact has optedIn, make sure they are still eligible and haven't been text within 3 days
  if (contact.textOptIn) {
    //if it's been atleast 3 days since last text
    iconColor = "green";
    icon = "mobile_friendly";
    toolTipTitle = "Opted in to text.";
  } else {
    iconColor = "red";
    icon = "phonelink_erase";
    toolTipTitle = "Not opted in to text.";
  }

  if (contact.emailOptOut) {
    iconColor = "red";
    icon = "error";
    toolTipTitle =
      "Can't send email because contact opted out of email (" +
      contact.emailOptOutReason +
      "). " +
      toolTipTitle;
  }

  const label = toFullName(contact.firstName, contact.lastName, contact.email);

  return (
    <Chip
      style={{
        margin: "3px",
        height: "24px",
        borderRadius: "5px",
        backgroundColor: "rgb(240, 241, 250)",
        border: "1px solid rgb(180, 187, 232)",
      }}
      label={
        <>
          {contact.id === "deletedCount" ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ fontSize: "13px" }}>{label}</span>
            </div>
          ) : (
            <TrappTip
              placement="top"
              title={
                <React.Fragment>
                  <span style={{ fontSize: "18px", marginBottom: "4px" }}>{label}</span>
                  <br />
                  <span style={{ fontSize: "14px", color: "grey" }}>{contact.email}</span>
                  <br />
                  <span style={{ fontSize: "14px", color: "grey" }}>
                    {contact.cellNumber}
                  </span>
                  <div>
                    <i
                      className="material-icons"
                      style={{ fontSize: "12px", color: iconColor, marginRight: "3px" }}>
                      {icon}
                    </i>
                    <span style={{ fontSize: "12px", color: "grey" }}>
                      {toolTipTitle}
                    </span>
                  </div>
                  {mergeFields && (
                    <>
                      <br />
                      {mergeFields.map((field) => {
                        let value = contact[field] ? contact[field] : null;
                        if (!value) return null;
                        return (
                          <span key={field} color="inherit">
                            <span
                              style={{
                                textTransform: "uppercase",
                                fontSize: "10px",
                                color: "grey",
                                lineHeight: "12px",
                              }}>
                              {field}
                            </span>
                            <br />
                            <span style={{ fontSize: "12px", lineHeight: "12px" }}>
                              {value}
                            </span>
                            <br />
                          </span>
                        );
                      })}
                    </>
                  )}
                </React.Fragment>
              }>
              <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                {contact.textOptIn && (
                  <i
                    className="material-icons"
                    style={{ fontSize: "18px", color: "green", marginRight: "3px" }}>
                    mobile_friendly
                  </i>
                )}
                {contact.emailOptOut && (
                  <i
                    className="material-icons"
                    style={{ fontSize: "18px", color: "red", marginRight: "3px" }}>
                    error
                  </i>
                )}
                <span style={{ fontSize: "13px" }}>{label}</span>
              </div>
            </TrappTip>
          )}
        </>
      }
      onDelete={props.onRemoveContact && (() => props.onRemoveContact(props.contact))}
    />
  );
}

ContactItem.propTypes = {
  contact: PropTypes.object.isRequired,
  mergeFields: PropTypes.array,
  onRemoveContact: PropTypes.func,
};
