export default function milestoneYearsOptions() {
  let yearsToShow = [
    2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 25, 30, 35, 40,
    45, 50,
  ];
  let options = yearsToShow.map((current) => {
    return { id: current, value: current.toString(), label: current + " years" };
  });
  return options;
}
