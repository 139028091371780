import React, { useState } from "react";
import PropTypes from "prop-types";
import { IconButton, TextField } from "@material-ui/core/";
import LoadingButton from "../../../util/buttons/loadingbutton";
import { updatePassword } from "../../../../services/api.service";

export default function Password(props) {
  let [password, setPassword] = useState("");
  let [newPassword, setNewPassword] = useState("");
  let [newPasswordTwo, setNewPasswordTwo] = useState("");
  let [open, setOpen] = useState(false);
  let [state, setState] = useState({});

  let updateFormPassword = async (e) => {
    if (e) e.preventDefault();
    setState({ buttonState: "loading" });

    try {
      if (password === "") {
        throw new Error("Please insert current password");
      } else if (newPassword === "") {
        throw new Error("Please insert new password");
      } else if (newPassword.length < 8) {
        throw new Error("Password needs to be at least 8 characters");
      } else if (newPasswordTwo === "") {
        throw new Error("Please re-insert new password");
      } else if (newPassword !== newPasswordTwo) {
        throw new Error("Passwords must match");
      } else {
        setState({ buttonState: "loading" });
        await updatePassword(password, newPassword);
        setState({ buttonState: "saved" });
      }
    } catch (err) {
      let errorMessage = err.data?.message || err.message;

      if (err?.data?.status === 403) {
        errorMessage = err.data.message;
      }

      setState({ buttonState: "regular", errorMessage: errorMessage });
    }
  };

  let resetState = (e) => {
    if (e) e.preventDefault();
    setPassword("");
    setNewPassword("");
    setNewPasswordTwo("");
    setState({});
  };

  let expansionPanelClass = open ? "expansionPanel" : "expansionPanel collapsible";

  //if signed in with sso then don't give option to change Gratavid password
  let { ssoProvider } = props;
  if (ssoProvider) return null;

  return (
    <div className="col-12 row adSectionBox" style={{ marginTop: "15px" }}>
      <div
        className="col-12 row"
        onClick={() => setOpen(!open)}
        style={{ cursor: "pointer" }}>
        <div style={{ width: "50px", display: "flex", alignItems: "center" }}>
          <i className="material-icons">lock</i>
        </div>
        <div style={{ flex: 1 }}>
          <h2 style={{ fontSize: "16px" }}>Your Password</h2>
          <span className="greyDesc" style={{ fontSize: "13px" }}>
            Change your password.
          </span>
        </div>
        <div style={{ width: "50px" }}>
          <IconButton onClick={() => setOpen(!open)}>
            <i className="material-icons">{open ? "expand_less" : "expand_more"}</i>
          </IconButton>
        </div>
      </div>

      <form
        className={"col-12 row " + expansionPanelClass}
        onSubmit={(e) => updateFormPassword(e)}>
        <div className="col-12" style={{ padding: "20px 0px" }}>
          <TextField
            label="Current Password"
            type="password"
            placeholder="******"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className="col-12">
          <TextField
            label="New Password"
            type="password"
            placeholder="******"
            variant="outlined"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>

        <div className="col-12" style={{ padding: "20px 0px 10px 0px" }}>
          <TextField
            label="Re-enter new password"
            type="password"
            placeholder="******"
            variant="outlined"
            value={newPasswordTwo}
            onChange={(e) => setNewPasswordTwo(e.target.value)}
          />
        </div>

        {state.errorMessage ? (
          <div className="errorMessageBox">
            <b>{state.errorMessage}</b>
          </div>
        ) : null}

        <div className="col-12">
          <LoadingButton
            variant="contained"
            type="submit"
            color="primary"
            buttonState={state.buttonState}
            savedLabel={"Success!"}
            label={"Update Password"}
            clickedRegularButton={(e) => updateFormPassword(e)}
            clickedSavedButton={(e) => resetState(e)}
          />
        </div>
      </form>
    </div>
  );
}

Password.propTypes = {
  ssoProvider: PropTypes.object,
};
