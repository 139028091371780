import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import QuestionToLink from "../../../../util/explainers/questionToLink";
import CheckBoxToApi from "components/util/hooks/input/checkBoxToApi";
import TextInputToApi from "components/util/hooks/input/textInputToApi";
import SelectInputToApi from "components/util/hooks/input/selectInputToApi";

export default function ResendSettings(props) {
  let [open, setOpen] = useState(false);
  let [emailBodyCustomContactProperties, setEmailBodyCustomContactProperties] = useState(
    []
  );
  let [emailBodyError, setEmailBodyError] = useState(null);
  let expansionPanelClass = open
    ? "expansionPanel-1000"
    : "expansionPanel-1000 collapsible";

  useEffect(() => {
    //body properties need to include the gratavid url and note sender name
    let tempEmailBodyCustomContactProperties = [...props.customContactProperties];
    tempEmailBodyCustomContactProperties.push({
      dv: "https://gratavid.com/",
      label: "Gratavid Link",
      id: "gratavidLink",
    });
    tempEmailBodyCustomContactProperties.push({
      dv: "Our team",
      label: "Sender Name",
      id: "senderName",
    });
    setEmailBodyCustomContactProperties(tempEmailBodyCustomContactProperties);
  }, [props.customContactProperties]);

  function checkEmailBodyForLink(state, value) {
    if (!value.includes("%gratavidLink%"))
      setEmailBodyError("Email body must include %gratavidLink%");
    else {
      setEmailBodyError(null);
      return props.passedSetState(state, value);
    }
  }

  let resendOptions = [
    { value: "1", label: "Resend in 1 day" },
    { value: "2", label: "Resend in 2 days" },
    { value: "3", label: "Resend in 3 days" },
    { value: "4", label: "Resend in 4 days" },
    { value: "5", label: "Resend in 5 days" },
    { value: "6", label: "Resend in 6 days" },
    { value: "7", label: "Resend in 7 days" },
  ];

  return (
    <div className="col-12 row adSectionBox" style={{ marginTop: "10px" }}>
      <div
        className="col-12 row"
        onClick={() => setOpen(!open)}
        style={{ cursor: "pointer" }}>
        <div style={{ flex: 1 }}>
          <div className="col-12 row">
            <div className="col-12 row align-items-center">
              <h2 style={{ marginRight: "2px" }}>Resend</h2>
              <QuestionToLink id="resendSettings" desc="resend settings" size="small" />
            </div>
            <div className="col-12">
              <span className="greyDesc">Resend settings and template</span>
            </div>
          </div>
        </div>
        <div style={{ width: "50px" }}>
          <IconButton onClick={() => setOpen(!open)}>
            <i className="material-icons">{open ? "expand_less" : "expand_more"}</i>
          </IconButton>
        </div>
      </div>

      <div className={"col-12 row " + expansionPanelClass}>
        <div className="col-12" style={{ marginTop: "5px" }}>
          <CheckBoxToApi
            initialValue={props.resendActive}
            field="resendActive"
            apiCall={props.passedSetState}
            label="Auto resend if email not opened"
          />
        </div>

        <div className="col-12" style={{ marginTop: "15px" }}>
          <SelectInputToApi
            label="Delay"
            field="resendInXDays"
            initialValue={props.resendInXDays}
            options={resendOptions}
            apiCall={(state, value) => props.passedSetState(state, +value)}
            variant="standard"
          />
        </div>

        <div className="col-12 " style={{ margin: "25px 0px 30px 0px" }}>
          <TextInputToApi
            initialValue={props.resendEmailSubject}
            multiline={false}
            customContactProperties={props.customContactProperties}
            field="resendEmailSubject"
            apiCall={props.passedSetState}
            label="Email Subject"
            variant="standard"
          />
        </div>

        <div className="col-12 " style={{ margin: "5px 0px 30px 0px" }}>
          <TextInputToApi
            initialValue={props.resendEmailBody}
            multiline={true}
            customContactProperties={emailBodyCustomContactProperties}
            apiCall={checkEmailBodyForLink}
            label="Email Body"
            variant="standard"
            field="resendEmailBody"
            parentErrorMessage={emailBodyError}
            helperText="Note: Gratavid will automatically add the correct link in place of %gratavidLink%"
          />
        </div>
      </div>
    </div>
  );
}

ResendSettings.propTypes = {
  passedSetState: PropTypes.func,
  resendActive: PropTypes.bool,
  resendInXDays: PropTypes.number,
  resendEmailSubject: PropTypes.string,
  resendEmailBody: PropTypes.string,
  customContactProperties: PropTypes.array,
};
