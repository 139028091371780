import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { LinearProgress } from "@material-ui/core";
import SentItem from "./item";
import LoadingButton from "../../../../util/buttons/loadingbutton";
import { getNoteHistory } from "services/api.service";

export default function SendHistory(props) {
  let [sendItems, setSendItems] = useState([]);
  let [count, setCount] = useState(0);
  let [isLoading, setIsLoading] = useState(false);
  let [state, setState] = useState({});

  const getItems = useCallback(
    (offset) => {
      setIsLoading(true);
      getNoteHistory(props.noteId, 11, offset, true)
        .then((noteHistory) => {
          setSendItems((items) => [...items, ...noteHistory.rows]);
          setCount(noteHistory.count);
        })
        .catch((err) => {
          console.error(err.message);
          setSendItems([]);
          setState({ errorMessage: err.message });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [props.noteId]
  );

  useEffect(() => {
    getItems(0);
  }, [getItems]);

  function loadMore() {
    getItems(sendItems.length);
  }

  if (isLoading && sendItems.length === 0) {
    return (
      <div className="col-12" style={{ padding: "20px" }}>
        <LinearProgress />
      </div>
    );
  }

  let showSendItems = sendItems.map((current, index) => {
    return <SentItem key={current.id} {...current} index={index} {...props} />;
  });

  if (sendItems.length === 0) {
    return (
      <div style={{ padding: "0px 20px 20px 20px" }}>
        {state.errorMessage && (
          <div className="col-12 errorMessageBox">
            <b>{state.errorMessage}</b>
          </div>
        )}
        No send history
      </div>
    );
  }

  return (
    <div
      className="row col-12 pagePadding align-items-start"
      style={{ minHeight: "100vh" }}>
      {state.errorMessage && (
        <div className="col-12 errorMessageBox">
          <b>{state.errorMessage}</b>
        </div>
      )}

      <div className="row col-12 align-items-start">{showSendItems}</div>

      {sendItems.length < count && (
        <div
          className="col-12 justify-content-center"
          style={{ margin: "10px 0px 20px 0px" }}>
          <LoadingButton
            type="button"
            variant="outlined"
            size="small"
            color="default"
            buttonState={isLoading ? "loading" : null}
            savedLabel={"Loaded"}
            label="Show more"
            clickedRegularButton={() => loadMore()}
            clickedSavedButton={() => {
              return null;
            }}
          />
        </div>
      )}
    </div>
  );
}

SendHistory.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  noteId: PropTypes.string.isRequired,
  analytics: PropTypes.object,
};
