import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Tabs,
  Tab,
  withStyles,
  BottomNavigation,
  BottomNavigationAction,
} from "@material-ui/core";
import Button from "../../util/button/button";
import ListHistory from "./listHistory/listHistory";
import ListTask from "./listTask/listTask";
import AddTask from "./addTask/addTask";
import QuestionToLink from "../../util/explainers/questionToLink";
import ScheduledHistory from "../notes/editNote/scheduledHistory/scheduledHistory";
import { useGetAccountCustomProperties } from "../../util/contacts/useGetAccountCustomProperties";
import { getAccountUsers } from "services/api.service";
import style from "./task.module.css";

function a11yProps(index) {
  return {
    id: `task-tab-${index}`,
    "aria-controls": `task-tabpanel-${index}`,
  };
}

const AntTabs = withStyles({
  indicator: {
    backgroundColor: "#f76c6c",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    marginRight: "10px",
  },
  selected: {},
}))((props) => <Tab {...props} />);

async function getUsers(accountId, requestedByUserId, gratavidUploadToken) {
  const users = await getAccountUsers("all");
  return users.map((user) => ({
    id: user.id,
    fullName: user.fullName,
    email: user.email,
    userUID: user.id,
    label: user.fullName ?? user.email,
    userToken: user.uploadToken,
    externalUser:
      user.roles?.find((role) => role.accountId === accountId)?.userType === "external",
    assigneeLink: `${process.env.REACT_APP_FRONTEND}/tasklist?a=${accountId}&rbu=${requestedByUserId}&u=${user.id}&at=${gratavidUploadToken}&ut=${user.uploadToken}`,
  }));
}

export default function Task(props) {
  const { accountInfo, userInfo } = props;

  let [tabSelected, setTabSelected] = useState(0);
  let [addNewDialogOpen, setAddNewDialogOpen] = useState(false);
  let [users, setUsers] = useState([]);

  useEffect(() => {
    const grabUsers = async () => {
      const fetchedUsers = await getUsers(
        accountInfo.id,
        userInfo.id,
        accountInfo.gratavidUploadToken
      );

      setUsers(fetchedUsers);
    };
    grabUsers();
  }, [accountInfo.id, userInfo.id]);

  let [accountCustomProperties, customContactProperties] = useGetAccountCustomProperties(
    props.accountInfo
  );

  const tabs = [
    {
      label: "Tasks",
      icon: "list",
    },
    {
      label: "Scheduled",
      icon: "schedule",
    },
    {
      label: "History",
      icon: "history",
    },
  ];

  return (
    <div>
      <div className="row col-12 align-items-start">
        {addNewDialogOpen ? (
          <AddTask
            {...props}
            setAddNewDialogOpen={setAddNewDialogOpen}
            accountCustomProperties={accountCustomProperties}
            customContactProperties={customContactProperties}
          />
        ) : (
          <>
            <div className="row col-12 " style={{ marginBottom: "10px" }}>
              <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                <h1>Task List</h1>
                <QuestionToLink id="task" desc="tasks" />
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setAddNewDialogOpen(true)}
                startIcon={<i className="material-icons">add</i>}>
                New Task
              </Button>
            </div>

            <div className={style.tabs}>
              <AntTabs
                value={tabSelected}
                onChange={(e, newValue) => setTabSelected(newValue)}
                aria-label="task tabs">
                {tabs.map((tab, index) => (
                  <AntTab
                    key={index}
                    label={tab.label}
                    {...a11yProps(index)}
                    value={index}
                    style={{ textTransform: "none" }}
                  />
                ))}
              </AntTabs>
            </div>

            <div className="row col-12">
              {tabSelected === 0 && (
                <ListTask
                  {...props}
                  setAddNewDialogOpen={setAddNewDialogOpen}
                  setTabSelected={setTabSelected}
                  users={users}
                  accountCustomProperties={accountCustomProperties}
                  customContactProperties={customContactProperties}
                />
              )}
              {tabSelected === 1 && (
                <ScheduledHistory {...props} calledFromTask={true} users={users} />
              )}
              {tabSelected === 2 && <ListHistory {...props} users={users} />}
            </div>
          </>
        )}
      </div>

      <div className={style.bottomNavigation}>
        <BottomNavigation
          onChange={(e, newValue) => setTabSelected(newValue)}
          value={tabSelected}
          showLabels>
          {tabs.map((tab, index) => (
            <BottomNavigationAction
              key={index}
              label={tab.label}
              icon={<i className="material-icons">{tab.icon}</i>}
              value={index}
            />
          ))}
        </BottomNavigation>
      </div>
    </div>
  );
}

Task.propTypes = {
  userInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    gratavidUploadToken: PropTypes.string.isRequired,
  }),
};
