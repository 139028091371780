import React from "react";
import InputColor from "components/util/inputColor/inputColor.js";
import Description from "./description.js";
import styles from "./colors.module.css";
import PropTypes from "prop-types";

const Colors = ({ onChange, primaryColor, secondaryColor }) => {
  return (
    <div className={styles.colors}>
      <Description
        title="Colors"
        description="Customize your landing page, messaging platform, and email messages."
        required
      />
      <div className={styles.color_wrapper}>
        <InputColor
          color={primaryColor}
          colorKey="primaryColor"
          onChange={onChange}
          title="Primary"
        />
        <InputColor
          color={secondaryColor}
          colorKey="secondaryColor"
          onChange={onChange}
          title="Secondary"
        />
      </div>
    </div>
  );
};

Colors.propTypes = {
  onChange: PropTypes.func.isRequired,
  primaryColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
};

export default Colors;
