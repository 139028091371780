import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import LoadingButton from "../buttons/loadingbutton";
import SSOLogins from "./ssoLogins";
import { login, forgotPassword } from "services/api.service";

export default function LogInBox(props) {
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [state, setState] = useState({});

  let clickedLogIn = async (e) => {
    try {
      e.preventDefault();
      if (email === "" || password === "")
        throw new Error("Please insert email and password");
      setState({ buttonState: "loading" });
      await login(email, password);
    } catch (error) {
      let errorMessage = error.message;
      if (error.status === 404) {
        errorMessage = <span>Email does not exist.</span>;
      } else if (error.status === 401) {
        errorMessage = (
          <span>
            Incorrect password.{" "}
            <button className="link-button" type="button" onClick={resetPassword}>
              Click here to reset password
            </button>
          </span>
        );
      }
      console.error(error.message);
      setState({ errorMessage });
    }
  };

  let resetPassword = async (e) => {
    try {
      e.preventDefault();
      if (email === "") throw new Error("Please insert email");
      setState({ buttonState: "loading" });
      await forgotPassword(email);
      setState({ warningMessage: "Reset email sent to " + email });
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.error(errorMessage);
      setState({ errorMessage });
    }
  };

  return (
    <div className="justify-content-center row" style={{ padding: "20px 10px 10px" }}>
      <div style={{ maxWidth: "348px", width: "100%" }}>
        <form
          onSubmit={clickedLogIn}
          className="boxOutline"
          style={{ padding: "15px 15px 0px 15px", width: "100%" }}>
          <div className="col-12" style={{ marginBottom: "20px" }}>
            <h1 style={{ fontSize: "26px" }}>Sign In</h1>
          </div>

          <div className="col-12">
            <TextField
              fullWidth={true}
              label="Email Address"
              id="email"
              placeholder="john@email.com"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="col-12" style={{ padding: "15px 0px 10px" }}>
            <TextField
              fullWidth={true}
              label="Password"
              type="password"
              id="password"
              placeholder="******"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          {state.errorMessage && (
            <div className="errorMessageBox">
              <b>{state.errorMessage}</b>
            </div>
          )}

          {state.warningMessage && (
            <div className="notificationMessageBox">
              <b>{state.warningMessage}</b>
            </div>
          )}

          <div className="col-12" style={{ padding: "10px 0px 0px 0px" }}>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              fullWidth={true}
              buttonState={state.buttonState}
              savedLabel={"Success! Logged In"}
              label={"Login"}
              clickedRegularButton={clickedLogIn}
              clickedSavedButton={() => {
                return true;
              }}
            />
          </div>

          <div className="col-12 justify-content-center" style={{ paddingTop: "10px" }}>
            <Button size="small" onClick={resetPassword}>
              <span style={{ textTransform: "none", fontSize: "13px" }}>
                Reset Password
              </span>
            </Button>
          </div>

          <SSOLogins />
        </form>
      </div>
    </div>
  );
}
