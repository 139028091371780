import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import AnalyticsInfoBox from "./analyticsInfoBox";

export default function SummaryBox(props) {
  let { dataType, loading, showUniqueData } = props;
  if (loading) {
    return (
      <div className="row col-12" style={{ padding: "10px", minHeight: "200px" }}>
        <div
          className="boxShadow col-12"
          style={{ backgroundColor: "white", padding: "20px", borderRadius: "3px" }}>
          <LinearProgress />
        </div>
      </div>
    );
  }

  let color,
    icon,
    label,
    kpi,
    kpiLabel,
    indicatorWidth,
    kpiTwo,
    kpiTwoLabel,
    kpiThree,
    kpiThreeLabel;

  if (dataType === "text") {
    let { text_delivered = 0, text_click = 0 } = props.data;
    color = "#087f23";
    icon = "textsms";
    label = "TEXTS";
    let ctr = ((text_click / text_delivered) * 100).toFixed(2);
    kpi = text_delivered === 0 ? "NA" : ctr + "%";
    kpiLabel = "CLICK-THROUGH RATE";
    indicatorWidth = ctr + "%";
    kpiTwo = text_delivered;
    kpiTwoLabel = " texts delivered";
    kpiThree = text_click;
    kpiThreeLabel = " texts clicked";
  } else if (dataType === "email-open") {
    let { email_delivered = 0, email_open = 0 } = props.data;
    color = "#087f23";
    icon = "email";
    label = "EMAILS";
    let ctr = ((email_open / email_delivered) * 100).toFixed(2);
    kpi = email_delivered === 0 ? "NA" : ctr + "%";
    kpiLabel = "OPEN RATE";
    indicatorWidth = ctr + "%";
    kpiTwo = email_delivered;
    kpiTwoLabel = " emails delivered";
    kpiThree = email_open;
    kpiThreeLabel = " emails opened";
  } else if (dataType === "email-ctr") {
    let { email_delivered = 0, email_click = 0 } = props.data;
    color = "#087f23";
    icon = "email";
    label = "EMAILS";
    let ctr = ((email_click / email_delivered) * 100).toFixed(2);
    kpi = email_delivered === 0 ? "NA" : ctr + "%";
    kpiLabel = "CLICK-THROUGH RATE";
    indicatorWidth = ctr + "%";
    kpiTwo = email_delivered;
    kpiTwoLabel = " emails delivered";
    kpiThree = email_click;
    kpiThreeLabel = " emails clicked";
  } else if (dataType === "note") {
    let { note_reply = 0, note_share = 0, note_pageView = 0 } = props.data;
    color = "#24305e";
    icon = "web";
    label = "NOTE";
    kpi = note_pageView;
    kpiLabel = "PAGE VIEWS";
    indicatorWidth = "100%";
    //make sure video play is awlays equal with half and full vid play
    kpiTwo = note_reply;
    kpiTwoLabel = " replies";
    kpiThree = note_share;
    kpiThreeLabel = " shares";
  } else if (dataType === "video") {
    let {
      note_playVideo = 0,
      note_playedFullVideo = 0,
      note_playedHalfVideo = 0,
    } = props.data;
    color = "#24305e";
    icon = "slideshow";
    label = "VIDEOS";
    //make sure video play is awlays equal with half and full vid play
    kpi = note_playedFullVideo > note_playVideo ? note_playedFullVideo : note_playVideo;
    kpi = note_playedHalfVideo > kpi ? note_playedHalfVideo : kpi;
    kpiLabel = "VIDEO PLAYS";
    indicatorWidth = "100%";
    //make sure video play is awlays equal with half and full vid play
    kpiTwo =
      note_playedFullVideo > note_playedHalfVideo
        ? note_playedFullVideo
        : note_playedHalfVideo;
    kpiTwo = note_playedHalfVideo > kpi ? note_playedHalfVideo : kpiTwo;
    kpiTwoLabel = " half video plays";
    kpiThree = note_playedFullVideo;
    kpiThreeLabel = " full video plays";
  } else if (dataType === "video-rate") {
    let { note_playVideo = 0, note_playedFullVideo = 0 } = props.data;
    color = "#24305e";
    icon = "slideshow";
    label = "VIDEOS";
    let vtr = ((note_playedFullVideo / note_playVideo) * 100).toFixed(2);
    kpi = note_playVideo === 0 ? "NA" : vtr + "%";
    kpiLabel = "VIEW-THROUGH RATE";
    indicatorWidth = vtr + "%";
    kpiTwo = note_playVideo;
    kpiTwoLabel = " videos played";
    kpiThree = note_playedFullVideo;
    kpiThreeLabel = " full video plays";
  }

  return (
    <div className="row col-12" style={{ padding: "10px", height: "100%" }}>
      <div
        className="row col-12 boxShadow"
        style={{ backgroundColor: "white", padding: "20px 15px", borderRadius: "3px" }}>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: 5,
            overflow: "hidden",
            display: "flex",
            borderRadius: "3px 3px 0px 0px",
          }}>
          <div style={{ width: indicatorWidth, backgroundColor: color }} />
          <div style={{ flex: 1, backgroundColor: "#bfcad8" }} />
        </div>

        <div className="col-12 row" style={{ marginBottom: "15px" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <i
              className="material-icons"
              style={{ marginRight: "5px", color: "#8E949D" }}>
              {icon}
            </i>
            <span style={{ fontSize: "12px", color: "grey", fontWeight: "bold" }}>
              {label}
            </span>
          </div>
          <div style={{ width: "28px" }}>
            <AnalyticsInfoBox
              size="medium"
              dataType={dataType}
              showUniqueData={showUniqueData}
              handleUniqueToggle={props.handleUniqueToggle}
            />
          </div>
        </div>

        <div className="col-12">
          <span style={{ fontSize: "28px", fontWeight: "bold" }}>{kpi}</span>
          <br />
          <span style={{ fontSize: "13px", color, fontWeight: "bold" }}>
            {showUniqueData ? "UNIQUE " + kpiLabel : kpiLabel}
          </span>
        </div>

        <div
          style={{
            margin: "10px 0px",
            backgroundColor: "#ddd",
            width: "20px",
            height: "2px",
          }}
        />

        <div className="col-12">
          <span style={{ fontSize: "12px", color: "#71859b" }}>
            <span style={{ fontSize: "12px", color: "#506070", fontWeight: "bold" }}>
              {kpiTwo}
            </span>
            {showUniqueData ? " unique " + kpiTwoLabel : kpiTwoLabel}
          </span>
          <br />
          <span style={{ fontSize: "12px", color: "#71859b" }}>
            <span style={{ fontSize: "12px", color: "#506070", fontWeight: "bold" }}>
              {kpiThree}
            </span>
            {showUniqueData ? " unique " + kpiThreeLabel : kpiThreeLabel}
          </span>
        </div>
      </div>
    </div>
  );
}
