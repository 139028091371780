export default function getMergedString(accountMergeFields, unmergedString, contactData) {
  try {
    //Safari doesn't support lookbehind, so we just have to grab all possible mergeFields and loop through them
    let mergeFields = ["firstName", "lastName", "salutation"];
    let accountMergeFieldsKeys =
      accountMergeFields && Object.keys(accountMergeFields)
        ? Object.keys(accountMergeFields)
        : [];
    accountMergeFieldsKeys.forEach((key) => {
      mergeFields.push(key);
    });

    //these are the default properties for every account.
    //however we could store firstName: {dv: "loyal fan"} to replace the default value
    let newAccountMergeFields = {
      firstName: { dv: "friend" },
      lastName: { dv: "" },
      salutation: { dv: "" },
    };
    accountMergeFieldsKeys.forEach((id) => {
      let current = accountMergeFields[id];
      newAccountMergeFields[id] = {};
      let defaultValue = current && current.dv ? current.dv : "";
      newAccountMergeFields[id].dv = defaultValue;
    });

    contactData = getContactDataWithFallbackValues(
      mergeFields,
      newAccountMergeFields,
      contactData
    );

    let mergedString = unmergedString ? unmergedString : "";

    mergeFields.forEach((currentProperty) => {
      let value = contactData[currentProperty];
      let replace = "%" + currentProperty + "%";
      let regex = new RegExp(replace, "g");
      mergedString = mergedString.replace(regex, value);
    });

    return mergedString;
  } catch (err) {
    console.error(err.message);
    return unmergedString;
  }
}

function getContactDataWithFallbackValues(
  uniqueMergeFields,
  accountMergeFields,
  contactData = {}
) {
  try {
    let newContactData = { ...contactData };

    //NOW make sure default values are set for contactData if contactData does not exist
    //have to pull defaultValues from accountMergeFields
    uniqueMergeFields.forEach((currentProperty) => {
      if (currentProperty === "salutation") {
        //get fist name value
        let firstNameDefaultValue =
          accountMergeFields["firstName"] && accountMergeFields["firstName"].dv
            ? accountMergeFields["firstName"].dv
            : "";
        let firstNameValue = newContactData["firstName"]
          ? newContactData["firstName"]
          : firstNameDefaultValue;

        //if salutation equals "" OR it equals its default value (which should not be set) then set value as first name value
        //the contactData["salutation"] === accountMergeFields["salutation"].dv part just protects is default value is accidentaly set for salutation
        let defaultValue =
          accountMergeFields[currentProperty] && accountMergeFields[currentProperty].dv
            ? accountMergeFields[currentProperty].dv
            : "";
        if (
          !newContactData["salutation"] ||
          newContactData["salutation"] === defaultValue
        )
          newContactData["salutation"] = firstNameValue;
      } else {
        //else any other property will just fallback on it's default value
        let defaultValue =
          accountMergeFields[currentProperty] && accountMergeFields[currentProperty].dv
            ? accountMergeFields[currentProperty].dv
            : "";
        if (!newContactData[currentProperty] || newContactData[currentProperty] === "")
          newContactData[currentProperty] = defaultValue;
      }
    });

    return newContactData;
  } catch (err) {
    return contactData;
  }
}
