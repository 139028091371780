/**
 * Sort array by prop and direction
 * @param {Array} array
 * @param {string} path path to value to sort on
 * @param {number} direction 1 = ascending, 0 = descending
 * @returns {Array} new sorted array
 */
export function sort(array, path, direction = 1) {
  const sorted = [...array];
  sorted.sort((originalA, originalB) => {
    const a = getNestedProp(originalA, path);
    const b = getNestedProp(originalB, path);
    if (a === b) {
      return 0;
    }
    return a > b ? direction : -direction;
  });
  return sorted;
}

/**
 * Filter and case-insensitive text search array
 * @param {Array} array items to search on
 * @param {function(any): boolean} predicate filter function that gets called on each item
 * @param {string[]} fields fields to search on
 * @param {string} search search target
 */
export function filterSearch(array, predicate, fields, search) {
  let filtered = array;

  if (predicate) {
    filtered = array.filter(predicate);
  }
  if (search) {
    const needle = search.toLowerCase();

    filtered = filtered.filter((item) =>
      fields.some((field) => getNestedProp(item, field)?.toLowerCase().includes(needle))
    );
  }
  return filtered;
}

/**
 * Return nested prop from . separated path
 * @param {any} object object to look into
 * @param {string} path . separated path to follow
 * @return {any} value from the path or undefined
 */
export function getNestedProp(object, path) {
  const props = path.split(".");
  return props.reduce((accumulator, currentValue) => {
    return accumulator?.[currentValue];
  }, object);
}
