import React, { useState } from "react";
import {
  Dialog,
  withMobileDialog,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import SearchIndex from "../../../../../util/search/searchIndex";
import ListVideos from "../../../../videos/listVideos/listVideos";

function SearchVideo(props) {
  let [searchList, setSearchList] = useState("loading");
  let [dialogOpen, setDialogOpen] = useState(true);

  let selectedItems = props.videos?.map((video) => video.video) || [];

  let closeDialog = () => {
    props.setAddState(null);
    setDialogOpen(false);
  };

  return (
    <Dialog
      fullScreen={props.fullScreen}
      open={dialogOpen}
      onClose={closeDialog}
      aria-labelledby="search existing videos dialog">
      <DialogContent
        style={{ minHeight: "500px", width: props.fullScreen ? "100%" : "600px" }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "18px" }}>Select Videos</span>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton aria-label="close" onClick={closeDialog}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        <SearchIndex
          {...props}
          showHideTaskToggle={true}
          setSearchList={setSearchList}
          searchList={searchList}
          indexName="videos"
          allSelected={false}
          setAllSelected={null}
          selectedItems={selectedItems}
          setSelectedItems={null}
          listComponent={ListVideos}
          calledFromSearchExistingVid={true}
          videoSelectedForNote={props.videoSelected}
        />
      </DialogContent>

      <DialogActions style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
        <Button onClick={closeDialog} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withMobileDialog()(SearchVideo);
