import React, { useState } from "react";
import Authenticate from "./authenticate";
import DeAuthenticateDialog from "./deAuthenticateDialog";
import IntegrationHeader from "../integration-header";
import OpportunityClosedWon from "./actions/opportunityClosedWon";
import PollCustomObject from "./actions/pollCustomObject/pollCustomObject";
import ImportContacts from "./contacts/importContacts";
import TaskSent from "./triggers/taskSent";
import SaveReply from "./triggers/saveReply";
import NoteWatched from "./triggers/noteWatched";

export default function Salesforce(props) {
  let { integrationDetails, data = {} } = props;
  let { events = {} } = data; //events are giftAdded and contactAddedUpdated
  let [connectionInfo, setConnectionInfo] = useState(data?.connectionInfo);

  if (!connectionInfo)
    return (
      <Authenticate
        connectionInfo={connectionInfo}
        setConnectionInfo={setConnectionInfo}
        {...props}
      />
    );

  const pollCustomObjectsList = events?.pollCustomObjects?.list || {};
  const pollCustomObjectsListKeys = pollCustomObjectsList
    ? Object.keys(pollCustomObjectsList)
    : [];

  return (
    <div className="col-12 row">
      <IntegrationHeader
        integrationDetails={integrationDetails}
        connectionInfo={connectionInfo}>
        <DeAuthenticateDialog {...props} />
      </IntegrationHeader>

      {pollCustomObjectsListKeys.map((id) => {
        return (
          <PollCustomObject key={id} {...props} eventData={pollCustomObjectsList[id]} />
        );
      })}
      <OpportunityClosedWon {...props} eventData={events?.opportunityClosedWon} />
      <ImportContacts {...props} eventData={events?.importContacts} />
      <TaskSent {...props} eventData={events?.taskSent} />
      <SaveReply {...props} eventData={events?.reply} />
      <NoteWatched {...props} eventData={events?.noteWatched} />
      <div className="col-12" style={{ height: "100px" }} />
    </div>
  );
}
