import papa from "papaparse";

/**
 * Takes in a string csv and uses papaparse to convert to js object
 * @param {string} csv
 * @param {object} options
 * @returns Objectified csv
 */
export function csvToObject(csv, options = {}) {
  options = {
    header: true,
    transform: (val) => val.trim(),
    skipEmptyLines: true,
    ...options,
  };
  return papa.parse(csv, options);
}

/**
 * Takes in an object and uses papaparse to convert to string csv
 * @param {object | array} data
 * @param {object} options
 * @param {string[]} fields - optional header definitions
 * @returns Objectified csv
 */
export function objectToCsv(data, options = {}, fields = []) {
  options = {
    header: true,
    transform: (val) => val.trim(),
    skipEmptyLines: true,
    ...options,
  };
  if (fields.length) {
    return papa.unparse({ fields, data }, options);
  }
  return papa.unparse(data, options);
}

export function mapRowToProperties(row, properties) {
  const mappedProperties = {};
  for (const [key, value] of Object.entries(properties)) {
    if (value.mapped) {
      const property = properties[key];
      mappedProperties[property.id || key] = row[value.columnHeader];
    }
  }
  return mappedProperties;
}
