import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import Query from "./query";
import { updateAccountIntegrations } from "services/api.service";
import { nanoid } from "nanoid";

export default function ListQueries(props) {
  const initialQueries =
    props?.integration?.events?.handleSlateQueryExport?.queries?.list ?? {};
  const [queries, setQueries] = useState(initialQueries);

  const clickedAddQuery = async () => {
    const newGratavidQueryId = nanoid(12);
    const newQueryData = {
      gratavidQueryId: newGratavidQueryId,
      gratavidQueryName: "New query",
      successFunc: "doNothing",
      noteId: null,
      assignedTo: null,
      backupAssignedTo: null,
    };
    await updateAccountIntegrations([
      {
        path: `/endpoint/slate/events/handleSlateQueryExport/queries/list/${newGratavidQueryId}`,
        value: newQueryData,
      },
    ]);

    const tempQueries = { ...queries };
    tempQueries[newGratavidQueryId] = newQueryData;
    setQueries(tempQueries);
  };

  const deleteQuery = async (gratavidQueryId) => {
    await updateAccountIntegrations([
      {
        path: `/endpoint/slate/events/handleSlateQueryExport/queries/list/${gratavidQueryId}`,
        value: null,
      },
    ]);
    const tempQueries = { ...queries };
    delete tempQueries[gratavidQueryId];
    setQueries(tempQueries);
  };

  const updateQuery = async (gratavidQueryId, key, value) => {
    await updateAccountIntegrations([
      {
        path: `/endpoint/slate/events/handleSlateQueryExport/queries/list/${gratavidQueryId}/${key}`,
        value: value,
      },
    ]);
    const tempQueries = { ...queries };
    tempQueries[gratavidQueryId][key] = value;
    setQueries(tempQueries);
  };

  let queriesKeys = queries ? Object.keys(queries) : [];

  return (
    <div className="row col-12">
      <div className="col-12">
        <div style={{ flex: 1, marginTop: "15px", padding: 5 }}>
          <h2 style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "5px" }}>
            Trigger Gratavid Actions (Task & Sends) From Slate Queries{" "}
          </h2>
          <p style={{ marginTop: "10px" }}>
            Create one or multiple queries in Slate and tell Gratavid how to handle each
            result row. Visit the help center page{" "}
            <a
              href="https://graduwayhelp.zendesk.com/hc/en-us/articles/6653072064924-Slate-Integration"
              target="_blank"
              rel="noopener noreferrer">
              Triggering Gratavid Actions ( Task & Sends) From Slate Queries
            </a>{" "}
            the to learn more.
          </p>
        </div>
      </div>

      <div className="col-12" style={{ marginTop: 20 }}>
        {queriesKeys.map((id, index) => {
          return (
            <Query
              key={id}
              {...queries[id]}
              {...props}
              deleteQuery={deleteQuery}
              updateQuery={updateQuery}
              listCount={index + 1}
            />
          );
        })}
      </div>

      <div
        className="col-12"
        style={{ display: "flex", justifyContent: "center", marginTop: 25 }}>
        <Button onClick={clickedAddQuery} variant="contained" color="primary">
          Add query
          <i className="material-icons" style={{ marginLeft: 5 }}>
            add
          </i>
        </Button>
      </div>
    </div>
  );
}

ListQueries.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  integration: PropTypes.object,
};
