import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";
import Checkbox from "@material-ui/core/Checkbox";
import { getAccountFolders } from "services/api.service";
import { ALL_CONTACTS_FOLDER_ID } from "components/util/data/constants";

export default function ListFolders(props) {
  const [folders, setFolders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (props.contactsCount > 0) {
      setIsLoading(true);
      getAccountFolders("contact")
        .then((folders) => {
          setFolders(() => {
            const allContacts = {
              id: ALL_CONTACTS_FOLDER_ID,
              name: `All Contacts (${props.contactsCount})`,
            };
            return [allContacts, ...folders];
          });
          setIsLoading(false);
        })
        .catch((err) => setErrorMessage(err.message));
    }
  }, [props.contactsCount]);

  if (isLoading) {
    return (
      <div className="col-12 pagePadding">
        <LinearProgress />
      </div>
    );
  }

  return (
    <div className="row col-12">
      {errorMessage && (
        <div className="errorMessageBox">
          <b>{errorMessage}</b>
        </div>
      )}

      {folders?.map((folder) => (
        <div
          key={folder.id}
          className="row col-12 editNoteListSelectableItem align-items-center"
          style={{ height: "35px", display: "flex", padding: "5px" }}
          onClick={() => props.onToggleFolder(folder)}>
          <div className="align-items-center">
            <Checkbox
              checked={!!props.noteContacts.find((nc) => nc.folderId === folder.id)}
              size="small"
              style={{ height: "15px", width: "15px", margin: "0px 10px" }}
              color="default"
            />
          </div>

          <div style={{ flex: 1 }} className="col-12 align-items-center">
            <span>{folder.name}</span>
          </div>
        </div>
      ))}
    </div>
  );
}

ListFolders.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  noteContacts: PropTypes.array.isRequired,
  onToggleFolder: PropTypes.func.isRequired,
  contactsCount: PropTypes.number,
};
