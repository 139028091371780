import React from "react";
import Navbar from "../../navbar/navbar";
import "../../../style/compliance.css";

export default function UserContentTerms() {
  return (
    <>
      <Navbar />
      <div className="compliancePage pagePadding row justify-content-center">
        <div className="col-12 col-md-11 col-lg-10">
          <div className="col-12 align-items-center justify-content-center">
            <h1 style={{ margin: "40px 0px", fontSize: "30px" }}>
              License You Grant to Us Over User Content
            </h1>
          </div>

          <div className="col-12">
            <p>
              Gratavid allows you to create and upload your own video messages and replies
              (“User Content”). User Content posted or uploaded to Gratavid belongs to
              you. However, we need your permission to use User Content in order for
              Gratavid to function.
            </p>

            <h2>Permission to Use User Content</h2>
            <p>
              By posting or uploading User Content through our Site, including replying to
              a video message, you grant us a license to use it. You grant Gratavid a
              non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable through
              multiple levels of sublicensees, perpetual license to use, publicly display
              (in any media or format), edit, stream, digitize, modify, reproduce,
              distribute, store, and prepare derivative works of User Content to provide
              our service and to promote Gratavid, in any formats and through any
              channels, including across any service that we may own or any other
              third-party website or advertising medium. We may feature User Content on
              our Site and in related marketing and promotional materials (both digital
              and print). If we do feature your User Content in this way, we will contact
              the organization who requested the video from you.
            </p>

            <h2>Responsibility for User Content</h2>
            <p>
              You understand that you are solely responsible for User Content. By
              uploading or posting User Content to Gratavid, you represent and warrant
              that you have all necessary rights to User Content and that you’re not
              infringing or violating any third party’s rights by posting it. When you
              submit, post, or otherwise direct communications to Gratavid (through the
              use of our Site, via a telephone call with a member of our team, via email,
              or otherwise) you give us a license to modify, use, adapt, copy, and publish
              your User Content. You agree that this license includes the right for
              Gratavid to use your User Content for promotional purposes and to improve
              the Site. You agree that the User Content provided to Gratavid is
              non-confidential and that Gratavid has the right to unrestricted use for any
              purpose, commercial or otherwise, without acknowledgment or compensation to
              you. If we do feature your User Content for promotional purposes, we will
              contact the organization who requested the video from you.
            </p>

            <p>
              The company or organization that requested a video from you or that you are
              replying to shall have the right to collect and analyze data and other
              information relating to the provision, use and performance of various
              aspects of the Gratavid Platform and related systems and technologies
              (including, without limitation, information concerning User Content and data
              derived therefrom), and Company will be free (during and after the term
              hereof) to (i) use such information and data to improve and enhance the
              Gratavid Platform and for other development, diagnostic and corrective
              purposes in connection with the Gratavid Platform and other Company
              offerings, and (ii) disclose such data solely in aggregate or other
              de-identified form in connection with its business.
            </p>

            <p>
              For a full list of Gratavid terms, visit{" "}
              <a href="https://account.gratavid.com/terms">
                https://account.gratavid.com/terms
              </a>
              . This page was last modified on August 23rd, 2020. Contact Information:
              support@gratavid.com
            </p>
            <div style={{ height: "40px" }} />
          </div>
        </div>
      </div>
    </>
  );
}
