import React, { useState } from "react";
import { LinearProgress, Button } from "@material-ui/core";
import Item from "./item";
import BulkImportDialog from "./bulkImportDialog";

export default function ListUnsubscribes(props) {
  const unsubscribesList = props.unsubscribesList;
  let [addNewDialogOpen, setAddNewDialogOpen] = useState(false);

  let downloadCSV = () => {
    let typeColumnName = props.type === "emails" ? "Email" : "Cell Number";
    let csvContent = typeColumnName + ",Opt Out Date\r\n";
    let listKeys = Object.keys(unsubscribesList);
    listKeys.forEach((key) => {
      let current = unsubscribesList[key];
      let value = props.type === "emails" ? current.email : current.cellNumber;
      let date =
        props.type === "emails"
          ? current.emailUnsubscribedDate && new Date(current.emailUnsubscribedDate)
          : current.textUnsubscribedDate && new Date(current.textUnsubscribedDate);
      let showDate = date?.toLocaleString() || "";
      csvContent += '"' + value + '","' + showDate + '"\r\n';
    });

    var blob = new Blob([csvContent]);
    if (window.navigator.msSaveOrOpenBlob) {
      // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
      window.navigator.msSaveBlob(blob, "filename.csv");
    } else {
      var a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(blob, { type: "text/plain" });
      a.download = props.type + "_unsubscribes.csv";
      document.body.appendChild(a);
      a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
      document.body.removeChild(a);
    }
  };

  if (unsubscribesList === "loading") {
    return (
      <div className="col-12" style={{ padding: "20px" }}>
        <LinearProgress />
      </div>
    );
  }

  let showList = unsubscribesList.map((contact) => {
    if (props.type === "texts" && contact.textUnsubscribed === true) {
      return (
        <Item current={contact} key={contact.id} contactId={contact.id} {...props} />
      );
    }
    if (props.type === "emails" && contact.emailUnsubscribed === true) {
      return (
        <Item current={contact} key={contact.id} contactId={contact.id} {...props} />
      );
    }
    return null;
  });

  return (
    <div className="row col-12">
      {unsubscribesList.length === 0 ? (
        <div className="col-12" style={{ padding: "20px" }}>
          <Button onClick={() => setAddNewDialogOpen(true)} variant="contained">
            Import
          </Button>
          <div style={{ marginTop: "20px" }}>
            No users have unsubscribed from {props.type} yet
          </div>
        </div>
      ) : (
        <>
          <div className="col-12" style={{ padding: "20px" }}>
            <Button
              onClick={downloadCSV}
              variant="contained"
              style={{ marginRight: "15px" }}>
              Download
            </Button>
            <Button onClick={() => setAddNewDialogOpen(true)} variant="contained">
              Import
            </Button>
          </div>

          <div className="col-12">{showList}</div>
        </>
      )}

      <BulkImportDialog
        {...props}
        type={props.type}
        addNewDialogOpen={addNewDialogOpen}
        setAddNewDialogOpen={setAddNewDialogOpen}
        list={unsubscribesList}
        onBulkImportDone={props.onBulkImportDone}
      />
    </div>
  );
}
