import React from "react";
import PropTypes from "prop-types";
import style from "./card.module.css";

export default function Card(props) {
  const noPadding = props.noPadding ? { padding: "0px" } : null;

  return (
    <div className={style.card} style={noPadding}>
      {props.children}
    </div>
  );
}

Card.propTypes = {
  children: PropTypes.node,
  noPadding: PropTypes.bool,
};
