import React from "react";
import GetListSelectItem from "./getListSelectItem";
import GetListSelectList from "./getListSelectList";
import { TextField } from "@material-ui/core";

export default function GetFormSelectFormData({
  currentCondition,
  baseGetUrl,
  source,
  booleanOptions,
  handleFormChange,
  handleFormFieldChange,
  handleFormFieldValueChange,
  accountInfo,
}) {
  /*
  {
    "operator": "formFieldValueEquals"
    "metric": "formFields",
    "formId": "123",
    "formFieldValueMetric": "equals",
    "formLabel": "123",
    "formFieldType": boolean,
    "formFieldId": "123",
    "formFieldLabel": "123",
    "formFieldValueId": "Yes",
    "formFieldValueLabel": "Yes",
  }
  */

  let {
    formId = "",
    formLabel = "",
    formFieldId = "",
    formFieldLabel = "",
    formFieldValueMetric = "equals",
    formFieldValue: selectedItems = {},
  } = currentCondition;

  let renderOptions = null;
  if (formFieldValueMetric === "includes") {
    renderOptions = (
      <>
        <TextField
          disabled
          variant="filled"
          value="Includes any of these"
          label="Condition"
          style={{ width: 200, marginTop: 5, marginRight: 15 }}
        />
        <GetListSelectList
          initialOptions="loading"
          optionsFetchNeeded={true}
          requestData={{
            source: "planningCenter",
            event: "getFormFieldOptions",
            formId,
            formFieldId,
          }}
          accountId={accountInfo.id}
          selectedItems={selectedItems}
          handleChange={handleFormFieldValueChange}
          label="Form Field Value"
          width="300px"
        />
      </>
    );
  } else if (formFieldValueMetric === "exist") {
    renderOptions = (
      <TextField
        disabled
        variant="filled"
        value="Is Selected"
        label="Field Value"
        style={{ width: "250px", marginTop: 5 }}
      />
    );
  }

  return (
    <div className="row">
      <span style={{ marginRight: 15 }}>
        <GetListSelectItem
          initialOptions="loading"
          optionsFetchNeeded={true}
          requestData={{ source, event: "getForms" }}
          accountId={accountInfo.id}
          selectedItem={{ id: formId, label: formLabel }}
          handleChange={handleFormChange}
          label="Form"
          width="250px"
        />
      </span>

      <span style={{ marginRight: 15 }}>
        {formId ? (
          <GetListSelectItem
            initialOptions="loading"
            optionsFetchNeeded={true}
            requestData={{ source: "planningCenter", event: "getFormFields", formId }}
            accountId={accountInfo.id}
            selectedItem={{ id: formFieldId, label: formFieldLabel }}
            handleChange={handleFormFieldChange}
            label="Form Field"
            width="320px"
          />
        ) : (
          <TextField
            disabled
            variant="outlined"
            value=""
            label="Form Field"
            style={{ width: "320px", marginTop: 5 }}
          />
        )}
      </span>

      <span>
        {formId && formFieldId ? (
          renderOptions
        ) : (
          <TextField
            disabled
            variant="outlined"
            value=""
            label="Form Value"
            style={{ width: "250px", marginTop: 5 }}
          />
        )}
      </span>
    </div>
  );
}
