import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import SearchInput from "./searchInput";
import ListFolders from "./listFolders";
import { getAccountContacts } from "services/api.service";
import { debounce, toFullName } from "util/helpers";

export default function SearchContacts(props) {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [contactsCount, setContactCounts] = useState(0);

  const getContacts = useCallback((offset = 0, searchTerm) => {
    getAccountContacts(offset, 20, searchTerm)
      .then((result) => {
        setContactCounts(result.count);
        if (offset === 0) {
          setSearchList(result.rows);
        } else {
          setSearchList((current) => [...current, ...result.rows]);
        }
      })
      .finally(() => setIsSearching(false));
  }, []);

  const search = debounce((term) => {
    setSearchTerm(term);
    if (term.length === 0 || term.length >= 2) {
      getContacts(0, term);
    }
  });

  useEffect(() => {
    getContacts(0);
  }, [getContacts]);

  return (
    <div className="contactPopover col-12" style={{ marginBottom: "50px" }}>
      <div style={{ height: "60px" }}>
        <SearchInput
          searchLoading={isSearching}
          searchTerm={searchTerm}
          onChange={(e) => search(e.target.value)}
        />
      </div>
      <div className="row" style={{ maxHeight: "300px", overflow: "scroll" }}>
        {errorMessage && (
          <div className="errorMessageBox">
            <b>{errorMessage}</b>
          </div>
        )}

        {searchTerm === "" && (
          <div className="row col-12">
            <span style={{ fontWeight: "bold", margin: "10px" }}>Lists</span>
            <div style={{ width: "100%" }}>
              <ListFolders
                accountInfo={props.accountInfo}
                noteContacts={props.noteContacts}
                onToggleFolder={props.onToggleFolder}
                contactsCount={contactsCount}
              />
            </div>
          </div>
        )}

        <div className="row col-12">
          <span style={{ fontWeight: "bold", margin: "10px" }}>Individual Contacts</span>
          <div style={{ width: "100%", marginBottom: "10px" }}>
            {searchList.length === 0 ? (
              <div
                className="col-12"
                key={"none"}
                style={{ padding: "5px 5px 5px 15px", color: "grey" }}>
                No results found
              </div>
            ) : (
              searchList.map((contact) => (
                <div
                  key={contact.id}
                  className="row col-12 editNoteListSelectableItem align-items-center"
                  style={{ height: "35px", display: "flex", padding: "5px" }}
                  onClick={() => props.onToggleContact(contact)}>
                  <div className="align-items-center">
                    <Checkbox
                      checked={
                        !!props.noteContacts.find((nc) => nc.contactId === contact.id)
                      }
                      size="small"
                      style={{ height: "15px", width: "15px", margin: "0px 10px" }}
                      color="default"
                    />
                  </div>
                  <div
                    style={{ flex: 1, overflow: "hidden" }}
                    className="col-12 align-items-center">
                    {contact.textOptIn && (
                      <i
                        className="material-icons"
                        style={{
                          fontSize: "14px",
                          color: "green",
                          marginRight: "3px",
                        }}>
                        mobile_friendly
                      </i>
                    )}
                    <span>
                      {toFullName(contact.firstName, contact.lastName, contact.email)}
                    </span>
                  </div>
                </div>
              ))
            )}
          </div>
          {searchList.length < contactsCount && (
            <div className="col-12 justify-content-center" style={{ padding: "10px" }}>
              <Button
                size="small"
                onClick={() => getContacts(searchList.length, searchTerm)}
                variant="outlined">
                Load More
              </Button>
            </div>
          )}
        </div>
      </div>
      <div
        style={{ height: "60px", borderTop: "1px solid #cbd6e2", padding: "10px 15px" }}>
        <Button
          size="small"
          style={{
            textTransform: "none",
            color: "#0091ae",
            fontWeight: "bold",
            fontSize: "14px",
            overflow: "hidden",
          }}
          onClick={() => props.setAddNewDialogOpen(true)}>
          <i className="material-icons" style={{ fontSize: "14px", marginRight: "3px" }}>
            add
          </i>
          Import/add new contacts
        </Button>
      </div>
    </div>
  );
}

SearchContacts.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  noteContacts: PropTypes.array.isRequired,
  onToggleFolder: PropTypes.func.isRequired,
  onToggleContact: PropTypes.func.isRequired,
};
