import React, { useEffect, useState } from "react";

export default function Track(props) {
  let { newPhrases = null, primaryISOLanguageCode = "en", label = "English" } = props;
  let [trackUrl, setTrackUrl] = useState(null);

  useEffect(() => {
    if (!newPhrases) return;

    let createSrc = () => {
      try {
        let phrasesKeys = Object.keys(newPhrases) ? Object.keys(newPhrases) : [];

        let webVTT = "WEBVTT\n\n";
        phrasesKeys.forEach((keyId, index) => {
          let current = newPhrases[keyId];
          let line = index + 1;
          webVTT +=
            line +
            "\n" +
            current.st +
            " --> " +
            current.et +
            " size:90%\n" +
            current.phrase +
            "\n\n";
        });

        const trackBlob = new Blob([webVTT], { type: "text/plain;charset=utf-8" });
        const trackUrl = URL.createObjectURL(trackBlob);

        setTrackUrl(trackUrl);
      } catch (err) {
        setTrackUrl(null);
      }
    };

    createSrc();
  }, [JSON.stringify(newPhrases)]);

  if (!newPhrases) return null;
  if (!trackUrl) return null;
  else
    return (
      <track
        src={trackUrl}
        default
        kind="captions"
        srcLang={primaryISOLanguageCode}
        label={label}
      />
    );
}
