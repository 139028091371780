import React, { useState } from "react";
import Autosuggest from "react-autosuggest";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import { getAccountContacts } from "services/api.service";

export default function AutoSuggestInput(props) {
  let [contacts, setContacts] = useState(null);
  let [searchTimer, setSearchTimer] = useState(null);
  let [searchLoading, setSearchLoading] = useState(null);

  const handleChange =
    (name) =>
    (event, { newValue }) => {
      props.passedSetState(props.type, newValue);
    };

  let handleSearchTextField = (searchTerm) => {
    setContacts({ loading: true });

    if (searchTimer) {
      clearTimeout(searchTimer);
    }

    let tempSearchTimer = setTimeout(() => {
      doSearch();
    }, 500);

    setSearchTimer(tempSearchTimer);
    setSearchLoading(true);
  };

  let doSearch = () => {
    getAccountContacts(0, 10, props[props.type])
      .then(({ rows }) => {
        setSearchLoading(false);
        setContacts(rows);
      })
      .catch((err) => {
        console.error(err.message);
        setContacts(null);
        setSearchLoading(false);
        props.passedSetState("errorMessage", err.message);
      });
  };

  //called when list item is clicked
  const getSuggestionValue = (contact) => {
    if (contact.loading) return "";

    let valueToReturn = "";

    //editvid SendRequest uses this component to provide search contacts when requesting a video
    if (props.calledFromSendRequest) {
      //this will either provide email or cellNumber back to sendVideoRequest
      let cellNumber = contact && contact.cellNumber ? contact.cellNumber : "";
      let valueToSend = props.type === "cellNumber" ? cellNumber : contact.email;
      props.passedSetState(props.type, valueToSend);
      valueToReturn = valueToSend;
    } else {
      //this will save the contact to contacts:{} at top of editNote
      props.addContactFromSeach(contact);
      //return "" will reset the search input to ""
      valueToReturn = "";
    }

    setContacts(null);
    return valueToReturn;
  };

  function renderInputComponent(inputProps) {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps;
    let label, placeholder;

    if (props.type === "email") {
      label = "Email";
      placeholder = "e.g. john@gmail.com";
    } else if (props.type === "cellNumber") {
      label = "Cell Number";
      placeholder = "e.g. 281-330-8004";
    } else {
      label = "Search Contacts";
      placeholder = "e.g. John Doe";
    }

    return (
      <TextField
        fullWidth
        label={label}
        placeholder={placeholder}
        InputProps={{
          inputRef: (node) => {
            ref(node);
            inputRef(node);
          },
        }}
        {...other}
      />
    );
  }

  const onSuggestionsClearRequested = (e) => {
    setContacts(null);
  };

  let renderSuggestionsContainer = (options) => (
    <Paper {...options.containerProps} square>
      {options.children}
    </Paper>
  );

  // Use your imagination to render suggestions.
  let renderSuggestion = (suggestion) => {
    let { email, cellNumber } = suggestion;
    if (suggestion && suggestion.loading)
      return (
        <div style={{ padding: "20px" }}>
          {" "}
          <LinearProgress />
        </div>
      );
    let firstName = suggestion.firstName ? suggestion.firstName : "";
    let lastName = suggestion.lastName ? suggestion.lastName : "";
    let name = firstName + " " + lastName;
    let primaryText = name === " " ? suggestion.email : name;
    return (
      <ListItem button component="div">
        <ListItemText
          primary={primaryText}
          secondary={
            <span style={{ fontSize: "12px" }}>
              {email}
              <br />
              {cellNumber}
            </span>
          }
        />
      </ListItem>
    );
  };

  let tempContacts = contacts ? contacts : {};
  let contactsKeys = Object.keys(tempContacts);
  let suggestions = [];
  suggestions = contactsKeys.map((key) => {
    if (key === "loading") return { loading: true };
    let current = contacts[key];
    return current;
  });

  let value = props[props.type];

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      onSuggestionsFetchRequested={(e) => handleSearchTextField(e.value)}
      renderInputComponent={renderInputComponent}
      renderSuggestion={renderSuggestion}
      renderSuggestionsContainer={renderSuggestionsContainer}
      inputProps={{ value, onChange: handleChange("single"), type: "search" }}
    />
  );
}
