import React from "react";
import PropTypes from "prop-types";
import styles from "./integrations.module.css";
import Integration from "./integration.js";

const integrations = [
  {
    name: "microsoftPowerAutomate",
    title: "Microsoft Power Automate",
    logoSrc: "/static/img/logos/microsoftPowerAutomate.svg",
  },
  {
    name: "advance",
    title: "Advance",
    logoSrc: "/static/img/logos/advanceIcon.png",
  },
  {
    name: "blackbaud",
    title: "Blackbaud",
    logoSrc: "/static/img/logos/blackbaudLogo.png",
  },
  {
    name: "bloomerang",
    title: "Bloomerang",
    logoSrc: "/static/img/logos/bloomerangIcon.png",
  },
  {
    name: "donorConnect",
    title: "DonorConnect",
    logoSrc: "/static/img/logos/donorConnectIcon.png",
  },
  {
    name: "donorPerfect",
    title: "DonorPerfect",
    logoSrc: "/static/img/logos/donorPerfectIcon.png",
  },
  {
    name: "graduwayEngage",
    title: "Graduway",
    logoSrc: "/static/img/logos/graduwayIcon.png",
  },
  {
    name: "kindful",
    title: "Kindful",
    logoSrc: "/static/img/logos/kindfulIcon.png",
  },
  {
    name: "neon",
    title: "Neon",
    logoSrc: "/static/img/logos/neonOneIcon.png",
  },
  {
    name: "planningCenter",
    title: "Planning Center",
    logoSrc: "/static/img/logos/planningCenter.png",
  },
  {
    name: "salesforce",
    title: "Salesforce",
    logoSrc: "/static/img/logos/salesforce.svg",
  },
  {
    name: "slate",
    title: "Slate",
    logoSrc: "/static/img/logos/slateIcon.png",
  },
  {
    name: "virtuous",
    title: "Virtuous",
    logoSrc: "/static/img/logos/virtuousIcon.png",
  },

  {
    name: "zapier",
    title: "Zapier",
    logoSrc: "/static/img/logos/zapierLogo.png",
  },
];

function createIntegrationComponents(integrations, control, getValues) {
  return integrations.map(({ name, title, logoSrc }) => (
    <Integration
      control={control}
      key={name}
      name={name}
      title={title}
      logoSrc={logoSrc}
      apiKey={getValues(`integrations.${name}.apiKey`)}
    />
  ));
}

const Integrations = ({ control, getValues }) => (
  <div>
    <h3 className={styles.title}>Integrations</h3>
    <div className={styles.integrations}>
      {createIntegrationComponents(integrations, control, getValues)}
    </div>
  </div>
);

Integrations.propTypes = {
  control: PropTypes.object.isRequired,
  getValues: PropTypes.func.isRequired,
};

export default Integrations;
