import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import CheckBoxToApi from "components/util/hooks/input/checkBoxToApi";
import TextInputToApi from "components/util/hooks/input/textInputToApi";
import React, { useState } from "react";
import { bulkPatchTask, getNote } from "services/api.service";
import LoadingButton from "../../../../../util/buttons/loadingbutton";
import SelectNote from "../../editTask/note/select/selectNote";

export default function EditNote(props) {
  let [state, setState] = useState({});
  let [noteId, setNoteId] = useState(null);
  let [noteTitle, setNoteTitle] = useState("loading");
  let [senderName, setSenderName] = useState(null);
  let [message, setMessage] = useState(null);
  let [shareViaEmail, setShareViaEmail] = useState(null);
  let [emailSubject, setEmailSubject] = useState(null);
  let [shareViaText, setShareViaText] = useState(null);
  let [textMessageBody, setTextMessageBody] = useState(null);
  let [appendNoteVids, setAppendNoteVids] = useState(false);

  let saveNotes = async () => {
    try {
      setState({ buttonState: "loading" });

      if (props.selectedItems.length > 250)
        throw new Error(
          "You can only edit up to 250 task at once. Please unselect some tasks."
        );

      const taskIds = props.selectedItems.map((selectedItem) => selectedItem.id);

      const updates = {
        noteId,
        shareViaEmail,
        shareViaText,
      };

      if (emailSubject) {
        updates.emailSubject = emailSubject;
      }

      if (appendNoteVids) {
        updates.appendNoteVids = appendNoteVids;
      }

      if (senderName) {
        updates.senderName = senderName;
      }

      if (message) {
        updates.message = message;
      }

      if (textMessageBody) {
        updates.textMessageBody = textMessageBody;
      }

      await bulkPatchTask(taskIds, updates, props.allSelected || false);
      const tasks = props.tasks.map((task) => {
        const selectedItem = props.selectedItems.find(
          (selectedItem) => selectedItem.id === task.id
        );
        return selectedItem ? { ...selectedItem, ...updates } : task;
      });
      props.setTasks(tasks);
      props.setEditTaskSelected(null);
      props.setSelectedItems([]);
      props.setAllSelected(false);
      setState({ buttonState: "saved" });
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  let noteSelectedFromSearch = async (item) => {
    try {
      if (!item) {
        setNoteId(null);
        return;
      }

      setNoteTitle("loading");
      const note = await getNote(item.id);
      const senderName = note.senderName || "Gratavid";
      setSenderName(senderName);
      setEmailSubject(note.emailSubject || senderName + " Shared A Video With You");
      setMessage(note.messageHtml || senderName + "Thank you! We appreciate all you do.");
      setTextMessageBody(note.textMessageBody || senderName + " shared a video with you");
      setShareViaEmail(note.shareViaEmail === false ? false : true);
      setShareViaText(note.shareViaText || false);
      setNoteTitle(note.title || "Title");
      setNoteId(item.id);
    } catch (err) {
      console.error(err.message);
    }
  };

  let textEnabled =
    props.accountInfo && props.accountInfo.textEnabled
      ? props.accountInfo.textEnabled
      : false;

  return (
    <Dialog
      fullScreen={props.fullScreen}
      open={props.dialogOpen}
      onClose={props.closeDialog}
      aria-labelledby="delete task dialog">
      <DialogContent
        style={{ minHeight: "500px", width: props.fullScreen ? "100%" : "600px" }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "18px" }}>Edit Note</span>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton aria-label="close" onClick={props.closeDialog}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        <SelectNote
          {...props}
          noteSelectedFromSearch={noteSelectedFromSearch}
          noteTitle={noteTitle}
        />

        {noteId && (
          <>
            <div className="col-12" style={{ marginTop: "15px" }}>
              <CheckBoxToApi
                initialValue={appendNoteVids}
                field="appendNoteVids"
                apiCall={(field, value) => setAppendNoteVids(value)}
                label="Include note videos after the task video"
              />
            </div>

            <div className="col-12" style={{ marginTop: "25px" }}>
              <TextInputToApi
                initialValue={senderName}
                field="senderName"
                apiCall={(field, value) => setSenderName(value)}
                label="Sender Name"
                variant="standard"
              />
            </div>

            <div className="col-12" style={{ marginTop: "25px" }}>
              <TextInputToApi
                initialValue={message}
                multiline={true}
                field="message"
                apiCall={(field, value) => setMessage(value)}
                label="Note Message"
                variant="standard"
              />
            </div>

            <div className="col-12" style={{ marginTop: "15px" }}>
              <CheckBoxToApi
                initialValue={shareViaEmail}
                field="shareViaEmail"
                apiCall={(field, value) => setShareViaEmail(value)}
                label="Send email"
              />
            </div>

            {shareViaEmail && (
              <div className="col-12" style={{ margin: "3px 0px 15px 0px" }}>
                <TextInputToApi
                  initialValue={emailSubject}
                  multiline={false}
                  field="emailSubject"
                  apiCall={(field, value) => setEmailSubject(value)}
                  label="Email Subject"
                  variant="standard"
                />
              </div>
            )}

            {textEnabled && (
              <>
                <div className="col-12" style={{ marginTop: "0px" }}>
                  <CheckBoxToApi
                    initialValue={shareViaText}
                    field="shareViaText"
                    apiCall={(field, value) => setShareViaText(value)}
                    label="Send Text"
                  />
                </div>

                {shareViaText && (
                  <div className="col-12" style={{ marginTop: "3px" }}>
                    <TextInputToApi
                      initialValue={textMessageBody}
                      maxLength={70}
                      multiline={false}
                      field="textMessageBody"
                      passedSetState={(field, value) => setTextMessageBody(value)}
                      label="Text Message"
                      variant="standard"
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}

        {state.errorMessage && (
          <div className="errorMessageBox">
            <b>{state.errorMessage}</b>
          </div>
        )}

        <div className="notificationMessageBox">
          <span>
            Saving these updates will override any existing note data for these{" "}
            {props.selectedCount} tasks. It will not impact your videos.
          </span>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
        <Button onClick={props.closeDialog} variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          buttonState={state.buttonState}
          disabled={!noteId}
          savedLabel={"Done"}
          label={"Save"}
          clickedRegularButton={saveNotes}
          clickedSavedButton={props.closeDialog}
        />
      </DialogActions>
    </Dialog>
  );
}
