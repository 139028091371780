import { useEffect, useState } from "react";
import defaultContactProperties from "../data/defaultContactProperties";

//call directly from task to get custom properties once so we can use them later on when we have to replace mergeFields in inputs
export const useGetAccountCustomProperties = (accountInfo) => {
  // State and setter for search term
  let [accountCustomProperties, setAccountCustomProperties] = useState("loading");
  let [customContactProperties, setCustomContactProperties] = useState("loading");

  useEffect(() => {
    try {
      setAccountCustomProperties(accountInfo.customContactProperties);

      let tempCustomContactPropertiesKey = Object.keys(
        accountInfo.customContactProperties || {}
      );
      let tempCustomContactProperties = tempCustomContactPropertiesKey.map((id) => {
        let item = accountInfo.customContactProperties[id];
        item.id = id;
        return item;
      });
      setCustomContactProperties(tempCustomContactProperties);
    } catch (err) {
      console.error(err.message);
      setAccountCustomProperties(defaultContactProperties);
    }
  }, [accountInfo.customContactProperties]);

  return [accountCustomProperties, customContactProperties];
};
