import React, { useState } from "react";
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
  withMobileDialog,
} from "@material-ui/core";
import LoadingButton from "../../../../../util/buttons/loadingbutton";
import { sendNote } from "services/api.service";

function ScheduleDialog(props) {
  let {
    taskId,
    noteId,
    videoIds = [],
    contacts = {},
    shareViaEmail,
    shareViaText,
    textEnabled,
    currentTask = {},
    dialogOpen,
  } = props;
  let { sendStatus = 0 } = currentTask;
  let [day, setDay] = useState(0);
  let [time, setTime] = useState("08:00");
  let [state, setState] = useState({});

  let scheduleNow = async () => {
    try {
      if (day === 0) throw new Error("Please select a day.");
      let scheduledDate = new Date(parseInt(day, 10));

      //get miliseconds of time selection
      let timeMiliseconds =
        Number(time.split(":")[0]) * 60 * 60 * 1000 +
        Number(time.split(":")[1]) * 60 * 1000;
      let scheduledDateTimestamp = scheduledDate.getTime();
      scheduledDateTimestamp += timeMiliseconds;

      if (!taskId) throw new Error("Missing task. Must delete task and start over.");
      if (!noteId) throw new Error("No note selected");
      if (sendStatus !== 0)
        throw new Error(
          "This task has already been sent. Refresh the page and try again."
        );
      if (!videoIds || videoIds.length === 0) throw new Error("No video selected");
      if (!shareViaEmail && !shareViaText)
        throw new Error(
          textEnabled
            ? "Must select send by email and/or text"
            : "Must select send by email"
        );
      setState({ buttonState: "loading" });

      if (props.taskContacts.length === 0)
        throw new Error(
          'No contacts selected. You must add contacts under the "Send To" section before sending.'
        );
      if (!videoIds || videoIds.length === 0)
        throw new Error("No video. You must select a video before sending a note.");
      if (!shareViaText && !shareViaEmail)
        throw new Error(
          "You must select either send email or send text under Send To section."
        );

      let twentyEightDaysMilliseconds = 86400000 * 28;
      let differenceInDates = scheduledDateTimestamp - Date.now();

      // Schedule time can not be in the past.
      let oneMinuteMiliseconds = 60000;

      if (differenceInDates < oneMinuteMiliseconds) {
        throw new Error("Scheduled date must be in the future.");
      } else if (differenceInDates > twentyEightDaysMilliseconds) {
        throw new Error("Scheduled date must be within 28 days from now.");
      } else {
        //save scheduled content

        setState({ buttonState: "loading" });
        let gvSendId = "sid_" + taskId;
        let sendObject = {
          scheduledDateTimestamp: new Date(scheduledDateTimestamp),
          noteId,
          taskId,
          gvSendId,
          status: "processing",
          calledFrom: "editNote",
          contactIds: props.currentTask.taskContacts.map(
            (taskContact) => taskContact.contactId
          ),
          type: "sendNote",
          emailProvider: props.userEmailProvider,
          shareViaEmail,
          shareViaText,
        };

        await sendNote(sendObject);

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: "gv.sendNote" });

        //this will segue into sendHistory
        setState({ buttonState: "saved" });
        props.closeDialog();
        props.setTabSelected(1);
      }
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.error(errorMessage);
      setState({ errorMessage: errorMessage, buttonState: "regular" });
    }
  };

  var today = new Date();
  var year = today.getFullYear();
  var month = today.getMonth();
  var date = today.getDate();
  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  let dayOptionsHTML = [];
  for (var i = 0; i < 28; i++) {
    var currentDay = new Date(year, month, date + i);
    let formattedDate = currentDay.toDateString();
    let timeStamp = currentDay.getTime();
    dayOptionsHTML.push(
      <option key={i} value={timeStamp}>
        {formattedDate}
      </option>
    );
  }

  return (
    <Dialog
      fullScreen={props.fullScreen}
      open={dialogOpen}
      onClose={props.closeDialog}
      aria-labelledby="Schedule send dialog">
      <DialogContent
        style={{
          height: props.fullScreen ? "auto" : "550px",
        }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <span style={{ fontSize: "22px" }} role="heading">
              Schedule send
            </span>
            <p className="greyDesc">Timezone: {timeZone}</p>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton aria-label="close" onClick={props.closeDialog}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        <div style={{ display: "flex", marginTop: "25px" }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor={"day-native-select"}>Select Day</InputLabel>
            <Select
              labelWidth={75}
              value={day}
              name="Select Day"
              native
              onChange={(e) => setDay(e.target.value)}
              inputProps={{ id: "day-native-select" }}>
              <option key={i} value={0} disabled>
                Select Day
              </option>
              {dayOptionsHTML}
            </Select>
          </FormControl>
        </div>

        <div style={{ display: "flex", marginTop: "25px" }}>
          <TextField
            variant="outlined"
            onChange={(e) => setTime(e.target.value)}
            id="time"
            label="Time"
            fullWidth
            type="time"
            value={time}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        {state.errorMessage && (
          <div className="col-12 errorMessageBox">
            <b>{state.errorMessage}</b>
          </div>
        )}
      </DialogContent>
      <DialogActions style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
        <Button variant="outlined" color="default" onClick={props.closeDialog}>
          Cancel
        </Button>
        <LoadingButton
          type="button"
          variant="contained"
          color="primary"
          buttonState={state.buttonState}
          savedLabel="Scheduled"
          label="Schedule now"
          clickedRegularButton={scheduleNow}
          clickedSavedButton={() => props.setDialogOpen(false)}
        />
      </DialogActions>
    </Dialog>
  );
}

export default withMobileDialog()(ScheduleDialog);
