import React from "react";
import { FormControl, Select, IconButton, InputLabel } from "@material-ui/core";
import GetListSelectList from "../../../util/getListSelectList";

export default function Condition(props) {
  let { current, actionIndex, conditionId, actions } = props;
  let { metric } = current;

  let handleMetricValueChange = (metric) => {
    if (metric === "groups") handleListChange("groups", [], "includes");
  };

  let handleListChange = (metric, newSelectedItems, operator) => {
    let metricValue = {};
    newSelectedItems.forEach(({ id, label }) => {
      metricValue[id] = { id, label };
    });

    let item = { metric, metricValue, operator };
    let tempActions = { ...actions };
    tempActions.list[actionIndex].conditions[conditionId] = item;
    props.setActions(tempActions);
  };

  let deleteCondition = () => {
    let tempActions = { ...actions };
    delete tempActions.list[actionIndex].conditions[conditionId];
    props.setActions(tempActions);
  };

  let currentCondition = actions.list[actionIndex].conditions[conditionId];
  let selectedItems =
    currentCondition && currentCondition.metricValue ? currentCondition.metricValue : [];

  return (
    <div style={{ display: "flex", paddingTop: "5px" }}>
      <div
        style={{
          width: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
          {props.order === 0 ? "IF" : "AND IF"}
        </h3>
      </div>

      <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
        <FormControl style={{ width: "350px", paddingRight: "15px" }} variant="filled">
          <InputLabel id="conditionType">Select Condition</InputLabel>
          <Select
            value={metric}
            native
            onChange={(e) => handleMetricValueChange(e.target.value)}
            inputProps={{
              "aria-label": "Select condition type",
              name: "conditionType",
              id: "conditionType",
            }}>
            <option value="groups">The user signed up for any of these groups</option>
          </Select>
        </FormControl>

        {metric === "groups" && (
          <GetListSelectList
            initialOptions="loading"
            optionsFetchNeeded={true}
            requestData={{ source: "graduway", event: "getGroupsList" }}
            accountId={props.accountId}
            selectedItems={selectedItems}
            handleChange={(value) => handleListChange("groups", value, "includes")}
            label="Groups"
            width="50%"
          />
        )}
      </div>
      <div
        style={{
          width: "70px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        {props.order > 0 && (
          <IconButton onClick={() => deleteCondition()} style={{ marginRight: "8px" }}>
            <i className="material-icons" style={{ fontSize: "18px" }}>
              close
            </i>
          </IconButton>
        )}
      </div>
    </div>
  );
}
