import React, { useState } from "react";
import { TextField, FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import LoadingButton from "../../../../util/buttons/loadingbutton";
import { authenticateAdvance } from "services/api.service";

import styles from "./authenticate.module.css";

const regionOptions = ["US", "EU", "Canada", "APEC"];

export default function Authenticate(props) {
  let [apiKey, setApiKey] = useState("");
  let [region, setRegion] = useState(regionOptions[0]);
  let [state, setState] = useState({});
  let { integrationDetails, showHeader = null } = props;
  let { header, logoSrc, brandHex } = integrationDetails;

  let saveApiToken = async () => {
    try {
      if (!apiKey) throw new Error("Please add api key");
      setState({ buttonState: "loading" });

      const postData = { apiKey, region };
      const result = await authenticateAdvance(postData);

      let { siteId, siteDomain } = result;

      let tempSites = { ...props.sites };
      tempSites[siteId] = {
        connectionInfo: { dateCreated: Date.now(), siteId, siteDomain },
      };

      props.setSelectedSiteId(siteId);
      props.setSites(tempSites);

      if (props.setDialogOpen) props.setDialogOpen(null);
    } catch (err) {
      let errorMessage =
        err.response?.data?.message ||
        err.data?.message ||
        err.message ||
        "Unknown error occurred";
      console.error(err);
      setState({ errorMessage });
    }
  };

  return (
    <div className={styles.page}>
      {showHeader && (
        <div className={styles.header}>
          <img
            src={logoSrc}
            width="50px"
            className={styles.logo}
            style={{ backgroundColor: brandHex }}
            alt="logo"
          />

          <div>
            <h1>{header}</h1>
            <div className={styles.subtitle}>
              <i className={"material-icons " + styles.redIcon} title="check icon">
                cancel
              </i>
              Your account is not connected.
            </div>
          </div>
        </div>
      )}
      <div className={styles.form}>
        <div>
          Need your Advance API Key? Follow the steps under{" "}
          <a
            href="https://graduwayhelp.zendesk.com/hc/en-us/articles/6652609570460-Advance-Integration"
            target="_blank"
            rel="noopener noreferrer">
            How To Connect
          </a>
        </div>
        <TextField
          fullWidth={true}
          id="apiKey"
          label="Advance API Key"
          variant="outlined"
          type="password"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
        />
        <FormControl variant="outlined" className={styles.regionSelect}>
          <InputLabel id="region-label">Region</InputLabel>
          <Select
            labelId="region-label"
            id="region-select"
            value={region}
            onChange={(e) => setRegion(e.target.value)}
            label="region">
            {regionOptions.map((option) => (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {state.errorMessage && <Alert severity="error">{state.errorMessage}</Alert>}
        <div>
          <LoadingButton
            variant="contained"
            color="primary"
            buttonState={state.buttonState}
            savedLabel="Connected!"
            label="Connect"
            clickedRegularButton={saveApiToken}
            clickedSavedButton={() => {
              setState({});
            }}
          />
        </div>
      </div>
    </div>
  );
}
