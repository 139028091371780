import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ProductsUsageMeter from "./util/productsUsageMeter";
import { LinearProgress, Tooltip } from "@material-ui/core";
import BillingFeaturesLookup from "../../util/data/billingFeaturesLookup.json";
import { getAccountSubscription } from "services/api.service";

export default function Subscription(props) {
  const [subscription, setSubscription] = useState({});
  const [loading, setLoading] = useState(true);

  const { accountInfo } = props;
  const integrationsEndpoint = accountInfo.integrations?.endpoint || {};

  useEffect(() => {
    getAccountSubscription(accountInfo.id)
      .then((data) => {
        setSubscription(data);
      })
      .catch()
      .finally(() => {
        setLoading(false);
      });
  }, [accountInfo]);

  const planLabel =
    subscription.plan === "recurring"
      ? subscription.interval === "month"
        ? "Monthly Plan"
        : "Annual Plan"
      : subscription.plan === "enterprise"
      ? "Enterprise Plan"
      : "Error retrieving plan data";

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <div className="row col-12 pagePadding">
      <div className="col-12" style={{ marginTop: "15px" }}>
        <h1>Subscription</h1>
      </div>

      <div className="col-12">
        <p style={{ margin: "10px 0px" }}>
          Email sales@gratavid.com to make any changes to your plan.
        </p>
      </div>

      {!accountInfo.active ? (
        <div
          className="col-12"
          style={{
            margin: "25px 0px 16px 0px",
            boxShadow: "inset 0 -1px #e3e8ee",
            paddingBottom: "16px",
          }}>
          <h2 style={{ fontSize: "14px", display: "flex", alignItems: "center" }}>
            <i
              className="material-icons"
              title="error icon"
              style={{ color: "red", fontSize: "25px", marginRight: "4px" }}>
              error
            </i>
            Your subscription is not active
          </h2>
        </div>
      ) : (
        <div>
          <div
            className="col-12"
            style={{
              margin: "25px 0px 16px 0px",
              boxShadow: "inset 0 -1px #e3e8ee",
              paddingBottom: "6px",
            }}>
            <h2 style={{ fontSize: "14px" }}>CURRENT PLAN</h2>
          </div>
          <div className="col-12">
            <h2
              style={{
                display: "flex",
                alignItems: "center",
                textTransform: "capitalize",
              }}>
              {subscription?.plan ? (
                <i
                  className="material-icons"
                  title="check icon"
                  style={{ color: "green", fontSize: "25px", marginRight: "4px" }}>
                  check_circle
                </i>
              ) : (
                <i
                  className="material-icons"
                  title="check icon"
                  style={{ color: "red", fontSize: "25px", marginRight: "4px" }}>
                  error
                </i>
              )}
              {planLabel}
            </h2>
          </div>

          <ProductsUsageMeter accountInfo={accountInfo} subscription={subscription} />

          <div className="row col-12">
            {Object.keys(integrationsEndpoint).map((integrationKey) => {
              const integration = integrationsEndpoint[integrationKey];
              const integrationName = integrationKey + "Integration";
              let active = integration.active;
              let feature = BillingFeaturesLookup[integrationName]
                ? BillingFeaturesLookup[integrationName]
                : {};
              let { label = null } = feature;
              if (!label || !active) return null;
              return (
                <Tooltip title={"Your plan includes " + label} key={integrationName}>
                  <div
                    style={{
                      display: "flex",
                      height: 35,
                      borderRadius: 24,
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                      alignItems: "center",
                      fontSize: 12,
                      padding: 12,
                      margin: 4,
                    }}>
                    <i className="material-icons">check</i>
                    {label}
                  </div>
                </Tooltip>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

Subscription.propTypes = {
  accountInfo: PropTypes.object.isRequired,
};
