import React, { useState } from "react";
import TextInputToApi from "../../../util/hooks/input/textInputToApi";
import { updateSelf } from "services/api.service";
import { IconButton } from "@material-ui/core/";

export default function Name(props) {
  let [open, setOpen] = useState(false);
  let { userInfo, accountInfo } = props;
  const fullName = userInfo?.fullName ?? "";
  let [name, setName] = useState(fullName);

  let expansionPanelClass = open ? "expansionPanel" : "expansionPanel collapsible";

  const onChange = async (field, value) => {
    if (field === "fullName") {
      setName(value);
    }

    try {
      await updateSelf({ [field]: value });
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <div className="col-12 row adSectionBox" style={{ marginTop: "15px" }}>
      <div
        className="col-12 row"
        onClick={() => setOpen(!open)}
        style={{ cursor: "pointer" }}>
        <div style={{ width: "50px", display: "flex", alignItems: "center" }}>
          <i className="material-icons">person</i>
        </div>
        <div style={{ flex: 1 }}>
          <h2 style={{ fontSize: "16px" }}>Your Name: {name}</h2>
          <span className="greyDesc" style={{ fontSize: "13px" }}>
            Change your name so it will automatically populate for any task you record.
          </span>
        </div>
        <div style={{ width: "50px" }}>
          <IconButton onClick={() => setOpen(!open)}>
            <i className="material-icons">{open ? "expand_less" : "expand_more"}</i>
          </IconButton>
        </div>
      </div>

      <div className={"col-12 row " + expansionPanelClass}>
        <div style={{ padding: "25px 0px", width: "100%" }}>
          <TextInputToApi
            initialValue={fullName}
            accountInfo={accountInfo}
            multiline={false}
            passedSetState={(state, value) => setName(value)}
            field="fullName"
            label="Your Name"
            variant="outlined"
            apiCall={onChange}
          />
        </div>
      </div>
    </div>
  );
}
