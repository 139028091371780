import React from "react";
import PropTypes from "prop-types";
import { FormControl, Select, InputLabel } from "@material-ui/core";

export default function SelectItem(props) {
  let {
    label = "",
    selectedItem = "",
    options = [],
    alt = "select",
    width = "50%",
  } = props; //selectedExcludes is all excludes for event ie - opportunityWon

  const handleChange = async (inputValue) => {
    props.handleChange(inputValue);
  };

  let optionsHTML = options.map((current) => {
    return (
      <option key={current.id} value={current.value}>
        {current.label}
      </option>
    );
  });

  return (
    <FormControl style={{ margin: "5px 0px", width }} variant="filled">
      <InputLabel id={alt}>{label}</InputLabel>
      <Select
        value={selectedItem}
        native
        onChange={(e) => handleChange(e.target.value)}
        inputProps={{ "aria-label": alt, name: alt, id: alt }}>
        {optionsHTML}
      </Select>
    </FormControl>
  );
}
SelectItem.propTypes = {
  label: PropTypes.string,
  selectedItem: PropTypes.any,
  options: PropTypes.array,
  alt: PropTypes.string,
  width: PropTypes.string,
  handleChange: PropTypes.func,
};
