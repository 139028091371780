import React from "react";
import timeSinceDate from "../functions/timeSinceDate";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";

export default function ItemAnalytics(props) {
  //valuesNegative is when sorting in desc order on contact main page
  let { analytics, dataIsTotals = false } = props;
  if (!analytics) return <div style={{ height: "24px" }} />;

  let resendEvents = [
    {
      event: "email_resend",
      icon: "replay",
      desc: "Resent email",
      totalsDesc: "email resend(s)",
    },
  ];

  let emailEvents = [
    {
      event: "email_processed",
      icon: "hourglass_empty",
      desc: "Email processing",
      totalsDesc: "email(s) processing",
    },
    {
      event: "email_deferred",
      icon: "warning",
      desc: "Email deferred",
      totalsDesc: "email(s) deferred",
    },
    { event: "edr", icon: "error", desc: "Email dropped", totalsDesc: "email drop(s)" },
    { event: "ebl", icon: "error", desc: "Email blocked", totalsDesc: "email block(s)" },
    {
      event: "email_bounce",
      icon: "error",
      desc: "Email bounced",
      totalsDesc: "email(s) bounced",
    },
    {
      event: "email_delivered",
      icon: "send",
      desc: "Email delivered",
      totalsDesc: "email(s) delivered",
    },
    {
      event: "email_open",
      icon: "visibility",
      desc: "Email opened",
      totalsDesc: "email open(s)",
    },
    {
      event: "email_click",
      icon: "touch_app",
      desc: "Email clicked",
      totalsDesc: "email click(s)",
    },
  ];

  let textEvents = [
    { event: "text_sent", icon: "send", desc: "Text sent", totalsDesc: "text send(s)" },
    {
      event: "text_failed",
      icon: "error",
      desc: "Text could not be delivered. Most likely not a mobile number. But possible landline, incorrect number or cell service refused it",
      totalsDesc: "text fail(s)",
    },
    {
      event: "text_undelivered",
      icon: "error",
      desc: "Text could not be delivered. Possible incorrect number for contact or cell service refused it",
      totalsDesc: "text fail(s)",
    },
    {
      event: "text_delivered",
      icon: "sms",
      desc: "Text delivered",
      totalsDesc: "text(s) delivered",
    },
    {
      event: "text_click",
      icon: "touch_app",
      desc: "Text clicked",
      totalsDesc: "text click(s)",
    },
  ];

  let noteEvents = [
    {
      event: "pageView",
      icon: "visibility",
      desc: "Note viewed",
      totalsDesc: "page view(s)",
    },
    {
      event: "playVideo",
      icon: "play_circle_outline",
      desc: "Video played",
      totalsDesc: "video play(s)",
    },
    {
      event: "ctaClick",
      icon: "touch_app",
      desc: "CTA clicked",
      totalsDesc: "CTA click(s)",
    },
    { event: "share", icon: "share", desc: "Note shared", totalsDesc: "share(s)" },
    { event: "reply", icon: "reply", desc: "Note reply", totalsDesc: "replies" },
  ];

  let getLabel = (currentEvent) => {
    let label = "";
    let data = analytics[currentEvent.event];
    if (!data) return null;
    if (dataIsTotals) label = data + " " + currentEvent.totalsDesc;
    else {
      label = currentEvent.desc + " " + timeSinceDate(data, "", "");
    }
    return label;
  };

  let resendIcon = [];
  resendEvents.forEach((currentEvent) => {
    let label = getLabel(currentEvent);
    if (!label) return;

    resendIcon[0] = (
      <Tooltip title={label} key={currentEvent.event} enterTouchDelay={0}>
        <Icon aria-label="resendIcon" size="small">
          <i className="material-icons" style={{ fontSize: "18px" }}>
            {currentEvent.icon}
          </i>
        </Icon>
      </Tooltip>
    );
  });

  let emailIcon = [];
  emailEvents.forEach((currentEvent) => {
    let label = getLabel(currentEvent);
    if (!label) return;

    emailIcon[0] = (
      <Tooltip title={label} key={currentEvent.event} enterTouchDelay={0}>
        <Icon aria-label="emailIcon" size="small">
          <i className="material-icons" style={{ fontSize: "18px" }}>
            {currentEvent.icon}
          </i>
        </Icon>
      </Tooltip>
    );
  });

  let textIcon = [];
  textEvents.forEach((currentEvent) => {
    let label = getLabel(currentEvent);
    if (!label) return;

    textIcon[0] = (
      <Tooltip title={label} key={currentEvent.event} enterTouchDelay={0}>
        <Icon aria-label="emailIcon" size="small">
          <i className="material-icons" style={{ fontSize: "18px" }}>
            {currentEvent.icon}
          </i>
        </Icon>
      </Tooltip>
    );
  });

  let noteIcons = noteEvents.map((currentEvent) => {
    let label = getLabel(currentEvent);
    if (!label) return null;

    if (currentEvent.event === "pageView" && analytics["playVideo"]) return null; //if video played then hide note view

    return (
      <Tooltip title={label} key={currentEvent.event} enterTouchDelay={0}>
        <Icon aria-label="emailIcon" size="small">
          <i className="material-icons" style={{ fontSize: "18px" }}>
            {currentEvent.icon}
          </i>
        </Icon>
      </Tooltip>
    );
  });

  return (
    <div>
      {resendIcon}
      {emailIcon}
      {textIcon}
      {noteIcons}
    </div>
  );
}
