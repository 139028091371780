import React from "react";
import PropTypes from "prop-types";
import styles from "./description.module.css";

const Description = (props) => {
  const { title, required, description, error } = props;
  const titleClasses = `${styles.title} ${error ? styles.error_text : ""}`;
  return (
    <div className={styles.description_wrapper}>
      <h3 className={titleClasses}>{title + (required ? " *" : "")}</h3>
      <p className={styles.description_text}>{description}</p>
      {error && <p className={styles.error_text}>{error}</p>}
    </div>
  );
};

Description.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  error: PropTypes.string,
  required: PropTypes.bool,
};

Description.defaultProps = {
  required: false,
  error: null,
};

export default Description;
