import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

export default function ThumbnailVideoDialog(props) {
  const [videoDialogOpen, setVideoDialogOpen] = useState(false);

  return (
    <>
      <Button
        variant="text"
        style={{ width: "75px", height: "50px", padding: "0px", position: "relative" }}
        onClick={() => setVideoDialogOpen(true)}>
        <img
          src={props.thumbnailUrl}
          style={{
            width: "75px",
            height: "50px",
            objectFit: "cover",
            borderRadius: "8px",
          }}
          alt="video thumbnail"
        />
        <div
          style={{
            position: "absolute",
            display: "flex",
            align: "center",
            justifyContent: "center",
          }}>
          <img
            src={"/static/img/playButton.svg"}
            style={{ maxWidth: "25px", maxHeight: "25px" }}
            alt="play thumbnail"
          />
        </div>
      </Button>

      <Dialog open={videoDialogOpen} onClose={() => setVideoDialogOpen(false)}>
        <video
          autoPlay
          controls
          src={props.video?.videoUrl + "?cacheBuster=123"}
          preload="none"
          width="100%"
          height="auto"
          type="video/mp4">
          Sorry, your browser doesn't support embedded videos.{" "}
          <a href={props.video?.videoUrl} target="_blank" rel="noopener noreferrer">
            Click here to view
          </a>
        </video>
      </Dialog>
    </>
  );
}
