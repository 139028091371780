import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

export default function AutoSuggestInput(props) {
  return (
    <>
      <Autocomplete
        id="country-select-demo"
        options={props.accountVideos}
        onChange={(e, item) => props.itemSelected(item)}
        getOptionLabel={(option) => option.title}
        renderOption={(option) => (
          <React.Fragment>
            <img
              src={option.thumbnailUrl || option.thumbnail}
              style={{
                width: "75px",
                height: "50px",
                objectFit: "cover",
                borderRadius: "8px",
                backgroundColor: "#ddd",
              }}
              alt="video thumbnail"
            />
            <span style={{ marginLeft: 10 }}>{option.title}</span>
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select video"
            variant="outlined"
            fullWidth
            placeholder="Search existing videos"
            autoComplete="off"
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
      />
    </>
  );
}
