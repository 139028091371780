import Button from "@material-ui/core/Button";
import React, { useState } from "react";
import PropTypes from "prop-types";
import LoadingButtonCheckProductLimits from "../../../../util/buttons/productLimits/loadingbutton";
import {
  importBlackbaudListContacts,
  importSalesforceListContacts,
} from "services/api.service";
import { downloadFile } from "util/download";
import { objectToCsv } from "util/csv";

export default function ConfirmAndAdd(props) {
  let { platformLabel = "", platformKey = "" } = props;
  let { properties, selectedList } = props;
  let { count: countToAdd = 0 } = selectedList; //this won't be populate for some integraitons like salesforce because api doesn't return number of people on list
  let [state, setState] = useState({});

  let confirmAndUploadList = async () => {
    try {
      //first make sure email is selected
      if (!properties.email.mapped) {
        throw new Error("Must map email");
      }
      if (!properties.customUserId.mapped) {
        throw new Error("Must map " + platformLabel + " Id to Gratavid Custom User Id");
      }

      setState({ buttonState: "loading" });

      let result;
      if (platformKey === "blackbaud") {
        result = await importBlackbaudListContacts(
          props.selectedList.id,
          props.properties,
          props.selectedList.label
        );
      } else if (platformKey === "salesforce") {
        result = await importSalesforceListContacts(
          props.selectedList.id,
          props.properties,
          props.selectedList.label
        );
      }

      props.clearProperties(); //this will clear all mappings in case we need to upload new list

      if (result.errors.length) {
        setState({
          buttonState: "saved",
          errorMessage: (
            <span>
              {result.errors.length} warnings/errors.&nbsp;
              <button
                className="link-button"
                style={{ marginLeft: "10px" }}
                onClick={() => downloadErrorsCSV(result.errors)}>
                View Details
              </button>
            </span>
          ),
        });
      } else {
        setState({
          buttonState: "saved",
          contactsUpdated: result?.contacts?.length ?? 0,
        });

        if (props.addContactOrFolder && result?.folder?.id) {
          let data = {
            type: "folder",
            folderId: result.folder.id,
            label: result.folder.name,
          };
          props.addContactOrFolder("folder", data);
          props.setAddNewDialogOpen(false);
        }
      }
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.error(errorMessage);
      setState({ errorMessage: errorMessage });
    }
  };

  let downloadErrorsCSV = (errors) => {
    try {
      downloadFile(objectToCsv(errors.map((message) => ({ message }))), "errors.csv");
    } catch (error) {
      setState({ errorMessage: error.message });
    }
  };

  let { errorMessage, buttonState, contactsUpdated = 0 } = state;
  let saveLabel = contactsUpdated
    ? contactsUpdated + " Contacts Uploaded"
    : "Contacts Uploaded";

  return (
    <>
      <div className="col-12" style={{ height: "70px" }} />
      <div className="bulkUploadActionBar">
        <div style={{ flex: 1 }} className="justify-content-end">
          {errorMessage && (
            <div className="bulkUploadActionBarErrorMessageBox">
              <i
                className="material-icons"
                style={{ marginRight: "5px", color: "darkorange" }}>
                warning
              </i>
              <b>{errorMessage}</b>
            </div>
          )}
        </div>
        <div className="justify-content-end" style={{ width: "240px" }}>
          {(!buttonState || buttonState === "regular") && (
            <Button
              variant="outlined"
              onClick={props.goBackClicked}
              style={{ marginRight: "20px" }}>
              Back
            </Button>
          )}

          <LoadingButtonCheckProductLimits
            accountInfo={props.accountInfo}
            countToAdd={countToAdd ? countToAdd : 1}
            productKey="contacts"
            productLabel="contact"
            userIsAdmin={props.userIsAdmin}
            variant="contained"
            color="primary"
            buttonState={buttonState}
            savedLabel={saveLabel}
            label="Upload Now"
            clickedRegularButton={confirmAndUploadList}
            clickedSavedButton={() => props.setAddNewDialogOpen(false)}
            showErrorAsTooltip={true}
          />
        </div>
      </div>
    </>
  );
}

ConfirmAndAdd.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  setAddNewDialogOpen: PropTypes.func.isRequired,
  userIsAdmin: PropTypes.bool,
};
