import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReplyDialog from "./replyDialog";
import { Button, LinearProgress } from "@material-ui/core";
import LoadingButton from "../../util/buttons/loadingbutton";
import { getNoteReplies } from "services/api.service";

export default function Replies(props) {
  const { accountInfo, noteId, gvSendId } = props;
  const [replies, setReplies] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  let [replySelected, setReplySelected] = useState(null);

  const getReplies = useCallback(
    (gvSendId, offset) => {
      setIsLoading(true);
      getNoteReplies(noteId, gvSendId, 6, offset)
        .then((result) => {
          if (offset === 0) {
            setReplies(result.rows);
          } else {
            setReplies((current) => [...current, ...result.rows]);
          }
          setCount(() => result.count);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [noteId, setReplies, setCount]
  );

  function loadMore() {
    getReplies(gvSendId, replies.length);
  }

  useEffect(() => {
    getReplies(gvSendId, 0);
  }, [getReplies, gvSendId]);

  //fullScreen is created from withMobileDialog
  if (isLoading === "loading" && replies.length === 0) {
    return (
      <div className="row col-12" style={{ padding: "10px", minHeight: "100px" }}>
        <div
          className="boxShadow col-12"
          style={{ backgroundColor: "white", padding: "20px", borderRadius: "3px" }}>
          <LinearProgress />
        </div>
      </div>
    );
  }

  let repliesHTML = replies.map((current) => {
    let { id, replyVideoSrc, replyText } = current;

    return (
      <div key={id} style={{ padding: "5px" }}>
        <Button
          onClick={() => setReplySelected(current)}
          variant="contained"
          style={{
            textTransform: "initial",
            textAlign: "left",
            backgroundColor: "#E0E0E0",
            borderRadius: "20px",
          }}>
          {replyVideoSrc ? (
            <video
              src={replyVideoSrc + "#t=0.01"}
              style={{ maxHeight: "60px", borderRadius: "8px" }}
            />
          ) : (
            <i
              className="material-icons"
              style={{ backgroundColor: "#BCBCBC", borderRadius: "50%" }}>
              format_quote
            </i>
          )}

          {replyText && <span style={{ marginLeft: "10px" }}>{replyText}</span>}
        </Button>
      </div>
    );
  });

  return (
    <div className="row col-12" style={{ padding: "10px" }}>
      <div
        className="row col-12 boxShadow"
        style={{ backgroundColor: "white", padding: "15px", borderRadius: "3px" }}>
        <div className="col-12">
          <h2 style={{ textTransform: "capitalize" }}>Replies</h2>
        </div>

        <div className="col-12" style={{ marginTop: "25px" }}>
          {replies.length === 0 ? (
            <p>No replies</p>
          ) : (
            <div className="row col-12">{repliesHTML}</div>
          )}
        </div>

        {replies.length < count && (
          <div
            className="col-12 justify-content-center"
            style={{ margin: "10px 0px 20px 0px" }}>
            <LoadingButton
              type="button"
              variant="outlined"
              size="small"
              color="default"
              buttonState={isLoading ? "loading" : null}
              savedLabel={"Loaded"}
              label="Show more"
              clickedRegularButton={loadMore}
              clickedSavedButton={() => {
                return null;
              }}
            />
          </div>
        )}

        {replySelected && (
          <ReplyDialog
            currentReply={replySelected}
            setReplySelected={setReplySelected}
            {...props}
          />
        )}
      </div>
    </div>
  );
}

Replies.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  noteId: PropTypes.string.isRequired,
  gvSendId: PropTypes.string,
};
