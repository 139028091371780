import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getAccountFolders } from "services/api.service";
import Folders from "components/util/folders/folders";

export default function ListFolders(props) {
  const [folders, setFolders] = useState([]);

  useEffect(() => {
    let getFoldersData = async () => {
      try {
        const folders = await getAccountFolders(props.indexName);
        const enrichedFolders = folders.map((folder) => ({
          ...folder,
          label: folder.name,
          selected: props.foldersFilter.some((folderId) => folderId === folder.id),
        }));
        enrichedFolders.unshift({
          id: "all",
          label: "All",
          selected: props.foldersFilter?.length === 0,
        });
        setFolders(enrichedFolders);
      } catch (err) {
        console.error(err.message);
      }
    };

    getFoldersData();
  }, [props.indexName]);

  const onToggle = (itemId) => {
    // find folder and manipulate data
    const found = folders.find(({ id }) => id === itemId);
    found.selected = !found.selected;
    const isAll = found.id === "all";

    // if is all de-select others
    if (isAll && found.selected) {
      folders.slice(1).forEach((folder) => (folder.selected = false));
    }

    // unselect "all" if another folder is selected
    if (!isAll && found.selected && folders[0].selected) {
      folders[0].selected = false;
    }

    // if no folder is selected, select "all"
    if (!isAll && !folders.some(({ selected }) => selected)) {
      folders[0].selected = true;
    }

    setFolders([...folders]);

    if (folders[0].selected) {
      props.setFoldersFilter([]);
    } else {
      props.setFoldersFilter(
        folders.filter(({ selected }) => selected).map(({ id }) => id)
      );
    }
  };

  return (
    <div
      className="row col-12"
      style={{ width: "250px", maxWidth: "250px", maxHeight: "400px" }}>
      <h3
        style={{
          margin: "20px 20px 10px 20px",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
        }}>
        <i className="material-icons" style={{ fontSize: "22px", marginRight: "15px" }}>
          filter_list
        </i>
        Filter by {props.indexName === "contacts" ? "list" : "tags"}
      </h3>

      <Folders
        accountId={props.accountId}
        folders={folders}
        setFolders={setFolders}
        onToggle={onToggle}
        indexName={props.indexName}
      />
    </div>
  );
}

ListFolders.propTypes = {
  accountId: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
  foldersFilter: PropTypes.array,
  setFoldersFilter: PropTypes.func,
};
