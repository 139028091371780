import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import LoadingButton from "../buttons/loadingbutton";
import SelectTags from "./selectTags";
import { listContactFolders, setContactFolders } from "services/api.service";

export default function EditExistingTags(props) {
  let [state, setState] = useState({});
  let [hasBeenUpdated, setHasBeenUpdated] = useState(null);
  const [selectedFoldersArray, setSelectedFoldersArray] = useState([]);
  let { contactId, accountInfo } = props;

  useEffect(() => {
    async function getFolders() {
      // get folders
      const contactFolders = await listContactFolders(contactId);
      setSelectedFoldersArray(
        contactFolders.map((cf) => ({ ...cf, label: cf.name, selected: true }))
      );
    }

    getFolders();
  }, [contactId, accountInfo.id]);

  let saveTags = async (e) => {
    if (e) e.preventDefault();
    try {
      setState({ buttonState: "loading" });
      await setContactFolders([
        {
          contactId,
          folderIds: selectedFoldersArray.map(({ id }) => id),
        },
      ]);
      setState({ buttonState: "saved" });
    } catch (err) {
      let errorMessage = err?.data?.message ? err.data.message : err.message;
      console.log(errorMessage);
      setState({ buttonState: null });
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1 }}>
        <SelectTags
          accountId={props.accountInfo.id}
          indexName="contact"
          setSelectedFoldersArray={setSelectedFoldersArray}
          selectedFoldersArray={selectedFoldersArray}
          setHasBeenUpdated={setHasBeenUpdated}
        />
      </div>
      {hasBeenUpdated && (
        <div
          style={{
            width: "80px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}>
          <LoadingButton
            variant="contained"
            color="primary"
            buttonState={state.buttonState}
            savedLabel="Saved!"
            label="Save"
            clickedRegularButton={saveTags}
            clickedSavedButton={() => setState({})}
          />
        </div>
      )}
    </div>
  );
}

EditExistingTags.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  contactId: PropTypes.string.isRequired,
};
