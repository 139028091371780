import React from "react";
import SelectUser from "../../../../../util/users/selectUser";
import SelectNote from "../../..//actions/selectNote";
import { FormControl, Select, IconButton, Button, InputLabel } from "@material-ui/core";
import Condition from "./condition";

export default function BlackbaudAction(props) {
  let { currentAction = {}, actions = {}, actionIndex, order } = props;
  let {
    successFunc = "doNothing",
    assignedTo = "",
    noteId = null,
    noteTitle = null,
    conditions,
  } = currentAction;

  let editAction = (defaultAction, key, value, conditionId) => {
    let tempActions = { ...actions };

    if (defaultAction) {
      tempActions[key] = value;
    } else {
      //metric values are unders the conditions. success functions are note
      if (conditionId) tempActions.list[actionIndex].conditions[conditionId][key] = value;
      tempActions.list[actionIndex][key] = value;
    }

    props.setActions(tempActions);
  };

  let assignUserSelected = (defaultAction, userUID) => {
    let tempActions = { ...actions };
    if (defaultAction) {
      tempActions.assignedTo = userUID;
    } else {
      tempActions.list[actionIndex].assignedTo = userUID;
    }

    props.setActions(tempActions);
  };

  let noteSelected = (defaultAction, note) => {
    let tempActions = { ...actions };
    if (defaultAction) {
      if (!note) delete tempActions.noteId;
      else {
        tempActions.noteId = note.id;
        //have to save title too
        tempActions.noteTitle = note.title;
      }
    } else {
      if (!note) delete tempActions.list[actionIndex].noteId;
      else {
        tempActions.list[actionIndex].noteId = note.id;
        //have to save title too
        tempActions.list[actionIndex].noteTitle = note.title;
      }
    }

    props.setActions(tempActions);
  };

  let deleteAction = (actionIndex) => {
    let tempActions = { ...actions };
    let list = tempActions.list;
    list.splice(actionIndex, 1);
    props.setActions(tempActions);
  };

  let addCondition = (newValue) => {
    //firebase keys are chronological, but order doesn't matter since all conditions must be true
    let newConditionId = crypto.randomUUID();

    let item = {
      metric: "transactionAmount",
      metricValue: 100,
      operator: "greaterEqual",
    };

    let tempActions = { ...actions };
    tempActions.list[actionIndex].conditions[newConditionId] = item;
    props.setActions(tempActions);
  };

  let conditionsKeys = Object.keys(conditions);

  return (
    <div>
      {actionIndex > 0 && (
        <div className="col-12" style={{ padding: "10px 0px 0px 70px" }}>
          else if the above rule doesn't evaluate to be true, then try this rule
        </div>
      )}

      <div className="row col-12" style={{ margin: "10px 0px" }}>
        <div
          style={{
            width: "70px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <IconButton
            onClick={() => deleteAction(actionIndex)}
            style={{ marginRight: "8px" }}>
            <i className="material-icons" style={{ fontSize: "18px" }}>
              delete
            </i>
          </IconButton>
        </div>

        <div
          style={{
            flex: 1,
            marginTop: "6px",
            border: "2px #DDECF6 solid",
            borderRadius: "4px",
          }}>
          <div
            style={{
              cursor: "pointer",
              width: "100%",
              display: "flex",
              height: "35px",
              borderBottom: "1px #ddd solid",
              padding: "5px",
              backgroundColor: "#DDECF6",
            }}>
            <i className="material-icons" style={{ color: "#487CB4" }}>
              drag_handle
            </i>
            <div
              style={{ margin: "2px 0px 0px 5px", color: "#487CB4", fontWeight: "bold" }}>
              Rule: {order}
            </div>
          </div>

          <div style={{ padding: "30px 30px 60px 30px" }}>
            {conditionsKeys.map((conditionId, index) => {
              return (
                <Condition
                  key={conditionId}
                  current={conditions[conditionId]}
                  conditions={conditions}
                  conditionId={conditionId}
                  order={index}
                  actionIndex={actionIndex}
                  actions={actions}
                  setActions={props.setActions}
                  accountId={props.accountInfo.id}
                />
              );
            })}

            <Button
              size="small"
              onClick={addCondition}
              variant="text"
              style={{ margin: "10px 0px 0px 70px" }}>
              <i className="material-icons" style={{ fontSize: "18px" }}>
                add
              </i>
              <span
                style={{
                  textTransform: "capitalize",
                  fontSize: "14px",
                  marginLeft: "3px",
                }}>
                Add Condition
              </span>
            </Button>

            <div
              className="col-12"
              style={{ paddingLeft: "70px", margin: "30px 0px 10px 0px" }}>
              <i
                className="material-icons"
                style={{ fontSize: "30px", color: "#487CB4" }}>
                arrow_downward
              </i>
              <i
                className="material-icons"
                style={{ fontSize: "30px", color: "#487CB4" }}>
                arrow_downward
              </i>
              <i
                className="material-icons"
                style={{ fontSize: "30px", color: "#487CB4" }}>
                arrow_downward
              </i>
            </div>

            <div style={{ display: "flex", paddingTop: "5px" }}>
              <div
                style={{
                  width: "80px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>THEN</h3>
              </div>

              <FormControl
                style={{ width: "175px", paddingRight: "15px" }}
                variant="filled">
                <InputLabel id="assignedTo">Select Action</InputLabel>
                <Select
                  value={successFunc}
                  native
                  onChange={(e) => editAction(false, "successFunc", e.target.value)}
                  inputProps={{
                    "aria-label": "Filter task by assignee",
                    name: "assignedTo",
                    id: "assignedTo",
                  }}>
                  <option aria-label="None" value="doNothing">
                    Do nothing
                  </option>
                  <option aria-label="Create Task" value="createTask">
                    Create Task
                  </option>
                  <option aria-label="Send Note" value="sendNote">
                    Send Note
                  </option>
                </Select>
              </FormControl>

              {successFunc === "createTask" && (
                <SelectUser
                  assignedTo={assignedTo}
                  {...props}
                  userSelected={(userUID) => assignUserSelected(false, userUID)}
                  variant="filled"
                  emptyLabel="unassigned"
                  label="Select Assignee"
                />
              )}
              {successFunc === "sendNote" && (
                <SelectNote
                  accountInfo={props.accountInfo}
                  noteId={noteId}
                  noteTitle={noteTitle}
                  itemSelected={(note) => noteSelected(false, note)}
                  variant="outlined"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
