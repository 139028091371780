import React from "react";
import { CircularProgress } from "@material-ui/core";
import "../../../style/toggleSwitch.css";
import PropTypes from "prop-types";

export default function LoadingSwitch(props) {
  if (props.buttonState === "loading")
    return (
      <div className="col-12" style={{ padding: "20px" }}>
        {" "}
        <CircularProgress style={{ width: "20px", height: "20px" }} />
      </div>
    );

  let disabled = props.disabled ? props.disabled : false;
  let checked = props.checked ? props.checked : false;
  return (
    <input
      className="toggle"
      type="checkbox"
      disabled={disabled}
      checked={checked}
      onClick={props.handleSwitch}
      onChange={() => {
        return null;
      }}
    />
  );
}
LoadingSwitch.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  buttonState: PropTypes.string,
  handleSwitch: PropTypes.func,
};
