import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import BackgroundStyle from "../preview/backgroundStyle";
import ListImagesDialog from "../../../../util/images/listImagesDialog";

export default function NoteBackground(props) {
  let {
    primaryColor,
    secondaryColor,
    background = {},
    accountInfo,
    saveNoteState,
    logo,
  } = props;
  const backgroundStyle = BackgroundStyle(background);
  backgroundStyle["height"] = "100px";
  backgroundStyle["top"] = "5px";
  backgroundStyle["minHeight"] = "100px";
  backgroundStyle["width"] = "100px";
  backgroundStyle["border"] = "1px #ddd solid";

  let [dialogOpen, setDialogOpen] = useState(false);

  return (
    <div>
      <label
        htmlFor="backgroundImage"
        style={{ fontSize: "12px", color: "rgba(0,0,0,0.54)", marginTop: "10px" }}>
        Background Image
      </label>
      <br />
      <Button
        variant="outlined"
        color="default"
        style={{ height: "110px", position: "relative", borderRadius: "16px" }}
        onClick={() => setDialogOpen(true)}
        aria-label="background image"
        id="backgroundImage">
        <div
          style={{ width: "100px", cursor: "pointer" }}
          onClick={() => setDialogOpen(true)}>
          <div key={background.id} style={backgroundStyle} />
        </div>
      </Button>

      <ListImagesDialog
        accountInfo={accountInfo}
        background={background}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        passedSetState={saveNoteState}
        logo={logo}
        type="background"
      />
    </div>
  );
}

NoteBackground.propTypes = {
  accountInfo: PropTypes.object,
  logo: PropTypes.string,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  background: PropTypes.object,
  saveNoteState: PropTypes.func,
};
