import React from "react";

export default function General(props) {
  let currentEmail = props.userInfo.email;

  return (
    <div className="col-12 row adSectionBox" style={{ marginTop: "15px" }}>
      <div className="col-12 row">
        <div style={{ width: "50px", display: "flex", alignItems: "center" }}>
          <i className="material-icons">email</i>
        </div>
        <div style={{ flex: 1 }}>
          <h2 style={{ fontSize: "16px" }}>Your Email: {currentEmail}</h2>
          <span className="greyDesc" style={{ fontSize: "13px" }}>
            The email where you will receive notifications and used for login.
          </span>
        </div>
      </div>
    </div>
  );
}
