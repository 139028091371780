import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Message from "./message/message";
import Email from "./message/email";
import Resend from "./message/resend";
import TextMessage from "./message/textMessage";
import SendTask from "./sendTask";
import SelectNote from "./select/selectNote";
import { LinearProgress, Button } from "@material-ui/core";
import { useGetNoteContent } from "../../../../../util/hooks/useGetNoteContent";
import { patchTask, getNote, getSelf, updateSelf } from "services/api.service";
import { debounce } from "util/helpers";

export default function EditNote(props) {
  let {
    accountInfo,
    noteId,
    customContactProperties,
    activeContactCountForTask,
    forceLoadFreshNoteContent,
    firstContactData,
    atleastOneContactOptedInToText,
  } = props;

  let [noteTitle, setNoteTitle] = useState("loading");
  let [shareViaEmail, setShareViaEmail] = useState("loading");
  let [shareViaText, setShareViaText] = useState("loading");
  let [resendActive, setResendActive] = useState(false);
  let [appendNoteVids, setAppendNoteVids] = useState(false);
  let [lastNoteSelected, setLastNoteSelected] = useState("loading");

  async function onUpdateTask(field, value, bulkUpdates) {
    const updates = bulkUpdates || { [field]: value };
    debouncedEditTask(props.currentTask.id, updates);
  }

  const debouncedEditTask = useCallback(debounce(patchTask), [props]);

  //have to get account accountCustomProperties so all inputs using a mergeFields can be converted to actual value
  let [noteContent] = useGetNoteContent(
    noteId,
    props.currentTask.id,
    null,
    forceLoadFreshNoteContent
  );

  useEffect(() => {
    if (!props.noteId) {
      setNoteTitle(null);
      return;
    }

    let getNoteData = async () => {
      try {
        setShareViaEmail("loading");
        if (noteContent === "loading") return;

        let {
          shareViaEmail: initialShareViaEmail = false,
          shareViaText: initialShareViaText = false,
          appendNoteVids: initialAppendNoteVids = false,
          resendActive: initialResendActive = false,
          title,
        } = noteContent;
        setAppendNoteVids(initialAppendNoteVids);
        setShareViaEmail(initialShareViaEmail);
        setResendActive(initialResendActive);
        setShareViaText(initialShareViaText);
        setNoteTitle(title);
      } catch (err) {
        console.error(err.message);

        //alright so if note gets deleted that was associated with a task, that will throw an error saying don't have access to delete
        //therefore we need to just delete the note reference to this task and start over
        try {
          await patchTask(props.currentTask.id, { noteId: null });
          props.setTasks(
            props.tasks.map((task) => {
              if (task.id === props.currentTask.id) {
                return {
                  ...task,
                  noteId: null,
                };
              } else {
                return task;
              }
            })
          );
        } catch (err) {
          console.error("inside catch catch: " + err.message);
        }
      }
    };

    getNoteData();
  }, [noteContent]);

  useEffect(() => {
    let getData = async () => {
      try {
        const { lastSelectedNoteId } = await getSelf();

        if (lastSelectedNoteId) {
          const note = await getNote(lastSelectedNoteId);
          if (note) {
            setLastNoteSelected({ id: note.id || null, title: note.title || "" });
          } else {
            setLastNoteSelected(null);
          }
        }
      } catch (err) {
        setLastNoteSelected(null);
        console.error(err.message);
      }
    };

    getData();
  }, []);

  let noteSelectedFromSearch = async (item) => {
    const noteId = item?.id || null;

    try {
      let updatePromises = [patchTask(props.currentTask.id, { noteId })];

      if (item) {
        updatePromises.push(updateSelf({ lastSelectedNoteId: noteId }));

        setNoteTitle(item.title);
        setLastNoteSelected({ id: noteId, title: item.title });
      }

      props.setTasks(
        props.tasks.map((task) => {
          if (task.id === props.currentTask.id) {
            return {
              ...task,
              noteId,
            };
          } else {
            return task;
          }
        })
      );

      await Promise.all(updatePromises);
    } catch (err) {
      console.error(err.message);
      //either lastNoteSelected has been deleted or other error
      setLastNoteSelected(null);
      await updateSelf({ lastSelectedNoteId: null });
    }
  };

  if (noteTitle === "loading") {
    return (
      <>
        <Button
          size="small"
          onClick={() => props.setActiveStep(0)}
          style={{ textTransform: "none", marginBottom: "5px" }}>
          <i
            className="material-icons"
            style={{ marginRight: "5px", color: "grey", fontSize: "12px" }}>
            keyboard_backspace
          </i>
          <span style={{ fontSize: "12px", color: "grey" }}>Back to video</span>
        </Button>
        <div style={{ padding: "20px" }}>
          <LinearProgress />
        </div>
      </>
    );
  }

  if (!props.videoIds) {
    return (
      <>
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.setActiveStep(0)}
          style={{ textTransform: "none", marginBottom: "5px" }}>
          <i
            className="material-icons"
            style={{ marginRight: "5px", color: "grey", fontSize: "12px" }}>
            arrow_upward
          </i>
          Back to video
        </Button>
        <div className="errorMessageBox">
          <b>No video id selected. Please go back and create video.</b>
        </div>
      </>
    );
  }

  let textEnabled =
    accountInfo && accountInfo.textEnabled ? accountInfo.textEnabled : false;
  let contactCountForTask = props.currentTask.taskContacts.length;

  return (
    <div className="row col-12">
      <Button
        size="small"
        onClick={() => props.setActiveStep(0)}
        style={{ textTransform: "none", marginBottom: "10px" }}>
        <i
          className="material-icons"
          style={{ marginRight: "5px", color: "grey", fontSize: "12px" }}>
          arrow_upward
        </i>
        <span style={{ fontSize: "12px", color: "grey" }}>Back to video</span>
      </Button>

      {lastNoteSelected && lastNoteSelected !== "loading" && !props.noteId && (
        <Button
          variant="outlined"
          onClick={() =>
            noteSelectedFromSearch({
              id: lastNoteSelected.id,
              title: lastNoteSelected.title,
            })
          }
          style={{
            width: "100%",
            marginBottom: 10,
            height: 55,
            justifyContent: "flex-start",
          }}>
          <div
            style={{ width: 40, display: "flex", alignItems: "center", paddingLeft: 5 }}>
            <i
              className="material-icons"
              style={{ marginRight: "5px", color: "black", fontSize: "22px" }}>
              schedule
            </i>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              textTransform: "none",
            }}>
            <span style={{ fontSize: 14 }}>Last note selected</span>
            <span style={{ fontSize: 12, color: "#6b6b6b" }}>
              {lastNoteSelected.title}
            </span>
          </div>
        </Button>
      )}

      <SelectNote
        {...props}
        noteSelectedFromSearch={noteSelectedFromSearch}
        noteTitle={noteTitle}
      />

      {props.noteId && (
        <>
          {customContactProperties === "loading" ||
          shareViaEmail === "loading" ||
          noteContent === "loading" ||
          shareViaText === "loading" ? (
            <div className="col-12" style={{ marginTop: "20px" }}>
              <LinearProgress />
            </div>
          ) : (
            <>
              <Message
                {...props}
                onUpdateTask={onUpdateTask}
                noteContent={noteContent}
                appendNoteVids={appendNoteVids}
                customContactProperties={customContactProperties}
                firstContactData={firstContactData}
                contactCountForTask={contactCountForTask}
              />

              <Email
                {...props}
                onUpdateTask={onUpdateTask}
                shareViaEmail={shareViaEmail}
                setShareViaEmail={setShareViaEmail}
                noteContent={noteContent}
                customContactProperties={customContactProperties}
                contactCountForTask={contactCountForTask}
              />

              {textEnabled && (
                <TextMessage
                  {...props}
                  onUpdateTask={onUpdateTask}
                  shareViaText={shareViaText}
                  setShareViaText={setShareViaText}
                  noteContent={noteContent}
                  customContactProperties={customContactProperties}
                  firstContactData={firstContactData}
                  atleastOneContactOptedInToText={atleastOneContactOptedInToText}
                  contactCountForTask={contactCountForTask}
                />
              )}

              <Resend
                {...props}
                onUpdateTask={onUpdateTask}
                taskId={props.currentTask.id}
                noteContent={noteContent}
                resendActive={resendActive}
                setResendActive={setResendActive}
                customContactProperties={customContactProperties}
              />

              {noteContent.customizeEmailBody && (
                <div className="notificationMessageBox">
                  <b>
                    'Customize Email Body' is active for selected note. The email message
                    will be separate from the note message.{" "}
                    <a
                      href="https://graduwayhelp.zendesk.com/hc/en-us/articles/6654703321628-What-is-Customize-Email-Body-"
                      target="_blank"
                      rel="noopener noreferrer">
                      Learn more
                    </a>{" "}
                  </b>
                </div>
              )}

              <SendTask
                {...props}
                textEnabled={textEnabled}
                taskId={props.currentTask.id}
                shareViaEmail={shareViaEmail}
                shareViaText={shareViaText}
                activeContactCountForTask={activeContactCountForTask}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}

EditNote.propTypes = {
  userInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    lastSelectedNoteId: PropTypes.string,
  }).isRequired,
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    textEnabled: PropTypes.bool,
  }).isRequired,
  tasks: PropTypes.array.isRequired,
  currentTask: PropTypes.object.isRequired,
  noteId: PropTypes.string,
  customContactProperties: PropTypes.array.isRequired,
  activeContactCountForTask: PropTypes.number.isRequired,
  forceLoadFreshNoteContent: PropTypes.number.isRequired,
  firstContactData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  setTasks: PropTypes.func.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  atleastOneContactOptedInToText: PropTypes.bool,
  videoIds: PropTypes.array,
};
