import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./account.module.css";
import axios from "axios";
import NavBar from "../navbar/navbar";
import AccountNavbar from "./accountNavbar/navbar";
import { Link } from "react-router-dom";
import LogInBox from "../util/logincreate/loginbox";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MobileDrawer from "./drawer/mobile";
import DesktopDrawer from "./drawer/desktop";
import { capitalize } from "util/helpers";
import { getSearchParams } from "util/helpers";
import DrawerItem from "./drawer/drawerItem";
import MaintenanceSnackbar from "components/maintenanceSnackbar";

export default function Account(props) {
  let { pageId } = props;
  let [gratavidStatus, setGratavidStatus] = useState({});
  const [desktopOpen, setDesktopOpen] = useState(true);
  const [snackbar, setSnackbar] = useState({
    severity: "success",
    message: "",
    open: false,
  });
  const drawerWidth = desktopOpen ? 240 : 80;

  function handleAccountChange(e) {
    const accountId = e.target.value;
    const role = props.userInfo.roles.find((role) => role.accountId === accountId);
    props.changeAccount(accountId, role?.account?.name);
  }

  useEffect(() => {
    let getStatus = async () => {
      if (props.accountInfo.active === false && !props.hideInactiveWarning) {
        setGratavidStatus({
          status: "DOWN",
          headline:
            "Your account subscription is not active. You can't create or send videos.",
          isError: true,
        });
      } else {
        try {
          let baseURL =
            "https://cdn.contentful.com/spaces/fpsmihx5p1sb/environments/master-gratavid-v1/entries/37bMGe5FkYZnAPiLy1Y9qB?access_token=mJpibErPy58K-WD9S_7PxgXsRlxcNuiIxkrqefAPeDo";
          let result = await axios.get(baseURL);
          let entries = result && result.data;
          let { fields = {} } = entries;
          let { status = "OK", headline = "" } = fields;
          setGratavidStatus({ status, headline, isError: false });
        } catch (err) {
          console.error(err.message);
        }
      }
    };

    getStatus();
  }, [props.accountInfo.active, props.hideInactiveWarning]);

  //close popup window once signed in
  //this is called from shopfiy signup page
  useEffect(() => {
    if (!props.userInfo) return;
    let closeOnSignIn = getSearchParams().get("closeOnSignIn") ? true : false;
    if (closeOnSignIn && props.userInfo) window.self.close();
  }, [props.userInfo]);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        flexShrink: 0,
      },
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      top: "60px",
      height: "calc(100vh - 60px)",
      width: drawerWidth,
      border: "unset",
      boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.08)",
      padding: "16px 12px",
      overflowX: "hidden",
      gap: "8px",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      top: "60px",
      height: "calc(100vh - 60px)",
      width: drawerWidth,
      border: "unset",
      boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.08)",
      padding: "16px 12px",
      overflowX: "hidden",
      gap: "8px",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
      },
    },
  }));

  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSnackbar = (severity, message) => {
    setSnackbar({ severity, message, open: true });
  };

  const handleClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  let listDrawerItems = [
    <DrawerItem
      key="task"
      to={`/a/${props.accountInfo.id}/task`}
      onClick={() => setMobileOpen(false)}
      icon="list"
      label="Task List"
      isDesktopOpen={desktopOpen}
      isActive={pageId === "task" || pageId === ""}
      isOutlinedIcon={false}
    />,
    <DrawerItem
      key="notes"
      to={`/a/${props.accountInfo.id}/notes`}
      onClick={() => setMobileOpen(false)}
      icon="edit_note"
      label="Note Templates"
      isDesktopOpen={desktopOpen}
      isActive={pageId === "notes"}
    />,
    <DrawerItem
      key="birthday"
      to={`/a/${props.accountInfo.id}/birthday`}
      onClick={() => setMobileOpen(false)}
      icon="cake"
      label="Birthday Notes"
      isDesktopOpen={desktopOpen}
      isActive={pageId === "birthday"}
    />,
    <DrawerItem
      key="videos"
      to={`/a/${props.accountInfo.id}/videos`}
      onClick={() => setMobileOpen(false)}
      icon="videocam"
      label="Videos"
      isDesktopOpen={desktopOpen}
      isActive={pageId === "videos"}
    />,
    <DrawerItem
      key="contacts"
      to={`/a/${props.accountInfo.id}/contacts`}
      onClick={() => setMobileOpen(false)}
      icon="perm_contact_calendar"
      label="Contacts"
      isDesktopOpen={desktopOpen}
      isActive={pageId === "contacts"}
    />,
  ];

  if (props.userIsAdmin)
    listDrawerItems.push(
      <DrawerItem
        key="integrations"
        to={`/a/${props.accountInfo.id}/integrations`}
        onClick={() => setMobileOpen(false)}
        icon="miscellaneous_services"
        label="Integrations"
        isDesktopOpen={desktopOpen}
        isActive={pageId === "integrations"}
      />
    );

  if (props.isInternalUser) {
    listDrawerItems.push(
      <DrawerItem
        key="admin"
        to={`/a/${props.accountInfo.id}/admin/accounts`}
        onClick={() => setMobileOpen(false)}
        icon="account_circle"
        label="Accounts"
        isDesktopOpen={desktopOpen}
        isActive={pageId === "admin"}
      />
    );
  }

  if (props.isSuperAdmin) {
    listDrawerItems.push(
      <DrawerItem
        key="superAdmins"
        to={`/a/${props.accountInfo.id}/admin/super-admins`}
        onClick={() => setMobileOpen(false)}
        icon="supervised_user_circle"
        label="Super Admins"
        isDesktopOpen={desktopOpen}
        isActive={pageId === "superAdmins"}
      />
    );
  }

  if (!props.userInfo) {
    return (
      <div>
        <MaintenanceSnackbar />
        <NavBar {...props} />
        <LogInBox />
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <CssBaseline />
        <MaintenanceSnackbar />
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleClose}>
          <Alert severity={snackbar.severity} onClose={handleClose}>
            {`${capitalize(snackbar.severity)}: ${snackbar.message}`}
          </Alert>
        </Snackbar>
        <AccountNavbar
          handleDrawerToggle={handleDrawerToggle}
          {...props}
          desktopOpen={desktopOpen}
          setDesktopOpen={setDesktopOpen}
          drawerWidth={drawerWidth}
          handleAccountChange={handleAccountChange}
        />

        <nav className={classes.drawer} aria-label="pages">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

          <MobileDrawer
            container={container}
            theme={theme}
            listDrawerItems={listDrawerItems}
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            pageId={props.pageId}
            calledFromMobile="true"
            desktopOpen={desktopOpen}
            handleAccountChange={handleAccountChange}
            accountInfo={props.accountInfo}
            userInfo={props.userInfo}
            userRole={props.userRole}
            isInternalUser={props.isInternalUser}
            passedSetStateFromApp={props.passedSetStateFromApp}
          />
          <DesktopDrawer
            desktopOpen={desktopOpen}
            classes={classes}
            listDrawerItems={listDrawerItems}
            pageId={props.pageId}
          />
        </nav>

        <main className={styles.contentWrapper}>
          {gratavidStatus.status === "DOWN" && gratavidStatus.headline && (
            <div
              className={`${
                gratavidStatus.isError ? "errorMessageBox" : "notificationMessageBox"
              } ${styles.alert}`}>
              <b>
                {gratavidStatus.headline}{" "}
                {gratavidStatus.isError ? (
                  <Link to={`/a/${props.accountInfo.id}/subscription`}>
                    Click Here To Update
                  </Link>
                ) : (
                  <a
                    href="https://gratavid.com/status"
                    rel="noopener noreferrer"
                    target="_blank">
                    Learn more
                  </a>
                )}
              </b>
            </div>
          )}

          <props.componentPath
            {...props}
            drawerWidth={drawerWidth}
            key={props.componentKey}
            onSnackbar={handleSnackbar}
          />
        </main>
      </div>
    );
  }
}

Account.propTypes = {
  accountInfo: PropTypes.shape({
    active: PropTypes.bool,
  }),
  hideInactiveWarning: PropTypes.bool,
};
