import { ImageSegmenter, FilesetResolver } from "@mediapipe/tasks-vision";

// https://ai.google.dev/edge/mediapipe/solutions/vision/image_segmenter/web_js
const wasmPath = "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.17/wasm";
const modelAssetPath =
  "https://storage.googleapis.com/mediapipe-models/image_segmenter/selfie_segmenter_landscape/float16/latest/selfie_segmenter_landscape.tflite";

const foregroundCanvas = document.createElement("canvas");
const foregroundCtx = foregroundCanvas.getContext("2d");

// must match the input size of the model
const segmentationWidth = 256;
const segmentationHeight = 144;
const segmentationCanvas = document.createElement("canvas");
const segmentationCtx = segmentationCanvas.getContext("2d");

let canvasCtx = null;
let effectType = "blur"; // blur | image | null
let backgroundImage = null;
let video = null;
let isStreamStopped = false;
let imageSegmenter = null;
let clippingMask;

async function setup() {
  const vision = await FilesetResolver.forVisionTasks(wasmPath);
  imageSegmenter = await ImageSegmenter.createFromOptions(vision, {
    baseOptions: {
      modelAssetPath,
      delegate: "CPU", // Using CPU delegation improves the mask but spikes CPU usage VS GPU
    },
    runningMode: "IMAGE", // using IMAGE instead of VIDEO to downsize input frame
    outputCategoryMask: true,
    outputConfidenceMasks: false,
  });
}
setup();

export function segmentBackground(inputVideo, outputCanvas) {
  isStreamStopped = false;
  foregroundCanvas.width = outputCanvas.width;
  foregroundCanvas.height = outputCanvas.height;
  canvasCtx = outputCanvas.getContext("2d", { willReadFrequently: true });
  video = inputVideo;
  initClippingMask(inputVideo);
}

function initClippingMask() {
  const length = segmentationWidth * segmentationHeight * 4;
  clippingMask = new Uint8ClampedArray(length);

  let j = 0;
  for (let i = 0; i < length; ++i) {
    clippingMask[j] = 0;
    clippingMask[j + 1] = 0;
    clippingMask[j + 2] = 0;
    j += 4;
  }
}

function onPlay() {
  if (isStreamStopped) return;
  // downsize video frame for segmentation
  segmentationCtx.drawImage(
    video,
    0,
    0,
    video.videoWidth,
    video.videoHeight,
    0,
    0,
    segmentationWidth,
    segmentationHeight
  );
  const imageData = segmentationCtx.getImageData(
    0,
    0,
    segmentationWidth,
    segmentationHeight
  );
  imageSegmenter.segment(imageData, render);
}

function render(result) {
  setTimeout(() => {
    if (!effectType) {
      canvasCtx.drawImage(video, 0, 0);
      requestAnimationFrame(onPlay);
      return;
    }

    const segmentationMask = result.categoryMask.getAsFloat32Array();

    const width = video.videoWidth;
    const height = video.videoHeight;
    let clip = 0;
    let discard = 255;

    if (effectType === "image") {
      canvasCtx.fillStyle = "white";
      canvasCtx.fillRect(0, 0, width, height);

      // scale image to fit container aka background-size: cover
      const hRatio = width / backgroundImage.width;
      const vRatio = height / backgroundImage.height;
      const ratio = Math.max(hRatio, vRatio);
      const xOffset = (width - backgroundImage.width * ratio) / 2;
      const yOffset = (height - backgroundImage.height * ratio) / 2;

      canvasCtx.drawImage(
        backgroundImage,
        0,
        0,
        backgroundImage.width,
        backgroundImage.height,
        xOffset,
        yOffset,
        backgroundImage.width * ratio,
        backgroundImage.height * ratio
      );
    } else if (effectType === "blur") {
      canvasCtx.drawImage(video, 0, 0);
      clip = 255;
      discard = 0;
    }

    // set alpha channel based on segmentation mask
    let j = 3;
    for (let i = 0; i < segmentationMask.length; ++i) {
      clippingMask[j] = segmentationMask[i] ? clip : discard;
      j += 4;
    }
    const imageData = new ImageData(clippingMask, segmentationWidth, segmentationHeight);
    segmentationCtx.putImageData(imageData, 0, 0);

    // draw mask and scale back up
    foregroundCtx.globalCompositeOperation = "copy";
    foregroundCtx.drawImage(
      segmentationCanvas,
      0,
      0,
      segmentationWidth,
      segmentationHeight,
      0,
      0,
      width,
      height
    );

    // draw video frame in mask
    foregroundCtx.globalCompositeOperation = "source-in";
    foregroundCtx.drawImage(video, 0, 0);
    canvasCtx.drawImage(foregroundCanvas, 0, 0);

    requestAnimationFrame(onPlay);
  }, 20); // Limit to 50fps
}

export function applyBlur(blurIntensity = 10) {
  if (blurIntensity <= 0) {
    effectType = null;
    foregroundCtx.filter = "none";
    return;
  }
  effectType = "blur";
  foregroundCtx.filter = `blur(${blurIntensity}px)`;
}

export function applyImageBackground(image) {
  backgroundImage = image;
  effectType = "image";
  foregroundCtx.filter = "none";
}

export function startSegmenting() {
  isStreamStopped = false;
  onPlay();
}

export function stopSegmenting() {
  isStreamStopped = true;
}
