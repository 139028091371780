import React from "react";
import { IconButton, FormControl, InputLabel, Select } from "@material-ui/core";
import CopyTextInput from "../../../../../util/copyTextInput";
import SelectUser from "../../../../../util/users/selectUser";
import SelectNote from "../../../actions/selectNote";
import { makeStyles } from "@material-ui/core/styles";
import TextInputToApi from "components/util/hooks/input/textInputToApi";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFilledInput-root": {
      background: "white",
      border: "rgb(0, 164, 189) 2px solid",
    },
  },
}));

export default function Query(props) {
  const classes = useStyles();
  let {
    accountInfo,
    successFunc = "doNothing",
    apiKey = "",
    gratavidQueryId = "",
    gratavidQueryName = "",
    assignedTo = "",
    backupAssignedTo = "",
    users = [],
    noteId = "",
    noteTitle = "",
    listCount = 1,
  } = props;

  let newUserArrayWithBB = [...users];
  newUserArrayWithBB.push({
    email: "Row value for column 'gratavidTaskAssigneeUserEmail' in Slate export",
    userUID: "gratavidTaskAssigneeUserEmail",
  });

  let url =
    `${process.env.REACT_APP_API_ENDPOINT}vendors/integrations-endpoint?source=slate&event=handleSlateQueryExport&accountUID=` +
    accountInfo.id +
    "&apiKey=" +
    apiKey +
    "&gratavidQueryId=" +
    gratavidQueryId;

  let noteSelected = (note) => {
    if (note) {
      let { id, title } = note;
      props.updateQuery(gratavidQueryId, "noteId", id);
      props.updateQuery(gratavidQueryId, "noteTitle", title);
    } else {
      props.updateQuery(gratavidQueryId, "noteId", null);
      props.updateQuery(gratavidQueryId, "noteTitle", null);
    }
  };

  return (
    <div className="row col-12" style={{ margin: "10px 0px" }}>
      <div
        style={{
          width: 100,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <div
          style={{
            backgroundColor: "rgb(0, 164, 189)",
            borderRadius: "50%",
            height: 12,
            width: 12,
          }}
        />
      </div>

      <div
        style={{
          flex: 1,
          border: "rgb(0, 164, 189) 2px solid",
          borderRadius: 3,
          backgroundColor: "#E8F4F7",
          padding: 12,
        }}>
        <div className="col-12" style={{ display: "flex" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <h4 style={{ fontSize: 16, fontWeight: 600, color: "grey" }}>
              Query #{listCount}
            </h4>
          </div>
          <div>
            <IconButton
              onClick={() => props.deleteQuery(gratavidQueryId)}
              style={{ marginRight: "8px" }}>
              <i className="material-icons" style={{ fontSize: "18px" }}>
                delete
              </i>
            </IconButton>
          </div>
        </div>

        <div style={{ padding: "30px 30px 60px 30px" }}>
          <div
            style={{
              flex: 1,
              marginTop: 5,
              backgroundColor: "white",
              padding: 8,
              border: "rgb(0, 164, 189) 2px solid",
              borderRadius: 4,
            }}>
            <TextInputToApi
              initialValue={gratavidQueryName}
              multiline={false}
              field="gratavidQueryName"
              apiCall={(field, val) => props.updateQuery(gratavidQueryId, field, val)}
              label="Query Nickname"
              variant="standard"
              helperText="A name you provide to help keep your queries organized. I.e. - 'New Campus Visitors'"
            />
          </div>

          <div
            style={{
              flex: 1,
              marginTop: 15,
              backgroundColor: "white",
              padding: 8,
              border: "rgb(0, 164, 189) 2px solid",
              borderRadius: 4,
            }}>
            <CopyTextInput label="Schedule Export Endpoint URL" copyURL={url} />
          </div>

          <div
            className="col-12"
            style={{ paddingLeft: "70px", margin: "30px 0px 10px 0px" }}>
            <i className="material-icons" style={{ fontSize: "30px", color: "#487CB4" }}>
              arrow_downward
            </i>
            <i className="material-icons" style={{ fontSize: "30px", color: "#487CB4" }}>
              arrow_downward
            </i>
            <i className="material-icons" style={{ fontSize: "30px", color: "#487CB4" }}>
              arrow_downward
            </i>
          </div>

          <div style={{ flex: 1, marginTop: 5, padding: 5 }}>
            <h2 style={{ fontSize: "16px", fontWeight: "bold", marginTop: "3px" }}>
              When data is exported to the above scheduled endpoint from Slate then do
              this in Gratavid...
            </h2>
          </div>

          <div style={{ flex: 1 }}>
            <div style={{ display: "flex", paddingTop: "5px" }}>
              <FormControl
                style={{ width: "175px", paddingRight: "15px" }}
                variant="filled"
                className={classes.root}>
                <InputLabel id="assignedTo">Select Action</InputLabel>
                <Select
                  value={successFunc}
                  native
                  onChange={(e) =>
                    props.updateQuery(gratavidQueryId, "successFunc", e.target.value)
                  }
                  inputProps={{
                    "aria-label": "Filter task by assignee",
                    name: "assignedTo",
                    id: "assignedTo",
                  }}>
                  <option aria-label="None" value="doNothing">
                    Do nothing
                  </option>
                  <option aria-label="Create Task" value="createTask">
                    Create Task
                  </option>
                  <option aria-label="Send Note" value="sendNote">
                    Send Note
                  </option>
                </Select>
              </FormControl>

              <FormControl
                style={{ width: "100%", paddingRight: "15px" }}
                variant="filled"
                className={classes.root}>
                {successFunc === "createTask" && (
                  <SelectUser
                    assignedTo={assignedTo ?? ""}
                    users={newUserArrayWithBB}
                    accountInfo={accountInfo}
                    userSelected={(userUID) =>
                      props.updateQuery(gratavidQueryId, "assignedTo", userUID)
                    }
                    variant="filled"
                    emptyLabel="unassigned"
                    label="Select Assignee"
                  />
                )}
                {successFunc === "sendNote" && (
                  <SelectNote
                    accountInfo={accountInfo}
                    noteId={noteId}
                    noteTitle={noteTitle}
                    itemSelected={noteSelected}
                    variant="outlined"
                  />
                )}
              </FormControl>
            </div>

            <div style={{ display: "flex", paddingTop: "5px" }}>
              <div style={{ width: "80px" }} />

              {successFunc === "createTask" &&
                assignedTo === "gratavidTaskAssigneeUserEmail" && (
                  <div className="row col-12" style={{ marginTop: 15 }}>
                    <div className="col-12">
                      <p style={{ fontSize: 15 }}>
                        If there is no value for 'gratavidTaskAssigneeUserEmail' in the
                        exported row, then assign the task to
                      </p>
                    </div>

                    <div className="col-12" style={{ marginTop: 4 }}>
                      <FormControl
                        style={{ width: "100%", paddingRight: "15px" }}
                        variant="filled"
                        className={classes.root}>
                        <SelectUser
                          assignedTo={backupAssignedTo}
                          users={users}
                          accountInfo={accountInfo}
                          userSelected={(userUID) =>
                            props.updateQuery(
                              gratavidQueryId,
                              "backupAssignedTo",
                              userUID
                            )
                          }
                          variant="filled"
                          emptyLabel="unassigned"
                          label="Select Backup Assignee"
                        />
                      </FormControl>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
