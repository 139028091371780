import React from "react";

export default function GrataStripes() {
  let colorOne = "#a8d0e6";
  let colorTwo = "#24305e";
  let colorThree = "#f8e9a1";

  return (
    <span
      style={{
        position: "fixed",
        top: "-200px",
        left: "0px",
        right: "0px",
        transform: "skewY(-12deg)",
      }}>
      <span className="row stripe" style={{ height: "400px" }}>
        <span className="col-12" style={{ backgroundColor: colorOne }} />
      </span>
      <span className="row stripe">
        <span
          className="hidden-sm-down col-md-2 col-lg-3"
          style={{ backgroundColor: colorOne }}
        />
        <span
          className="col-4 col-md-6 col-lg-3"
          style={{ backgroundColor: colorThree }}
        />
        <span className="col-8 col-md-4 col-lg-6" style={{ backgroundColor: colorOne }} />
      </span>
      <span className="row stripe">
        <span
          className="hidden-sm-down col-md-1 col-lg-2"
          style={{ backgroundColor: colorOne }}
        />
        <span className="col-6 col-md-5 col-lg-4" style={{ backgroundColor: colorTwo }} />
        <span
          className="col-6 col-md-5 col-lg-4"
          style={{ backgroundColor: colorThree }}
        />
        <span
          className="hidden-sm-down col-md-1 col-lg-2"
          style={{ backgroundColor: colorOne }}
        />
      </span>
      <span className="row stripe">
        <span className="col-2 col-lg-3" />
        <span
          className="col-4 col-md-6 col-lg-3"
          style={{ boxShadow: "inset 0 0 4px rgba(0,0,0,.15)" }}
        />
        <span className="col-6 col-md-4 col-lg-6" />
      </span>
      <span className="row stripe" />
      <span className="row stripe">
        <span className="col-8" />
        <span className="col-2" style={{ boxShadow: "inset 0 0 4px rgba(0,0,0,.15)" }} />
        <span className="col-2" />
      </span>
    </span>
  );
}
