import React, { useState } from "react";
import PropTypes from "prop-types";
import SectionItem from "./sectionItem";

export default function Notifications(props) {
  let [notifications, setNotifications] = useState(props.userRole.notifications);

  //get notified for all replies
  //only get notified for replies from notes and task you send

  let sections = [
    {
      settingKey: "replies",
      title: "Replies",
      onlyMeNotifDesc: "Someone replies to a note or task you sent",
      allNotifDesc: "Someone replies to any note or task",
      desc: "Get notified when someone replies to a note, email, or text.",
      settingIcon: "chat",
    },
    {
      settingKey: "newTask",
      title: "New Task",
      onlyMeNotifDesc: "A new task is created and assigned to you",
      allNotifDesc: "A new task is created",
      desc: "Get notified when a task is created through an integration (ie - new donation creates a task).",
      settingIcon: "list",
    },
    {
      settingKey: "noteWatched",
      title: "Task Video Watched",
      onlyMeNotifDesc: "A task you sent is watched all the way through",
      allNotifDesc: "Any task is watched all the way through",
      desc: "Get notified when someone watches a task video.",
      settingIcon: "play_circle_outline",
    },
    {
      settingKey: "videoRequest",
      title: "Video Request Uploaded",
      onlyMeNotifDesc: "A video you requested is finished uploading",
      allNotifDesc: "Any video request is finished uploading",
      desc: "Get notified when someone uploads a video request.",
      settingIcon: "backup",
    },
    {
      settingKey: "bounce",
      title: "Email Bounced",
      onlyMeNotifDesc: "An email you sent bounces after sending a task",
      allNotifDesc: "Any email bounces after sending a task",
      desc: "Get notified when a contact's email bounces after sending a task.",
      settingIcon: "cancel_schedule_send",
    },
    {
      settingKey: "unsubscribe",
      title: "Unsubscribes (Opt-outs)",
      onlyMeNotifDesc:
        "A contact opts-out of email or text after receiving a message you sent",
      allNotifDesc: "Any opt-out occurs",
      desc: "Get notified when a contact unsubscribes (opts-out) from email or text.",
      settingIcon: "unsubscribe",
    },
  ];

  return (
    <div className="row col-12" style={{ marginTop: "5px" }}>
      {sections.map((current) => {
        let settingNotifications = notifications[current.settingKey]
          ? notifications[current.settingKey]
          : {};
        return (
          <SectionItem
            current={current}
            key={current.settingKey}
            notifications={notifications}
            setNotifications={setNotifications}
            settingNotifications={settingNotifications}
            userInfo={props.userInfo}
            accountInfo={props.accountInfo}
          />
        );
      })}
    </div>
  );
}

Notifications.propTypes = {
  userInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};
