import { useEffect, useState } from "react";
import { getAnalytics } from "services/api.service";

export const useGetAccountData = () => {
  let [lastXDays, setLastXDays] = useState(7);
  let [showUniqueData, setShowUniqueData] = useState(false);
  let [errorMessage, setErrorMessage] = useState(null);
  let [replies, setReplies] = useState([]);
  let [summaryBoxTotals, setSummaryBoxTotals] = useState({});
  let [uniqueSummaryBoxTotals, setUniqueSummaryBoxTotals] = useState({});
  let [dataByShortDateArray, setDataByShortDateArray] = useState("loading");
  let [uniqueDataByShortDateArray, setUniqueDataByShortDateArray] = useState("loading");
  let [dataByNoteId, setDataByNoteId] = useState({});
  let [uniqueDataByNoteId, setUniqueDataByNoteId] = useState({});

  let getData = async (tempLastXdays) => {
    try {
      setDataByShortDateArray("loading");
      let timezoneDifference = new Date().getTimezoneOffset() / 60;
      //If you lived in -01:00 zone, UTC is 60 minutes ahead of you, or +60. Therefore, we need to multiple times -1
      timezoneDifference = timezoneDifference * -1;
      let result = await getAnalytics(tempLastXdays, timezoneDifference, null);
      let {
        summaryBoxTotals,
        uniqueSummaryBoxTotals,
        dataByNoteId,
        uniqueDataByNoteId,
        dataByShortDateArray,
        uniqueDataByShortDateArray,
        replies,
      } = result;
      setSummaryBoxTotals(summaryBoxTotals);
      setUniqueSummaryBoxTotals(uniqueSummaryBoxTotals);
      setDataByShortDateArray(dataByShortDateArray);
      setUniqueDataByShortDateArray(uniqueDataByShortDateArray);
      setDataByNoteId(dataByNoteId);
      setUniqueDataByNoteId(uniqueDataByNoteId);
      setReplies(replies);
    } catch (err) {
      console.error(err);
      setErrorMessage(err.message);
    }
  };

  useEffect(() => {
    getData(lastXDays);
  }, [lastXDays]);

  let updateDays = (e) => {
    let tempLastXdays = +e.target.value || 0;
    if (tempLastXdays) {
      setLastXDays(tempLastXdays);
      setDataByShortDateArray("loading");
    }
  };

  let handleUniqueToggle = (e) => {
    //no need to load new data
    let checked = e.target.checked;
    setShowUniqueData(checked);
  };

  //this will control wether we are showing normal or unique data
  let finalsummaryBoxTotals = showUniqueData ? uniqueSummaryBoxTotals : summaryBoxTotals;
  let finaldataByShortDateArray = showUniqueData
    ? uniqueDataByShortDateArray
    : dataByShortDateArray;
  let finaldataByNoteId = showUniqueData ? uniqueDataByNoteId : dataByNoteId;

  return [
    lastXDays,
    setLastXDays,
    showUniqueData,
    setShowUniqueData,
    errorMessage,
    finalsummaryBoxTotals,
    finaldataByShortDateArray,
    handleUniqueToggle,
    updateDays,
    finaldataByNoteId,
    replies,
  ];
};
