export default function milestoneTransactionTierOptions(currencyLabel) {
  if (!currencyLabel) currencyLabel = "$";
  let amountsToShow = [
    500, 750, 1000, 1500, 2000, 2500, 3000, 4000, 5000, 10000, 20000, 25000, 30000, 40000,
    50000, 75000, 100000, 250000, 500000, 750000, 1000000, 2000000, 5000000,
  ];
  let options = amountsToShow.map((current) => {
    let numberWithCommas = current.toLocaleString("en-US");
    return {
      id: current,
      value: current.toString(),
      label: currencyLabel + numberWithCommas,
    };
  });

  return options;
}
