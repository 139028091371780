import React, { useState } from "react";
import ContactItem from "./contactItem";
import AddEditContactDialog from "../addEdit/addEditContactDialog";

export default function ListSelectedContactsWith(props) {
  let { accountInfo, customContactProperties } = props;
  let [editContactSelected, setEditContactSelected] = useState(null);

  let handleEditContactClick = (e, contact) => {
    e.stopPropagation();
    setEditContactSelected(contact);
  };

  let contactsHTML = props.taskContacts.length
    ? props.taskContacts.map((taskContact) => {
        return (
          <ContactItem
            handleEditContactClick={handleEditContactClick}
            currentItem={taskContact}
            key={taskContact.contact.id}
            deleteContactFromTask={props.deleteContactFromTask}
          />
        );
      })
    : null;

  return (
    <>
      <div
        className="col-12"
        style={{
          border: "1px solid #cbd6e2",
          backgroundColor: "#f5f8fa",
          padding: "5px",
          cursor: "pointer",
          minHeight: "42px",
        }}
        onClick={() => props.setShowSearchContacts(true)}>
        {contactsHTML}
      </div>

      {editContactSelected && (
        <AddEditContactDialog
          accountInfo={accountInfo}
          contactData={editContactSelected}
          customContactProperties={customContactProperties}
          closeDialog={() => setEditContactSelected(null)}
          taskContacts={props.taskContacts}
          setTaskContacts={props.setTaskContacts}
          userIsAdmin={props.userIsAdmin}
        />
      )}
    </>
  );
}
