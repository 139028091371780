import React, { useState } from "react";
import Badge from "@material-ui/core/Badge";
import ListFolders from "./listFolders";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";

export default function FilterSearchFolders(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  let open = Boolean(anchorEl);

  return (
    <>
      <div
        style={{ width: "45px", height: "45px", margin: "0px 15px" }}
        className="align-items-center">
        <IconButton
          style={{ padding: "12px" }}
          onClick={(e) => setAnchorEl(e.currentTarget)}>
          <Badge
            color="secondary"
            badgeContent=" "
            size="large"
            invisible={props.foldersFilter.length === 0}>
            <i className="material-icons" style={{ fontSize: "22px" }}>
              filter_list
            </i>
          </Badge>
        </IconButton>
      </div>

      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}>
        <ListFolders
          accountId={props.accountInfo.id}
          indexName={props.indexName}
          foldersFilter={props.foldersFilter}
          setFoldersFilter={props.setFoldersFilter}
        />
      </Popover>
    </>
  );
}
