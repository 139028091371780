import React, { useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import LoadingButton from "../../../../../../util/buttons/loadingbutton";
import LoadingSwitch from "../../../../../../util/buttons/loadingSwitch";
import DefaultAction from "../../../../actions/action";
import { updateAccountIntegrations } from "services/api.service";

export default function NewUserRegistration(props) {
  let { eventData = {}, users } = props;
  eventData = eventData || {};
  //this allows front end to assign this task to whoever the assigned person is in blackbaud. our logic check on backend
  let initialActions = eventData.actions || {};
  let [actions, setActions] = useState(initialActions);
  let [state, setState] = useState({});
  let initialActive = eventData.active ? eventData.active : false;
  let [active, setActive] = useState(initialActive);
  let [open, setOpen] = useState(false);

  let saveActions = async () => {
    try {
      setState({ buttonState: "loading" });
      const path = `endpoint/graduwayEngage/events/newUserRegistration/actions`;
      const value = actions;
      await updateAccountIntegrations([{ path, value }]);

      setState({ buttonState: "saved" });
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  const handleChange = (event, isExpanded) => {
    setOpen(isExpanded ? true : false);
  };

  const handleSwitch = async (e) => {
    e.stopPropagation();
    let newActiveValue = e.target.checked;

    try {
      setState({ buttonState: "loading" });

      let updates = [];

      updates.push({
        path: `endpoint/graduwayEngage/events/newUserRegistration`,
        value: newActiveValue
          ? {
              active: true,
            }
          : null,
      });

      await updateAccountIntegrations(updates);

      setState({});
      setActive(newActiveValue);
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  return (
    <Accordion
      expanded={open}
      onChange={handleChange}
      style={{
        cursor: "pointer",
        borderRadius: "8px",
        marginTop: "25px",
        width: "100%",
      }}>
      <AccordionSummary
        expandIcon={<i className="material-icons">expand_less</i>}
        aria-controls="panel1bh-content"
        id="panel1bh-header">
        <div style={{ width: "80px", display: "flex", alignItems: "center" }}>
          <LoadingSwitch
            checked={active}
            handleSwitch={handleSwitch}
            buttonState={state && state.buttonState}
          />
        </div>
        <div style={{ flex: 1 }}>
          <h2>New User Registration</h2>
          <span className="greyDesc">
            Create a task or send a note when a user signs up in your Graduway platform.
            Please note: the rule is triggered only when the user's account is approved on
            the platform.
          </span>
        </div>
      </AccordionSummary>
      <AccordionDetails className="row col-12" style={{ cursor: "auto" }}>
        <div className="row col-12">
          <div
            style={{
              width: "70px",
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              marginTop: "5px",
            }}>
            <span className="circleStep">1</span>
          </div>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <h2 style={{ fontSize: "16px", fontWeight: "bold", marginTop: "3px" }}>
              When a user registers on your Graduway Platform...
            </h2>
          </div>
        </div>

        <DefaultAction
          {...props}
          users={users}
          defaultAction={true}
          currentAction={actions}
          actions={actions}
          setActions={setActions}
        />

        {state.errorMessage && (
          <div className="errorMessageBox">
            <b>{state.errorMessage}</b>
          </div>
        )}

        <div className="col-12 justify-content-center" style={{ padding: "20px 0px" }}>
          <LoadingButton
            variant="contained"
            color="primary"
            buttonState={state.buttonState}
            savedLabel={"Saved"}
            label={"Save"}
            clickedRegularButton={saveActions}
            clickedSavedButton={() => {
              setState({});
            }}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
