import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import {
  TextField,
  Dialog,
  DialogActions,
  IconButton,
  DialogContent,
} from "@material-ui/core";
import SelectTags from "../../../util/tags/selectTags";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { addNote } from "services/api.service";
import LoadingButton from "components/util/buttons/loadingbutton";

function AddNoteDialog(props) {
  const [state, setState] = useState({});
  const [title, setTitle] = useState("");
  const [selectedFoldersArray, setSelectedFoldersArray] = useState([]);
  const history = useHistory();

  async function createNote(e) {
    e.preventDefault();
    try {
      if (title === "") throw new Error("Insert note title");

      //only add the user selected folders to our firebase data
      const folders = selectedFoldersArray.map((current) => {
        return current.id;
      });

      setState({ buttonState: "loading" });
      //if videoId exist, it was called from videos "Create Note From Video" button
      //if contactGroupId exist, it was called from videos "Create Note With Contacts" button
      const postData = {
        title,
        folders,
        videoId: props.videoId,
        shareViaEmail: true,
        showShare: true,
      };
      const newNote = await addNote(postData);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: "gv.addNote" });

      history.push(`/a/${props.accountId}/notes/` + newNote.id);
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.log(errorMessage);
      setState({ errorMessage });
    }
  }

  if (!props.addNewDialogOpen) return null;

  return (
    <Dialog
      fullScreen={props.fullScreen}
      open={props.addNewDialogOpen}
      onClose={() => props.setAddNewDialogOpen(false)}
      aria-labelledby="add note dialog">
      <DialogContent
        style={{
          height: props.fullScreen ? "auto" : "550px",
          width: props.fullScreen ? "auto" : "600px",
        }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "18px" }}>Create note</span>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton
              aria-label="close"
              onClick={() => props.setAddNewDialogOpen(false)}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        <div className="col-12" style={{ padding: "10px 0px" }}>
          <TextField
            variant="outlined"
            id="noteTitle"
            placeholder="e.g. Donor Appreciation"
            label="Note Title"
            style={{ width: "100%" }}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

        <div className="col-12" style={{ marginTop: "15px" }}>
          <SelectTags
            accountId={props.accountId}
            selectedFoldersArray={selectedFoldersArray}
            setSelectedFoldersArray={setSelectedFoldersArray}
            indexName="notes"
          />
        </div>
      </DialogContent>

      {state.errorMessage && (
        <div className="errorMessageBox">
          <b>{state.errorMessage}</b>
        </div>
      )}

      <DialogActions style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
        <LoadingButton
          variant="contained"
          color="primary"
          buttonState={state.buttonState}
          savedLabel={"View Note"}
          label={"Create Note"}
          clickedRegularButton={createNote}
          clickedSavedButton={() => {
            window.open(`/a/${props.accountId}/notes/` + state.noteId);
          }}
        />
      </DialogActions>
    </Dialog>
  );
}
AddNoteDialog.propTypes = {
  accountId: PropTypes.string,
  videoId: PropTypes.string,
  addNewDialogOpen: PropTypes.bool.isRequired,
  setAddNewDialogOpen: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool,
};

export default withMobileDialog()(AddNoteDialog);
