import React from "react";
import PropTypes from "prop-types";
import SendTestDialog from "./sendTestDialog";
import SendNowButton from "./sendNowButton";

export default function SendNote(props) {
  return (
    <div
      className="row col-12 adSectionBox"
      style={{ padding: "20px", margin: "10px 0px 20px 0px" }}>
      {props.errorMessage && (
        <div className="errorMessageBox">
          <b>{props.errorMessage}</b>
        </div>
      )}

      {props.videosAreProcessing ? (
        <div>Please wait to send. Videos processing...</div>
      ) : (
        <div className="col-12 row ">
          <div className="col-6">
            <SendTestDialog {...props} />
          </div>
          <div className="col-6 justify-content-end">
            <SendNowButton {...props} />
          </div>
        </div>
      )}
    </div>
  );
}

SendNote.propTypes = {
  errorMessage: PropTypes.string,
  videosAreProcessing: PropTypes.bool.isRequired,
};
