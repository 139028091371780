import React, { useState } from "react";
import AddSingleTask from "./addSingleTask";
import AddBulkStepper from "./bulk/addBulkStepper";
import IconButton from "@material-ui/core/IconButton";
import Radio from "@material-ui/core/Radio";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";

export default function AddTask(props) {
  const [expanded, setExpanded] = useState("addSingle");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="row col-12" style={{ minWidth: "250px" }}>
      <div className="col-10 align-items-center">
        <h2>Add Task</h2>
      </div>

      <div className="col-2 justify-content-end">
        <IconButton
          aria-label="Delete"
          onClick={() => {
            props.setAddNewDialogOpen(false);
          }}>
          <i className="material-icons">close</i>
        </IconButton>
      </div>

      <div className="col-12 row">
        <Accordion
          className="col-12"
          expanded={expanded === "addSingle"}
          onChange={handleChange("addSingle")}>
          <AccordionSummary
            style={{ padding: "10px 15px 10px 10px" }}
            expandIcon={<i className="material-icons">expand_more</i>}
            aria-controls="panel2bh-content"
            id="panel2bh-header">
            <div style={{ display: "flex" }}>
              <div style={{ width: "40px" }} className="align-items-center">
                <Radio
                  style={{ width: "40px" }}
                  checked={expanded === "addSingle"}
                  value="pro"
                  color="primary"
                  aria-label="contact form"
                />
              </div>
              <div style={{ flex: 1 }}>
                <h2>Add Single Task</h2>
                <p style={{ fontSize: "14px" }}>Manually add task one at a time.</p>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <AddSingleTask {...props} />
          </AccordionDetails>
        </Accordion>

        <Accordion
          className="col-12"
          expanded={expanded === "addBulk"}
          onChange={handleChange("addBulk")}>
          <AccordionSummary
            style={{ padding: "10px 15px 10px 10px" }}
            expandIcon={<i className="material-icons">expand_more</i>}
            aria-controls="panel3bh-content"
            id="panel3bh-header">
            <div style={{ display: "flex" }}>
              <div style={{ width: "40px" }} className="align-items-center">
                <Radio
                  style={{ width: "40px" }}
                  checked={expanded === "addBulk"}
                  value="pro"
                  color="primary"
                  aria-label="contact form"
                />
              </div>
              <div style={{ flex: 1 }}>
                <h2>Bulk Upload</h2>
                <p style={{ fontSize: "14px" }}>Bulk upload task from a csv file.</p>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <AddBulkStepper {...props} />
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
