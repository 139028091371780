import React from "react";
import PropTypes from "prop-types";
import QuestionToLink from "../../../util/explainers/questionToLink";
import styles from "./integration-header.module.css";

export default function IntegrationHeader(props) {
  const failureDate = props.connectionInfo?.refreshTokenLastFailureDate
    ? new Date(props.connectionInfo.refreshTokenLastFailureDate).toLocaleDateString()
    : "";

  const status = failureDate
    ? { icon: "cancel", color: "red" }
    : { icon: "check_circle", color: "green" };

  const { header, id, desc, logoSrc } = props.integrationDetails;

  return (
    <div className={styles.wrapper}>
      <img src={logoSrc} alt="" aria-hidden="true" className={styles.logo} />
      <div>
        <div className={styles.row}>
          <h1>{header}</h1>
          <QuestionToLink id={id} desc={desc} />
        </div>
        <div className={styles.row}>
          <i
            className="material-icons"
            aria-hidden="true"
            style={{ color: status.color }}>
            {status.icon}
          </i>
          <span>
            {failureDate
              ? `Connection failed on ${failureDate}. Please reconnect your account.`
              : "Connected."}
          </span>

          {props.children}
        </div>
      </div>
    </div>
  );
}

IntegrationHeader.propTypes = {
  integrationDetails: PropTypes.shape({
    header: PropTypes.string,
    id: PropTypes.string,
    desc: PropTypes.string,
    logoSrc: PropTypes.string,
  }),
  connectionInfo: PropTypes.object,
  children: PropTypes.node,
};
