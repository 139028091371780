import React from "react";
import Dialog from "@material-ui/core/Dialog";
import AddContactDialog from "../../../contacts/addContact/addContactDialog";
export default function AddContacts(props) {
  return (
    <Dialog
      fullScreen={true}
      open={props.addNewDialogOpen}
      onClose={() => props.passedSetState("addNewDialogOpen", false)}
      aria-label="add contact dialog">
      <div style={{ width: "100%", padding: "20px" }}>
        <div className="row col-12" style={{ minWidth: "250px" }}>
          <AddContactDialog
            {...props}
            calledFromNotes={true}
            addNewDialogOpen={props.addNewDialogOpen}
            bulkContactsAdded={() => {
              return null;
            }}
          />
        </div>
      </div>
    </Dialog>
  );
}
