import React from "react";
import { Tooltip, CircularProgress, Button } from "@material-ui/core";

function LoadingButton({
  buttonState,
  type = "button",
  disabled = false,
  disabledReason = null,
  color = "default",
  size = "medium",
  id,
  icon = null,
  iconColor = "black",
  label = "",
  savedLabel = "",
  clickedSavedButton,
  fullWidth = false,
  variant = "contained",
  clickedRegularButton,
}) {
  if (buttonState === "saved") {
    return (
      <Button
        type="button"
        color={color}
        style={{ backgroundColor: "green" }}
        variant={variant}
        fullWidth={fullWidth}
        onClick={(event) => clickedSavedButton(event)}>
        <i className="material-icons" style={{ color: "white" }}>
          check_circle
        </i>
        {savedLabel}
      </Button>
    );
  } else if (disabled && disabledReason) {
    return (
      <Tooltip title={disabledReason}>
        <span>
          <Button
            color={color}
            id={id}
            size={size}
            disabled={disabled}
            type={type}
            variant={variant}
            fullWidth={fullWidth}
            onClick={(event) => clickedRegularButton(event)}>
            {label}
            {icon && (
              <i
                className="material-icons"
                style={{ color: iconColor, marginLeft: "3px" }}>
                {icon}
              </i>
            )}
          </Button>
        </span>
      </Tooltip>
    );
  } else if (buttonState === "loading") {
    return (
      <Button type="button" color={color} variant={variant} fullWidth={fullWidth}>
        <CircularProgress size={30} color="inherit" />
      </Button>
    );
  } else {
    return (
      <Button
        color={color}
        id={id}
        size={size}
        disabled={disabled}
        type={type}
        variant={variant}
        fullWidth={fullWidth}
        onClick={(event) => clickedRegularButton(event)}>
        {label}
        {icon && (
          <i className="material-icons" style={{ color: iconColor, marginLeft: "3px" }}>
            {icon}
          </i>
        )}
      </Button>
    );
  }
}

export default LoadingButton;
